import React, { ChangeEvent, useCallback, useEffect } from 'react';
import s from '../Tabs.module.css';
import ParameterForm, {
  ShowHistoryCallback,
} from 'views/Parameters/components/form/ParameterForm';
import StoreStructureItemPicker from 'components/StoreStructureItemPicker';
import { StoreStructureType } from 'core/commonTypes';
import Select, { Option } from '@ingka/select';
import { useStoreStructureSearchParamState } from 'hooks/storeStructure';
import {
  useDefaultDivision,
  useDivisionDefaults,
  useHfbDefaults,
  useMutations,
  useQueries,
  useStoreDefaults,
} from 'views/Parameters/hooks/defaultParameters';
import { FormId, HistoryFilter } from 'views/Parameters/redux/types';
import { useSingleSearchParamState } from 'hooks/routing';
import pm from 'views/Parameters/parametersMessages';
import { useIntl } from 'react-intl';
import ParameterLoadingMessages from 'views/Parameters/components/messages/ParameterLoadingMessages';
import { useParameterHistory } from 'views/Parameters/hooks/history';
import ChangeHistory from 'views/Parameters/components/modal/ChangeHistory';
import { useRouteBuCode } from 'hooks/buCode';

const DefaultParametersTab: React.FC = () => {
  const defaultDivision = useDefaultDivision();

  const { divisionId, onStructureItemPick } = useStoreStructureSearchParamState(
    { defaultDivisionId: defaultDivision.id }
  );

  const [hfbId, setHfbId] = useSingleSearchParamState('hfb');

  const queries = useQueries({ divisionId, hfbId });

  const { getDefaultParametersQuery } = queries;

  useEffect(() => {
    const { hfb = [] } = getDefaultParametersQuery.data ?? {};
    if (hfbId === null && hfb.length > 0) {
      setHfbId(hfb[0].id);
    }
  }, [hfbId, getDefaultParametersQuery.data, setHfbId]);

  const mutations = useMutations();

  const { defaults: storeDefaults, onSubmit: onStoreFormSubmit } =
    useStoreDefaults(queries, mutations);

  const {
    defaults: divisionDefaults,
    isFormSubmitting: isDivisionFormSubmitting,
    onFormChange: onDivisionFormChange,
    onSubmit: onDivisionFormSubmit,
  } = useDivisionDefaults(queries, mutations, divisionId);

  const {
    defaults: hfbDefaults,
    hfbOptions,
    isFormSubmitting: isHfbFormSubmitting,
    onFormChange: onHfbFormChange,
    onSubmit: onHfbFormSubmit,
  } = useHfbDefaults(queries, mutations, hfbId);

  const onHfbChange = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const { value } = ev.target;
      if (hfbId !== value) {
        setHfbId(value);
      }
    },
    [hfbId, setHfbId]
  );

  const { historySelection, showHistory, closeHistory } = useParameterHistory();

  const buCode = useRouteBuCode();
  const onShowStoreHistory: ShowHistoryCallback = useCallback(
    parameter => {
      showHistory({
        filter: HistoryFilter.Store,
        filterValue: buCode,
        parameter,
      });
    },
    [buCode, showHistory]
  );

  const onShowDivisionHistory: ShowHistoryCallback = useCallback(
    parameter => {
      showHistory({
        filter: HistoryFilter.Division,
        filterValue: divisionId,
        parameter,
      });
    },
    [divisionId, showHistory]
  );

  const onShowHfbHistory: ShowHistoryCallback = useCallback(
    parameter => {
      showHistory({ filter: HistoryFilter.Hfb, filterValue: hfbId, parameter });
    },
    [hfbId, showHistory]
  );

  const { $t } = useIntl();

  return (
    <>
      <ParameterLoadingMessages
        isLoading={queries.isLoading}
        isError={queries.isError}
      />
      {queries.isSuccess && (
        <div className={s.grid}>
          <fieldset>
            <legend>{$t(pm.storeLevelDefaultsTitle)}</legend>
            <ParameterForm
              fields={storeDefaults}
              formId={FormId.STORE}
              onShowHistory={onShowStoreHistory}
              onSubmit={onStoreFormSubmit}
            />
          </fieldset>
          <fieldset>
            <legend>{$t(pm.divisionLevelDefaultValues)}</legend>
            <StoreStructureItemPicker
              depth={StoreStructureType.Division}
              divisionId={divisionId}
              onStructureItemPick={onStructureItemPick}
              disabled={isDivisionFormSubmitting}
            />
            <ParameterForm
              fields={divisionDefaults}
              formId={FormId.DIVISION}
              onShowHistory={onShowDivisionHistory}
              onChange={onDivisionFormChange}
              onSubmit={onDivisionFormSubmit}
            />
          </fieldset>
          <fieldset>
            <legend>{$t(pm.hfbLevelDefaultValues)}</legend>
            <Select
              id="hfb"
              label={$t(pm.hfb)}
              title={$t(pm.hfb)}
              onChange={onHfbChange}
              value={hfbId ?? ''}
              hintText={null}
              disabled={isHfbFormSubmitting}
            >
              {hfbOptions.map(option => (
                <Option
                  key={option.value}
                  value={option.value}
                  name={option.name}
                />
              ))}
            </Select>
            <ParameterForm
              fields={hfbDefaults}
              formId={FormId.HFB}
              onShowHistory={onShowHfbHistory}
              onChange={onHfbFormChange}
              onSubmit={onHfbFormSubmit}
            />
          </fieldset>
        </div>
      )}
      {historySelection && (
        <ChangeHistory
          selection={historySelection}
          closeHistory={closeHistory}
        />
      )}
    </>
  );
};
export default DefaultParametersTab;
