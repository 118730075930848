export const availableLanguages = [
  'cs-CZ',
  'da-DK',
  'de-DE',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IN',
  'en-US',
  'es-ES',
  'fi-FI',
  'fr-BE',
  'fr-CA',
  'fr-FR',
  'hr-HR',
  'hu-HU',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nl-BE',
  'nl-NL',
  'no-NO',
  'pl-PL',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'sk-SK',
  'sl-SI',
  'sr-RS',
  'sv-SE',
  'uk-UA',
  'zh-CN',
];

export const findBestFitLocale: (string) => string = locale => {
  const bestFit = availableLanguages.find(l => locale === l);
  if (bestFit) return bestFit;
  const regionlessLocale = locale.split('-')[0];
  const justLangFit = availableLanguages.find(l =>
    l.startsWith(regionlessLocale)
  );
  if (justLangFit) return justLangFit;
  return 'en-GB';
};
