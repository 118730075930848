import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import {
  Auth0ContextInterface,
  initialContext,
  User,
  RedirectLoginOptions,
} from '@auth0/auth0-react';
import { Config } from 'core/commonTypes';
import {
  GetTokenSilentlyOptions,
  GetTokenSilentlyVerboseResponse,
} from '@auth0/auth0-spa-js';
import { request } from 'core/ApiService';

export const MockAuth0Context =
  createContext<Auth0ContextInterface>(initialContext);

interface reducerState {
  isLoading: boolean;
  isAuthenticated: boolean;
  user?: User;
  error?: Error;
}

const mockUserReducer = (state: reducerState, action: any) => {
  return {
    ...state,
    ...action.payload,
  };
};

const MockAuth0Provider = ({
  children,
  ssoDomain,
}: PropsWithChildren<Config>) => {
  const [state, dispatch] = useReducer(mockUserReducer, {
    isLoading: true,
    isAuthenticated: false,
    __mockState: null,
  });
  // const [mockState, setMockState] = useState(null);

  useEffect(() => {
    const fetchMockDetails = async () => {
      return await request(ssoDomain, null, { noAuth: true });
    };
    if (!state.__mockState) {
      fetchMockDetails()
        .then(details => {
          // setMockState(details);
          // @ts-ignore
          if (window.__mockIsLoggedIn) {
            dispatch({
              payload: {
                isLoading: false,
                isAuthenticated: true,
                user: details.user,
                __mockState: details,
              },
            });
          } else {
            dispatch({
              payload: {
                isLoading: false,
                __mockState: details,
              },
            });
          }
        })
        // eslint-disable-next-line no-console
        .catch(err => console.error(err));
    }
  }, [state.__mockState, ssoDomain]);

  const loginWithRedirect = useCallback(
    async (options?: RedirectLoginOptions) => {
      if (!state.__mockState?.user) {
        window.location.assign('/sso/mock');
        return;
      }
      dispatch({
        payload: {
          isLoading: false,
          isAuthenticated: true,
          user: state.__mockState.user,
        },
      });
    },
    [state.__mockState?.user]
  );

  const getAccessTokenSilently = useCallback(
    async (options?: GetTokenSilentlyOptions): Promise<any> => {
      const ret: GetTokenSilentlyVerboseResponse = {
        access_token: state.__mockState.access_token,
        id_token: '',
        expires_in: 30,
        scope: '',
      };
      if (options?.detailedResponse) return ret;
      return state.__mockState.access_token;
    },
    [state.__mockState?.access_token]
  );

  const context: Auth0ContextInterface = useMemo(
    () => ({
      ...initialContext,
      ...state,
      getAccessTokenSilently,
      loginWithRedirect,
    }),
    [state, getAccessTokenSilently, loginWithRedirect]
  );

  return (
    <MockAuth0Context.Provider value={context}>
      {children}
    </MockAuth0Context.Provider>
  );
};

export default MockAuth0Provider;
