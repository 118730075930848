import { createApi } from '@reduxjs/toolkit/query/react';
import { userApiQuery } from 'core/rtkQuery';
import { hasApiAccessToken } from 'core/apiConfig';

export type GetStoresRequest = {
  userId: string;
};

export type StoreOption = {
  store: StoreDto;
  region: string;
  country: string;
};

export type GetStoresResponse = {
  /**
   * Output from the API
   */
  regions: RegionDto[];

  /**
   * Stores names by buCode.
   */
  storeNames: { [buCode: string]: string };

  /**
   * Flattened list of store options.
   */
  storeOptions: StoreOption[];
};

export type GetPrivilegesRequest = {
  userId: string;
  buCode: string;
};

export type GetPrivilegesResponse = {
  buCode: string;
  privileges: Privilege[];
};

export enum Privilege {
  STORE_ACCESS = 'STORE_ACCESS',
  READ_STORE_STRUCTURE = 'READ_STORE_STRUCTURE',
  UPDATE_STORE_STRUCTURE = 'UPDATE_STORE_STRUCTURE',
  READ_SALES_LOCATION = 'READ_SALES_LOCATION',
  UPDATE_PARAMETERS = 'UPDATE_PARAMETERS',
  UPDATE_ITEM_ASSIGNED_SALES_LOCATION = 'UPDATE_ITEM_ASSIGNED_SALES_LOCATION',
  BULK_UPDATE_ITEM_ASSIGNED_SALES_LOCATION = 'BULK_UPDATE_ITEM_ASSIGNED_SALES_LOCATION',
  READ_REPORTS = 'READ_REPORTS',
  DEVELOPER = 'DEVELOPER',
}

export type BaseDto = {
  code: string;
};

export type RegionDto = BaseDto & {
  countries: CountryDto[];
};

export type CountryDto = BaseDto & {
  stores: StoreDto[];
};

export type StoreDto = BaseDto & {
  name: string;
};

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: userApiQuery(),

  endpoints: builder => ({
    getStores: builder.query<GetStoresResponse, GetStoresRequest>({
      query: ({ userId }) => ({
        url: userId && hasApiAccessToken() ? `/stores` : null,
        params: {
          userId,
        },
      }),

      transformResponse(response: { regions: RegionDto[] }): GetStoresResponse {
        if (response === null) {
          return null;
        }

        const { regions } = response;

        const storeNames = Object.fromEntries(
          regions.flatMap(region =>
            region.countries.flatMap(country =>
              country.stores.map(store => [store.code, store.name])
            )
          )
        );

        const storeOptions = regions.flatMap(region =>
          region.countries.flatMap(country =>
            country.stores.map(store => ({
              store,
              region: region.code,
              country: country.code,
            }))
          )
        );

        return {
          regions,
          storeNames,
          storeOptions,
        };
      },
    }),

    getPrivileges: builder.query<GetPrivilegesResponse, GetPrivilegesRequest>({
      query: ({ userId, buCode }) => {
        return {
          url: userId && buCode && hasApiAccessToken() ? `/privileges` : null,
          params: {
            userId,
            buCode,
          },
        };
      },
    }),
  }),
});

export const { useGetStoresQuery, useGetPrivilegesQuery } = userApi;

export default userApi;
