import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  encodeQueryString,
  selectSearchParamsState,
  setRouteSearchParams,
} from 'core/redux/searchParamsSlice';
import { useAppDispatch } from 'hooks/redux';
import { useSelector } from 'react-redux';
import { useRouteBuCode } from 'hooks/buCode';

/**
 * Used for synchronizing state between Router paths and Tabs component state. I.e. pages where the selected tab is
 * considered to be part of the route, for example the StoreStructure and Parameters pages.
 *
 * @param pathPattern The path pattern for the pages contained inside a Tabs component.
 * The pattern should contain a route param representing the tab ID. Typically, this param is named `tabId`.
 * @param key The name of the param used in `pathPattern`.
 *
 * @return A 2-element tuple containing the current value of the tab ID and an `onChange` callback.
 * These can be passed as-is to the Tabs component.
 *
 * @see App
 */
export function useTabPathRoute(
  pathPattern: string,
  key: string
): [string, (value: string) => void] {
  const match = useMatch(pathPattern);
  const value = match?.params[key];

  const navigate = useNavigate();

  const searchParamsState = useSelector(selectSearchParamsState);

  const buCode = useRouteBuCode();

  const onChange = useCallback(
    (value: string) => {
      const pathname = pathPattern
        .replace(`:${key}`, encodeURIComponent(value))
        .replace(':buCode', encodeURIComponent(buCode));
      const searchParams = searchParamsState[pathname];
      const href = encodeQueryString(pathname, searchParams);
      return navigate(href, { replace: true });
    },
    [pathPattern, key, buCode, searchParamsState, navigate]
  );

  return [value, onChange];
}

export type SearchParamValue = string | null;

export type SearchParamValues = {
  [key: string]: SearchParamValue;
};

/**
 * Convenience type for defining hooks that use a single query param.
 */
export type UseSingleSearchParamState = [
  SearchParamValue,
  (value: SearchParamValue) => void
];

/**
 * Synchronizes Router SearchParam state.
 *
 * This hook can be used for maintaining a set of dependent query parameters.
 *
 * @param defaultValues The default values, keyed by query param name.
 * @return A 2-element tuple containing the current values and a setter.
 */
export function useSearchParamState(
  defaultValues: SearchParamValues
): [SearchParamValues, (value: SearchParamValues) => void] {
  const [searchParams, setSearchParams] = useSearchParams();

  const values = useMemo<SearchParamValues>(() => {
    const entries = Object.entries(defaultValues).map(([key, defaultValue]) => [
      key,
      searchParams.get(key) ?? defaultValue,
    ]);
    return Object.fromEntries(entries);
  }, [defaultValues, searchParams]);

  const pathname = useLocation().pathname;
  const dispatch = useAppDispatch();
  const setValues = useCallback(
    (newValues: SearchParamValues) => {
      const existingEntries = Array.from(searchParams.entries()).filter(
        ([key]) => newValues[key] === undefined
      );
      const newEntries = Object.entries(newValues).filter(
        ([_, value]) => value !== null
      );

      const entries = [...existingEntries, ...newEntries];
      entries.sort(([a], [b]) => a.localeCompare(b));
      const newSearchParams = Object.fromEntries(entries);

      setSearchParams(newSearchParams, { replace: true });
      dispatch(
        setRouteSearchParams({
          pathname,
          searchParams: newSearchParams,
        })
      );
    },
    [searchParams, setSearchParams, dispatch, pathname]
  );

  const initialValues = useRef(values);

  useEffect(() => {
    if (initialValues.current === null) {
      return;
    }

    const entries = Object.entries(initialValues.current).filter(
      ([_, value]) => value !== null
    );
    entries.sort(([a], [b]) => a.localeCompare(b));

    dispatch(
      setRouteSearchParams({
        pathname,
        searchParams: Object.fromEntries(entries),
      })
    );

    initialValues.current = null;
  }, [dispatch, pathname]);

  return [values, setValues];
}

export function useSingleSearchParamState<T = string>(
  key: string,
  defaultValue = null
): [T, (value: T) => void] {
  const [values, setValues] = useSearchParamState({ [key]: defaultValue });

  const setValue = useCallback(
    (newValue: T) => {
      setValues({ [key]: newValue === null ? null : newValue.toString() });
    },
    [key, setValues]
  );

  return [values[key] as T, setValue];
}
