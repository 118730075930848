import React from 'react';
import { useIntl } from 'react-intl';
import {
  ItemNotification,
  NotificationMessage,
  NotificationType,
} from 'apis/backendApi';
import { notificationTypeMessages } from 'views/Actions/actionMessages';

const NotificationTypeText: React.FC<{
  data: ItemNotification | NotificationMessage;
}> = ({ data }) => {
  const { $t } = useIntl();
  switch (data.type) {
    case NotificationType.OTHER:
      return (
        <>
          {$t(notificationTypeMessages[NotificationType.OTHER])}:{' '}
          {data.otherText}
        </>
      );
    default:
      return <>{$t(notificationTypeMessages[data.type])}</>;
  }
};

export default NotificationTypeText;
