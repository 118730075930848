import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BulkAssignment,
  BulkAssignmentDto,
  ErrorResponse,
} from 'apis/backendApi';
import {
  getApiAccessToken,
  getApiBuCode,
  getApiLanguage,
  getApiRoot,
} from 'core/apiConfig';
import { RootState } from 'core/store';

export enum ImportStage {
  FILE = 'file',
  ASSIGNMENTS = 'assignments',
  COMPLETE = 'complete',
}

export type ImportState = {
  stage: ImportStage;
  bulkAssignments?: BulkAssignmentDto;
  errorResponse?: ErrorResponse;
};

const importSlice = createSlice({
  name: 'import',
  initialState: {
    stage: ImportStage.FILE,
  } as ImportState,
  reducers: {
    reset(state) {
      state.stage = ImportStage.FILE;
      delete state.bulkAssignments;
      delete state.errorResponse;
    },

    completeImport(state, action: PayloadAction<BulkAssignmentDto>) {
      state.bulkAssignments = action.payload;
      state.stage = ImportStage.ASSIGNMENTS;
    },

    completeBulkAssignment(state) {
      delete state.bulkAssignments;
      delete state.errorResponse;
      state.stage = ImportStage.COMPLETE;
    },

    setErrorResponse(state, action: PayloadAction<ErrorResponse>) {
      state.errorResponse = action.payload;
    },

    retry(state) {
      delete state.errorResponse;
    },
  },
});

export const selectImportState = (root: RootState) => root.import;

export const { reset, retry } = importSlice.actions;

export default importSlice;

export const excelImport = createAsyncThunk(
  'import/excelImport',
  async (file: File, { dispatch }) => {
    const { completeImport } = importSlice.actions;
    const apiUrl = new URL(
      `${getApiRoot()}/slm/backend/items/v1/${getApiBuCode()}/excel-import`
    );

    const formData = new FormData();
    formData.append('file', file); // API handles only 1 part
    const response = await fetch(apiUrl.toString(), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getApiAccessToken()}`,
        'Accept-Language': getApiLanguage(),
      },
      body: formData,
    });

    const dto: BulkAssignmentDto = await response.json();
    dispatch(completeImport(dto));
  }
);

export const bulkAssign = createAsyncThunk(
  'import/bulkAssign',
  async (assignments: BulkAssignment[], { dispatch }) => {
    const { completeBulkAssignment, setErrorResponse } = importSlice.actions;
    try {
      const apiUrl = new URL(
        `${getApiRoot()}/slm/backend/items/v1/${getApiBuCode()}/bulk-assignment`
      );

      const dto: BulkAssignmentDto = { assignments };

      const response = await fetch(apiUrl.toString(), {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${getApiAccessToken()}`,
          'Accept-Language': getApiLanguage(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dto),
      });
      if (response.status === 200) {
        dispatch(completeBulkAssignment());
      } else {
        const json = await response.json();
        dispatch(setErrorResponse(json));
      }
    } catch (error) {
      dispatch(setErrorResponse(error));
    }
  }
);
