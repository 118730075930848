import React, { useCallback, useEffect, useMemo } from 'react';
import InputField from '@ingka/input-field';
import { useForm } from 'react-hook-form';
import s from 'views/Parameters/components/tabs/Tabs.module.css';
import cm from 'core/commonMessages';
import pm from 'views/Parameters/parametersMessages';
import Button from '@ingka/button';
import { useIntl } from 'react-intl';

import { ARTICLE_NUMBER_PATTERN } from 'views/Parameters/types';

type ArticleSearchFormProps = {
  /**
   * The default article number.
   */
  articleNumber: string | null;

  /**
   * Callback invoked when the user searches for an article number.
   *
   * @param articleNumber The sanitized article number.
   */
  onSearch: (articleNumber: string) => void;
};

const ArticleSearchForm: React.FC<ArticleSearchFormProps> = ({
  articleNumber,
  onSearch,
}) => {
  const { register, handleSubmit, formState, reset } = useForm({
    mode: 'onChange',
  });

  const defaultValues = useMemo(() => {
    return { articleNumber };
  }, [articleNumber]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onValid = useCallback(
    ({ articleNumber }) => {
      if (articleNumber.length < 8) {
        const prefix = '0'.repeat(8 - articleNumber.length);
        articleNumber = `${prefix}${articleNumber}`;
      }
      onSearch(articleNumber);
    },
    [onSearch]
  );

  const { isValid } = formState;

  const { $t } = useIntl();

  return (
    <form className={s.artFormGrid} onSubmit={handleSubmit(onValid)}>
      <InputField
        id="articleNumber"
        label={$t(pm.articleNumber)}
        type="text"
        {...register('articleNumber', {
          required: true,
          pattern: ARTICLE_NUMBER_PATTERN,
        })}
      />
      <Button
        text={$t(cm.search)}
        type="primary"
        htmlType="submit"
        disabled={!isValid}
      />
    </form>
  );
};

export default ArticleSearchForm;
