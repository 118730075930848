/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SpecialityShopDto } from './SpecialityShopDto';
import {
    SpecialityShopDtoFromJSON,
    SpecialityShopDtoFromJSONTyped,
    SpecialityShopDtoToJSON,
} from './SpecialityShopDto';

/**
 * 
 * @export
 * @interface DivisionDto
 */
export interface DivisionDto {
    /**
     * Division id
     * @type {string}
     * @memberof DivisionDto
     */
    id?: string;
    /**
     * Business Unit code
     * @type {string}
     * @memberof DivisionDto
     */
    buCode?: string;
    /**
     * Name of the division
     * @type {string}
     * @memberof DivisionDto
     */
    name?: string;
    /**
     * Volume that all items will occupy based on package/dimension and Sales Space Quantity
     * @type {number}
     * @memberof DivisionDto
     */
    salesSpaceQuantityVolume?: number;
    /**
     * Area m2
     * @type {number}
     * @memberof DivisionDto
     */
    areaM2: number;
    /**
     * Max Density
     * @type {number}
     * @memberof DivisionDto
     */
    maxDensity?: number;
    /**
     * Planned Max Density
     * @type {number}
     * @memberof DivisionDto
     */
    plannedMaxDensity: number;
    /**
     * Planned Filling Rate
     * @type {number}
     * @memberof DivisionDto
     */
    plannedFillingRate: number;
    /**
     * Planned Density
     * @type {number}
     * @memberof DivisionDto
     */
    plannedDensity?: number;
    /**
     * 
     * @type {Array<SpecialityShopDto>}
     * @memberof DivisionDto
     */
    specialityShops?: Array<SpecialityShopDto>;
    /**
     * String representation of the date/time the assignment was last updated. Intended for caching purposes only.
     * @type {string}
     * @memberof DivisionDto
     */
    lastUpdated?: string;
}

/**
 * Check if a given object implements the DivisionDto interface.
 */
export function instanceOfDivisionDto(value: object): value is DivisionDto {
    if (!('areaM2' in value) || value['areaM2'] === undefined) return false;
    if (!('plannedMaxDensity' in value) || value['plannedMaxDensity'] === undefined) return false;
    if (!('plannedFillingRate' in value) || value['plannedFillingRate'] === undefined) return false;
    return true;
}

export function DivisionDtoFromJSON(json: any): DivisionDto {
    return DivisionDtoFromJSONTyped(json, false);
}

export function DivisionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DivisionDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'buCode': json['buCode'] == null ? undefined : json['buCode'],
        'name': json['name'] == null ? undefined : json['name'],
        'salesSpaceQuantityVolume': json['salesSpaceQuantityVolume'] == null ? undefined : json['salesSpaceQuantityVolume'],
        'areaM2': json['areaM2'],
        'maxDensity': json['maxDensity'] == null ? undefined : json['maxDensity'],
        'plannedMaxDensity': json['plannedMaxDensity'],
        'plannedFillingRate': json['plannedFillingRate'],
        'plannedDensity': json['plannedDensity'] == null ? undefined : json['plannedDensity'],
        'specialityShops': json['specialityShops'] == null ? undefined : ((json['specialityShops'] as Array<any>).map(SpecialityShopDtoFromJSON)),
        'lastUpdated': json['lastUpdated'] == null ? undefined : json['lastUpdated'],
    };
}

export function DivisionDtoToJSON(value?: DivisionDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'buCode': value['buCode'],
        'name': value['name'],
        'salesSpaceQuantityVolume': value['salesSpaceQuantityVolume'],
        'areaM2': value['areaM2'],
        'maxDensity': value['maxDensity'],
        'plannedMaxDensity': value['plannedMaxDensity'],
        'plannedFillingRate': value['plannedFillingRate'],
        'plannedDensity': value['plannedDensity'],
        'specialityShops': value['specialityShops'] == null ? undefined : ((value['specialityShops'] as Array<any>).map(SpecialityShopDtoToJSON)),
        'lastUpdated': value['lastUpdated'],
    };
}

