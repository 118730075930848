import { MessageDescriptor } from 'react-intl';
import { ErrorResponse } from 'apis/backendApi';
import { ReactElement } from 'react';
import { Primitive } from 'core/types';

export type FeedbackMessage = {
  /**
   * Indicates if the message is an error.
   */
  isError: boolean;

  /**
   * Translated summary, displayed as-is
   */
  summary: ReactElement | string;

  /**
   * The message ID, used to prevent duplicate messages for the same action.
   */
  id?: Primitive;

  details?: {
    /**
     * Detail error. Either an ErrorResponse from the backend or an Error object.
     */
    error: ErrorResponse | Error;

    /**
     * The HTTP status code.
     */
    status?: number;

    /**e
     * Message descriptors for the detail messages.
     */
    messageDescriptors: MessageDescriptors;

    /**
     * Values passed to the detail messages.
     */
    values?: Record<string, Primitive>;
  };
};

export type FeedbackMessageDescriptors = {
  /**
   * Error summary message.
   */
  error: MessageDescriptor;

  /**
   * Optional success summary message.
   */
  success?: MessageDescriptor;

  /**
   * Optional "pending" success summary message displayed when MHS is enabled.
   */
  pendingSuccess?: MessageDescriptor;
} & Record<string, MessageDescriptor>;

export type MessageDescriptors =
  | FeedbackMessageDescriptors
  | [FeedbackMessageDescriptors, Record<string, MessageDescriptor>];

export function resolveConstraintMessage(
  messages: MessageDescriptors,
  constraint: string
): MessageDescriptor {
  if (Array.isArray(messages)) {
    return (messages as Array<Record<string, MessageDescriptor>>).find(
      x => x[constraint] !== undefined
    )?.[constraint];
  } else {
    return messages[constraint];
  }
}
