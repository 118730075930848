/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Product area and range group connected to them
 * @export
 * @interface ProductAreaDto
 */
export interface ProductAreaDto {
    /**
     * Product area
     * @type {string}
     * @memberof ProductAreaDto
     */
    id?: string;
    /**
     * Product area description
     * @type {string}
     * @memberof ProductAreaDto
     */
    name?: string;
}

/**
 * Check if a given object implements the ProductAreaDto interface.
 */
export function instanceOfProductAreaDto(value: object): value is ProductAreaDto {
    return true;
}

export function ProductAreaDtoFromJSON(json: any): ProductAreaDto {
    return ProductAreaDtoFromJSONTyped(json, false);
}

export function ProductAreaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductAreaDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
    };
}

export function ProductAreaDtoToJSON(value?: ProductAreaDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
    };
}

