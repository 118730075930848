/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible Availability values
 * @export
 */
export const SearchAvailabilityEnum = {
    LOC_OVERSTOCK: '1',
    NO_SUPPLY: '2',
    LIM_SUPPLY: '3'
} as const;
export type SearchAvailabilityEnum = typeof SearchAvailabilityEnum[keyof typeof SearchAvailabilityEnum];


export function instanceOfSearchAvailabilityEnum(value: any): boolean {
    for (const key in SearchAvailabilityEnum) {
        if (Object.prototype.hasOwnProperty.call(SearchAvailabilityEnum, key)) {
            if (SearchAvailabilityEnum[key as keyof typeof SearchAvailabilityEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SearchAvailabilityEnumFromJSON(json: any): SearchAvailabilityEnum {
    return SearchAvailabilityEnumFromJSONTyped(json, false);
}

export function SearchAvailabilityEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchAvailabilityEnum {
    return json as SearchAvailabilityEnum;
}

export function SearchAvailabilityEnumToJSON(value?: SearchAvailabilityEnum | null): any {
    return value as any;
}

