import React, { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import LandingPage from 'views/LandingPage/LandingPage';
import Maintain from 'views/Maintain/Maintain';
import Parameters, { ParametersTabId } from 'views/Parameters';
import Summaries from 'views/Summaries';
import RangeSlidTab from 'views/StoreStructure/components/Tabs/Slid/RangeSlidTab';
import RangePATab from 'views/StoreStructure/components/Tabs/PA/RangePATab';
import SpecialityShopRangeGroupTab from 'views/StoreStructure/components/Tabs/SpecialityShop/SpecialityShopRangeGroupTab';
import DivisionSpecialityShopTab from 'views/StoreStructure/components/Tabs/Division/DivisionSpecialityShopTab';
import DefaultParametersTab from 'views/Parameters/components/tabs/Default/DefaultParametersTab';
import F6ParametersTab from 'views/Parameters/components/tabs/F6/F6ParametersTab';
import ArticleParametersTab from 'views/Parameters/components/tabs/Article/ArticleParametersTab';
import GroupParametersTab from 'views/Parameters/components/tabs/Group/GroupParametersTab';
import Frame from 'components/Frame/Frame';
import BuCodeIntegration from 'components/BuCodeIntegration';
import { SummariesTabId } from 'views/Summaries/Summaries';
import SnapshotViewTab from 'views/Summaries/components/tabs/SnapshotView/SnapshotViewTab';
import { Auth0Provider } from 'components/Auth';
import { Config } from 'core/commonTypes';
import { UserProvider } from '../contexts/UserContext';
import ErrorBoundary from 'components/ErrorBoundary';
import { FeedbackContextProvider } from '../contexts/feedback/FeedbackContext';
import TimeViewTab from 'views/Summaries/components/tabs/TimeView/TimeViewTab';
import { MessageDescriptor, useIntl } from 'react-intl';
import cm from 'core/commonMessages';
import ssm from 'views/StoreStructure/storeStructureMessages';
import pm from 'views/Parameters/parametersMessages';
import sm from 'views/Summaries/summariesMessages';
import { matchPath } from 'react-router';
import Analytics from '../contexts/Analytics/Analytics';
import ItemDetails from 'views/Maintain/components/ItemDetails/ItemDetails';
import Actions, { ActionsTabId } from 'views/Actions/Actions';
import ItemAlertsTab from 'views/Actions/components/tabs/ItemAlertsTab';
import NotificationsTab from 'views/Actions/components/tabs/NotificationsTab';
import DevTools from 'views/DevTools/DevTools';
import ItemComputer from 'views/DevTools/components/ItemComputer';
import Home from 'views/DevTools/components/Home';
import StoreStructure, { StoreStructureTabId } from 'views/StoreStructure';
import Import from 'views/Import/Import';
import FileSelection from 'views/Import/components/stages/FileSelection';
import Assignments from 'views/Import/components/stages/Assignments';
import { ImportStage } from 'views/Import/redux/importSlice';
import Complete from 'views/Import/components/stages/Complete';
import AssignmentMigration from 'views/DevTools/components/AssignmentMigration';

/**
 * Represents route title info.
 *
 * Route titles are matches in the order specified. I.e. fallback routes are
 */
type RouteTitle = {
  // The message
  pathPattern: string;
  // The messages in hierarchy order.
  messages: MessageDescriptor[];
};

const ROUTE_TITLES: RouteTitle[] = [
  {
    pathPattern: '/store/:buCode/maintain/*',
    messages: [cm.maintainTitle],
  },
  {
    pathPattern: '/store/:buCode/structure/division-speciality-shop',
    messages: [cm.storeStructureTitle, ssm.divisionSpecialityShop],
  },
  {
    pathPattern: '/store/:buCode/structure/speciality-shop-range-group',
    messages: [cm.storeStructureTitle, ssm.specialityShopRangeGroup],
  },
  {
    pathPattern: '/store/:buCode/structure/range-group-pa',
    messages: [cm.storeStructureTitle, ssm.rangeGroupPA],
  },
  {
    pathPattern: '/store/:buCode/structure/range-group-slid',
    messages: [cm.storeStructureTitle, ssm.rangeGroupSLID],
  },
  {
    pathPattern: '/store/:buCode/structure/*',
    messages: [cm.storeStructureTitle],
  },
  {
    pathPattern: '/store/:buCode/parameters/default',
    messages: [cm.parametersTitle, pm.defaultParameters],
  },
  {
    pathPattern: '/store/:buCode/parameters/group',
    messages: [cm.parametersTitle, pm.groupParameters],
  },
  {
    pathPattern: '/store/:buCode/parameters/article',
    messages: [cm.parametersTitle, pm.articleParameters],
  },
  {
    pathPattern: '/store/:buCode/parameters/f6',
    messages: [cm.parametersTitle, pm.f6Parameters],
  },
  {
    pathPattern: '/store/:buCode/parameters/*',
    messages: [cm.parametersTitle],
  },
  {
    pathPattern: '/store/:buCode/summaries/time-view',
    messages: [cm.summariesTitle, sm.timeView],
  },
  {
    pathPattern: '/store/:buCode/summaries/snapshot-view',
    messages: [cm.summariesTitle, sm.snapshotView],
  },
  {
    pathPattern: '/store/:buCode/summaries/*',
    messages: [cm.summariesTitle],
  },
  {
    pathPattern: '/store/:buCode/import/*',
    messages: [cm.importTitle],
  },
  {
    pathPattern: '/store/:buCode/actions/*',
    messages: [cm.actionsTitle],
  },
  {
    pathPattern: '/store/:buCode/devtools/item-computer',
    messages: [cm.itemComputerTitle],
  },
  {
    pathPattern: '/store/:buCode/devtools/*',
    messages: [cm.devToolsTitle],
  },
];

function useRouteTitle() {
  const location = useLocation();

  return useMemo(() => {
    return ROUTE_TITLES.find(pageTitle => {
      return matchPath(pageTitle.pathPattern, location.pathname) !== null;
    });
  }, [location.pathname]);
}

/**
 * Obtains the main title message descriptor or `null` if no match could be found.
 */
export function useMainTitle(): MessageDescriptor | null {
  return useRouteTitle()?.messages[0] ?? null;
}

export type AppProps = {
  config: Config;
};

const App: React.FC<AppProps> = ({ config }) => {
  const routeTitle = useRouteTitle();
  const { $t } = useIntl();

  useEffect(() => {
    const messages: MessageDescriptor[] = routeTitle?.messages;

    const titles = messages
      ? [
          // Show only leaf page/tab
          $t(messages[messages.length - 1]),
          // Or show all titles in reverse hierarchy order.
          // ...messages.map(message => $t(message)).reverse(),
          $t(cm.appTitle),
        ]
      : [$t(cm.appTitle)];
    document.title = titles.join(' - ');
  }, [routeTitle, $t]);

  return (
    <Auth0Provider config={config}>
      <UserProvider>
        <ErrorBoundary>
          <Analytics config={config}>
            <FeedbackContextProvider>
              <Routes>
                <Route Component={Frame}>
                  <Route path="/" element={<LandingPage />} />

                  <Route path="/store">
                    <Route path="" element={<Navigate to={'/'} replace />} />
                    <Route path=":buCode" element={<BuCodeIntegration />}>
                      <Route path="" element={<LandingPage />} />
                      <Route path="maintain" element={<Maintain />} />
                      <Route
                        path="maintain/item/:itemNo?"
                        element={<ItemDetails />}
                      />
                      <Route path="actions/:divId" element={<Actions />}>
                        <Route
                          path=""
                          element={
                            <Navigate to={`${ActionsTabId.DEFAULT}`} replace />
                          }
                        />
                        <Route
                          path={ActionsTabId.ALERTS}
                          element={<ItemAlertsTab mode="alerts" />}
                        />
                        <Route
                          path={ActionsTabId.WARNINGS}
                          element={<ItemAlertsTab mode="warnings" />}
                        />
                        <Route
                          path={ActionsTabId.NOTIFICATIONS}
                          element={<NotificationsTab />}
                        />
                      </Route>
                      <Route path="structure" element={<StoreStructure />}>
                        <Route
                          path=""
                          element={
                            <Navigate
                              to={`${StoreStructureTabId.DEFAULT}`}
                              replace
                            />
                          }
                        />
                        <Route
                          path={StoreStructureTabId.DIVISION_SPECIALITY_SHOP}
                          element={<DivisionSpecialityShopTab />}
                        />
                        <Route
                          path={StoreStructureTabId.SPECIALITY_SHOP_RANGE_GROUP}
                          element={<SpecialityShopRangeGroupTab />}
                        />
                        <Route
                          path={StoreStructureTabId.RANGE_GROUP_PA}
                          element={<RangePATab />}
                        />
                        <Route
                          path={StoreStructureTabId.RANGE_GROUP_SLID}
                          element={<RangeSlidTab />}
                        />
                      </Route>
                      <Route path="summaries" element={<Summaries />}>
                        <Route
                          path=""
                          element={
                            <Navigate
                              to={`${SummariesTabId.TIME_VIEW}`}
                              replace
                            />
                          }
                        />
                        <Route
                          path={SummariesTabId.TIME_VIEW}
                          element={<TimeViewTab />}
                        />
                        <Route
                          path={SummariesTabId.SNAPSHOT_VIEW}
                          element={<SnapshotViewTab />}
                        />
                      </Route>
                      <Route path="parameters" element={<Parameters />}>
                        <Route
                          path=""
                          element={
                            <Navigate
                              to={`${ParametersTabId.DEFAULT}`}
                              replace
                            />
                          }
                        />
                        <Route
                          path={ParametersTabId.DEFAULT}
                          element={<DefaultParametersTab />}
                        />
                        <Route
                          path={ParametersTabId.GROUP}
                          element={<GroupParametersTab />}
                        />
                        <Route
                          path={ParametersTabId.ARTICLE}
                          element={<ArticleParametersTab />}
                        />
                        <Route
                          path={ParametersTabId.F6}
                          element={<F6ParametersTab />}
                        />
                      </Route>
                      <Route path="import" element={<Import />}>
                        <Route
                          path=""
                          element={<Navigate to={ImportStage.FILE} replace />}
                        />
                        <Route
                          path={ImportStage.FILE}
                          element={<FileSelection />}
                        />
                        <Route
                          path={ImportStage.ASSIGNMENTS}
                          element={<Assignments />}
                        />
                        <Route
                          path={ImportStage.COMPLETE}
                          element={<Complete />}
                        />
                      </Route>
                      <Route path="devtools" element={<DevTools />}>
                        <Route
                          path=""
                          element={<Navigate to={`home`} replace />}
                        />
                        <Route path={'home'} element={<Home />} />
                        <Route
                          path={'item-computer'}
                          element={<ItemComputer />}
                        />
                        <Route
                          path={'assignment-migration'}
                          element={<AssignmentMigration />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </FeedbackContextProvider>
          </Analytics>
        </ErrorBoundary>
      </UserProvider>
    </Auth0Provider>
  );
};

export default App;
