import React, { useCallback } from 'react';
import { useF6ParametersQuery } from 'views/Parameters/hooks/f6Parameters';
import ParameterForm, {
  ShowHistoryCallback,
} from 'views/Parameters/components/form/ParameterForm';
import { FormId, HistoryFilter } from 'views/Parameters/redux/types';
import pm from '../../../parametersMessages';
import { useIntl } from 'react-intl';
import s from 'views/Parameters/components/tabs/Tabs.module.css';
import { useParameterHistory } from 'views/Parameters/hooks/history';
import ChangeHistory from 'views/Parameters/components/modal/ChangeHistory';
import { usePostF6ParametersMutation } from 'views/Parameters/redux/f6ParametersApi';
import { useAsyncFeedback } from 'hooks/feedback';
import { ParameterDto } from 'apis/backendApi';
import ParameterLoadingMessages from 'views/Parameters/components/messages/ParameterLoadingMessages';

const F6ParametersTab: React.FC = () => {
  const query = useF6ParametersQuery();

  const [postF6Parameters] = usePostF6ParametersMutation();

  const onSubmit = useAsyncFeedback(
    async ({ dirty }) => {
      const parameters = dirty as unknown as ParameterDto[];
      await postF6Parameters(parameters).unwrap();
    },
    [postF6Parameters],
    {
      messages: [pm.useCases.updateF6Parameters, pm.constraints],
      id: 'f6Parameters',
    }
  );

  const { historySelection, showHistory, closeHistory } = useParameterHistory();

  const onShowHistory: ShowHistoryCallback = useCallback(
    parameter => {
      showHistory({ filter: HistoryFilter.F6, filterValue: '', parameter });
    },
    [showHistory]
  );

  const { $t } = useIntl();

  return (
    <>
      <ParameterLoadingMessages
        isLoading={query.isLoading}
        isError={query.isError}
      />
      {query.isSuccess && (
        <div className={s.grid}>
          <fieldset>
            <legend>{$t(pm.f6Parameters)}</legend>
            <ParameterForm
              fields={query.fields}
              formId={FormId.F6}
              onShowHistory={onShowHistory}
              onSubmit={onSubmit}
            />
          </fieldset>
        </div>
      )}
      {historySelection && (
        <ChangeHistory
          selection={historySelection}
          closeHistory={closeHistory}
        />
      )}
    </>
  );
};

export default F6ParametersTab;
