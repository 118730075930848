import React, { useCallback, useMemo } from 'react';
import Select, { Option, SelectProps } from '@ingka/select';
import { useIntl } from 'react-intl';
import { MessageDescriptorWithEnum } from 'views/Maintain/types';
import { findEnumMessage } from 'core/util/enum';

interface EnumSelectorProps extends SelectProps {
  messages: Record<string, MessageDescriptorWithEnum>;
  emptyOption?: { value: any; label: string };
  EnumType: Record<string, any>;
  onChange: (any) => void;
}

const EnumSelector: React.FC<EnumSelectorProps> = ({
  EnumType,
  emptyOption,
  onChange,
  value,
  messages,
  ...restProps
}) => {
  const { $t } = useIntl();

  const options = useMemo(
    () =>
      Object.values(EnumType)
        .map(enumValue => [enumValue, findEnumMessage(messages, enumValue)])
        .filter(([_, message]) => message !== null)
        .map(([enumValue, message]) => (
          <Option value={enumValue} label={$t(message)} key={enumValue} />
        )),
    [$t, EnumType, messages]
  );

  const onSelectChange = useCallback(
    (e: React.MouseEvent<HTMLSelectElement>) => {
      const newValue = e.currentTarget.value;
      onChange(
        // Select does not support null as value, so we coerce null to empty string
        // Check if emptyOption value is null and newValue is empty, to either call back
        // with actual new value or the emptyOption value.
        !emptyOption || (emptyOption.value === null && newValue !== '')
          ? newValue
          : emptyOption.value
      );
    },
    [onChange, emptyOption]
  );

  return (
    <Select
      {...restProps}
      hintText=""
      onChange={onSelectChange}
      value={value ?? ''}
    >
      {emptyOption && (
        <Option value={emptyOption.value ?? ''} label={emptyOption.label} />
      )}
      {options}
    </Select>
  );
};

export default EnumSelector;
