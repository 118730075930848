import React from 'react';
import { useRouteBuCode } from 'hooks/buCode';
import { Link } from 'react-router-dom';

const ItemDetailsLink: React.FC<{
  itemNo: string;
  query?: string;
  target?: '_self' | '_blank';
  children?: React.ReactNode;
}> = ({ itemNo, query, target = '_self', children }) => {
  const buCode = useRouteBuCode();
  itemNo = itemNo.replaceAll('.', '');
  let href = `/store/${encodeURIComponent(buCode)}/maintain/item/${itemNo}`;
  if (query) {
    href = `${href}?${query}`;
  }
  return (
    <Link to={href} target={target}>
      {children}
    </Link>
  );
};

export default ItemDetailsLink;
