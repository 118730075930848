import React, { useCallback } from 'react';
import { FormId } from 'views/Parameters/redux/types';
import Button from '@ingka/button';
import historyIcon from '@ingka/ssr-icon/paths/history';
import SwitchParameterField from 'views/Parameters/components/form/SwitchParameterField';
import NumericParameterField from 'views/Parameters/components/form/NumericParameterField';
import { ParameterField, ParameterType } from 'views/Parameters/types';
import {
  Control,
  UseFormGetFieldState,
  UseFormRegister,
} from 'react-hook-form/dist/types/form';
import {
  ParameterFormValues,
  ShowHistoryCallback,
} from 'views/Parameters/components/form/ParameterForm';
import s from 'views/Parameters/components/tabs/Tabs.module.css';
import { useIntl } from 'react-intl';
import pm from 'views/Parameters/parametersMessages';
import { UseFormResetField } from 'react-hook-form';
import { useFieldLabel } from 'views/Parameters/hooks/format';

export type ParameterFieldProps = {
  field: ParameterField;
  formId: FormId;
  getFieldState: UseFormGetFieldState<ParameterFormValues>;
  isLocked: boolean;
};

type ParameterFieldRowProps = ParameterFieldProps & {
  control: Control<ParameterFormValues>;
  register: UseFormRegister<ParameterFormValues>;
  resetField: UseFormResetField<ParameterFormValues>;
  onShowHistory?: ShowHistoryCallback;
};

export const ParameterFieldRow: React.FC<ParameterFieldRowProps> = ({
  field,
  formId,
  onShowHistory,
  isLocked,
  register,
  control,
  getFieldState,
  resetField,
}) => {
  const onHistoryClick = useCallback(
    () => onShowHistory(field.name),
    [onShowHistory, field.name]
  );

  const { invalid } = getFieldState(field.name);

  const { isReadonly, isComputed } = field;

  const { $t } = useIntl();

  return (
    <tr className={invalid ? s.invalid : undefined}>
      <td>
        {!isComputed && onShowHistory && (
          <Button
            ssrIcon={historyIcon}
            type="plain"
            title={$t(pm.openChangeHistory)}
            onClick={onHistoryClick}
            disabled={isReadonly}
          />
        )}
      </td>
      <td>{useFieldLabel(field.name)}</td>
      <td>
        {(field.type === ParameterType.BOOLEAN && (
          <SwitchParameterField
            field={field}
            formId={formId}
            control={control}
            isLocked={isLocked}
          />
        )) || (
          <NumericParameterField
            field={field}
            formId={formId}
            isLocked={isLocked}
            register={register as any}
            getFieldState={getFieldState}
            resetField={resetField as any}
          />
        )}
      </td>
    </tr>
  );
};
