import React, { useCallback, useState } from 'react';
import { HideModal, OnDialogOutcome, useDialogActions } from 'hooks/modal';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import Button from '@ingka/button';
import cm from 'core/commonMessages';
import mm from 'views/Maintain/maintainMessages';
import { useIntl } from 'react-intl';
import './UnassignLocationModal.scss';
import InputField from '@ingka/input-field';
import { getApiDate, getInputDate } from 'core/util/date';
import { useOnChangeDate } from 'hooks/date';
import LocationHierarchy from 'views/Maintain/components/ItemDetails/LocationHierarchy';
import { useDeleteItemAssignmentMutation } from 'views/Maintain/redux/itemApi';
import { useConfirmDialog } from 'components/Modal/Dialog';
import { UnassignLocationConflict } from 'apis/backendApi';

export const UnassignLocationModal: React.FC<{
  itemNo: string;
  rangeId: string;
  slid: string;
  initialDate: Date;
  maxDate: Date;
  hideModal: HideModal;
  onOutcome: OnDialogOutcome<Date>;
  onError: (error: Error) => void;
}> = ({
  itemNo,
  rangeId,
  slid,
  initialDate,
  maxDate,
  hideModal,
  onOutcome,
  onError,
}) => {
  const { isModalVisible, handleOk, onCancel, onClose, onModalClosed } =
    useDialogActions<Date>({
      hideModal,
      onOutcome,
    });

  const [date, setDate] = useState(initialDate);

  const onChangeDate = useOnChangeDate(newDate => {
    setDate(newDate);
  }, new Date());

  const [deleteItemAssignment] = useDeleteItemAssignmentMutation();

  const { $t } = useIntl();

  const [loading, setLoading] = useState(false);

  const confirmDelete = useConfirmDialog({
    title: $t(mm.maintainUnassignConfirmSgfTitle),
    body: $t(mm.maintainUnassignConfirmSgfBody),
    onOutcome: useCallback<OnDialogOutcome<boolean>>(
      async outcome => {
        if (outcome) {
          setLoading(true);
          try {
            await deleteItemAssignment({
              itemNo,
              slid: slid,
              date: getApiDate(date),
              confirm: true,
            }).unwrap();
            handleOk(date);
          } catch (err) {
            onError(err);
          } finally {
            setLoading(false);
          }
        }
      },
      [onError, date, deleteItemAssignment, handleOk, itemNo, slid]
    ),
  });

  const onOk = useCallback(async () => {
    setLoading(true);
    try {
      await deleteItemAssignment({
        itemNo,
        slid: slid,
        date: getApiDate(date),
      }).unwrap();
      handleOk(date);
    } catch (err) {
      if (
        err.status === 409 &&
        err?.data?.conflict === UnassignLocationConflict.INTERNAL_ORDER
      ) {
        confirmDelete();
      } else {
        onError(err);
      }
    } finally {
      setLoading(false);
    }
  }, [
    confirmDelete,
    date,
    deleteItemAssignment,
    handleOk,
    itemNo,
    onError,
    slid,
  ]);

  return (
    <Modal
      visible={isModalVisible}
      handleCloseBtn={onClose}
      onModalClosed={onModalClosed}
    >
      <Prompt
        className="slm-ok-cancel slm-unassign-location"
        title={$t(mm.maintainUnassignTitle)}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button
              type="primary"
              text={$t(cm.ok)}
              onClick={onOk}
              disabled={date === null}
              loading={loading}
            />
            <Button text={$t(cm.cancel)} onClick={onCancel} />
          </ModalFooter>
        }
      >
        <div className="slm-container">
          <label>{$t(cm.articleNumber)}</label>
          <span>{itemNo}</span>
          <label>{$t(mm.maintainUnassignLocation)}</label>
          <span>
            <LocationHierarchy rangeId={rangeId} />/ {slid}
          </span>
          <label>{$t(mm.maintainUnassignDate)}</label>
          <InputField
            type="date"
            id="snapshotDate"
            onChange={onChangeDate}
            onKeyDown={e => e.preventDefault()}
            value={getInputDate(date)}
            className="slm-d-inline-block"
            min={getInputDate(initialDate)}
            max={getInputDate(maxDate)}
            required
            aria-required
          />
        </div>
      </Prompt>
    </Modal>
  );
};
