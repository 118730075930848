import React, { createContext, useCallback, useContext, useMemo } from 'react';
import Toast from '@ingka/toast';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';
import { FeedbackMessage } from './types';
import {
  ADD_MESSAGE,
  CLOSE,
  TRANSITION_END,
  useFeedbackState,
} from './FeedbackState';
import { useShowErrorDetailsDialog } from './ErrorDetailsDialog';
import { checkmark, warningTriangle } from '@ingka/ssr-icon/icons';
import SSRIcon from '@ingka/ssr-icon';
import './FeedbackContext.scss';

const FeedbackContext = createContext<{
  showFeedback: (message: FeedbackMessage) => void;
}>(null);

export const FeedbackContextProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [state, dispatch] = useFeedbackState();

  const showFeedback = useCallback(
    message => dispatch({ type: ADD_MESSAGE, payload: { message } }),
    [dispatch]
  );

  const onCloseRequest = useCallback(() => {
    dispatch({ type: CLOSE });
  }, [dispatch]);

  const onTransitionEnd = useCallback(() => {
    dispatch({ type: TRANSITION_END });
  }, [dispatch]);

  const { messages } = state;

  const errorMessages = useMemo(() => {
    return state.messages.filter(x => x.details);
  }, [state]);

  const showErrorDetailsDialog = useShowErrorDetailsDialog(errorMessages);

  const actionClick = useCallback(() => {
    showErrorDetailsDialog();
  }, [showErrorDetailsDialog]);

  const { $t } = useIntl();

  return (
    <FeedbackContext.Provider
      value={{
        showFeedback,
      }}
    >
      <Toast
        className="slm-feedback"
        isOpen={state.isOpen}
        text={
          messages.length > 0 ? (
            <>
              {messages.map((message, index) => (
                <span key={index}>
                  <SSRIcon
                    paths={message.isError ? warningTriangle : checkmark}
                  />
                  <span>{message.summary}</span>
                </span>
              ))}
            </>
          ) : (
            // Toast requires non-whitespace content, otherwise it bails out with an error.
            <span>Hej</span>
          )
        }
        actionClick={actionClick}
        actionButtonText={errorMessages.length > 0 ? $t(cm.details) : null}
        onCloseRequest={onCloseRequest}
        onTransitionEnd={onTransitionEnd}
      />
      {children}
    </FeedbackContext.Provider>
  );
};

export function useFeedback() {
  const context = useContext(FeedbackContext);

  if (context === undefined) {
    throw new Error('This hook must be invoked within a ToastFeedbackContext');
  }

  return context;
}
