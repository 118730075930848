/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible ways to sort the article search
 * @export
 */
export const SearchSortOrderEnum = {
    ASCENDING_ARTICLE_NAME: 'aan',
    EXPECTED_FORECAST_REVENUE_AVG: 'efra',
    MOVES_PER_SOLD_M3: 'mpsc',
    ASCENDING_FILLING_RATE: 'afr',
    CALCULATED_SELL_OUT_DATE: 'csod',
    CALCULATED_REMAINING_M3_AT_EDS: 'crce'
} as const;
export type SearchSortOrderEnum = typeof SearchSortOrderEnum[keyof typeof SearchSortOrderEnum];


export function instanceOfSearchSortOrderEnum(value: any): boolean {
    for (const key in SearchSortOrderEnum) {
        if (Object.prototype.hasOwnProperty.call(SearchSortOrderEnum, key)) {
            if (SearchSortOrderEnum[key as keyof typeof SearchSortOrderEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SearchSortOrderEnumFromJSON(json: any): SearchSortOrderEnum {
    return SearchSortOrderEnumFromJSONTyped(json, false);
}

export function SearchSortOrderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSortOrderEnum {
    return json as SearchSortOrderEnum;
}

export function SearchSortOrderEnumToJSON(value?: SearchSortOrderEnum | null): any {
    return value as any;
}

