import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery, apiSerializeQueryArgs } from 'core/rtkQuery';
import {
  GetSnapshotViewRequest,
  GetTimeViewRequest,
  SummaryDto,
} from 'apis/backendApi';

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: apiQuery(),
  serializeQueryArgs: apiSerializeQueryArgs,

  endpoints: builder => ({
    getTimeView: builder.query<SummaryDto, GetTimeViewRequest>({
      query: ({ reportname, date }) => {
        return {
          url: reportname && date ? '/reports/v1/{buCode}/timeview' : null,
          params: {
            reportname,
            date,
          },
        };
      },
    }),

    getSnapshotView: builder.query<SummaryDto, GetSnapshotViewRequest>({
      query: ({ reportname, date }) => {
        return {
          url: reportname && date ? '/reports/v1/{buCode}/snapshotview' : null,
          params: {
            reportname,
            date,
          },
        };
      },
    }),
  }),
});

export const { useGetTimeViewQuery, useGetSnapshotViewQuery } = reportsApi;

export default reportsApi;
