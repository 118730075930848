import React from 'react';
import { SearchCriteria, Summary } from 'views/Maintain/types';
import ResultTabs from 'views/Maintain/components/Result/ResultTabs';
import ArticlePagination, {
  OnPageSelect,
} from 'components/Pagination/ArticlePagination';
import { PaginatedItemSearchResultDto } from 'apis/backendApi';
import Button from '@ingka/button';
import { arrowRightToBase } from '@ingka/ssr-icon/icons';
import em from 'views/Import/exportMessages';
import { useIntl } from 'react-intl';
import { useExportDialog } from 'views/Import/components/export/ExportDialog';
import { useRouteBuCode } from 'hooks/buCode';
import { isBulkAssignmentEnabled } from 'core/features';
import { useHasBulkUpdateItemAssignedSalesLocation } from 'hooks/privilege';
import { Col, Row } from 'react-bootstrap';

export type ResultProps = {
  isLoading: boolean;
  isFetching: boolean;
  timeViewData: PaginatedItemSearchResultDto;
  attributesData: PaginatedItemSearchResultDto;
  summary: Summary;
  onPageSelect: OnPageSelect;
  searchCriteria: SearchCriteria;
};

const Result: React.FC<ResultProps> = ({
  attributesData,
  timeViewData,
  summary,
  onPageSelect,
  searchCriteria,
}) => {
  const onExportClick = useExportDialog(searchCriteria);

  const hasBulkAssignmentPrivilege =
    useHasBulkUpdateItemAssignedSalesLocation();
  const bulkAssignmentEnabled = isBulkAssignmentEnabled(useRouteBuCode());

  const { $t } = useIntl();

  return (
    <>
      <div className="row">
        <div className="col">
          <ResultTabs
            attributesData={attributesData}
            timeViewData={timeViewData}
            summary={summary}
          />
        </div>
      </div>
      {attributesData && (
        <Row>
          <Col md={{ span: 6, offset: 3 }} style={{ marginTop: '-15px' }}>
            <ArticlePagination
              onPageSelect={onPageSelect}
              pageNr={attributesData.pageNr}
              pageSize={attributesData.pageSize}
              totalElements={attributesData.totalElements}
            />
          </Col>
          {hasBulkAssignmentPrivilege && bulkAssignmentEnabled && (
            <Col md={3} className="slm-tae">
              <Button
                onClick={onExportClick}
                iconPosition="trailing"
                text={$t(em.exportButton)}
                type="tertiary"
                ssrIcon={arrowRightToBase}
              />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default Result;
