import { ChangeEvent, DependencyList, useCallback } from 'react';

/**
 * Wraps the `InputField.onChange` with a handler that accepts date selection.
 *
 * @param onChange Receives the Date or `null` if no valid selection was made.
 * @param defaultValue The default value to use in case the date picker is set to a blank value.
 * @param deps Optional dependencies
 */
export function useOnChangeDate(
  onChange: (value: Date | null) => void,
  defaultValue: Date = null,
  deps: DependencyList = []
) {
  return useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;
      if (value.match(/\d{4}-\d{2}-\d{2}/)) {
        onChange(new Date(value));
      } else if (value === '' && defaultValue) {
        onChange(defaultValue);
      } else {
        onChange(null);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultValue, onChange, ...deps]
  );
}
