import {
  ArticleAssignmentStatus,
  LocationType,
  MerchandisingSolution,
  UpdateAssignmentDto,
} from 'apis/backendApi';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import {
  useGetItemAssignmentsQuery,
  useGetItemDetailsQuery,
  usePostItemAssignmentMutation,
  useUpdateAssignmentsMutation,
} from 'views/Maintain/redux/itemApi';
import { getApiDate, getApiDateFromInput, parseApiDate } from 'core/util/date';
import { selectPendingUpdates } from 'core/redux/pendingUpdatesSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import {
  DayAssignment,
  findPendingAssignments,
  getLocationType,
  WeekAssignments,
} from 'views/Maintain/types';
import { useRouteBuCode } from 'hooks/buCode';
import { useAsyncFeedback } from 'hooks/feedback';
import {
  NewAssignmentFormValues,
  useNewAssignmentModal,
} from 'views/Maintain/components/ItemDetails/NewAssignmentModal';
import { useFeedback } from 'contexts/feedback/FeedbackContext';
import { useModal } from 'react-modal-hook';
import { WeekAssignmentsModal } from 'views/Maintain/components/ItemDetails/WeekAssignmentsModal';
import { UnassignLocationModal } from 'views/Maintain/components/ItemDetails/UnassignLocationModal';
import { OnDialogOutcome } from 'hooks/modal';
import { useIntl } from 'react-intl';
import mm from 'views/Maintain/maintainMessages';
import {
  ItemAssignmentConstraints,
  ItemAssignmentConstraintsContext,
} from 'views/Maintain/components/ItemDetails/ItemDetails';
import { RtkQueryConfig } from 'core/rtkQuery';

export function usePendingAssignments(itemNo: string, slid?: string) {
  const entities = useSelector((state: RootState) =>
    selectPendingUpdates(state.pendingUpdates)
  );

  return useMemo(
    () => findPendingAssignments(entities, itemNo, slid),
    [entities, itemNo, slid]
  );
}

export function useAddAssignLocationAction(
  itemNo: string,
  suggestedSlid?: string
) {
  const { showFeedback } = useFeedback();
  const { $t } = useIntl();
  const assignmentConstraints = useContext<ItemAssignmentConstraints>(
    ItemAssignmentConstraintsContext
  );

  const [postItemAssignmentMutation] = usePostItemAssignmentMutation();

  const onSubmit = useCallback(
    async (values: NewAssignmentFormValues) => {
      const {
        slid,
        quantity,
        primary,
        homebase,
        merchandisingSolution,
        productDominance,
        manualPick,
        fromDate,
        fireClassOverride,
      } = values;

      const date = getApiDateFromInput(fromDate);
      try {
        await postItemAssignmentMutation({
          itemNo,
          slid,
          date,
          fireClassOverride,
          body: {
            quantity,
            merchandisingSolution,
            productDominance,
            manualPick,
            locationType: getLocationType(homebase, primary),
          },
        }).unwrap();
      } catch (e) {
        if ('data' in e && e.status === 409) {
          throw e; // need the modal to handle this
        } else {
          showFeedback({
            isError: true,
            id: slid,
            summary: $t(mm.useCases.addNewAssignment.error),
          });
          return false;
        }
      }

      showFeedback({
        isError: false,
        id: slid,
        summary: $t(
          RtkQueryConfig.instance.isMhsEnabled
            ? mm.useCases.addNewAssignment.pendingSuccess
            : mm.useCases.addNewAssignment.success
        ),
      });
      return true;
    },
    [$t, showFeedback, itemNo, postItemAssignmentMutation]
  );

  const setupValues = useMemo(() => {
    const {
      minDate,
      maxDate,
      recommendedStructure,
      rssq,
      startDateSale,
      articleAssignmentStatus,
    } = assignmentConstraints;

    const initQty =
      articleAssignmentStatus === ArticleAssignmentStatus.UNPLANNED
        ? rssq
        : null;

    const initFromDate =
      articleAssignmentStatus === ArticleAssignmentStatus.UNPLANNED &&
      minDate < startDateSale
        ? startDateSale
        : null;

    return {
      itemNo: itemNo,
      divisionId: recommendedStructure?.division.id,
      specialityShopId: recommendedStructure?.specialityShop.id,
      rangeGroupId: recommendedStructure?.rangeGroup.id,
      quantity: initQty,
      fromDate: initFromDate,
      minDate: minDate,
      maxDate: maxDate,
      slid: suggestedSlid,
    };
  }, [itemNo, suggestedSlid, assignmentConstraints]);

  return useNewAssignmentModal(setupValues, onSubmit);
}

function useWeekAssignments(
  itemNo: string,
  slid: string,
  weekNumber: number
): WeekAssignments {
  const locations = useGetItemAssignmentsQuery({ itemNo })?.data?.locations;
  const weeks = useGetItemDetailsQuery({ itemNo })?.data?.weeks;

  return useMemo(() => {
    const week = (weeks ?? []).find(x => x.weekNumber === weekNumber);
    const location = (locations ?? []).find(x => x.salesLocation.id === slid);
    if (!week || !location) {
      return;
    }

    const selectedWeekIdx = weeks.indexOf(week);
    let currentAssignment = location.assignments[selectedWeekIdx][0];
    if (!currentAssignment) {
      // Traverse backwards through previous weeks to find the nearest assignment.
      findAssignment: for (
        let weekIdx = selectedWeekIdx - 1;
        weekIdx >= 0;
        weekIdx--
      ) {
        for (let dayIdx = 7; dayIdx >= 0; dayIdx--) {
          currentAssignment = location.assignments[weekIdx][dayIdx];
          if (currentAssignment) {
            break findAssignment;
          }
        }
      }
    }

    const today = getApiDate(new Date());

    if (!currentAssignment) {
      currentAssignment = {
        fromDate: today,
        quantity: 0,
        productDominance: 0,
        merchandisingSolution: MerchandisingSolution.F_NotRTS,
        locationType: LocationType.NORMAL,
        manualPick: false,
      };
    }

    const dayAssignments: DayAssignment[] = [];
    for (let dayIdx = 0; dayIdx < 7; dayIdx++) {
      const assignment = location.assignments[selectedWeekIdx][dayIdx];
      if (assignment) {
        currentAssignment = assignment;
      }

      const fromDate = week.dates[dayIdx];
      const {
        quantity,
        productDominance,
        merchandisingSolution,
        locationType,
        manualPick,
        isDeleted,
      } = currentAssignment;

      dayAssignments[dayIdx] = {
        fromDate,
        quantity,
        productDominance,
        merchandisingSolution,
        primary:
          locationType === LocationType.PRIMARY ||
          locationType === LocationType.HOMEBASE_PRIMARY,
        homebase:
          locationType === LocationType.HOMEBASE ||
          locationType === LocationType.HOMEBASE_PRIMARY,
        manualPick,
        isExplicit: assignment?.fromDate === fromDate,
        lastUpdated: assignment?.lastUpdated,
        isEditable: today <= fromDate,
        isModified: false,
        isDeleted,
      };
    }

    return {
      itemNo,
      weekNumber,
      slid,
      assignments: dayAssignments,
    };
  }, [itemNo, locations, slid, weekNumber, weeks]);
}

export function useEditWeekAssignmentsAction(
  itemNo: string,
  slid: string,
  weekNumber: number
) {
  const { mutation: updateAssignments } = useUpdateAssignmentsMutation();

  const onConfirm = useAsyncFeedback<DayAssignment[]>(
    async assignments => {
      const requests = assignments.map(
        ({
          quantity,
          productDominance,
          fromDate,
          merchandisingSolution,
          manualPick,
          lastUpdated,
          homebase,
          primary,
        }: DayAssignment) => {
          const locationType = getLocationType(homebase, primary);

          const body: UpdateAssignmentDto = {
            quantity,
            productDominance,
            merchandisingSolution,
            locationType,
            manualPick,
          };

          return {
            itemNo,
            slid,
            date: fromDate,
            body,
            lastUpdated,
          };
        }
      );
      await updateAssignments(requests);
    },
    [updateAssignments, itemNo, slid],
    { messages: [mm.useCases.editAssignment, {}] }
  );

  const value = useWeekAssignments(itemNo, slid, weekNumber);

  const [showModal, hideModal] = useModal(
    () => (
      <WeekAssignmentsModal
        value={value}
        hideModal={hideModal}
        onConfirm={onConfirm}
      />
    ),
    [value]
  );

  return showModal;
}

export type UnassignLocation = (params: {
  itemNo: string;
  slid: string;
  rangeId: string;
}) => void;

export function useUnassignLocationAction(): UnassignLocation {
  const buCode = useRouteBuCode();

  const { showFeedback } = useFeedback();

  const { $t } = useIntl();
  const { minDate, maxDate } = useContext<ItemAssignmentConstraints>(
    ItemAssignmentConstraintsContext
  );

  const onOutcome = useCallback<OnDialogOutcome<Date>>(
    date => {
      if (date) {
        showFeedback({
          isError: false,
          summary: $t(
            RtkQueryConfig.instance.isMhsEnabled
              ? mm.useCases.unassignLocation.pendingSuccess
              : mm.useCases.unassignLocation.success
          ),
        });
      }
    },
    [showFeedback, $t]
  );

  const onError = useCallback(
    (_: Error) => {
      showFeedback({
        isError: true,
        summary: $t(mm.useCases.unassignLocation.error),
      });
    },
    [$t, showFeedback]
  );

  const [location, setLocation] = useState<{
    itemNo: string;
    slid: string;
    rangeId: string;
  }>();

  const [showModal, hideModal] = useModal(() => {
    const { itemNo, rangeId, slid } = location;
    return (
      <UnassignLocationModal
        itemNo={itemNo}
        slid={slid}
        rangeId={rangeId}
        initialDate={parseApiDate(minDate)}
        maxDate={parseApiDate(maxDate)}
        hideModal={hideModal}
        onOutcome={onOutcome}
        onError={onError}
      />
    );
  }, [buCode, location, onOutcome, onError]);

  return useCallback(
    ({ itemNo, slid, rangeId }) => {
      setLocation({ itemNo, rangeId, slid });
      showModal();
    },
    [showModal]
  );
}
