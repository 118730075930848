/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Sales location and range group connected to them
 * @export
 * @interface SalesLocationDto
 */
export interface SalesLocationDto {
    /**
     * sales location ID
     * @type {string}
     * @memberof SalesLocationDto
     */
    id?: string;
    /**
     * A description about the sales location
     * @type {string}
     * @memberof SalesLocationDto
     */
    description?: string;
    /**
     * Type of sales location. Locations created by users will be of type L.
     * @type {string}
     * @memberof SalesLocationDto
     */
    type?: SalesLocationDtoTypeEnum;
    /**
     * Speifies if a sales location ID is hidden
     * @type {boolean}
     * @memberof SalesLocationDto
     */
    hidden?: boolean;
    /**
     * Range group connected to the sales location
     * @type {string}
     * @memberof SalesLocationDto
     */
    rangeGroup?: string;
    /**
     * If there is no any location assigned then returns 1 (is empty)
     * @type {boolean}
     * @memberof SalesLocationDto
     */
    isEmpty?: boolean;
}


/**
 * @export
 */
export const SalesLocationDtoTypeEnum = {
    C: 'C',
    D: 'D',
    L: 'L',
    W: 'W'
} as const;
export type SalesLocationDtoTypeEnum = typeof SalesLocationDtoTypeEnum[keyof typeof SalesLocationDtoTypeEnum];


/**
 * Check if a given object implements the SalesLocationDto interface.
 */
export function instanceOfSalesLocationDto(value: object): value is SalesLocationDto {
    return true;
}

export function SalesLocationDtoFromJSON(json: any): SalesLocationDto {
    return SalesLocationDtoFromJSONTyped(json, false);
}

export function SalesLocationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalesLocationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'description': json['description'] == null ? undefined : json['description'],
        'type': json['type'] == null ? undefined : json['type'],
        'hidden': json['hidden'] == null ? undefined : json['hidden'],
        'rangeGroup': json['rangeGroup'] == null ? undefined : json['rangeGroup'],
        'isEmpty': json['isEmpty'] == null ? undefined : json['isEmpty'],
    };
}

export function SalesLocationDtoToJSON(value?: SalesLocationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'description': value['description'],
        'type': value['type'],
        'hidden': value['hidden'],
        'rangeGroup': value['rangeGroup'],
        'isEmpty': value['isEmpty'],
    };
}

