/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const KeyArticleEnum = {
    INDICATOR_VALUE_KING: 'N',
    INDICATOR_VALUE_TURNOVER_ACE: 'T',
    INDICATOR_VALUE_PROFIT_ACE: 'M',
    INDICATOR_VALUE_BTI_ACE: 'L',
    INDICATOR_VALUE_SUSTAINABLE_ACE: 'S'
} as const;
export type KeyArticleEnum = typeof KeyArticleEnum[keyof typeof KeyArticleEnum];


export function instanceOfKeyArticleEnum(value: any): boolean {
    for (const key in KeyArticleEnum) {
        if (Object.prototype.hasOwnProperty.call(KeyArticleEnum, key)) {
            if (KeyArticleEnum[key as keyof typeof KeyArticleEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function KeyArticleEnumFromJSON(json: any): KeyArticleEnum {
    return KeyArticleEnumFromJSONTyped(json, false);
}

export function KeyArticleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyArticleEnum {
    return json as KeyArticleEnum;
}

export function KeyArticleEnumToJSON(value?: KeyArticleEnum | null): any {
    return value as any;
}

