/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  HfbParameterDto,
  ParameterDto,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HfbParameterDtoFromJSON,
    HfbParameterDtoToJSON,
    ParameterDtoFromJSON,
    ParameterDtoToJSON,
} from '../models/index';

export interface PostDivisionDefaultParametersRequest {
    buCode: string;
    divId: string;
    parameterDto: Array<ParameterDto>;
}

export interface PostF6ParametersRequest {
    buCode: string;
    parameterDto: Array<ParameterDto>;
}

export interface PostHfbDefaultParametersRequest {
    buCode: string;
    hfbParameterDto: HfbParameterDto;
}

export interface PostParametersForItemsRequest {
    buCode: string;
    filter: string;
    filterValue: string;
    parameterDto: ParameterDto;
}

export interface PostParametersForSingleItemRequest {
    buCode: string;
    itemNo: string;
    parameterDto: Array<ParameterDto>;
}

export interface PostStoreDefaultParametersRequest {
    buCode: string;
    parameterDto: Array<ParameterDto>;
}

/**
 * 
 */
export class POSTParametersApi extends runtime.BaseAPI {

    /**
     * Updates the default values of the parameters on division level
     * Update the parameter values on division level
     */
    async postDivisionDefaultParametersRaw(requestParameters: PostDivisionDefaultParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling postDivisionDefaultParameters().'
            );
        }

        if (requestParameters['divId'] == null) {
            throw new runtime.RequiredError(
                'divId',
                'Required parameter "divId" was null or undefined when calling postDivisionDefaultParameters().'
            );
        }

        if (requestParameters['parameterDto'] == null) {
            throw new runtime.RequiredError(
                'parameterDto',
                'Required parameter "parameterDto" was null or undefined when calling postDivisionDefaultParameters().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/defaults/division/{divId}`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))).replace(`{${"divId"}}`, encodeURIComponent(String(requestParameters['divId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['parameterDto']!.map(ParameterDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the default values of the parameters on division level
     * Update the parameter values on division level
     */
    async postDivisionDefaultParameters(requestParameters: PostDivisionDefaultParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postDivisionDefaultParametersRaw(requestParameters, initOverrides);
    }

    /**
     * Update the list of SLM F6 parameters for a store
     * Updates the value of F6 parameters
     */
    async postF6ParametersRaw(requestParameters: PostF6ParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling postF6Parameters().'
            );
        }

        if (requestParameters['parameterDto'] == null) {
            throw new runtime.RequiredError(
                'parameterDto',
                'Required parameter "parameterDto" was null or undefined when calling postF6Parameters().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/f6`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['parameterDto']!.map(ParameterDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the list of SLM F6 parameters for a store
     * Updates the value of F6 parameters
     */
    async postF6Parameters(requestParameters: PostF6ParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postF6ParametersRaw(requestParameters, initOverrides);
    }

    /**
     * Updates the default values of the parameters on hfb level
     * Update the parameter values on hfb level
     */
    async postHfbDefaultParametersRaw(requestParameters: PostHfbDefaultParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling postHfbDefaultParameters().'
            );
        }

        if (requestParameters['hfbParameterDto'] == null) {
            throw new runtime.RequiredError(
                'hfbParameterDto',
                'Required parameter "hfbParameterDto" was null or undefined when calling postHfbDefaultParameters().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/defaults/hfb`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HfbParameterDtoToJSON(requestParameters['hfbParameterDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the default values of the parameters on hfb level
     * Update the parameter values on hfb level
     */
    async postHfbDefaultParameters(requestParameters: PostHfbDefaultParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postHfbDefaultParametersRaw(requestParameters, initOverrides);
    }

    /**
     * Update the parameter values that match the filters. Setting a value to NULL resets it to its default.
     * Update the parameters values that match the filters
     */
    async postParametersForItemsRaw(requestParameters: PostParametersForItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling postParametersForItems().'
            );
        }

        if (requestParameters['filter'] == null) {
            throw new runtime.RequiredError(
                'filter',
                'Required parameter "filter" was null or undefined when calling postParametersForItems().'
            );
        }

        if (requestParameters['filterValue'] == null) {
            throw new runtime.RequiredError(
                'filterValue',
                'Required parameter "filterValue" was null or undefined when calling postParametersForItems().'
            );
        }

        if (requestParameters['parameterDto'] == null) {
            throw new runtime.RequiredError(
                'parameterDto',
                'Required parameter "parameterDto" was null or undefined when calling postParametersForItems().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['filterValue'] != null) {
            queryParameters['filterValue'] = requestParameters['filterValue'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/items`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ParameterDtoToJSON(requestParameters['parameterDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the parameter values that match the filters. Setting a value to NULL resets it to its default.
     * Update the parameters values that match the filters
     */
    async postParametersForItems(requestParameters: PostParametersForItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postParametersForItemsRaw(requestParameters, initOverrides);
    }

    /**
     * Update the parameter values for the given item number. Setting a value to NULL resets it to its default.
     * Update the parameter values for the given item number
     */
    async postParametersForSingleItemRaw(requestParameters: PostParametersForSingleItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling postParametersForSingleItem().'
            );
        }

        if (requestParameters['itemNo'] == null) {
            throw new runtime.RequiredError(
                'itemNo',
                'Required parameter "itemNo" was null or undefined when calling postParametersForSingleItem().'
            );
        }

        if (requestParameters['parameterDto'] == null) {
            throw new runtime.RequiredError(
                'parameterDto',
                'Required parameter "parameterDto" was null or undefined when calling postParametersForSingleItem().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/item/{itemNo}`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))).replace(`{${"itemNo"}}`, encodeURIComponent(String(requestParameters['itemNo']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['parameterDto']!.map(ParameterDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the parameter values for the given item number. Setting a value to NULL resets it to its default.
     * Update the parameter values for the given item number
     */
    async postParametersForSingleItem(requestParameters: PostParametersForSingleItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postParametersForSingleItemRaw(requestParameters, initOverrides);
    }

    /**
     * Updates the default values of the parameters on store level
     * Update the parameter values on store level
     */
    async postStoreDefaultParametersRaw(requestParameters: PostStoreDefaultParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling postStoreDefaultParameters().'
            );
        }

        if (requestParameters['parameterDto'] == null) {
            throw new runtime.RequiredError(
                'parameterDto',
                'Required parameter "parameterDto" was null or undefined when calling postStoreDefaultParameters().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/defaults/store`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['parameterDto']!.map(ParameterDtoToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates the default values of the parameters on store level
     * Update the parameter values on store level
     */
    async postStoreDefaultParameters(requestParameters: PostStoreDefaultParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postStoreDefaultParametersRaw(requestParameters, initOverrides);
    }

}
