import React, { useMemo } from 'react';
import StructureDetailsForm from '../../StructureDetailsForm/StructureDetailsForm';
import StoreStructureItemPicker, {
  useStoreStructureItemPicker,
} from '../../../../../components/StoreStructureItemPicker';
import { HtmlFormIdPrefix, StoreStructureType } from 'core/commonTypes';
import {
  createStructureDetails,
  StructureDetails,
  StructureDetailsUpdateValues,
} from 'views/StoreStructure/types';
import {
  useGetStoreStructureDivisionQuery,
  usePutStoreStructureDivisionMutation,
} from 'views/StoreStructure/redux/structuresApi';
import NotFoundMessage from 'components/Message/NotFoundMessage';
import SpecialityShopListEditor from 'views/StoreStructure/components/Tabs/Division/SpecialityShopListEditor';
import {
  useStoreStructureCrudState,
  useStoreStructureSearchParamState,
} from 'hooks/storeStructure';
import {
  useGetStoreStructureTreeDivisionQuery,
  usePendingDivisionUpdate,
} from 'views/StoreStructure/hooks/division';
import ssm from 'views/StoreStructure/storeStructureMessages';

import { useAsyncFeedback } from 'hooks/feedback';
import CleanPendingUpdates from 'views/Maintain/components/ItemDetails/CleanPendingUpdates';

const DivisionSpecialityShopTab: React.FC = () => {
  const pickerState = useStoreStructureItemPicker();

  const { divisionId, onStructureItemPick } =
    useStoreStructureSearchParamState();

  const { isSuccess, isError, data } =
    useGetStoreStructureTreeDivisionQuery(divisionId);

  const structureDetails = useMemo<StructureDetails | null>(() => {
    return data ? createStructureDetails(data) : null;
  }, [data]);

  const [putStoreStructureDivision] = usePutStoreStructureDivisionMutation();

  const onSubmit = useAsyncFeedback(
    async (values: StructureDetailsUpdateValues) => {
      const { specialityShops, ...division } = data;
      const dto = {
        ...division,
        ...values,
      };
      await putStoreStructureDivision(dto).unwrap();
    },
    [data, putStoreStructureDivision],
    {
      messages: [ssm.useCases.updateDivision, ssm.constraints],
      values: { name: data?.name },
    }
  );

  const { isUpdating } = useStoreStructureCrudState(
    StoreStructureType.Division,
    divisionId
  );

  const { update, pollingInterval } = usePendingDivisionUpdate(divisionId);

  useGetStoreStructureDivisionQuery(
    { divId: divisionId },
    {
      skip: update === undefined,
      pollingInterval,
    }
  );

  return (
    <div className="slm-d-flex-row-150">
      <div className="plate">
        <div className="slm-mb-150">
          <StoreStructureItemPicker
            {...pickerState}
            selectorPrefix="divsp_"
            depth={StoreStructureType.Division}
            divisionId={divisionId}
            onStructureItemPick={onStructureItemPick}
          />
        </div>
        {isSuccess && (
          <StructureDetailsForm
            structureDetails={structureDetails}
            formIdPrefix={HtmlFormIdPrefix.Division}
            onSubmit={onSubmit}
            isSubmitting={isUpdating.item}
            disabled={update !== undefined}
            type={StoreStructureType.Division}
          />
        )}
        {isError && <NotFoundMessage />}
      </div>
      <div className="plate">
        <SpecialityShopListEditor divisionId={divisionId} />
      </div>
      <CleanPendingUpdates interval={pollingInterval} />
    </div>
  );
};

export default DivisionSpecialityShopTab;
