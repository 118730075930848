import React, { PropsWithChildren } from 'react';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { Config } from 'core/commonTypes';
import { MockAuth0Provider } from './mock';

export type LocalAuth0Props = PropsWithChildren<{
  config: Config;
}>;
const Auth0ProviderWithHistory = ({ config, children }: LocalAuth0Props) => {
  const navigate = useNavigate();

  if (config.ssoClientId === 'mock') {
    return <MockAuth0Provider {...config}>{children}</MockAuth0Provider>;
  }

  const onRedirectCallback = appState => {
    // console.log(`Pushing back: ${appState}`) // FIXME: Use abstracted logging
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={config.ssoDomain}
      clientId={config.ssoClientId}
      redirectUri={`${window.location.origin}${config.redirectUri}`}
      onRedirectCallback={onRedirectCallback}
      audience={config.audience}
      scope={config.scope}
    >
      {children}
    </Auth0Provider>
  );
};

Auth0ProviderWithHistory.propTypes = {
  children: propTypes.node,
  config: propTypes.shape({
    ssoDomain: propTypes.string,
    ssoClientId: propTypes.string,
    redirectUri: propTypes.string,
    audience: propTypes.string,
    scope: propTypes.string,
  }),
};

export default Auth0ProviderWithHistory;
