import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery } from 'core/rtkQuery';
import { ActionsDto, ActionsSummaryDto } from 'apis/backendApi';

export const actionsApi = createApi({
  reducerPath: 'actionsApi',
  baseQuery: apiQuery(),

  endpoints: builder => ({
    getActionsSummary: builder.query<ActionsSummaryDto, void>({
      query: () => ({
        url: '/actions/v1/{buCode}/summary',
      }),
      // Summary is fetched explicitly, so does not need to be cached.
      keepUnusedDataFor: 0,
    }),

    getActions: builder.query<ActionsDto, string>({
      query: divId => ({
        url: '/actions/v1/{buCode}/division/{divId}',
        pathVariables: { divId },
      }),
    }),
  }),
});

export default actionsApi;

export const {
  useLazyGetActionsSummaryQuery,
  useGetActionsSummaryQuery,
  useGetActionsQuery,
} = actionsApi;
