import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ParameterFormValues } from 'views/Parameters/components/form/ParameterForm';
import { ParameterField } from 'views/Parameters/types';
import { RootState } from 'core/store';
import { FormId } from 'views/Parameters/redux/types';

type ComputationState = {
  computedValues: ParameterFormValues;

  /**
   * The names of the non-computed parameters. These parameters may be editable or read-only.
   */
  nonComputedParameters: string[];
};

type InternalFormComputationState = {
  computations: { [formId: string]: ComputationState };
};

function computeHfbValues(values: ParameterFormValues) {
  const { highTurnoverMark, lowTurnoverMark } = values;
  let turnOverMarkDftp: number;
  if (
    typeof highTurnoverMark === 'number' &&
    !Number.isNaN(highTurnoverMark) &&
    typeof lowTurnoverMark === 'number' &&
    !Number.isNaN(lowTurnoverMark)
  ) {
    turnOverMarkDftp =
      100 - (highTurnoverMark as number) - (lowTurnoverMark as number);
  }

  return {
    turnOverMarkDftp,
  };
}

function computeValues(
  formId: FormId,
  state: ComputationState,
  values: ParameterFormValues
) {
  const nonComputedValues = Object.fromEntries(
    state.nonComputedParameters
      .filter(name => values[name] !== undefined)
      .map(name => [name, values[name]])
  );

  switch (formId) {
    case FormId.HFB:
      state.computedValues = computeHfbValues(nonComputedValues);
      break;
    default:
      break;
  }
}

const parameterComputationSlice = createSlice({
  name: 'parameterComputation',
  initialState: { computations: {} } as InternalFormComputationState,
  reducers: {
    reset(
      state,
      action: PayloadAction<{
        formId: FormId;
        fields: ParameterField[];
        defaultValues: ParameterFormValues;
      }>
    ) {
      const { formId, fields, defaultValues } = action.payload;
      state.computations[formId] = {
        computedValues: {},
        nonComputedParameters: fields
          .filter(field => !field.isComputed)
          .map(field => field.name),
      };
      computeValues(formId, state.computations[formId], defaultValues);
    },

    setFormValues(
      state,
      action: PayloadAction<{
        formId: FormId;
        values: ParameterFormValues;
      }>
    ) {
      const {
        payload: { formId, values },
      } = action;
      computeValues(formId, state.computations[formId], values);
    },
  },
});

export default parameterComputationSlice;

export const { reset, setFormValues } = parameterComputationSlice.actions;

const selectSliceRoot = (root: RootState) => root.computedParameters;

export const selectComputedValues = createSelector(
  [selectSliceRoot, (state, formId: FormId) => formId],
  (root: InternalFormComputationState, formId: FormId): ParameterFormValues =>
    root.computations[formId]?.computedValues ?? {}
);
