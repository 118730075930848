import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { bulkAssign, selectImportState } from 'views/Import/redux/importSlice';
import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { BulkAssignment, LocationType } from 'apis/backendApi';
import SlmTable from 'components/Table/SlmTable';
import { useMerchandisingTerms } from 'views/Maintain/hooks/language';
import { useIntl } from 'react-intl';
import im from 'views/Import/importMessages';
import Button from '@ingka/button';
import { useAppDispatch } from 'hooks/redux';
import cm from 'core/commonMessages';
import ItemDetailsLink from 'components/ItemDetailsLink';
import InlineMessage from '@ingka/inline-message';

function useBulkAssignmentTable(data: BulkAssignment[]) {
  const merchString = useMerchandisingTerms('normal');
  const { $t } = useIntl();
  const columns = useMemo<ColumnDef<BulkAssignment, BulkAssignment>[]>(
    () => [
      {
        accessorKey: 'rowNumber',
        header: $t(im.rowNumber),
        meta: {
          style: {
            minWidth: '1rem',
          },
        },
      },
      {
        accessorKey: 'itemNo',
        header: $t(im.itemNo),
        meta: {
          style: {
            minWidth: '6rem',
          },
        },
      },
      {
        accessorKey: 'name',
        header: $t(im.itemName),
        accessorFn: dto => dto,
        cell: props => {
          const value = props.getValue();
          const { itemNo, name } = value;
          return (
            <ItemDetailsLink itemNo={itemNo} target="_blank">
              {name ?? $t(cm.noDescription)}
            </ItemDetailsLink>
          );
        },
        meta: {
          style: {
            minWidth: '8rem',
          },
        },
      },
      {
        accessorKey: 'fromDate',
        header: $t(im.fromDate),
        meta: {
          style: {
            minWidth: '6rem',
          },
        },
      },
      {
        accessorKey: 'slid',
        header: $t(im.slid),
        meta: {
          style: {
            minWidth: '4rem',
          },
        },
      },
      {
        accessorKey: 'quantity',
        header: $t(im.quantity),
        meta: {
          style: {
            minWidth: '1rem',
          },
        },
      },
      {
        accessorKey: 'productDominance',
        header: $t(im.productDominance),
        meta: {
          style: {
            minWidth: '1rem',
          },
        },
      },
      {
        accessorKey: 'merchandisingSolution',
        header: $t(im.merchandisingSolution),
        accessorFn: ({ merchandisingSolution }) => {
          return merchString(merchandisingSolution);
        },
        meta: {
          style: {
            minWidth: '1rem',
          },
        },
      },
      {
        accessorKey: 'homebase',
        header: $t(im.homebase),
        accessorFn: ({ locationType }) =>
          locationType === LocationType.HOMEBASE ||
          locationType === LocationType.HOMEBASE_PRIMARY,
        meta: {
          style: {
            minWidth: '1rem',
          },
        },
      },
      {
        accessorKey: 'primary',
        header: $t(im.primary),
        accessorFn: ({ locationType }) =>
          locationType === LocationType.PRIMARY ||
          locationType === LocationType.HOMEBASE_PRIMARY,
        meta: {
          style: {
            minWidth: '1rem',
          },
        },
      },
      {
        accessorKey: 'manualPick',
        header: $t(im.manualPick),
        meta: {
          style: {
            minWidth: '1rem',
          },
        },
      },
      {
        accessorKey: 'action',
        header: $t(im.assignmentAction),
        meta: {
          style: {
            minWidth: '1rem',
          },
        },
      },
      {
        accessorKey: 'error',
        header: $t(im.assignmentError),
        meta: {
          style: {
            minWidth: '1rem',
          },
        },
      },
      {
        accessorKey: 'warning',
        header: $t(im.assignmentWarning),
        meta: {
          style: {
            minWidth: '1rem',
          },
        },
      },
    ],
    [merchString, $t]
  );
  return useReactTable({ columns, data, getCoreRowModel: getCoreRowModel() });
}

const Assignments: React.FC = () => {
  const { bulkAssignments } = useSelector(selectImportState);

  const data = useMemo(
    () => bulkAssignments?.assignments ?? [],
    [bulkAssignments]
  );

  const table = useBulkAssignmentTable(data);

  const assignments = useMemo(() => {
    return data.filter(x => x.action);
  }, [data]);

  const hasErrors = useMemo(() => {
    return data.some(x => x.error);
  }, [data]);

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const onBulkAssignClick = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(bulkAssign(assignments));
    } finally {
      setLoading(false);
    }
  }, [assignments, dispatch]);

  const { $t } = useIntl();

  return (
    <>
      <h2>{$t(im.assignmentsTitle)}</h2>
      <SlmTable table={table} />
      <p>
        <Button
          text={$t(im.bulkAssignButton)}
          onClick={onBulkAssignClick}
          loading={loading}
          disabled={assignments.length === 0 || hasErrors}
        />
      </p>
    </>
  );
};

export default Assignments;
