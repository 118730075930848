import React, { useMemo } from 'react';
import { parseApiDate } from 'core/util/date';
import cm from 'core/commonMessages';
import { useIntl } from 'react-intl';
import Tooltip from '@ingka/tooltip';

type WeekDayProps = { value: string };

const DAYS = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

const WeekDay: React.FC<WeekDayProps> = ({ value }) => {
  const { $t, formatDate } = useIntl();
  const [text, humanDate] = useMemo(() => {
    const date = parseApiDate(value);
    const day = date.getDay();
    return [$t(cm[DAYS[day]]), formatDate(date)];
  }, [$t, formatDate, value]);

  return (
    <Tooltip tooltipText={humanDate}>
      <span>{text}</span>
    </Tooltip>
  );
};

export default WeekDay;
