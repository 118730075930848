import React, { useCallback, useMemo, useState } from 'react';
import RadioButton from '@ingka/radio-button';
import { availableLanguages } from './util';
import Button from '@ingka/button';
import globe from '@ingka/ssr-icon/paths/globe';
import Modal, { ModalHeader, Sheets } from '@ingka/modal';
import { useLanguageProvider } from './LanguageProvider';
import { Status } from 'core/commonTypes';
import FormField from '@ingka/form-field';
import style from './style.module.css';
import { useAppDispatch } from 'hooks/redux';
import searchApi from 'views/Maintain/redux/searchApi';
import { setApiLanguage } from 'core/apiConfig';

const LanguageSelector = () => {
  const { locale, messageStatus, setLocale, error } = useLanguageProvider();
  const [showLanguage, setShowLanguage] = useState(false);
  const showLang = useCallback(() => setShowLanguage(true), []);
  const hideLang = useCallback(() => setShowLanguage(false), []);

  const dispatch = useAppDispatch();

  const onChange = useCallback(
    e => {
      const language = e.target.value;
      setLocale(language);
      setApiLanguage(language);
      dispatch(searchApi.util.resetApiState());

      setShowLanguage(false);
    },
    [setShowLanguage, setLocale, dispatch]
  );

  const options = useMemo(() => {
    return availableLanguages.map(lang => {
      const [langOnly, regionOnly] = lang.split('-');
      const dnLanguage = new Intl.DisplayNames(langOnly, {
        type: 'language',
      });
      const dnRegion = new Intl.DisplayNames(langOnly, {
        type: 'region',
      });
      return {
        value: lang,
        label: `${dnLanguage.of(langOnly)} (${dnRegion.of(regionOnly)})`,
      };
    });
  }, []);

  return (
    <>
      <Button
        onClick={showLang}
        iconOnly
        ssrIcon={globe}
        loading={messageStatus === Status.LOADING}
        type="plain"
      />
      <Modal handleCloseBtn={hideLang} visible={showLanguage}>
        <Sheets
          footer={null}
          alignment={'right'}
          header={
            <ModalHeader closeBtnClick={hideLang} title="Select Language" />
          }
        >
          <div className={style.optionList}>
            {options.map(({ value, label }) => {
              return (
                <FormField
                  key={value}
                  shouldValidate
                  valid={!(error?.locale === value)}
                  validation={{
                    msg: error?.locale === value ? error?.error.message : null,
                  }}
                >
                  <RadioButton
                    id={`lang-${value}`}
                    value={value}
                    name="langRadios"
                    checked={(locale ?? error?.locale) === value}
                    label={label}
                    onChange={onChange}
                    className={style.languageOption}
                  />
                </FormField>
              );
            })}
          </div>
        </Sheets>
      </Modal>
    </>
  );
};

export default LanguageSelector;
