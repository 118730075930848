import React, { ReactElement, useRef } from 'react';
import { FeedbackMessage, resolveConstraintMessage } from './types';
import { MessageDescriptor, useIntl } from 'react-intl';
import {
  hasDetails,
  hasMessage,
  isErrorResponse,
} from 'core/util/errorResponse';
import { HideModal, useDialogActions } from 'hooks/modal';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import Button from '@ingka/button';
import cm from 'core/commonMessages';
import { useModal } from 'react-modal-hook';
import { Primitive } from 'core/types';
import { useFormattedValues } from 'hooks/locale';

const FormattedMessage: React.FC<{
  message: MessageDescriptor;
  values: Record<string, Primitive>;
}> = ({ message, values }) => {
  const { $t } = useIntl();
  const formattedValues = useFormattedValues(values);
  return <>{$t(message, formattedValues)}</>;
};

const ErrorDetails: React.FC<{
  message: FeedbackMessage;
}> = ({ message }) => {
  let content: ReactElement | string;

  const { $t } = useIntl();

  const { error, status, messageDescriptors, values } = message.details;
  if (status === 404) {
    content = $t(cm.notFoundBody);
  } else if (isErrorResponse(error)) {
    if (hasDetails(error)) {
      content = (
        <ul className="slm-plain">
          {error.details.map((item: any, index) => {
            const message = resolveConstraintMessage(
              messageDescriptors,
              item.constraint
            );
            if (message) {
              return (
                <li key={index}>
                  <FormattedMessage
                    message={message}
                    values={{ ...values, ...item.value }}
                  />
                </li>
              );
            } else if (item.explanation) {
              return <li key={index}>{item.explanation}</li>;
            } else {
              return (
                <li key={index}>
                  <pre className="json" key={index}>
                    {JSON.stringify(item, null, 2)}
                  </pre>
                </li>
              );
            }
          })}
        </ul>
      );
    } else if (hasMessage(error)) {
      content = <p>{error.message}</p>;
    } else {
      content = <pre className="json">{JSON.stringify(error, null, 2)}</pre>;
    }
  }

  return (
    <>
      <h2>{message.summary}</h2>
      {content}
    </>
  );
};

const ErrorDetailsDialog: React.FC<{
  messages: FeedbackMessage[];
  hideModal: HideModal;
}> = ({ messages, hideModal }) => {
  const { isModalVisible, onModalClosed, onClose } = useDialogActions({
    hideModal,
    onOutcome: () => undefined,
  });

  // Keep a reference to the messages, because the underlying feedback state may be cleared while the modal is shown.
  const messagesRef = useRef(messages);
  if (messages?.length > 0) {
    messagesRef.current = messages;
  }

  const { $t } = useIntl();

  return (
    <Modal
      visible={isModalVisible}
      handleCloseBtn={onClose}
      onModalClosed={onModalClosed}
      className="slm-feedback-details"
    >
      <Prompt
        title={''}
        header={<ModalHeader title={$t(cm.details)} />}
        footer={
          <ModalFooter>
            <Button text={$t(cm.ok)} onClick={onClose} />
          </ModalFooter>
        }
      >
        {messagesRef.current.map((message, index) => (
          <ErrorDetails message={message} key={index} />
        ))}
      </Prompt>
    </Modal>
  );
};

export function useShowErrorDetailsDialog(messages: FeedbackMessage[]) {
  const [showModal, hideModal] = useModal(
    () => <ErrorDetailsDialog messages={messages} hideModal={hideModal} />,
    [messages]
  );
  return showModal;
}
