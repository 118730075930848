import React from 'react';
import Tooltip from '@ingka/tooltip';

type MissingFieldProps = {
  tooltipText: string;
};

const MissingField: React.FC<MissingFieldProps> = ({ tooltipText }) => {
  return (
    <Tooltip tooltipText={tooltipText}>
      <span>!</span>
    </Tooltip>
  );
};

export default MissingField;
