import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import MissingField from 'views/Maintain/components/Result/MissingField';
import mm from 'views/Maintain/maintainMessages';
import { findEnumMessage } from 'core/util/enum';
import Tooltip from '@ingka/tooltip';
import { MessageDescriptorWithEnum } from 'views/Maintain/types';
import { parseApiDate } from 'core/util/date';

/**
 * Returns a function for cell rendering with tanstack table, to show a number as an Integer
 */
export function useIntegerCell() {
  const { $t, formatNumber } = useIntl();
  return useCallback(
    props => {
      const value = props.getValue() as number;
      return value === null ? (
        <MissingField tooltipText={$t(mm.maintainMissingDataTooltip)} />
      ) : (
        formatNumber(value, {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        })
      );
    },
    [$t, formatNumber]
  );
}

export function useApiDateCell() {
  const { formatDate } = useIntl();
  return useCallback(
    props => {
      const date = props.getValue() as string;
      return date ? formatDate(parseApiDate(date)) : '-';
    },
    [formatDate]
  );
}

export type EnumArrayCellHookArgs = {
  shortMessages?: Record<string, MessageDescriptorWithEnum>;
  tooltipMessages: Record<string, MessageDescriptorWithEnum>;
  showValues?: boolean;
  maxItems?: number;
  tooManyText?: string;
};

export function useEnumArrayCell({
  shortMessages,
  tooltipMessages,
  showValues = false,
  maxItems = 1,
  tooManyText = '(...)',
}: EnumArrayCellHookArgs) {
  const { $t } = useIntl();
  return useCallback(
    props => {
      const values = (props.getValue() as string[]) ?? [];
      const items = values.map(value => {
        const itemTooltipDescriptor = findEnumMessage(tooltipMessages, value);
        const itemTooltip = itemTooltipDescriptor
          ? $t(itemTooltipDescriptor)
          : value;
        if (showValues) {
          return { itemValue: value, itemTooltip };
        }
        if (shortMessages) {
          const itemMessage = findEnumMessage(shortMessages, value);
          return {
            itemValue: itemMessage ? $t(itemMessage) : value,
            itemTooltip,
          };
        }
        return { itemValue: itemTooltip, itemTooltip };
      });

      const tooltipText = items.map(item => item.itemTooltip).join(', ');
      const fieldText =
        items.length <= maxItems
          ? items.map(item => item.itemValue).join(', ')
          : tooManyText;

      if (!showValues && !shortMessages && items.length <= maxItems) {
        return <span>{fieldText}</span>;
      }
      return (
        <Tooltip tooltipText={tooltipText} position="bottom">
          <span>{fieldText}</span>
        </Tooltip>
      );
    },
    [$t, maxItems, shortMessages, showValues, tooManyText, tooltipMessages]
  );
}

/*
 Simple currency cell that does not know of currency type, and makes assumption of two decimal digits if not an integer
 */
export function useSimpleCurrencyCell() {
  const { formatNumber } = useIntl();
  return useCallback(
    props => {
      const value = props.getValue() as number;
      const fractionDigits = Number.isInteger(value) ? 0 : 2;
      return value
        ? formatNumber(value, {
            maximumFractionDigits: fractionDigits,
            minimumFractionDigits: fractionDigits,
          })
        : '';
    },
    [formatNumber]
  );
}
