import { crudState, CrudState } from 'core/commonTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { selectCrudOperation } from 'core/redux/crudOperationsSlice';
import { useMemo } from 'react';

export function useTaggedCrudState(id: string): CrudState {
  const { type, tags } =
    useSelector((state: RootState) =>
      selectCrudOperation(state.crudOperations, id)
    ) ?? {};
  return useMemo<CrudState>(() => crudState(type, tags), [type, tags]);
}
