import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/root';
import defaultParametersApi from 'views/Parameters/redux/defaultParametersApi';
import structuresApi from 'views/StoreStructure/redux/structuresApi';
import f6ParametersApi from 'views/Parameters/redux/f6ParametersApi';
import parameterLimitsApi from 'views/Parameters/redux/parameterLimitsApi';
import articleParametersApi from 'views/Parameters/redux/articleParametersApi';
import groupParametersApi from 'views/Parameters/redux/groupParametersApi';
import parameterHistoryApi from 'views/Parameters/redux/parameterHistoryApi';
import userApi from 'core/redux/userApi';
import reportsApi from 'views/Summaries/redux/reportsApi';
import searchApi from 'views/Maintain/redux/searchApi';
import itemApi from 'views/Maintain/redux/itemApi';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import actionsApi from 'views/Actions/redux/actionsApi';
import utilityApi from 'views/DevTools/redux/utilityApi';
import storeMetadataApi from 'core/redux/storeMetadataApi';

type RootReducer = typeof rootReducer;

const persistedReducer = persistReducer(
  {
    whitelist: ['pendingUpdates', 'userPreferences'], // Parts of the rootReducer state to persist.
    storage, // Use Local Storage
    key: 'root', // Local Storage key
  },
  rootReducer
);

if (module.hot && process.env.NODE_ENV === 'development') {
  module.hot.accept('./reducers/root', () =>
    store.replaceReducer(persistedReducer as RootReducer)
  );
}

const store = configureStore({
  reducer: persistedReducer as RootReducer,
  middleware: getDefaultMiddleware =>
    // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      userApi.middleware,
      structuresApi.middleware,
      actionsApi.middleware,
      parameterLimitsApi.middleware,
      parameterHistoryApi.middleware,
      defaultParametersApi.middleware,
      articleParametersApi.middleware,
      groupParametersApi.middleware,
      f6ParametersApi.middleware,
      reportsApi.middleware,
      searchApi.middleware,
      itemApi.middleware,
      utilityApi.middleware,
      storeMetadataApi.middleware
    ),
});

export default store;

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
