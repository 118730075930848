import React, { useCallback, useMemo } from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Button from '@ingka/button';
import historyIcon from '@ingka/ssr-icon/paths/history';
import {
  ItemParameterDto,
  PaginatedItemParameterDto,
  ParameterDto,
} from 'apis/backendApi';
import { useIntl } from 'react-intl';
import pm from 'views/Parameters/parametersMessages';
import cm from 'core/commonMessages';
import { ArticlePagination } from 'components/Pagination';
import ParameterForm, {
  OnParameterFormSubmit,
  ParameterFormSubmitAction,
} from 'views/Parameters/components/form/ParameterForm';
import { FormId } from 'views/Parameters/redux/types';
import {
  createParameterFields,
  ParameterField,
  ParameterType,
} from 'views/Parameters/types';
import { useFormatNumber } from 'hooks/locale';
import { OnPageSelect } from 'components/Pagination/ArticlePagination';
import { OnGroupParameterSubmit } from 'views/Parameters/hooks/groupParameters';

type SearchResultTableRowProps = {
  item: ItemParameterDto;
  onShowHistory: (itemNo: string) => void;
};

function useItemFormatting(item: ItemParameterDto) {
  const { $t } = useIntl();

  const formatNumber = useFormatNumber();

  return useMemo(() => {
    const article = item.itemNo;

    const description = item.itemName?.length
      ? item.itemName
      : $t(cm.noDescription);

    let value: string;
    const [field] = createParameterFields(item.parameters, []);
    switch (field.type) {
      case ParameterType.BOOLEAN:
        value = field.value !== null ? $t(field.value ? cm.on : cm.off) : null;
        break;
      case ParameterType.DOUBLE:
      case ParameterType.INTEGER:
        value = formatNumber(field.value as number);
        break;
      default:
        throw new Error(`Unhandled type: ${field.type}`);
    }

    return { article, description, value };
  }, [$t, item, formatNumber]);
}

const SearchResultTableRow: React.FC<SearchResultTableRowProps> = ({
  item,
  onShowHistory,
}) => {
  const { article, description, value } = useItemFormatting(item);

  const onHistoryClick = useCallback(() => {
    onShowHistory(item.itemNo);
  }, [item, onShowHistory]);

  const { $t } = useIntl();

  return (
    <tr>
      <td>
        <Button
          ssrIcon={historyIcon}
          type="plain"
          title={$t(pm.openChangeHistory)}
          onClick={onHistoryClick}
        />
      </td>
      <td>{article}</td>
      <td>{description}</td>
      <td>{value}</td>
    </tr>
  );
};

export type GroupSearchResultsProps = {
  items: PaginatedItemParameterDto;
  fields: ParameterField[];
  onSubmit: OnGroupParameterSubmit;
  onShowHistory: (itemNo: string) => void;
  onPageSelect: OnPageSelect;
};

const GroupSearchResults: React.FC<GroupSearchResultsProps> = ({
  items,
  fields,
  onSubmit,
  onShowHistory,
  onPageSelect,
}) => {
  const onParameterFormSubmit = useCallback<OnParameterFormSubmit>(
    ({ all }) => onSubmit(all[0] as unknown as ParameterDto),
    [onSubmit]
  );

  const { $t } = useIntl();

  return (
    <>
      <fieldset>
        <legend>{$t(cm.searchResults)}</legend>
        <Table>
          <TableHeader>
            <tr>
              <th />
              <th>{$t(pm.article)}</th>
              <th>{$t(cm.description)}</th>
              <th>{$t(pm.parameterValue)}</th>
            </tr>
          </TableHeader>
          <TableBody striped>
            {items.content.map(item => (
              <SearchResultTableRow
                item={item}
                onShowHistory={onShowHistory}
                key={item.itemNo}
              />
            ))}
          </TableBody>
        </Table>
        {items.totalElements > items.pageSize && (
          <ArticlePagination
            onPageSelect={onPageSelect}
            pageNr={items.pageNr}
            pageSize={items.pageSize}
            totalElements={items.totalElements}
          />
        )}
      </fieldset>
      <fieldset>
        <legend>{$t(pm.newParameterValue)}</legend>
        <ParameterForm
          fields={fields}
          formId={FormId.GROUP}
          onSubmit={onParameterFormSubmit}
          isNonDirtySubmitEnabled
          showReset
          submitAction={ParameterFormSubmitAction.SUBMIT_ALL}
        />
      </fieldset>
    </>
  );
};

export default GroupSearchResults;
