import { merchandisingMessages } from 'views/Maintain/maintainMessages';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { MerchandisingSolution } from 'apis/backendApi';

function useMerchandisingTerms(form: keyof typeof merchandisingMessages) {
  const { $t } = useIntl();

  return useCallback(
    (props: MerchandisingSolution) => {
      const message = merchandisingMessages[form][props];
      if (message) {
        return $t(message);
      }
    },
    [$t, form]
  );
}

export { useMerchandisingTerms };
