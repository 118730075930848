import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ParameterFormValues } from 'views/Parameters/components/form/ParameterForm';
import { ParameterField } from 'views/Parameters/types';
import { RootState } from 'core/store';
import { FormId } from 'views/Parameters/redux/types';

export enum Approval {
  /**
   * Indicates that approval is required for the given parameter.
   */
  REQUIRED = 'required',
  /**
   * Indicates that the parameter change is explicitly accepted by the user.
   */
  ACCEPTED = 'accepted',
}

export type ApprovalValues = {
  [name: string]: Approval;
};

/**
 * The part of the approval state intended for UI consumption.
 */
export type ApprovalState = {
  /**
   * The dirty form values for critical parameters.
   */
  dirtyValues: ParameterFormValues;

  /**
   * The approval status for each critical parameter.
   */
  approvalValues: ApprovalValues;

  /**
   * Indicates if there are any pending approvals.
   */
  hasPendingApprovals: boolean;
};

/**
 * State for internal use.
 */
type InternalApprovalState = ApprovalState & {
  /**
   * The names of the critical parameters.
   *
   * The implementation uses this to filter out the form values that actually represent critical parameters.
   */
  criticalParameters: string[];

  /**
   * The changes that are explicitly approved.
   */
  acceptedChanges: ParameterFormValues;
};

/**
 * The top-level state, grouped by form.
 */
type InternalFormApprovalState = {
  // Maintains form-specific approval state
  // Note: index signatures cannot be of a custom type, so we use string here instead of FormId
  approvals: { [formId: string]: InternalApprovalState };
};

function approvalValues(state: InternalApprovalState): ApprovalValues {
  type ApprovalStatusEntry = [string, Approval];
  const accepted: ApprovalStatusEntry[] = state.criticalParameters
    .filter(
      name =>
        state.dirtyValues[name] &&
        state.dirtyValues[name] === state.acceptedChanges[name]
    )
    .map(name => [name, Approval.ACCEPTED]);
  return {
    ...Object.fromEntries(accepted),
  };
}

function hasPendingApprovals(state: InternalApprovalState): boolean {
  const { dirtyValues, acceptedChanges } = state;
  const names = Object.keys(dirtyValues);
  const pending = names.reduce(
    (count, name) =>
      count - (dirtyValues[name] === acceptedChanges[name] ? 1 : 0),
    names.length
  );
  return pending > 0;
}

const DEFAULT_APPROVAL: ApprovalState = {
  hasPendingApprovals: false,
  approvalValues: {},
  dirtyValues: {},
};

/**
 * Redux slice for maintaining the state of parameter approval. Approval is needed when the user changes parameters that
 * are considered critical.
 *
 * This slice is designed to be extended with future functional requirements related to parameters, not just approval.
 */
const parameterApprovalSlice = createSlice({
  name: 'parameterApproval',
  initialState: { approvals: {} } as InternalFormApprovalState,
  reducers: {
    /**
     * Resets approval state. Should be invoked when resetting the form.
     */
    resetApprovals(
      state,
      action: PayloadAction<{ formId: FormId; fields: ParameterField[] }>
    ) {
      const { formId, fields } = action.payload;

      state.approvals[formId] = {
        ...DEFAULT_APPROVAL,
        criticalParameters: fields
          .filter(field => field.isCritical && !field.isReadonly)
          .map(field => field.name),
        acceptedChanges: {},
      };
    },

    /**
     * Sets all dirty form values. For ease-of-use, the implementation ignores non-critical parameters.
     *
     * This action does not affect the accepted/rejected state.
     */
    setDirtyFormValues(
      state,
      action: PayloadAction<{
        formId: FormId;
        values: ParameterFormValues;
      }>
    ) {
      const {
        payload: { formId, values },
      } = action;
      const approval = state.approvals[formId];

      approval.dirtyValues = Object.fromEntries(
        approval.criticalParameters
          .filter(name => values[name] !== undefined)
          .map(name => [name, values[name]])
      );

      approval.approvalValues = approvalValues(approval);
      approval.hasPendingApprovals = hasPendingApprovals(approval);
    },

    /**
     * Accepts the change to the given parameter.
     *
     * @param state
     * @param action
     */
    acceptChange(
      state,
      action: PayloadAction<{ formId: FormId; name: string }>
    ) {
      const {
        payload: { formId, name },
      } = action;
      const approval = state.approvals[formId];

      approval.acceptedChanges[name] = approval.dirtyValues[name];

      approval.approvalValues = approvalValues(approval);
      approval.hasPendingApprovals = hasPendingApprovals(approval);
    },

    /**
     * Rejects the change to the given parameter.
     *
     * This is relevant when the user rejects a parameter change that was previously accepted.
     *
     * @param state
     * @param action
     */
    rejectChange(
      state,
      action: PayloadAction<{ formId: FormId; name: string }>
    ) {
      const {
        payload: { formId, name },
      } = action;
      const approval = state.approvals[formId];

      delete approval.acceptedChanges[name];

      approval.approvalValues = approvalValues(approval);
      approval.hasPendingApprovals = hasPendingApprovals(approval);
    },
  },
});

export default parameterApprovalSlice;

export const {
  resetApprovals,
  setDirtyFormValues,
  acceptChange,
  rejectChange,
} = parameterApprovalSlice.actions;

const selectSliceRoot = (root: RootState) => root.criticalParameters;

export const selectApproval = createSelector(
  [selectSliceRoot, (state, formId: FormId) => formId],
  (root: InternalFormApprovalState, formId: FormId): ApprovalState =>
    root.approvals[formId] ?? DEFAULT_APPROVAL
);
