/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible Life Cycle values
 * @export
 */
export const SearchLifeCycleEnum = {
    RUNNING: 'running',
    NEWS: 'news',
    ACTUAL_DISCONTINUED: 'actDisc',
    PAST_DISCONTINUED: 'pastDisc',
    ALL_DISCONTINUED: 'allDisc',
    NEWS_PLUS_DISCONTINUED: 'newsDisc'
} as const;
export type SearchLifeCycleEnum = typeof SearchLifeCycleEnum[keyof typeof SearchLifeCycleEnum];


export function instanceOfSearchLifeCycleEnum(value: any): boolean {
    for (const key in SearchLifeCycleEnum) {
        if (Object.prototype.hasOwnProperty.call(SearchLifeCycleEnum, key)) {
            if (SearchLifeCycleEnum[key as keyof typeof SearchLifeCycleEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SearchLifeCycleEnumFromJSON(json: any): SearchLifeCycleEnum {
    return SearchLifeCycleEnumFromJSONTyped(json, false);
}

export function SearchLifeCycleEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchLifeCycleEnum {
    return json as SearchLifeCycleEnum;
}

export function SearchLifeCycleEnumToJSON(value?: SearchLifeCycleEnum | null): any {
    return value as any;
}

