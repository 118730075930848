import React, { ReactNode, useCallback } from 'react';
import {
  HideModal,
  OnDialogOutcome,
  useBooleanDialogActions,
} from 'hooks/modal';
import { useModal } from 'react-modal-hook';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import Button from '@ingka/button';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';

type DialogProps = {
  /**
   * Modal title
   */
  title: string;

  /**
   * Modal body.
   */
  body: ReactNode;

  onOutcome: OnDialogOutcome<boolean>;

  /**
   * Function to invoke for hiding the modal.
   */
  hideModal: HideModal;

  /**
   * Optional confirm button label.
   *
   * @default "OK" translation
   */
  okLabel?: string;

  /**
   * Indicates whether the OK button is shown as Primary.
   *
   * @default true
   */
  okPrimary?: boolean;

  /**
   * Optional cancel button label.
   *
   * @default "Cancel" translation
   */
  cancelLabel?: string;

  /**
   * Indicates whether to show the Cancel button.
   *
   * @default true
   */
  showCancel?: boolean;

  /**
   * Optional CSS class.
   */
  className?: string;
};

const Dialog: React.FC<DialogProps> = ({
  title,
  body,
  hideModal,
  onOutcome,
  okLabel,
  okPrimary = true,
  cancelLabel,
  showCancel = true,
}) => {
  const { isModalVisible, onModalClosed, onOk, onCancel, onClose } =
    useBooleanDialogActions({
      hideModal,
      onOutcome,
    });

  const { $t } = useIntl();
  okLabel = okLabel ?? $t(cm.ok);
  cancelLabel = cancelLabel ?? $t(cm.cancel);

  return (
    <Modal
      visible={isModalVisible}
      handleCloseBtn={onClose}
      onModalClosed={onModalClosed}
    >
      <Prompt
        className="slm-ok-cancel"
        title={title}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button
              type={okPrimary ? 'primary' : 'secondary'}
              text={okLabel}
              onClick={onOk}
            />
            {showCancel && <Button text={cancelLabel} onClick={onCancel} />}
          </ModalFooter>
        }
      >
        {body}
      </Prompt>
    </Modal>
  );
};

export function useConfirmDialog({
  title,
  body,
  onOutcome,
}: {
  title: string;
  body: ReactNode;
  onOutcome: OnDialogOutcome<boolean>;
}) {
  const [showModal, hideModal] = useModal(
    () => (
      <Dialog
        hideModal={hideModal}
        title={title}
        body={body}
        onOutcome={onOutcome}
      />
    ),
    [title, body, onOutcome]
  );
  return showModal;
}

export function useAlertDialog({
  title,
  body,
  onClose = () => {},
}: {
  title: string;
  body: ReactNode;
  onClose?: OnDialogOutcome<void>;
}) {
  const onOutcome = useCallback<OnDialogOutcome<boolean>>(() => {
    onClose();
  }, [onClose]);

  const [showModal, hideModal] = useModal(
    () => (
      <Dialog
        hideModal={hideModal}
        title={title}
        body={body}
        onOutcome={onOutcome}
        showCancel={false}
      />
    ),
    [title, body, onOutcome]
  );
  return showModal;
}

/**
 * Show a dialog that encapsulates an entire view.
 *
 * @param title
 * @param body
 */
export function useViewDialog({
  title,
  body,
}: {
  title: string;
  body: ReactNode;
}) {
  const { $t } = useIntl();
  const [showModal, hideModal] = useModal(
    () => (
      <Dialog
        hideModal={hideModal}
        title={title}
        body={body}
        okLabel={$t(cm.close)}
        okPrimary={false}
        onOutcome={() => undefined}
        showCancel={false}
      />
    ),
    [title, body]
  );
  return showModal;
}
