import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import InlineMessage from '@ingka/inline-message';
import messages from 'core/commonMessages';

type NotFoundMessageProps = {
  /**
   * Optional message title.
   *
   * @default the common "not found" title.
   */
  title?: MessageDescriptor;

  /**
   * Optional message body.
   *
   * @default the common "not found" body.
   */
  body?: MessageDescriptor;
};

/**
 * Displays a "404 Not Found"-like message.
 *
 * @see https://skapa.ikea.net/components/messages/presenting-messages
 */
const NotFoundMessage: React.FC<NotFoundMessageProps> = ({
  title = messages.notFoundTitle,
  body = messages.notFoundBody,
}) => {
  const { $t } = useIntl();
  return <InlineMessage body={$t(body)} title={$t(title)} variant="negative" />;
};

export default NotFoundMessage;
