/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DefaultParameterDto,
  ErrorResponse,
  ItemParameterDto,
  PaginatedItemParameterDto,
  ParameterDto,
  ParameterHistory,
  ParameterLimits,
} from '../models/index';
import {
    DefaultParameterDtoFromJSON,
    DefaultParameterDtoToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    ItemParameterDtoFromJSON,
    ItemParameterDtoToJSON,
    PaginatedItemParameterDtoFromJSON,
    PaginatedItemParameterDtoToJSON,
    ParameterDtoFromJSON,
    ParameterDtoToJSON,
    ParameterHistoryFromJSON,
    ParameterHistoryToJSON,
    ParameterLimitsFromJSON,
    ParameterLimitsToJSON,
} from '../models/index';

export interface GetDefaultParametersRequest {
    buCode: string;
}

export interface GetF6ParametersRequest {
    buCode: string;
}

export interface GetParameterHistoryRequest {
    buCode: string;
    filter: GetParameterHistoryFilterEnum;
    filterValue: string;
    paramId?: string;
}

export interface GetParameterValueLimitsRequest {
    buCode: string;
    filter: GetParameterValueLimitsFilterEnum;
    filterValue: string;
}

export interface GetParametersForItemsRequest {
    buCode: string;
    filter: string;
    filterValue: string;
    parameter: string;
    pageNr?: number;
    pageSize?: number;
}

export interface GetParametersForSingleItemRequest {
    buCode: string;
    itemNo: string;
}

/**
 * 
 */
export class GETParametersApi extends runtime.BaseAPI {

    /**
     * Gets the defualt parameter and their values
     * Gets the parameters and their values on store, division and HFB level
     */
    async getDefaultParametersRaw(requestParameters: GetDefaultParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DefaultParameterDto>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling getDefaultParameters().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/defaults`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DefaultParameterDtoFromJSON(jsonValue));
    }

    /**
     * Gets the defualt parameter and their values
     * Gets the parameters and their values on store, division and HFB level
     */
    async getDefaultParameters(requestParameters: GetDefaultParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DefaultParameterDto> {
        const response = await this.getDefaultParametersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the SLM F6 parameters for a store
     * Fetches all the SLM F6 parameters
     */
    async getF6ParametersRaw(requestParameters: GetF6ParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParameterDto>>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling getF6Parameters().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/f6`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParameterDtoFromJSON));
    }

    /**
     * Get the SLM F6 parameters for a store
     * Fetches all the SLM F6 parameters
     */
    async getF6Parameters(requestParameters: GetF6ParametersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParameterDto>> {
        const response = await this.getF6ParametersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all changes within the last year of any paramter that match the filter as a list without pagination.
     * Fetches the history for all parameters that match the filter
     */
    async getParameterHistoryRaw(requestParameters: GetParameterHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParameterHistory>>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling getParameterHistory().'
            );
        }

        if (requestParameters['filter'] == null) {
            throw new runtime.RequiredError(
                'filter',
                'Required parameter "filter" was null or undefined when calling getParameterHistory().'
            );
        }

        if (requestParameters['filterValue'] == null) {
            throw new runtime.RequiredError(
                'filterValue',
                'Required parameter "filterValue" was null or undefined when calling getParameterHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['filterValue'] != null) {
            queryParameters['filterValue'] = requestParameters['filterValue'];
        }

        if (requestParameters['paramId'] != null) {
            queryParameters['paramId'] = requestParameters['paramId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/history`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParameterHistoryFromJSON));
    }

    /**
     * Returns all changes within the last year of any paramter that match the filter as a list without pagination.
     * Fetches the history for all parameters that match the filter
     */
    async getParameterHistory(requestParameters: GetParameterHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParameterHistory>> {
        const response = await this.getParameterHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets value ranges allowed for parameters
     * Fetches all the items that match the filters
     */
    async getParameterValueLimitsRaw(requestParameters: GetParameterValueLimitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ParameterLimits>>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling getParameterValueLimits().'
            );
        }

        if (requestParameters['filter'] == null) {
            throw new runtime.RequiredError(
                'filter',
                'Required parameter "filter" was null or undefined when calling getParameterValueLimits().'
            );
        }

        if (requestParameters['filterValue'] == null) {
            throw new runtime.RequiredError(
                'filterValue',
                'Required parameter "filterValue" was null or undefined when calling getParameterValueLimits().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['filterValue'] != null) {
            queryParameters['filterValue'] = requestParameters['filterValue'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/limits`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ParameterLimitsFromJSON));
    }

    /**
     * Gets value ranges allowed for parameters
     * Fetches all the items that match the filters
     */
    async getParameterValueLimits(requestParameters: GetParameterValueLimitsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ParameterLimits>> {
        const response = await this.getParameterValueLimitsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the items that match the filters
     * Fetches all the items that match the filters
     */
    async getParametersForItemsRaw(requestParameters: GetParametersForItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PaginatedItemParameterDto>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling getParametersForItems().'
            );
        }

        if (requestParameters['filter'] == null) {
            throw new runtime.RequiredError(
                'filter',
                'Required parameter "filter" was null or undefined when calling getParametersForItems().'
            );
        }

        if (requestParameters['filterValue'] == null) {
            throw new runtime.RequiredError(
                'filterValue',
                'Required parameter "filterValue" was null or undefined when calling getParametersForItems().'
            );
        }

        if (requestParameters['parameter'] == null) {
            throw new runtime.RequiredError(
                'parameter',
                'Required parameter "parameter" was null or undefined when calling getParametersForItems().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['filterValue'] != null) {
            queryParameters['filterValue'] = requestParameters['filterValue'];
        }

        if (requestParameters['parameter'] != null) {
            queryParameters['parameter'] = requestParameters['parameter'];
        }

        if (requestParameters['pageNr'] != null) {
            queryParameters['pageNr'] = requestParameters['pageNr'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/items`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedItemParameterDtoFromJSON(jsonValue));
    }

    /**
     * Gets the items that match the filters
     * Fetches all the items that match the filters
     */
    async getParametersForItems(requestParameters: GetParametersForItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PaginatedItemParameterDto> {
        const response = await this.getParametersForItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the item and it\'s parameter values
     * Gets parameters and their values for the given item number
     */
    async getParametersForSingleItemRaw(requestParameters: GetParametersForSingleItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ItemParameterDto>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling getParametersForSingleItem().'
            );
        }

        if (requestParameters['itemNo'] == null) {
            throw new runtime.RequiredError(
                'itemNo',
                'Required parameter "itemNo" was null or undefined when calling getParametersForSingleItem().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/parameters/v1/{buCode}/item/{itemNo}`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))).replace(`{${"itemNo"}}`, encodeURIComponent(String(requestParameters['itemNo']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemParameterDtoFromJSON(jsonValue));
    }

    /**
     * Gets the item and it\'s parameter values
     * Gets parameters and their values for the given item number
     */
    async getParametersForSingleItem(requestParameters: GetParametersForSingleItemRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ItemParameterDto> {
        const response = await this.getParametersForSingleItemRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const GetParameterHistoryFilterEnum = {
    F6: 'f6',
    Store: 'store',
    Division: 'division',
    Hfb: 'hfb',
    Item: 'item'
} as const;
export type GetParameterHistoryFilterEnum = typeof GetParameterHistoryFilterEnum[keyof typeof GetParameterHistoryFilterEnum];
/**
 * @export
 */
export const GetParameterValueLimitsFilterEnum = {
    Store: 'store',
    Division: 'division',
    SpecialityShop: 'speciality_shop',
    Hfb: 'hfb',
    RangeArea: 'range_area',
    ProductArea: 'product_area',
    SalesMethod: 'sales_method',
    ServiceClass: 'service_class',
    Item: 'item'
} as const;
export type GetParameterValueLimitsFilterEnum = typeof GetParameterValueLimitsFilterEnum[keyof typeof GetParameterValueLimitsFilterEnum];
