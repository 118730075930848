import { useGetTimeViewQuery } from 'views/Summaries/redux/reportsApi';
import { getApiDate } from 'core/util/date';
import { useCallback, useMemo } from 'react';
import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  columnKey,
  HEADING,
  NumberFormatter,
  StatusColorResolver,
  SummaryLine,
  SummaryValue,
  TimeViewTopic,
} from 'views/Summaries/types';
import { useFormatNumber } from 'hooks/locale';
import { TopicOption, useTopicSearchParam } from 'views/Summaries/hooks/topic';
import { useIntl } from 'react-intl';
import sm from 'views/Summaries/summariesMessages';
import { useGetF6ParametersQuery } from 'views/Parameters/redux/f6ParametersApi';
import { enumValue } from 'core/util/enum';
import { StatusColor } from 'core/types';
import { SubRowsDto } from 'apis/backendApi';
import { useSummariesContext } from 'views/Summaries/Summaries';

export function useTimeViewSelection() {
  const { topic, onChangeTopic } = useTopicSearchParam();

  const { $t } = useIntl();

  const topicOptions = useMemo<TopicOption[]>(() => {
    return [
      { value: TimeViewTopic.DIRECT_FLOW, name: $t(sm.directflowOption) },
      { value: TimeViewTopic.SPACE_NEED, name: $t(sm.spaceneedOption) },
      {
        value: TimeViewTopic.EXPECTED_TURNOVER_REVENUE,
        name: $t(sm.expectedturnoverrevenueOption),
      },
      {
        value: TimeViewTopic.EXPECTED_TURNOVER_REVENUE_M2,
        name: $t(sm.expectedturnoverrevenuem2Option),
      },
      { value: TimeViewTopic.MAX_DENSITY, name: $t(sm.maxdensityOption) },
    ];
  }, [$t]);

  return {
    topic,
    onChangeTopic,
    topicOptions,
  };
}

function mapTimeViewSummaryLine(
  dto: SubRowsDto,
  topic: TimeViewTopic,
  formatNumber: NumberFormatter,
  resolveStatusColor: StatusColorResolver
): SummaryLine {
  const headingCell: [string, SummaryValue] = [
    columnKey(HEADING),
    { text: dto.name },
  ];

  const valueCells = dto.values.map<[string, SummaryValue]>(
    (dtoValue, columnIndex) => {
      let value = dtoValue as unknown as number;
      let useGrouping: boolean;
      switch (topic) {
        case TimeViewTopic.SPACE_NEED:
          useGrouping = false;
          break;
        case TimeViewTopic.EXPECTED_TURNOVER_REVENUE:
          value = Math.floor(value * 0.00001);
          break;
        case TimeViewTopic.EXPECTED_TURNOVER_REVENUE_M2:
          value = Math.floor(value * 0.01);
          useGrouping = false;
          break;
      }
      return [
        columnKey(columnIndex),
        {
          text: formatNumber(value, {
            useGrouping,
          }),
          statusColor: resolveStatusColor(value),
        },
      ];
    }
  );

  const row = Object.fromEntries([headingCell, ...valueCells]) as SummaryLine;
  row.children = dto.subRows?.map(dto =>
    mapTimeViewSummaryLine(dto, topic, formatNumber, resolveStatusColor)
  );
  return row;
}

export function useTimeViewTable(topic: string | null) {
  const skipF6 = topic !== TimeViewTopic.DIRECT_FLOW;

  const f6ParametersQuery = useGetF6ParametersQuery(undefined, {
    skip: skipF6,
  });

  const { data: f6Parameters } = f6ParametersQuery;

  const directFlowGreenLimit = useMemo(
    () =>
      f6Parameters?.find(param => param.name === 'SLM_DIR_FLOW_GREEN')?.value,
    [f6Parameters]
  ) as unknown as number;

  const directFlowYellowLimit = useMemo(
    () =>
      f6Parameters?.find(param => param.name === 'SLM_DIR_FLOW_YELLOW')?.value,
    [f6Parameters]
  ) as unknown as number;

  const resolveStatus = useCallback<StatusColorResolver>(
    value => {
      if (
        skipF6 ||
        directFlowGreenLimit === undefined ||
        directFlowYellowLimit === undefined
      ) {
        return null;
      }
      if (value >= directFlowGreenLimit) {
        return StatusColor.GREEN;
      }
      if (value >= directFlowYellowLimit) {
        return StatusColor.YELLOW;
      }
      return StatusColor.RED;
    },
    [directFlowGreenLimit, directFlowYellowLimit, skipF6]
  );

  const timeViewQuery = useGetTimeViewQuery({
    buCode: '',
    reportname: topic,
    date: getApiDate(new Date()),
  });

  const columns = useMemo(() => {
    const headingColumn = {
      accessorKey: columnKey(HEADING),
      header: null,
    };

    const valueColumns = (timeViewQuery.data?.columns ?? []).map(
      (name, index) => ({
        accessorKey: columnKey(index),
        header: name,
      })
    );

    return [headingColumn, ...valueColumns];
  }, [timeViewQuery.data]);

  const formatNumber = useFormatNumber();

  const data = useMemo(() => {
    return timeViewQuery.data?.baseNodes.map<SummaryLine>(dto =>
      mapTimeViewSummaryLine(
        dto,
        enumValue(TimeViewTopic, topic),
        formatNumber,
        resolveStatus
      )
    );
  }, [formatNumber, resolveStatus, timeViewQuery.data, topic]);

  const { timeViewExpandedState, setTimeViewExpandedState } =
    useSummariesContext();

  const table = useReactTable<SummaryLine>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row: SummaryLine) => {
      return row.children;
    },
    state: {
      expanded: timeViewExpandedState,
    },
    onExpandedChange: (newState: ExpandedState) => {
      setTimeViewExpandedState(newState);
    },
  });

  return {
    isLoading: f6ParametersQuery.isLoading || timeViewQuery.isLoading,
    isFetching: f6ParametersQuery.isFetching || timeViewQuery.isFetching,
    isError: f6ParametersQuery.isError || timeViewQuery.isError,
    isSuccess:
      (skipF6 || f6ParametersQuery.isSuccess) && timeViewQuery.isSuccess,
    table: data ? table : undefined,
  };
}
