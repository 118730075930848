import React, { useCallback, useState } from 'react';
import InputField from '@ingka/input-field';
import { getInputDate } from 'core/util/date';
import cm from 'core/commonMessages';
import { useIntl } from 'react-intl';
import { useSearchQueriesUrl } from 'views/Maintain/hooks/searchQueries';
import { useOnChangeDate } from 'hooks/date';
import Button from '@ingka/button';

const AttributeDateInput: React.FC = () => {
  const { $t } = useIntl();
  const { searchCriteria, setSearchCriteria } = useSearchQueriesUrl();
  const [date, setDate] = useState(searchCriteria.attributeDate);
  const onAttrDate = useOnChangeDate(newDate => {
    setDate(newDate);
  });
  const applyDate = useCallback(() => {
    setSearchCriteria({ ...searchCriteria, attributeDate: date });
  }, [date, searchCriteria, setSearchCriteria]);
  const inputDate = getInputDate(date);
  return (
    <div className="slm-d-flex-row-ae-50 slm-mb-100">
      <InputField
        type="date"
        id="attrDateCommTable"
        onChange={onAttrDate}
        value={inputDate}
        className="slm-d-inline-block slm-table-size"
        min={getInputDate(new Date())}
      />
      <Button
        onClick={applyDate}
        text={$t(cm.apply)}
        disabled={inputDate === getInputDate(searchCriteria.attributeDate)}
        small
      />
    </div>
  );
};

export default AttributeDateInput;
