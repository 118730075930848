import React, { useMemo } from 'react';
import Select, { SingleValue } from 'react-select';
import { StoreOption } from 'core/redux/userApi';
import { SelectedStore } from 'core/types';
import ListView, { ListViewItem } from '@ingka/list-view';
import pinIcon from '@ingka/ssr-icon/paths/location-pin-small';
import cm from 'core/commonMessages';
import { useIntl } from 'react-intl';

type StoreListProps = {
  storeOptions: StoreOption[];
  selectStore: (store: SelectedStore) => void;
  /**
   * Treshold at which to render text filter widget, instead of a list.
   *
   * @default 6
   */
  textFilterTreshold?: number;
};

const StoreList: React.FC<StoreListProps> = ({
  storeOptions,
  selectStore,
  textFilterTreshold = 6,
}) => {
  const options = useMemo(
    () =>
      storeOptions.map(({ store, region, country }, i) => ({
        value: i,
        code: store.code,
        name: store.name,
        label: `${store.code}: ${region} - ${country} - ${store.name}`,
      })),
    [storeOptions]
  );

  const { $t } = useIntl();

  return (
    <div className="slm-tas">
      {options.length > textFilterTreshold ? (
        <Select
          id="storeSelector"
          defaultMenuIsOpen
          defaultValue={null}
          autoFocus
          maxMenuHeight={550}
          options={options}
          placeholder={$t(cm.selectStorePlaceholder)}
          onChange={(item: SingleValue<{ code: string; name: string }>) => {
            selectStore(item);
          }}
        />
      ) : (
        <ListView id="storeMenu">
          {options.map(item => (
            <ListViewItem
              key={item.value}
              leadingIcon={pinIcon}
              control="navigational"
              controlIcon={null}
              controlProps={{
                value: '',
              }}
              title={item.label}
              onClick={() => selectStore(options[item.value])}
              data-anchor={`storeMenu-${item.code}`}
            />
          ))}
        </ListView>
      )}
    </div>
  );
};

export default StoreList;
