/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DivisionDto } from './DivisionDto';
import {
    DivisionDtoFromJSON,
    DivisionDtoFromJSONTyped,
    DivisionDtoToJSON,
} from './DivisionDto';

/**
 * 
 * @export
 * @interface OverallStoreStructureDto
 */
export interface OverallStoreStructureDto {
    /**
     * 
     * @type {Array<DivisionDto>}
     * @memberof OverallStoreStructureDto
     */
    divisions?: Array<DivisionDto>;
}

/**
 * Check if a given object implements the OverallStoreStructureDto interface.
 */
export function instanceOfOverallStoreStructureDto(value: object): value is OverallStoreStructureDto {
    return true;
}

export function OverallStoreStructureDtoFromJSON(json: any): OverallStoreStructureDto {
    return OverallStoreStructureDtoFromJSONTyped(json, false);
}

export function OverallStoreStructureDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OverallStoreStructureDto {
    if (json == null) {
        return json;
    }
    return {
        
        'divisions': json['divisions'] == null ? undefined : ((json['divisions'] as Array<any>).map(DivisionDtoFromJSON)),
    };
}

export function OverallStoreStructureDtoToJSON(value?: OverallStoreStructureDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'divisions': value['divisions'] == null ? undefined : ((value['divisions'] as Array<any>).map(DivisionDtoToJSON)),
    };
}

