import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery, apiSerializeQueryArgs } from 'core/rtkQuery';
import { CalculationMetaDataDto } from 'apis/backendApi';

const utilityApi = createApi({
  reducerPath: 'utilityApi',
  baseQuery: apiQuery(),
  serializeQueryArgs: apiSerializeQueryArgs,

  endpoints: builder => ({
    getCalculationsItemMetadata: builder.query<
      CalculationMetaDataDto,
      { itemNo: string }
    >({
      query: ({ itemNo }) => ({
        url: '/calculations/v1/{buCode}/metadata/{itemNo}',
        pathVariables: {
          itemNo,
        },
      }),
      keepUnusedDataFor: 0,
    }),

    postCalculationsTriggerItem: builder.mutation<
      void,
      { itemNo: string; date?: string }
    >({
      query: ({ itemNo, date }) => ({
        method: 'POST',
        url: '/calculations/v1/{buCode}/trigger/{itemNo}',
        pathVariables: {
          itemNo,
        },
        params: { date },
      }),
    }),

    getCalculationsStoreMetadata: builder.query<CalculationMetaDataDto, void>({
      query: () => ({
        url: '/calculations/v1/{buCode}/metadata',
      }),
      keepUnusedDataFor: 0,
    }),

    postCalculationsTriggerStore: builder.mutation<void, { date?: string }>({
      query: ({ date }) => ({
        method: 'POST',
        url: '/calculations/v1/{buCode}/trigger',
        params: { date },
      }),
    }),

    postItemAssignmentMigration: builder.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: '/items/v1/{buCode}/assignment-migration',
        params: { background: true },
      }),
    }),
  }),
});

export const {
  useLazyGetCalculationsItemMetadataQuery,
  usePostCalculationsTriggerItemMutation,
  usePostCalculationsTriggerStoreMutation,
  useLazyGetCalculationsStoreMetadataQuery,
  usePostItemAssignmentMigrationMutation,
} = utilityApi;

export default utilityApi;
