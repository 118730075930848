import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Config } from 'core/commonTypes';
import { useOptionalUser } from '../UserContext';
import ConsentPopup from './ConsentPopup';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

type AnalyticsProps = {
  config: Config;
  children?: React.ReactNode;
};

export type AnalyticsControls = {
  analyticsConsent: boolean | null;
  setAnalyticsConsent: (givesConsent: boolean) => void;
  hasAnalyticsCapability: boolean;
};

const LOCAL_STORAGE_KEY = 'slm_analytics_consent' as const;

const AnalyticsContext = createContext<AnalyticsControls>(null);

const Analytics: React.FC<AnalyticsProps> = ({ config, children }) => {
  const user = useOptionalUser();
  const [consent, setConsent] = useState<Gtag.ConsentParams | null>(() => {
    const storedConsent = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    return storedConsent === null ? null : JSON.parse(storedConsent); // intentionally allow null to check if user made active choice
  });
  const [initialized, setInitialized] = useState(false);
  const [gaEnabled, setGaEnabled] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      return;
    }

    if (!initialized && config && user && typeof window.gtag !== 'function') {
      if (!user.country || config.ga.countryExclusions.includes(user.country)) {
        setInitialized(true);
        return;
      }

      window.dataLayer = window.dataLayer || [];
      window.gtag = function () {
        window.dataLayer.push(arguments);
      };
      window.gtag(
        'consent',
        'default',
        consent || {
          ad_storage: 'denied',
          analytics_storage: 'denied',
        }
      );
      window.gtag('js', new Date());
      window.gtag('config', config.ga.tag);
      const script = window.document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${config.ga.tag}`;
      window.document.body.appendChild(script);
      setInitialized(true);
      setGaEnabled(true);
    }
  }, [config, user, consent, initialized, gaEnabled]);

  const setConsentFn = useCallback(givesConsent => {
    const consentValue: Gtag.ConsentParams = {
      ad_storage: 'denied',
      analytics_storage: givesConsent ? 'granted' : 'denied',
    };
    setConsent(consentValue);
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(consentValue));
    if (window.gtag) {
      window.gtag('consent', 'update', consentValue);
    }
  }, []);

  const analyticsValue: AnalyticsControls = {
    analyticsConsent: consent?.analytics_storage === 'granted',
    setAnalyticsConsent: setConsentFn,
    hasAnalyticsCapability: initialized && gaEnabled,
  };

  return (
    <AnalyticsContext.Provider value={analyticsValue}>
      <ConsentPopup
        hasSetConsent={consent !== null}
        givesConsent={consent?.analytics_storage === 'granted' || false}
        setConsent={setConsentFn}
      />
      {children}
    </AnalyticsContext.Provider>
  );
};

export default Analytics;

export function useAnalytics(): AnalyticsControls | null {
  const analytics = useContext(AnalyticsContext);
  if (analytics === null) {
    throw new Error('Analytics hook used outside of AnalyticsContext');
  }
  return analytics;
}
