import React, { useMemo } from 'react';
import { CommonTableProps } from 'views/Maintain/types';
import { useIntl } from 'react-intl';
import { ColumnDef } from '@tanstack/react-table';
import { SearchResultItemDto } from 'apis/backendApi';
import {
  keyArticleMessages,
  priceLevelMessages,
  priceLevelShortMessages,
  productInfoMessages,
} from 'views/Maintain/tableMessages';
import mm from 'views/Maintain/maintainMessages';
import AttributesTable from 'views/Maintain/components/Result/AttributesTable';
import {
  useEdsColumnDef,
  useFamilyPriceColumnDef,
  useLocalPriceColumnDef,
  usePriceColumnDef,
} from 'views/Maintain/hooks/columns';
import {
  useApiDateCell,
  useEnumArrayCell,
  useIntegerCell,
} from 'views/Maintain/hooks/cells';

const ProductInfoTable: React.FC<CommonTableProps> = ({ data }) => {
  const { $t } = useIntl();
  const integerCell = useIntegerCell();
  const dateCell = useApiDateCell();
  const edsColumnDef = useEdsColumnDef();
  const priceColumnDef = usePriceColumnDef();
  const localPriceColumnDef = useLocalPriceColumnDef();
  const familyPriceColumnDef = useFamilyPriceColumnDef();
  const keyArticleCell = useEnumArrayCell({
    tooltipMessages: keyArticleMessages,
    showValues: true,
    maxItems: 2,
    tooManyText: '*',
  });
  const priceLevelCell = useEnumArrayCell({
    shortMessages: priceLevelShortMessages,
    tooltipMessages: priceLevelMessages,
  });

  const columns: ColumnDef<SearchResultItemDto>[] = useMemo(() => {
    return [
      {
        accessorFn: ({ productInfo }) => productInfo.startDateSale,
        id: 'productInfo_salesStartDate',
        meta: {
          headerMessages: {
            text: $t(mm.maintainSalesStartDate),
            tooltip: $t(mm.maintainSalesStartDateTooltip),
          },
        },
        cell: dateCell,
      },
      edsColumnDef,
      {
        accessorFn: ({ productInfo }) => {
          return null;
        },
        id: 'productInfo_commercialClass',
        meta: {
          headerMessages: {
            text: $t(productInfoMessages.commercialClass),
            tooltip: $t(productInfoMessages.commercialClassTooltip),
          },
        },
        cell: props => '!',
      },
      {
        accessorFn: ({ productInfo }) => productInfo.serviceLevel,
        id: 'productInfo_serviceLevelClassification',
        meta: {
          headerMessages: {
            text: $t(productInfoMessages.serviceLevelClassification),
            tooltip: $t(productInfoMessages.serviceLevelClassificationTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: ({ productInfo }) => productInfo.keyArticle || [],
        id: 'productInfo_keyArticle',
        meta: {
          headerMessages: {
            text: $t(productInfoMessages.keyArticle),
            tooltip: $t(productInfoMessages.keyArticleTooltip),
          },
        },
        cell: keyArticleCell,
      },
      {
        accessorFn: ({ productInfo }) => productInfo.styleGroup,
        id: 'productInfo_styleGroup',
        meta: {
          headerMessages: {
            text: $t(productInfoMessages.styleGroup),
            tooltip: $t(productInfoMessages.styleGroupTooltip),
          },
        },
        cell: props => props.getValue() as string,
      },
      priceColumnDef,
      localPriceColumnDef,
      {
        accessorFn: ({ productInfo }) => [productInfo.priceLevel],
        id: 'productInfo_priceLevel',
        meta: {
          headerMessages: {
            text: $t(productInfoMessages.priceLevel),
            tooltip: $t(productInfoMessages.priceLevelTooltip),
          },
        },
        cell: priceLevelCell,
      },
      familyPriceColumnDef,
      {
        accessorFn: ({ productInfo }) => productInfo.designer,
        id: 'productInfo_designer',
        meta: {
          headerMessages: {
            text: $t(productInfoMessages.designer),
            tooltip: $t(productInfoMessages.designerTooltip),
          },
        },
        cell: props => props.getValue() as string,
      },
    ];
  }, [
    $t,
    dateCell,
    edsColumnDef,
    familyPriceColumnDef,
    integerCell,
    keyArticleCell,
    localPriceColumnDef,
    priceColumnDef,
    priceLevelCell,
  ]);
  return <AttributesTable data={data} columns={columns} />;
};

export default ProductInfoTable;
