import React, { useMemo } from 'react';
import { parseApiDate } from 'core/util/date';
import TimeAgo, { Suffix, Unit } from 'react-timeago';
import { useIntl } from 'react-intl';

function useFormatRelativeDate() {
  const { formatRelativeTime } = useIntl();

  return useMemo(() => {
    return (value: number, unit: Unit, suffix: Suffix) => {
      if (suffix === 'ago') {
        value *= -1;
      }
      return formatRelativeTime(value, unit, {
        numeric: 'auto',
      });
    };
  }, [formatRelativeTime]);
}

export type RelativeDateProps = {
  date: string;
};

function startOfToday() {
  const date = new Date();
  date.setUTCHours(0, 0, 0, 0);
  return date.getTime();
}

/**
 * Formats approximate relative dates. E.g. "2 months ago".
 *
 * Accepts API dates and formats messages using the standard translations.
 */
const RelativeDate: React.FC<RelativeDateProps> = ({ date }) => {
  return (
    <TimeAgo
      date={parseApiDate(date)}
      now={startOfToday}
      formatter={useFormatRelativeDate()}
      live={false}
      title=""
    />
  );
};

export default RelativeDate;
