import React, { useCallback } from 'react';
import Switch from '@ingka/switch';
import { ParameterField } from 'views/Parameters/types';
import { Control } from 'react-hook-form/dist/types/form';
import { ParameterFormValues } from 'views/Parameters/components/form/ParameterForm';
import { useController } from 'react-hook-form';
import { useHasUpdateParametersPrivilege } from 'hooks/privilege';
import { FormId } from 'views/Parameters/redux/types';

type SwitchParameterFieldProps = {
  field: ParameterField;
  formId: FormId;
  control: Control<ParameterFormValues>;
  isLocked: boolean;
};

const SwitchParameterField: React.FC<SwitchParameterFieldProps> = ({
  field,
  formId,
  control,
  isLocked,
}) => {
  const { value, isReadonly } = field;
  const {
    field: { name, onBlur, onChange: fieldOnChange },
  } = useController({ name: field.name, control });

  const onChange = useCallback(
    ev => fieldOnChange(ev.target.checked),
    [fieldOnChange]
  );

  const isAuthorized = useHasUpdateParametersPrivilege();

  return (
    <Switch
      id={`${formId}_${name}`}
      title={name}
      value="true"
      disabled={isReadonly || !isAuthorized || isLocked}
      defaultChecked={value as boolean}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

export default SwitchParameterField;
