import React from 'react';
import { FormattedDate } from 'react-intl';
import { useGetMetadataQuery } from 'core/redux/storeMetadataApi';

const TimeZoneDate: React.FC<{ value: string }> = ({ value }) => {
  const { data } = useGetMetadataQuery();
  if (!data) {
    return;
  }

  let timeZone: string;

  const { isCloudMaster, addressTimeZone } = data;
  if (isCloudMaster && addressTimeZone) {
    // Assume UTC timestamp and use timezone
    value += 'Z';
    timeZone = addressTimeZone;
  } // otherwise format Date value as-is.

  return (
    <FormattedDate
      value={value}
      dateStyle="short"
      timeStyle="short"
      timeZone={timeZone}
    />
  );
};

export default TimeZoneDate;
