import React from 'react';
import Result from 'views/Maintain/components/Result/Result';
import Search from 'views/Maintain/components/Search/Search';
import {
  useItemQueries,
  useSearchQueriesUrl,
} from 'views/Maintain/hooks/searchQueries';
import withStoreStructure from 'components/WithStoreStructure';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

const Maintain = () => {
  const { isLoading, isFetching, data, summary, onPageSelect } =
    useItemQueries();

  const { searchCriteria, setSearchCriteria, clearSearchCriteria } =
    useSearchQueriesUrl();

  return (
    <div>
      <Search
        isLoading={isLoading}
        searchCriteria={searchCriteria}
        setSearchCriteria={setSearchCriteria}
        clearSearchCriteria={clearSearchCriteria}
      />
      <hr />
      <LoadingOverlay loading={!isLoading && isFetching}>
        <Result
          isLoading={isLoading}
          isFetching={isFetching}
          timeViewData={data}
          attributesData={data}
          summary={summary}
          onPageSelect={onPageSelect}
          searchCriteria={searchCriteria}
        />
      </LoadingOverlay>
    </div>
  );
};

export default withStoreStructure(Maintain);
