import { useSearchParamState, UseSingleSearchParamState } from 'hooks/routing';
import { useCallback, useMemo } from 'react';
import { useGetParametersForSingleItemQuery } from 'views/Parameters/redux/articleParametersApi';
import {
  Article,
  ARTICLE_NUMBER_PATTERN,
  createParameterFields,
} from 'views/Parameters/types';
import { useGetParameterValueLimitsQuery } from 'views/Parameters/redux/parameterLimitsApi';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

function useArticleNumberSearchParamState(): UseSingleSearchParamState {
  const [values, setValues] = useSearchParamState({
    articleNumber: null,
  });

  let { articleNumber } = values;
  if (articleNumber?.match(ARTICLE_NUMBER_PATTERN) === null) {
    articleNumber = null;
  }

  const setArticleNumber = useCallback(
    newArticleNumber => {
      setValues({ articleNumber: newArticleNumber });
    },
    [setValues]
  );

  return [articleNumber, setArticleNumber];
}

export function useArticleParametersQuery() {
  const [articleNumber, setArticleNumber] = useArticleNumberSearchParamState();

  const {
    isFetching,
    isSuccess,
    isError,
    data: dto,
    error,
    refetch,
  } = useGetParametersForSingleItemQuery(articleNumber);

  const { data: limits } = useGetParameterValueLimitsQuery({
    buCode: '',
    filter: 'item',
    filterValue: dto?.itemNo,
  });

  const { $t } = useIntl();

  const article = useMemo<Article | null>(
    () =>
      dto && limits
        ? {
            name: dto.itemName.length > 0 ? dto.itemName : $t(cm.noDescription),
            articleNumber,
            fields: createParameterFields(dto.parameters, limits),
          }
        : null,
    [$t, articleNumber, dto, limits]
  );

  const isNotFound = isError && (error as FetchBaseQueryError)?.status === 404;

  const onSearch = useCallback(
    (newArticleNumber: string) => {
      if (newArticleNumber !== articleNumber) {
        setArticleNumber(newArticleNumber);
      } else if (!isFetching && isSuccess) {
        refetch();
      }
    },
    [articleNumber, isFetching, isSuccess, refetch, setArticleNumber]
  );

  if (isError && !isNotFound) {
    throw error; // Will be caught by ErrorBoundary
  }

  return {
    articleNumber,
    isFetching,
    isError,
    isNotFound,
    isSuccess,
    article,
    onSearch,
  };
}
