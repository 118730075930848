/* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */

import { DivisionDto, RangeGroupDto, SpecialityShopDto } from 'apis/backendApi';

export enum Status {
  EMPTY = 'EMPTY',
  LOADING = 'LOADING',
  OK = 'OK',
  ERROR = 'ERROR',
}

export type Config = {
  apiRoot: string;
  integrationRoot: string;
  /**
   * Indicates if the application uses MHS.
   * @default true
   */
  mhsEnabled?: boolean;
  ssoDomain: string;
  ssoClientId: string;
  redirectUri: string;
  audience: string;
  scope: string;
  ga: GoogleAnalyticsConfig;
};

export type GoogleAnalyticsConfig = {
  tag: string;
  countryExclusions: string[];
};

export type StoreStructurePath = {
  division: DivisionDto;
  specialityShop: SpecialityShopDto;
  rangeGroup: RangeGroupDto;
};

export enum StoreStructureType {
  Division = 'Division',
  SpecialityShop = 'SpecialityShop',
  RangeGroup = 'RangeGroup',
  SalesLocation = 'SalesLocation',
}

/**
 * Creates an Operation ID for use with StoreStructureType.
 *
 * @param type The item type
 * @param id Object ID
 */
export const storeStructureOperationId = (
  type: StoreStructureType,
  id?: string
) => `${type}${id ? `/${id}` : ''}`;

export type CrudTagState =
  | {
      [tag: string]: true;
    }
  | undefined;

/**
 * Represents the state of CRUD operations for a particular item.
 */
export type CrudState = {
  isCreating: CrudTagState;
  isReading: CrudTagState;
  isUpdating: CrudTagState;
  isDeleting: CrudTagState;

  /**
   * Shortcut indicating that any operation is active.
   */
  isActive: boolean;
};

export enum OperationType {
  /**
   * Indicates the item is currently being created.
   */
  CREATE = 'create',

  /**
   * Indicates the item is currently being read.
   *
   * This state may occur when refreshing an item after an Update.
   */
  READ = 'read',

  /**
   * Indicates the item is currently being updated.
   *
   * Depending on the use case, and backend capabilities, multiple concurrent updates are possible.
   */
  UPDATE = 'update',

  /**
   * Indicates the item is currently being deleted.
   */
  DELETE = 'delete',
}

export function crudState(type: OperationType, tags: string[] = []) {
  const tagState: CrudTagState = Object.fromEntries(
    tags.map(tag => [tag, true])
  );
  return {
    isCreating: type === OperationType.CREATE ? tagState : {},
    isReading: type === OperationType.READ ? tagState : {},
    isUpdating: type === OperationType.UPDATE ? tagState : {},
    isDeleting: type === OperationType.DELETE ? tagState : {},
    isActive: type !== undefined,
  };
}

export enum HtmlFormIdPrefix {
  Division = 'div_',
  SpecShop = 'sp_',
}

/**
 * Mixin for types that represent fields in an HTML form.
 */
export type HtmlFormFields = {
  /**
   * The prefix for the form's child elements' ID.
   */
  formIdPrefix: HtmlFormIdPrefix;
};

/**
 * Contains form state information
 */
export type FormChangeEvent = {
  isDirty: boolean;
  isValid: boolean;
  isSubmitting: boolean;
};
