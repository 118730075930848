import React, { useCallback, useState } from 'react';
import useKeyboardShortcut from 'use-keyboard-shortcut';
import { useNavigate } from 'react-router';
import Button from '@ingka/button';
import menuIcon from '@ingka/ssr-icon/paths/menu';
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal';
import ListView, { ListViewItem } from '@ingka/list-view';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';
import { useMainTitle } from 'components/App';
import { MenuItem, useMenuItems } from 'hooks/menu';
import { KB_MOD } from 'core/util/keyboard';
import { useRouteBuCode } from 'hooks/buCode';

const MenuListItem: React.FC<{
  item: MenuItem;
  onMenuItemClick: (item: MenuItem) => void;
}> = ({ item, onMenuItemClick }) => {
  const { $t } = useIntl();

  return item.isAuthorized ? (
    <ListViewItem
      control="navigational"
      controlIcon="chevronRightSmall"
      controlProps={{
        value: '',
      }}
      title={$t(item.message)}
      onClick={() => onMenuItemClick(item)}
    />
  ) : (
    <></>
  );
};

export type MenuProps = {
  disabled: boolean;
};

const Menu: React.FC<MenuProps> = ({ disabled }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const buCode = useRouteBuCode();

  const onMenuItemClick = useCallback(
    (item: MenuItem) => {
      if (item.isAuthorized && modalOpen && !disabled) {
        navigate(`/store/${encodeURIComponent(buCode)}${item.url}`);
        setModalOpen(false);
      }
    },
    [buCode, disabled, modalOpen, navigate]
  );

  const shortcutOptions = {
    overrideSystem: true,
    ignoreInputFields: true,
    repeatOnHold: false,
  };

  useKeyboardShortcut([KB_MOD, 'M'], () => setModalOpen(true), shortcutOptions);

  const menuItems = useMenuItems();

  menuItems.forEach(item => {
    // We can ignore the rules of hooks, because invocations are constant.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useKeyboardShortcut(
      item.shortcut,
      () => onMenuItemClick(item),
      shortcutOptions
    );
  });

  const { $t } = useIntl();

  const title = useMainTitle() ?? cm.appTitleAbbr;

  return (
    <nav>
      <Button
        data-anchor="mainMenuBtn"
        type={'plain'}
        ssrIcon={menuIcon}
        text={$t(title)}
        onClick={() => {
          setModalOpen(true);
        }}
        className="menu"
        title={$t(cm.mainMenu)}
      />
      <Modal
        id="mainMenuModal"
        visible={modalOpen}
        handleCloseBtn={() => {
          setModalOpen(false);
        }}
      >
        <Sheets
          alignment="left"
          header={
            <ModalHeader title={$t(cm.headerNavigationMenu)} floating={false} />
          }
          footer={<></>}
        >
          <ModalBody>
            <ListView id="mainMenu">
              {menuItems.map((item, index) => (
                <MenuListItem
                  key={index}
                  item={item}
                  onMenuItemClick={onMenuItemClick}
                />
              ))}
            </ListView>
          </ModalBody>
        </Sheets>
      </Modal>
    </nav>
  );
};

export default Menu;
