import React, { useMemo } from 'react';
import AttributesTable from 'views/Maintain/components/Result/AttributesTable';
import { CommonTableProps } from 'views/Maintain/types';
import { useIntl } from 'react-intl';
import { commercialInfoMessages } from 'views/Maintain/tableMessages';
import {
  SearchDataCommercialInfoTurnoverEnum,
  SearchResultItemDto,
} from 'apis/backendApi';
import {
  useAvailabilityColumnDef,
  useForecastColumnDef,
} from 'views/Maintain/hooks/columns';

const CommercialInfoTable: React.FC<CommonTableProps> = ({ data, summary }) => {
  const { $t } = useIntl();
  const avinCoulmnDef = useAvailabilityColumnDef();
  const forecastColumnDef = useForecastColumnDef();
  const columns = useMemo(() => {
    const turnoverText = [
      $t(commercialInfoMessages.turnoverMarkHigh),
      $t(commercialInfoMessages.turnoverMarkMedium),
      $t(commercialInfoMessages.turnoverMarkLow),
    ];
    const turnoverMarkText = (value: SearchDataCommercialInfoTurnoverEnum) => {
      switch (value) {
        case 1:
          return turnoverText[0];
        case 2:
          return turnoverText[1];
        case 3:
          return turnoverText[2];
        default:
          return String(value);
      }
    };

    return [
      avinCoulmnDef,
      {
        accessorFn: (data: SearchResultItemDto) => data.commercialInfo.turnover,
        id: 'commercialInfo_turnover',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.turnover),
            tooltip: $t(commercialInfoMessages.turnoverTooltip),
          },
        },
        cell: props =>
          turnoverMarkText(
            props.getValue() as SearchDataCommercialInfoTurnoverEnum
          ),
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.commercialInfo.averageWeeklySales,
        id: 'commercialInfo_averageWeeklySales',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.averageWeeklySales),
            tooltip: $t(commercialInfoMessages.averageWeeklySalesTooltip),
          },
        },
      },
      forecastColumnDef,
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.commercialInfo.biggestPeakDayForecast,
        id: 'commercialInfo_biggestPeakDayForecast',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.biggestPeakDayForecast),
            tooltip: $t(commercialInfoMessages.biggestPeakDayForecastTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.commercialInfo.replenishmentPoint,
        id: 'commercialInfo_replenishmentPoint',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.replenishmentPoint),
            tooltip: $t(commercialInfoMessages.replenishmentPointTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.commercialInfo.averageSalesPerReceipt,
        id: 'commercialInfo_averageSalesPerReceipt',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.averageSalesPerReceipt),
            tooltip: $t(commercialInfoMessages.averageSalesPerReceiptTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.commercialInfo.minSafetyStock,
        id: 'commercialInfo_minSafetyStock',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.minimumSafetyStock),
            tooltip: $t(commercialInfoMessages.minimumSafetyStockTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.commercialInfo.lowestSalesQuantity,
        id: 'commercialInfo_lowestSalesQuantity',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.lowestSalesQuantity),
            tooltip: $t(commercialInfoMessages.lowestSalesQuantityTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.commercialInfo.mathematicalSafetyStock,
        id: 'commercialInfo_mathematicalSafetyStock',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.mathSafetyStock),
            tooltip: $t(commercialInfoMessages.mathSafetyStockTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.commercialInfo.maximumSafetyStock,
        id: 'commercialInfo_maximumSafetyStock',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.maxSafetyStock),
            tooltip: $t(commercialInfoMessages.maxSafetyStockTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.commercialInfo.commercialQuantity,
        id: 'commercialInfo_commercialQuantity',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.commercialQuantity),
            tooltip: $t(commercialInfoMessages.commercialQuantityTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.commercialInfo.fillingRate,
        id: 'commercialInfo_fillingRate',
        meta: {
          headerMessages: {
            text: $t(commercialInfoMessages.fillingRate),
            tooltip: $t(commercialInfoMessages.fillingRateTooltip),
          },
        },
        footer: props => {
          return props.table.options.meta.summary.data?.fillingRate;
        },
      },
    ];
  }, [$t, avinCoulmnDef, forecastColumnDef]);
  return (
    <AttributesTable data={data} summary={summary} columns={columns} footer />
  );
};

export default CommercialInfoTable;
