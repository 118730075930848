/**
 * Converts a fraction to a percentage.
 *
 * @param fraction
 * @return The rounded-down percentage.
 */
export function toPercentage(fraction: number) {
  return Math.floor(fraction * 100);
}

/**
 * Converts a percentage to a fraction.
 *
 * @param percentage
 * @return The rounded-down percentage as a fraction.
 */
export function toFraction(percentage: number) {
  return Math.floor(percentage) * 0.01;
}
