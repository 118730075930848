import {
  type ActionsDivisionSummary,
  ItemAlert,
  ItemNotification,
} from 'apis/backendApi';
import {
  CellContext,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cm from 'core/commonMessages';
import am, { alertTypeMessages } from 'views/Actions/actionMessages';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ColumnDef } from '@tanstack/table-core/src/types';
import ItemDetailsLink from 'components/ItemDetailsLink';
import { Link } from 'react-router-dom';
import { useRouteBuCode } from 'hooks/buCode';
import CountBadge, {
  CountBadgeColour,
} from 'components/NumberBadge/CountBadge';
import { ItemAlertMode } from 'views/Actions/types';
import NotificationTypeText from 'views/Actions/components/NotificationTypeText';
import Tooltip from '@ingka/tooltip';
import RelativeDate from 'components/RelativeDate/RelativeDate';

function useItemNameColumn() {
  const { $t } = useIntl();
  return useMemo<ColumnDef<ItemAlert, ItemAlert>>(() => {
    return {
      id: 'itemName',
      header: () => $t(cm.articleName),
      accessorFn: alert => alert,
      cell: props => {
        const { itemNo, itemName } = props.getValue();
        return (
          <ItemDetailsLink itemNo={itemNo}>
            {itemName ?? $t(cm.noDescription)}
          </ItemDetailsLink>
        );
      },
      meta: {
        style: {
          minWidth: '24rem',
        },
      },
    };
  }, [$t]);
}

function useItemAlertColumns(mode: ItemAlertMode) {
  const { $t } = useIntl();
  const itemNameColumn = useItemNameColumn();

  return useMemo<ColumnDef<ItemAlert, ItemAlert>[]>(
    () =>
      [
        {
          accessorKey: 'productArea',
          header: () => $t(am.productArea),
          meta: {
            style: {
              minWidth: '3rem',
            },
          },
        },
        {
          accessorKey: 'itemNo',
          header: () => $t(cm.articleNumber),
          meta: {
            style: {
              minWidth: '4rem',
            },
          },
        },
        itemNameColumn,
        {
          accessorKey: 'type',
          header: () => $t(am.type),
          cell: (props: CellContext<ItemAlert, any>) =>
            $t(alertTypeMessages[props.getValue()]),
          meta: {
            style: {
              minWidth: '12rem',
            },
          },
        },
        {
          accessorKey: 'fromDate',
          header: () => $t(cm.date),
          meta: {
            style: {
              minWidth: '4rem',
            },
          },
        },
        {
          id: 'when',
          accessorFn: alert => alert,
          header: () => $t(cm.when),
          cell: props => {
            const { days, fromDate } = props.getValue();
            return (
              <>
                <Tooltip
                  tooltipText={$t(
                    mode === 'alerts' ? am.daysOpen : am.daysUntil,
                    { days }
                  )}
                  position="trailing"
                >
                  <span>
                    <RelativeDate date={fromDate} />
                  </span>
                </Tooltip>
              </>
            );
          },
          meta: {
            style: {
              minWidth: '4rem',
            },
          },
        } as ColumnDef<ItemAlert, ItemAlert>,
      ].filter(x => x !== undefined),
    [$t, itemNameColumn, mode]
  );
}

export function useItemAlertsTable(data: ItemAlert[], mode: ItemAlertMode) {
  return useReactTable({
    columns: useItemAlertColumns(mode),
    data,
    getCoreRowModel: getCoreRowModel(),
  });
}

export function useNotificationsTable(data: any[]) {
  const { $t } = useIntl();
  const itemNameColumn = useItemNameColumn();
  return useReactTable({
    columns: [
      {
        accessorKey: 'productArea',
        header: () => $t(am.productArea),
      },
      {
        accessorKey: 'itemNo',
        header: () => $t(cm.articleNumber),
      },
      itemNameColumn,
      {
        id: 'type',
        header: () => $t(am.type),
        accessorFn: notification => notification,
        cell: (props: CellContext<ItemNotification, ItemNotification>) => (
          <NotificationTypeText data={props.getValue()} />
        ),
      },
      {
        accessorKey: 'fromDate',
        header: () => $t(cm.date),
      },
      {
        id: 'when',
        accessorFn: notification => notification,
        header: () => $t(cm.when),
        cell: props => {
          const { daysOpen: days, fromDate } = props.getValue();
          return (
            <>
              <Tooltip
                tooltipText={$t(am.daysOpen, { days })}
                position="trailing"
              >
                <span>
                  <RelativeDate date={fromDate} />
                </span>
              </Tooltip>
            </>
          );
        },
        meta: {
          style: {
            minWidth: '4rem',
          },
        },
      } as ColumnDef<ItemNotification, ItemNotification>,
    ],
    data,
    getCoreRowModel: getCoreRowModel(),
  });
}

function useCountBadgeColumn(
  type: 'alerts' | 'warnings' | 'notifications',
  header: string,
  colour: CountBadgeColour
): ColumnDef<ActionsDivisionSummary, ActionsDivisionSummary> {
  const buCode = useRouteBuCode();

  return {
    id: type,
    accessorFn: summary => summary,
    header,
    cell: props => {
      const summary = props.getValue();
      return (
        <Link to={`/store/${buCode}/actions/${summary.id}/${type}`}>
          <CountBadge total={summary[type]} colour={colour} />
        </Link>
      );
    },
  };
}

export function useSummaryTable(data: ActionsDivisionSummary[]) {
  const { $t } = useIntl();

  const buCode = useRouteBuCode();

  return useReactTable({
    columns: [
      {
        id: 'name',
        accessorFn: divisionSummary => divisionSummary,
        header: () => $t(am.division),
        cell: props => {
          const { id, name } = props.getValue();
          return (
            <Link
              to={`/store/${encodeURIComponent(
                buCode
              )}/actions/${encodeURIComponent(id)}`}
            >
              {name}
            </Link>
          );
        },
      },
      useCountBadgeColumn('alerts', $t(am.alertsCount), 'red'),
      useCountBadgeColumn('warnings', $t(am.warningsCount), 'orange'),
      useCountBadgeColumn('notifications', $t(am.notificationsCount), 'blue'),
    ],
    data,
    getCoreRowModel: getCoreRowModel(),
  });
}
