import React, { useEffect, useState } from 'react';
import { default as Select, Option } from '@ingka/select';
import { StoreStructureType } from 'core/commonTypes';
import { DivisionDto, RangeGroupDto, SpecialityShopDto } from 'apis/backendApi';
import { useGetStoreStructureTreeQuery } from 'views/StoreStructure/redux/structuresApi';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';
import ssm from 'views/StoreStructure/storeStructureMessages';
import { StoreStructureSelection } from 'core/types';
/**
 * Callback when store structure item is picked
 * @param type
 * @param id
 */
export type OnStructureItemPick = (
  type: StoreStructureType,
  id: string
) => void;

export type StoreStructureItemPickerProps = StoreStructureSelection & {
  onStructureItemPick: OnStructureItemPick;
  pickOnLoad?: boolean;
  selectorPrefix?: string;
  depth: StoreStructureType;
  disabled?: boolean;
};

type StructureItem = {
  name: string;
  value: string;
};

function storeStructureListMapper(
  ssi: DivisionDto | SpecialityShopDto | RangeGroupDto
): StructureItem {
  return {
    value: ssi.id,
    name:
      'description' in ssi ? ssi.description : 'name' in ssi ? ssi.name : '',
  };
}

const StoreStructureItemPicker = ({
  pickOnLoad = false,
  selectorPrefix = '',
  onStructureItemPick,
  specialityShopId = null,
  rangeGroupId = null,
  divisionId,
  depth = StoreStructureType.Division,
  disabled = false,
}: StoreStructureItemPickerProps) => {
  const { isLoading, isSuccess, isError, data } =
    useGetStoreStructureTreeQuery();

  //Store if we have broadcasted the inital load of divisions. Initial state set such that if pickOnLoad isn't used, it
  // gets treated as we have already sent it.
  const [hasSentInitial, setSentInitial] = useState(!pickOnLoad);

  useEffect(() => {
    if (!hasSentInitial && isSuccess) {
      setSentInitial(true);
      onStructureItemPick(StoreStructureType.Division, data.divisions[0].id);
    }
  }, [data, hasSentInitial, isSuccess, onStructureItemPick]);

  const hasSpecialityShop =
    depth === StoreStructureType.SpecialityShop ||
    depth === StoreStructureType.RangeGroup;
  const hasRangeGroup = depth === StoreStructureType.RangeGroup;

  const onGenericPick = (type: StoreStructureType) => (e, hintSelected) => {
    const newValue = hintSelected ? null : e.target.value;
    onStructureItemPick(type, newValue);
  };
  const onPickDivision = onGenericPick(StoreStructureType.Division);
  const onPickSpShop = onGenericPick(StoreStructureType.SpecialityShop);
  const onPickRangeGroup = onGenericPick(StoreStructureType.RangeGroup);

  const divList: Array<StructureItem> = data?.divisions.map(
    storeStructureListMapper
  );
  const activeDivision = data?.divisions.find(div => div.id === divisionId);
  const spList: Array<StructureItem> | null = hasSpecialityShop
    ? activeDivision?.specialityShops
        .filter(sp => !sp.hidden)
        .map(storeStructureListMapper)
    : null;
  const activeSpShop = activeDivision?.specialityShops.find(
    sp => sp.id === specialityShopId
  );
  const rgList: Array<StructureItem> | null = hasRangeGroup
    ? activeSpShop?.rangeGroups
        .filter(rg => !rg.hidden)
        .map(storeStructureListMapper)
    : null;

  const { $t } = useIntl();

  if (isLoading) {
    return <span>{$t(cm.loading)}</span>;
  }

  if (isError) {
    return <span>{$t(cm.storeStructureLoadingError)}</span>;
  }

  return (
    <>
      <Select
        id={`${selectorPrefix}division`}
        className="slm-division-select"
        label={$t(ssm.division)}
        onChange={onPickDivision}
        value={divisionId ?? ''}
        hintText={pickOnLoad ? null : undefined}
        disabled={disabled}
      >
        {divList.map(div => (
          <Option value={div.value} name={div.name} key={div.value} />
        ))}
      </Select>
      {hasSpecialityShop && (
        <Select
          id={`${selectorPrefix}specialityShop`}
          className="slm-speciality-shop-select"
          label={$t(ssm.specialityShop)}
          onChange={onPickSpShop}
          value={specialityShopId ?? ''}
        >
          {spList?.map(sp => (
            <Option value={sp.value} name={sp.name} key={sp.value} />
          ))}
        </Select>
      )}
      {hasRangeGroup && (
        <Select
          id={`${selectorPrefix}rangeGroup`}
          className="slm-range-group-select"
          label={$t(ssm.rangeGroup)}
          onChange={onPickRangeGroup}
          value={rangeGroupId ?? ''}
        >
          {rgList?.map(rg => (
            <Option value={rg.value} name={rg.name} key={rg.value} />
          ))}
        </Select>
      )}
    </>
  );
};

export default StoreStructureItemPicker;
