import React, { useCallback } from 'react';
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal';
import ListView, { ListViewItem } from '@ingka/list-view';
import { useAnalytics } from '../../contexts/Analytics/Analytics';
import { useIntl } from 'react-intl';
import m from 'core/commonMessages';

type UserDetailsModalProps = {
  isVisible: boolean;
  onCloseModal: () => void;
};

// Temporary user details just for Cookie settings, until we take the time to implement the Co-worker subsystem
const UserDetailsModal: React.FC<UserDetailsModalProps> = ({
  isVisible,
  onCloseModal,
}) => {
  const { hasAnalyticsCapability, analyticsConsent, setAnalyticsConsent } =
    useAnalytics();
  const { $t } = useIntl();

  const toggleAnalyticsConset = useCallback(() => {
    setAnalyticsConsent(!analyticsConsent);
  }, [setAnalyticsConsent, analyticsConsent]);

  return (
    <Modal handleCloseBtn={onCloseModal} visible={isVisible}>
      <Sheets
        alignment="right"
        size="small"
        header={
          <ModalHeader
            closeBtnClick={onCloseModal}
            title={$t(m.privacyPolicyPanelHeader)}
          />
        }
        footer={<></>}
      >
        <ModalBody>
          <h3>{$t(m.cookieConsentPopupTitle)}</h3>
          <p>{$t(m.cookieConsentPopupBodyText)}</p>
          <ListView id="cookieSettings">
            <ListViewItem
              control="switch" //@ts-ignore (ListViewItem controlProps type only has value, but passes along everything)
              controlProps={{ defaultChecked: true, disabled: true, value: '' }}
              title={$t(m.cookieConsentPopupEssentialButton)}
            />
            <ListViewItem
              control="switch"
              title={$t(m.cookieConsentPopupAllowStatisticsButton)}
              onChange={toggleAnalyticsConset}
              controlProps={{
                //@ts-ignore
                checked: analyticsConsent,
                disabled: !hasAnalyticsCapability,
                value: '',
              }}
            />
          </ListView>
        </ModalBody>
      </Sheets>
    </Modal>
  );
};

export default UserDetailsModal;
