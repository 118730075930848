import { useSingleSearchParamState } from 'hooks/routing';
import { useSelectOnChange } from 'hooks/select';

export type TopicOption = {
  value: string;
  name: string;
};

export function useTopicSearchParam() {
  const [topic, setTopic] = useSingleSearchParamState('topic');

  const onChangeTopic = useSelectOnChange(topic, value => {
    setTopic(value);
  });

  return { topic, onChangeTopic };
}
