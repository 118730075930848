import React, { useCallback, useEffect, useState } from 'react';
import Button from '@ingka/button';
import bell from '@ingka/ssr-icon/paths/bell';
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal';
import am from 'views/Actions/actionMessages';
import { useIntl } from 'react-intl';
import Loading, { LoadingBall } from '@ingka/loading';
import { useLocation } from 'react-router-dom';
import { useRouteBuCode } from 'hooks/buCode';
import './ActionsSelector.scss';
import { useGetActionsSummaryQuery } from 'views/Actions/redux/actionsApi';
import SlmTable from 'components/Table/SlmTable';
import { useSummaryTable } from 'views/Actions/hooks/table';

const ActionsSelector: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const onClick = useCallback(() => {
    setVisible(true);
  }, []);

  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  const location = useLocation();
  useEffect(() => {
    setVisible(false);
  }, [location]);

  const buCode = useRouteBuCode();

  const { isLoading, isSuccess, data } = useGetActionsSummaryQuery(undefined, {
    skip: buCode === null,
  });

  const [count, setCount] = useState(0);

  const table = useSummaryTable(data?.divisions ?? []);
  useEffect(() => {
    if (data === undefined) {
      return;
    }
    const count = data?.divisions.reduce((count, division) => {
      return (
        count + division.alerts + division.warnings + division.notifications
      );
    }, 0);
    setCount(count);
  }, [data]);

  const { $t } = useIntl();

  return (
    <>
      <Button
        iconOnly
        ssrIcon={bell}
        type="plain"
        onClick={onClick}
        className={count > 0 ? 'slm-highlight' : undefined}
      />
      <Modal visible={visible} handleCloseBtn={onClose}>
        <Sheets
          footer={<></>}
          alignment="right"
          header={
            <ModalHeader closeBtnClick={onClose} title={$t(am.actionsTitle)} />
          }
        >
          <ModalBody>
            <div>
              {isLoading && (
                <Loading>
                  <LoadingBall />
                </Loading>
              )}
              {isSuccess && (
                <SlmTable table={table} className="slm-actions-selector" />
              )}
            </div>
          </ModalBody>
        </Sheets>
      </Modal>
    </>
  );
};

export default ActionsSelector;
