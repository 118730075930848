import React, { useMemo } from 'react';
import { CommonTableProps } from 'views/Maintain/types';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import Table, { TableBody, TableHeader } from '@ingka/table';
import { useIntl } from 'react-intl';
import Tooltip from '@ingka/tooltip';
import { SearchResultItemDto } from 'apis/backendApi';
import mm from 'views/Maintain/maintainMessages';
import MissingField from 'views/Maintain/components/Result/MissingField';
import { useItemColumnDefs } from 'views/Maintain/hooks/columns';
import './AttributesTable.scss';

const AttributesTable: React.FC<
  CommonTableProps & {
    columns: ColumnDef<SearchResultItemDto>[];
    footer?: boolean;
  }
> = ({ data, summary, columns, footer = false }) => {
  const { $t, formatNumber } = useIntl();
  const massagedData = useMemo(() => data?.attributesContent ?? [], [data]);
  const { itemNo, itemName } = useItemColumnDefs();
  const allColumns: ColumnDef<SearchResultItemDto>[] = useMemo(
    () => [itemNo, itemName, ...columns],
    [itemNo, itemName, columns]
  );

  const table = useReactTable({
    data: massagedData,
    defaultColumn: {
      header: props => {
        return (
          <Tooltip
            tooltipText={props.column.columnDef.meta.headerMessages.tooltip}
            position="bottom"
          >
            <span>{props.column.columnDef.meta.headerMessages.text}</span>
          </Tooltip>
        );
      },
      cell: props => {
        const value = props.getValue() as number;
        return value === null ? (
          <MissingField tooltipText={$t(mm.maintainMissingDataTooltip)} />
        ) : (
          formatNumber(value, {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
          })
        );
      },
    },
    columns: allColumns,
    meta: { summary },
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Table className="slm-table slm-attributes">
      <TableHeader>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => (
              <th key={header.id} className={`slm-${header.id}`}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </th>
            ))}
          </tr>
        ))}
      </TableHeader>
      <TableBody striped>
        {table.getRowModel().rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map(cell => {
              const cellContext = cell.getContext();
              const column = cellContext.column;
              const dynamicClassName =
                typeof column.columnDef.meta.getCellClassName === 'function'
                  ? column.columnDef.meta.getCellClassName(cellContext)
                  : '';
              return (
                <td
                  key={cell.id}
                  className={`slm-${column.id} ${dynamicClassName}`}
                >
                  {flexRender(cell.column.columnDef.cell, cellContext)}
                </td>
              );
            })}
          </tr>
        ))}
      </TableBody>
      <tfoot>
        {footer &&
          table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(footerCell => (
                <td key={footerCell.id}>
                  {footerCell.isPlaceholder
                    ? ''
                    : flexRender(
                        footerCell.column.columnDef.footer,
                        footerCell.getContext()
                      )}
                </td>
              ))}
            </tr>
          ))}
      </tfoot>
    </Table>
  );
};

export default AttributesTable;
