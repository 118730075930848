import { useCallback, useState } from 'react';

export type HideModal = () => void;
export type OnDialogOutcome<T> = (
  outcome: T | undefined
) => Promise<void> | void;

export type UseDialogActionsParams<T> = {
  /**
   * The function to invoke for hiding the modal.
   *
   * This should map to the `hide` function returned by `useModal()`.
   */
  hideModal: HideModal;

  /**
   * Callback for receiving the dialog outcome data.
   */
  onOutcome: OnDialogOutcome<T>;
};

export type DialogActions = {
  /**
   * Indicates if the modal is visible.
   */
  isModalVisible: boolean;

  /**
   * Invoked after the modal's close animation has completed.
   */
  onModalClosed: () => void;

  /**
   * Invoked for an explicit Cancel action.
   */
  onCancel: () => void;

  /**
   * Invoked when the modal is closed without explicitly selecting OK or Cancel.
   */
  onClose: () => void;
};

export type UseDialogActions<T> = DialogActions & {
  /**
   * Handles the OK action and communicates the outcome data.
   */
  handleOk: (data: T) => void;
};

export type UseBooleanDialogActions = DialogActions & {
  onOk: () => void;
};

type ModalState<T> = {
  isModalVisible: boolean;
  isOk?: boolean;
  data?: T;
};

export function useDialogActions<T>({
  hideModal,
  onOutcome,
}: UseDialogActionsParams<T>): UseDialogActions<T> {
  const [state, setState] = useState<ModalState<T>>({
    isModalVisible: true,
  });

  const handleOk = useCallback((data: T) => {
    setState({
      isModalVisible: false,
      isOk: true,
      data,
    });
  }, []);

  const onCancel = useCallback(() => {
    setState({
      isModalVisible: false,
      isOk: false,
    });
  }, []);

  const onClose = useCallback(() => {
    setState({
      isModalVisible: false,
      isOk: undefined,
    });
  }, []);

  const onModalClosed = useCallback(async () => {
    hideModal();

    const { isOk, data } = state;
    await onOutcome(data !== undefined ? data : (isOk as T));
  }, [hideModal, onOutcome, state]);

  const { isModalVisible } = state;

  return { isModalVisible, onModalClosed, handleOk, onCancel, onClose };
}

/**
 * Convenience hook for receiving a `boolean` outcome.
 *
 * An `undefined` outcome indicates that the dialog was closed without an explicit OK or Cancel.
 */
export function useBooleanDialogActions({
  hideModal,
  onOutcome,
}: UseDialogActionsParams<boolean>): UseBooleanDialogActions {
  const actions = useDialogActions<boolean>({ hideModal, onOutcome });

  const onOk = useCallback(() => {
    actions.handleOk(true);
  }, [actions]);

  return { ...actions, onOk };
}
