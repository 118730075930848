import React from 'react';
import { flexRender, Table as TanstackTable } from '@tanstack/react-table';
import Table, { TableBody, TableHeader } from '@ingka/table';
import classNames from 'classnames';

export type SlmTableProps = {
  /**
   * Tanstack table model
   */
  table: TanstackTable<any>;

  fullWidth?: boolean;

  /**
   * The class name to apply on the table container element.
   *
   * @default "slm-table"
   */
  className?: string;

  /**
   * Display the table footer.
   */
  footer?: boolean;
};

/**
 * Renders a table model as a Skapa table.
 */
const SlmTable: React.FC<SlmTableProps> = ({
  table,
  className = 'slm-table',
  fullWidth,
  footer,
}) => {
  return (
    <Table className={className} fullWidth={fullWidth}>
      <TableHeader>
        {table.getHeaderGroups().map(group => (
          <tr key={group.id}>
            {group.headers.map(header => {
              const meta = header.getContext().column.columnDef.meta;
              return (
                <th
                  key={header.id}
                  className={classNames(meta?.headerClassName, meta?.className)}
                  style={meta?.style}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows.map(row => {
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => {
                const meta = cell.getContext().column.columnDef.meta;
                return (
                  <td
                    key={cell.id}
                    className={classNames(meta?.bodyClassName, meta?.className)}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </TableBody>
      {footer && table.getFooterGroups().length > 0 && (
        <tfoot>
          {table.getFooterGroups().map(group => (
            <tr key={group.id}>
              {group.headers.map(footer => {
                const meta = footer.getContext().column.columnDef.meta;
                return (
                  <th
                    key={footer.id}
                    className={classNames(
                      meta?.footerClassName,
                      meta?.className
                    )}
                  >
                    {flexRender(
                      footer.column.columnDef.header,
                      footer.getContext()
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </tfoot>
      )}
    </Table>
  );
};

export default SlmTable;
