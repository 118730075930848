import React from 'react';
import Badge from '@ingka/badge';

export type CountBadgeColour = 'red' | 'orange' | 'blue';

export type CountBadgeProps = {
  current?: number;
  total: number;
  size?: 'small' | 'medium';
  colour?: CountBadgeColour;
};

const CountBadge: React.FC<CountBadgeProps> = ({
  current,
  total,
  size,
  colour,
}) => {
  return (
    <Badge
      label={`${
        typeof current === 'number' && current !== total ? current + ' / ' : ''
      }${total}`}
      size={size}
      colour={total === 0 ? 'white' : colour}
    />
  );
};

export default CountBadge;
