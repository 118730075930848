import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'core/store';

export type SearchParams = {
  [name: string]: string;
};

export type SearchParamsState = {
  [pathname: string]: SearchParams;
};

/**
 * Maintains Search Parameter state across Routes.
 */
const searchParamsSlice = createSlice({
  name: 'searchParams',
  initialState: {} as SearchParamsState,
  reducers: {
    setRouteSearchParams(
      state,
      action: PayloadAction<{ pathname: string; searchParams: SearchParams }>
    ) {
      const { pathname, searchParams } = action.payload;
      state[pathname] = searchParams;
    },
  },
});

export const selectSearchParamsState = (root: RootState) => root.searchParams;

export const { setRouteSearchParams } = searchParamsSlice.actions;

/**
 * Appends the SearchParams encoded as a query string to the given path.
 *
 * The implementation sorts query parameters by name to ensure stable output.
 *
 */
export function encodeQueryString(
  pathname: string,
  searchParams: SearchParams = {}
): string {
  const names = Object.keys(searchParams);
  if (names.length === 0) {
    return pathname;
  }

  const queryString = names
    .sort((a, b) => a.localeCompare(b))
    .map(
      name =>
        `${encodeURIComponent(name)}=${encodeURIComponent(searchParams[name])}`
    )
    .join('&');

  return `${pathname}?${queryString}`;
}

export default searchParamsSlice;
