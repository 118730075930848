import React from 'react';
import Banner from '@ingka/banner';
import { warningTriangle } from '@ingka/ssr-icon/icons';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';

type ErrorMessageProps = {
  error: Error;
};

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  const { $t } = useIntl();

  const isDevelopment = process.env.NODE_ENV === 'development';

  if (isDevelopment) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  const body = isDevelopment ? (
    <pre>{error.message}</pre>
  ) : (
    <p>{$t(cm.genericErrorMessage)}</p>
  );

  return <Banner ssrIcon={warningTriangle} body={body} emergency dismissable />;
};

type ErrorBoundaryState = {
  error: Error | null;
};

class ErrorBoundary extends React.Component<
  { children?: React.ReactNode },
  ErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  constructor(props: {}) {
    super(props);

    this.state = { error: null };
  }

  render() {
    const { error } = this.state;
    if (error) {
      return <ErrorMessage error={error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
