import { createApi } from '@reduxjs/toolkit/query/react';
import {
  DefaultParameterDto,
  DefaultParameterDtoFromJSON,
  HfbParameterDto,
  ParameterDto,
} from 'apis/backendApi';
import { apiQuery, apiSerializeQueryArgs } from 'core/rtkQuery';

import { applyUpdates } from 'views/Parameters/redux/types';

export type DivisionDefaults = {
  divisionId: string;
  parameters: ParameterDto[];
};

const GET_DEFAULT_PARAMETERS_ENDPOINT = 'getDefaultParameters';

export const defaultParametersApi = createApi({
  reducerPath: 'defaultParametersApi',
  baseQuery: apiQuery(),
  serializeQueryArgs: apiSerializeQueryArgs,

  endpoints: builder => ({
    [GET_DEFAULT_PARAMETERS_ENDPOINT]: builder.query<DefaultParameterDto, void>(
      {
        query: () => ({
          url: '/parameters/v1/{buCode}/defaults',
        }),
        transformResponse: DefaultParameterDtoFromJSON,
      }
    ),

    postStoreDefaultParameters: builder.mutation<void, ParameterDto[]>({
      query: parameters => ({
        url: '/parameters/v1/{buCode}/defaults/store',
        method: 'POST',
        body: parameters,
      }),
      async onQueryStarted(
        parameters: ParameterDto[],
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;
        const { updateQueryData } = defaultParametersApi.util;
        dispatch(
          updateQueryData(GET_DEFAULT_PARAMETERS_ENDPOINT, undefined, draft => {
            applyUpdates(parameters, draft.storeDefaults);
          })
        );
      },
    }),

    postDivisionDefaultParameters: builder.mutation<void, DivisionDefaults>({
      query: ({ divisionId, parameters }) => ({
        url: `/parameters/v1/{buCode}/defaults/division/${encodeURIComponent(
          divisionId
        )}`,
        method: 'POST',
        body: parameters,
      }),
      async onQueryStarted(
        { divisionId, parameters }: DivisionDefaults,
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;
        const { updateQueryData } = defaultParametersApi.util;
        dispatch(
          updateQueryData(GET_DEFAULT_PARAMETERS_ENDPOINT, undefined, state => {
            const divisionParameters = state.divisions
              .filter(division => division.id === divisionId)
              .flatMap(division => division.parameters);
            applyUpdates(parameters, divisionParameters);
          })
        );
      },
    }),

    postHfbDefaultParameters: builder.mutation<void, HfbParameterDto>({
      query: hfb => ({
        url: `/parameters/v1/{buCode}/defaults/hfb`,
        method: 'POST',
        body: hfb,
      }),
      async onQueryStarted(hfb, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        const { updateQueryData } = defaultParametersApi.util;
        dispatch(
          updateQueryData(GET_DEFAULT_PARAMETERS_ENDPOINT, undefined, draft => {
            const target = draft.hfb.find(x => x.id === hfb.id);
            Object.assign(target, hfb);
          })
        );
      },
    }),
  }),
});

export const {
  useGetDefaultParametersQuery,
  usePostStoreDefaultParametersMutation,
  usePostDivisionDefaultParametersMutation,
  usePostHfbDefaultParametersMutation,
} = defaultParametersApi;

export default defaultParametersApi;
