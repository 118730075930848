import React from 'react';
import style from './style.module.css';
import List from '@ingka/list';
import StoreStructureListItem from './StoreStructureListItem';
import { StoreStructureListItemModel } from './types';

export type StoreStructureListProps = {
  items: StoreStructureListItemModel[];
};

const StoreStructureList = (props: StoreStructureListProps) => {
  const { items, ...restProps } = props;

  return (
    <List className={style.list}>
      {items.map(item => {
        return (
          <StoreStructureListItem
            key={item.rangeGroupId ?? item.specialityShopId}
            {...item}
            {...restProps}
          />
        );
      })}
    </List>
  );
};

export default StoreStructureList;
