import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery, apiSerializeQueryArgs } from 'core/rtkQuery';
import { ParameterDto, ParameterDtoFromJSON } from 'apis/backendApi';
import { applyUpdates } from 'views/Parameters/redux/types';

const GET_F6_PARAMETERS_ENDPOINT = 'getF6Parameters';

const f6ParametersApi = createApi({
  reducerPath: 'f6ParametersApi',
  baseQuery: apiQuery(),
  serializeQueryArgs: apiSerializeQueryArgs,

  endpoints: builder => ({
    /**
     * The boolean parameter is used for conditional fetching.
     */
    [GET_F6_PARAMETERS_ENDPOINT]: builder.query<ParameterDto[], void>({
      query: () => ({
        url: '/parameters/v1/{buCode}/f6',
      }),

      transformResponse: (json: any) => {
        const parameters: ParameterDto[] =
          json?.map(ParameterDtoFromJSON) ?? [];
        // Uncomment to test these new parameters, until they appear in the backend response.
        // parameters.push({
        //   name: 'SLM_AUTO_INC_RP',
        //   value: false as unknown as object,
        // });
        // parameters.push({
        //   name: 'SLM_SMALL_STORE_MDQ',
        //   value: 50 as unknown as object,
        // });
        return parameters;
      },
    }),

    postF6Parameters: builder.mutation<void, ParameterDto[]>({
      query: parameters => ({
        url: '/parameters/v1/{buCode}/f6',
        method: 'POST',
        body: parameters,
      }),

      async onQueryStarted(
        parameters: ParameterDto[],
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;

        const { updateQueryData } = f6ParametersApi.util;
        dispatch(
          updateQueryData(GET_F6_PARAMETERS_ENDPOINT, undefined, draft => {
            applyUpdates(parameters, draft);
          })
        );
      },
    }),
  }),
});
export const { useGetF6ParametersQuery, usePostF6ParametersMutation } =
  f6ParametersApi;

export default f6ParametersApi;
