import { ErrorResponse } from 'apis/backendApi';

export function isErrorResponse(value: any): value is ErrorResponse {
  return value.hasOwnProperty('error') && value.hasOwnProperty('details');
}

export function hasDetails(error: ErrorResponse) {
  return Array.isArray(error.details);
}

export function hasMessage(error: ErrorResponse) {
  return typeof error.message === 'string';
}
