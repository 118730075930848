import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import InlineMessage from '@ingka/inline-message';
import cm from 'core/commonMessages';

type NotImplementedMessageProps = {
  /**
   * Optional message title.
   *
   * @default the common "not implemented" title.
   */
  title?: MessageDescriptor;

  /**
   * Optional message body.
   *
   * @default the common "not implemented" body.
   */
  body?: MessageDescriptor;
};

/**
 * Displays a friendly message that a particular operation is not yet implemented.
 */
const NotImplementedMessage: React.FC<NotImplementedMessageProps> = ({
  title = cm.notImplementedTitle,
  body = cm.notImplementedBody,
}) => {
  const { $t } = useIntl();
  return (
    <InlineMessage body={$t(body)} title={$t(title)} variant="informative" />
  );
};

export default NotImplementedMessage;
