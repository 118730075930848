import React, { useEffect, useMemo, useState } from 'react';
import { HideModal, useDialogActions } from 'hooks/modal';
import { useModal } from 'react-modal-hook';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import Button from '@ingka/button';
import commonMessages from 'core/commonMessages';
import cm from 'core/commonMessages';
import { useIntl } from 'react-intl';
import { AssignLocationConflict, MerchandisingSolution } from 'apis/backendApi';
import StoreStructureItemPicker, {
  useStoreStructureItemPicker,
} from 'components/StoreStructureItemPicker';
import { StoreStructureType } from 'core/commonTypes';
import { useGetSalesLocationQuery } from 'views/StoreStructure/redux/structuresApi';
import { formatSlid } from 'core/util/formatters';
import { useForm } from 'react-hook-form';
import InputField from '@ingka/input-field';
import Checkbox from '@ingka/checkbox';
import { getApiDateFromInput, getInputDateFromApi } from 'core/util/date';
import { OnStructureItemPick } from 'components/StoreStructureItemPicker/StoreStructureItemPicker';
import mm from 'views/Maintain/maintainMessages';
import SkapaSelect from 'components/SkapaSelect';
import './NewAssignmentModal.scss';
import { useAlertDialog, useConfirmDialog } from 'components/Modal/Dialog';
import { useMerchandisingTerms } from 'views/Maintain/hooks/language';
import InlineMessage from '@ingka/inline-message';

export type NewAssignmentSetupValues = {
  itemNo: string;
  divisionId: string;
  specialityShopId: string;
  rangeGroupId: string;
  quantity: number | null;
  fromDate: string | null;
  minDate: string;
  maxDate: string;
  slid: string | null;
};

type NewAssignmentModalProps = {
  hideModal: HideModal;
  onChange: (values: NewAssignmentFormValues) => Promise<boolean | void>;
  setupValues: NewAssignmentSetupValues;
};

export type NewAssignmentFormValues = {
  slid: string;
  quantity: number;
  productDominance: number;
  merchandisingSolution: MerchandisingSolution;
  homebase: boolean;
  primary: boolean;
  manualPick: boolean;
  fromDate: string;
  fireClassOverride: boolean;
};

const NewAssignmentModal: React.FC<NewAssignmentModalProps> = ({
  hideModal,
  onChange,
  setupValues,
}) => {
  const { $t } = useIntl();
  const merchText = useMerchandisingTerms('normal');
  const [loading, setLoading] = useState(false);
  const { isModalVisible, onModalClosed, onClose, onCancel, handleOk } =
    useDialogActions<NewAssignmentFormValues>({
      hideModal,
      onOutcome: async () => {
        /* noop, outcome is handled by the submit function */
      },
    });

  const storeStructureItemPickerState = useStoreStructureItemPicker(
    setupValues.divisionId,
    setupValues.specialityShopId,
    setupValues.rangeGroupId
  );

  const { currentData: remoteSlidList, isFetching } = useGetSalesLocationQuery(
    storeStructureItemPickerState.rangeGroupId,
    {
      skip: !storeStructureItemPickerState.rangeGroupId,
    }
  );

  const slidList = useMemo(() => {
    return (
      remoteSlidList &&
      remoteSlidList
        .filter(sl => !sl.hidden)
        .sort((a, b) => a.id.localeCompare(b.id))
        .map(sl => ({
          key: sl.id,
          label: `${formatSlid(sl.id)}${
            sl.isEmpty ? ` - ${$t(cm.empty)}` : ''
          }`,
          value: sl.id,
        }))
    );
  }, [$t, remoteSlidList]);

  const {
    handleSubmit,
    register,
    formState,
    setValue: setFormValue,
  } = useForm<NewAssignmentFormValues>({
    mode: 'onChange',
    defaultValues: {
      slid: '',
      quantity: setupValues.quantity,
      fromDate: getInputDateFromApi(
        setupValues.fromDate || setupValues.minDate
      ),
      productDominance: 0,
      merchandisingSolution: MerchandisingSolution.F_NotRTS,
      homebase: false,
      primary: false,
      manualPick: false,
      fireClassOverride: false,
    },
  });

  const { isValid } = formState;

  const confirmFireClass = useConfirmDialog({
    title: $t(mm.maintainAssignmentFireclassTitle),
    body: $t(mm.maintainAssignmentFireclassBody),
    onOutcome: async outcome => {
      if (outcome) {
        setFormValue('fireClassOverride', true);
        await handleSubmit(onSubmit)();
      }
    },
  });

  const alertAlreadyExists = useAlertDialog({
    title: $t(mm.maintainAssignmentAlreadyExistsTitle),
    body: $t(mm.maintainAssignmentAlreadyExistsBody),
  });

  const onSubmit = async (values: NewAssignmentFormValues) => {
    setLoading(true);
    try {
      const result = await onChange(values);
      if (result) {
        handleOk(values);
      }
    } catch (e) {
      if (e.status === 409) {
        switch (e.data) {
          case AssignLocationConflict.FIRE_CLASS:
            confirmFireClass();
            break;
          case AssignLocationConflict.ALREADY_EXISTS:
            alertAlreadyExists();
            break;
          default:
            throw e;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onStructureItemPick: OnStructureItemPick = (...props) => {
    storeStructureItemPickerState.onStructureItemPick(...props);
    setFormValue('slid', '', { shouldValidate: true });
    setFormValue('fireClassOverride', false);
  };

  useEffect(() => {
    if (slidList && slidList.length > 0) {
      if (setupValues.slid) {
        setFormValue(
          'slid',
          slidList.find(s => s.value === setupValues.slid) !== null
            ? setupValues.slid
            : '',
          { shouldValidate: true }
        );
      } else {
        setFormValue('slid', '', { shouldValidate: true });
      }
      setFormValue('fireClassOverride', false);
    }
  }, [setupValues, setFormValue, slidList]);

  const { divisionId } = storeStructureItemPickerState;
  const [warehouseWarning, setWarehouseWarning] = useState(false);
  useEffect(() => {
    setWarehouseWarning(divisionId === 'WH');
  }, [divisionId]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Modal
        handleCloseBtn={onClose}
        onModalClosed={onModalClosed}
        visible={isModalVisible}
      >
        <Prompt
          title={$t(mm.maintainAssignmentNewLocationTitle)}
          className="slm-ok-cancel slm-new-assignment"
          header={<ModalHeader closeBtnClick={onClose} />}
          footer={
            <ModalFooter>
              <Button
                type="primary"
                text={$t(commonMessages.ok)}
                htmlType="submit"
                onClick={() => {}} // ModalFooter forces a close callback if no onClick is provided
                disabled={!isValid}
                loading={loading}
              />
              <Button text={$t(commonMessages.cancel)} onClick={onCancel} />
            </ModalFooter>
          }
        >
          <div className="slm-conditional-columns">
            <div>
              <StoreStructureItemPicker
                {...storeStructureItemPickerState}
                onStructureItemPick={onStructureItemPick}
                depth={StoreStructureType.RangeGroup}
              />
              <SkapaSelect
                id="slid"
                disabled={isFetching}
                label={$t(mm.maintainAssignmentSalesLocation)}
                {...register('slid', {
                  required: true,
                })}
              >
                {slidList &&
                  slidList.map(sl => (
                    <option key={sl.key} label={sl.label} value={sl.value} />
                  ))}
              </SkapaSelect>
            </div>
            <div>
              <InputField
                id="quantity"
                type="number"
                autoComplete="off"
                label={$t(mm.maintainAssignmentQuantity)}
                {...register('quantity', {
                  valueAsNumber: true,
                  required: true,
                  validate: value => !Number.isNaN(value) && value >= 0,
                  // validate function instead of min, need the NaN check
                })}
              />
              <InputField
                id="productDominance"
                type="number"
                autoComplete="off"
                label={$t(mm.maintainAssignmentProductDominance)}
                {...register('productDominance', {
                  valueAsNumber: true,
                  required: true,
                  validate: value => !Number.isNaN(value) && value >= 0,
                  // validate function instead of min, need the NaN check
                })}
              />
              <div className="form-field">
                <SkapaSelect
                  id="merchandisingSolution"
                  label={$t(mm.maintainAssignmentMerchandisingSolution)}
                  {...register('merchandisingSolution')}
                >
                  {Object.values(MerchandisingSolution).map(m => (
                    <option value={m} key={m}>
                      {merchText(m)}
                    </option>
                  ))}
                </SkapaSelect>
              </div>
              <div className="form-field">
                <Checkbox
                  id="homebase"
                  value={''}
                  label={$t(mm.maintainAssignmentHomebase)}
                  {...register('homebase')}
                />
              </div>
              <div className="form-field">
                <Checkbox
                  id="primary"
                  value={''}
                  label={$t(mm.maintainAssignmentPrimary)}
                  {...register('primary')}
                />
              </div>
              <div className="form-field">
                <Checkbox
                  id="manualPick"
                  value={''}
                  label={$t(mm.maintainAssignmentManualPick)}
                  {...register('manualPick')}
                />
              </div>
              <InputField
                type="date"
                id="fromDate"
                className="input-field--labelled" // Skapa Workaround - Force text to be visible
                min={getInputDateFromApi(setupValues.minDate)} // react-hook-form min max doesn't work with type=date
                max={getInputDateFromApi(setupValues.maxDate)}
                label={$t(mm.maintainAssignmentValidFrom)}
                {...register('fromDate', {
                  required: true,
                  validate: value => {
                    const apiDate = getApiDateFromInput(value);
                    return !(
                      apiDate < setupValues.minDate ||
                      apiDate > setupValues.maxDate
                    );
                  },
                })}
              />
            </div>
          </div>
          {warehouseWarning && (
            <InlineMessage
              title={$t(mm.maintainAssignmentWarehouseWarningTitle)}
              body={$t(mm.maintainAssignmentWarehouseWarningBody)}
              variant="cautionary"
              className="slm-mt-100 slm-mb-100"
            />
          )}
        </Prompt>
      </Modal>
    </form>
  );
};

export function useNewAssignmentModal(
  setupValues: NewAssignmentSetupValues,
  onSubmit: (values: NewAssignmentFormValues) => Promise<boolean | void>
) {
  const [showModal, hideModal] = useModal(
    () => (
      <NewAssignmentModal
        hideModal={hideModal}
        onChange={onSubmit}
        setupValues={setupValues}
      />
    ),
    [setupValues]
  );

  return showModal;
}
