import { useIntl } from 'react-intl';
import messages from 'core/validationMessages';

export function useValidationMessage(id: string = 'unknown') {
  if (id === 'nullable') {
    // Map ID from Yup resolver
    id = 'required';
  }
  const message = messages[id];
  if (message === undefined) {
    throw new Error(`Invalid validation message ID: ${id}`);
  }
  const { $t } = useIntl();
  return $t(message);
}
