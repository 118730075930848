import { ParameterDto, ParameterLimits } from 'apis/backendApi';

const BOOLEAN_PARAMETERS = [
  'DIRECT_DEL_PICKING',
  'ORDER_FULL_PLT_ONLY',
  'USE_COMM_QTY',
  'ALERT_LOC_LOC_SHORT',
  'ALERT_OVER_MAX',
  'ALERT_UNDER_MIN',
  'ALERT_UNPLANNED_ART',
  'SLM_AUTO_INC_RP',
];

const DOUBLE_PARAMETERS = ['BACKPANEL_VOL_AVG', 'HANDSTACK_CAP_FACTOR'];

const READONLY_PARAMETERS = [
  'ALERT_LOC_LOC_SHORT',
  'ALERT_OVER_MAX',
  'ALERT_UNDER_MIN',
  'ALERT_UNPLANNED_ART',
];

const COMPUTED_PARAMETERS = ['turnOverMarkDftp'];

const CRITICAL_PARAMETERS = [
  'COMM_QTY_LIMIT_DAYS',
  'COV_DAYS_DIRECT',
  'COV_DAYS_INDIRECT',
  'MAX_SAFETY_STOCK_D',
  'MIN_SAFETY_STOCK',
  'PALLET_ROUND_FACTOR',
];

export const GROUP_PARAMETERS = [
  'BACKPANEL_VOL_AVG',
  'COMM_QTY_LIMIT_DAYS',
  'COV_DAYS_DIRECT',
  'COV_DAYS_DTFPP',
  'COV_DAYS_INDIRECT',
  'DELIVERY_MAX_PARAM',
  'DIRECT_DEL_PICKING',
  'FLEXIBILITY_PCT',
  'HANDSTACK_CAP_FACTOR',
  'MAX_SAFETY_STOCK_D',
  'MIN_SAFETY_STOCK',
  'ORDER_FULL_PLT_ONLY',
  'OVERSTOCK_LIMIT_DAYS',
  'PALLET_ROUND_FACTOR',
  'PEAK_DAY_FACTOR_PCT',
  'UNIT_LOAD_LIMIT',
  'USE_COMM_QTY',
];

const IGNORED_PARAMETERS = [
  'SLM_HIST_DAYS',
  'SLM_SPAC_NEED_GREEN',
  'SLM_SPAC_NEED_YELLOW',
  'SLM_SPAC_USE_GREEN',
  'SLM_SPAC_USE_YELLOW',
];

const DEFAULT_LIMIT = { min: 0, max: 999 };

export enum ParameterType {
  INTEGER,
  DOUBLE,
  BOOLEAN,
}

export type ParameterMetadata = {
  type: ParameterType;
  isReadonly: boolean;
  isCritical: boolean;
  isComputed: boolean;
};

/**
 * In case of a `string`, the value is a reference to another parameter.
 */
export type ParameterValue = number | boolean | string;

export type Parameter = {
  readonly name: string;
  readonly value: ParameterValue | null;
};

type ParameterValidation = {
  readonly min: ParameterValue;
  readonly max: ParameterValue;
};

export type ParameterField = Parameter &
  ParameterMetadata &
  ParameterValidation;

export function parameterMetadata(name: string): ParameterMetadata {
  let type: ParameterType;
  if (BOOLEAN_PARAMETERS.includes(name)) {
    type = ParameterType.BOOLEAN;
  } else if (DOUBLE_PARAMETERS.includes(name)) {
    type = ParameterType.DOUBLE;
  } else {
    type = ParameterType.INTEGER;
  }
  const isReadonly = READONLY_PARAMETERS.includes(name);
  const isCritical = CRITICAL_PARAMETERS.includes(name);
  const isComputed = COMPUTED_PARAMETERS.includes(name);

  return {
    type,
    isReadonly,
    isCritical,
    isComputed,
  };
}

export function createParameterFields(
  parameters: ParameterDto[],
  limits: ParameterLimits[],
  sortByNameAndType: boolean = true
): ParameterField[] {
  const fields = parameters
    .filter(param => !IGNORED_PARAMETERS.includes(param.name))
    .map((param): ParameterField => {
      const matchingLimits =
        limits.find(limit => limit.name === param.name) ?? {};
      const { min = DEFAULT_LIMIT.min, max = DEFAULT_LIMIT.max } =
        matchingLimits;
      const { name, value } = param;
      const metadata = parameterMetadata(name);
      return {
        name,
        value: value as unknown as ParameterValue,
        ...metadata,
        min: min as unknown as ParameterValue,
        max: max as unknown as ParameterValue,
      };
    });

  const numericFields = fields.filter(
    field =>
      field.type === ParameterType.INTEGER ||
      field.type === ParameterType.DOUBLE
  );
  if (sortByNameAndType) {
    numericFields.sort((a, b) => a.name.localeCompare(b.name));
  }

  const booleanFields = fields.filter(
    field => field.type === ParameterType.BOOLEAN
  );
  if (sortByNameAndType) {
    booleanFields.sort((a, b) => a.name.localeCompare(b.name));
  }

  return [...numericFields, ...booleanFields];
}

export const ARTICLE_NUMBER_PATTERN = /\d{1,8}/;

export type Article = {
  name: string;
  articleNumber: string;
  fields: ParameterField[];
};

export enum GroupFilter {
  DIVISION = 'division',
  SPECIALITY_SHOP = 'speciality_shop',
  HFB = 'hfb',
  RANGE_AREA = 'range_area',
  PRODUCT_AREA = 'product_area',
  SALES_METHOD = 'sales_method',
  SERVICE_CLASS = 'service_class',
}
