import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import sm from 'views/Summaries/summariesMessages';
import m from 'views/Summaries/summariesMessages';
import { TopicOption, useTopicSearchParam } from './topic';
import { useGetSnapshotViewQuery } from 'views/Summaries/redux/reportsApi';
import { getApiDate } from 'core/util/date';
import { useOnChangeDate } from 'hooks/date';
import {
  columnKey,
  HEADING,
  NumberFormatter,
  SnapshotViewTopic,
  SummaryLine,
  SummaryValue,
} from 'views/Summaries/types';
import {
  CommercialEfficiencyColumn,
  SpaceNeedColumn,
  SubRowsDto,
} from 'apis/backendApi';
import {
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { enumValue } from 'core/util/enum';
import { useFormatNumber } from 'hooks/locale';
import { useSummariesContext } from 'views/Summaries/Summaries';

export function useSnapshotViewSelection() {
  const { topic, onChangeTopic } = useTopicSearchParam();

  const { $t } = useIntl();

  const topicOptions = useMemo<TopicOption[]>(() => {
    return [
      { value: SnapshotViewTopic.SPACE_NEED, name: $t(sm.spaceneedOption) },
      {
        value: SnapshotViewTopic.COMMERCIAL_EFFICIENCY,
        name: $t(sm.commercialefficiencyOption),
      },
      {
        value: SnapshotViewTopic.DENSITY_AND_FILLING_RATE,
        name: $t(sm.densityandfillingrateOption),
      },
    ];
  }, [$t]);

  const { date, setDate } = useSummariesContext();

  const onChangeDate = useOnChangeDate(newDate => {
    setDate(newDate);
  }, new Date());

  return {
    topic,
    onChangeTopic,
    topicOptions,
    date: topic === SnapshotViewTopic.SPACE_NEED ? date : new Date(),
    isDateRequired: topic === SnapshotViewTopic.SPACE_NEED.toString(),
    onChangeDate,
  };
}

function mapSnapshotViewSummaryLine(
  dto: SubRowsDto,
  topic: SnapshotViewTopic,
  formatNumber: NumberFormatter,
  columns: string[]
): SummaryLine {
  const headingCell: [string, SummaryValue] = [
    columnKey(HEADING),
    { text: dto.name },
  ];

  const valueCells = dto.values.map<[string, SummaryValue]>(
    (dtoValue, columnIndex) => {
      let value = dtoValue as unknown as number;
      let minimumFractionDigits: number = 1;
      switch (topic) {
        case SnapshotViewTopic.SPACE_NEED: {
          minimumFractionDigits = 1;
          switch (enumValue(SpaceNeedColumn, columns[columnIndex])) {
            case SpaceNeedColumn.SpaceNeed: // Fall through
            case SpaceNeedColumn.SpaceUtilization:
              value = Math.floor(value);
              minimumFractionDigits = 0;
              break;
          }
          break;
        }
        case SnapshotViewTopic.COMMERCIAL_EFFICIENCY: {
          minimumFractionDigits = 0;
          switch (enumValue(CommercialEfficiencyColumn, columns[columnIndex])) {
            case CommercialEfficiencyColumn.ExpNetTurnRev:
              value = Math.floor(value * 0.00001);
              break;
            case CommercialEfficiencyColumn.ExpNetTurnRevM2:
              value = Math.floor(value * 0.01);
              break;
          }
          break;
        }
      }

      return [
        columnKey(columnIndex),
        { text: formatNumber(value, { minimumFractionDigits }) },
      ];
    }
  );

  const row = Object.fromEntries([headingCell, ...valueCells]) as SummaryLine;
  row.children = dto.subRows?.map(dto =>
    mapSnapshotViewSummaryLine(dto, topic, formatNumber, columns)
  );
  return row;
}

export function useSnapshotViewTable(topic: string | null, date: Date) {
  const {
    isLoading,
    isFetching,
    isSuccess,
    isError,
    data: snapshotViewData,
  } = useGetSnapshotViewQuery({
    buCode: '',
    reportname: topic,
    date: date ? getApiDate(date) : null,
  });

  const formatNumber = useFormatNumber();

  const { $t } = useIntl();

  const columns = useMemo(() => {
    const headingColumn = {
      accessorKey: columnKey(HEADING),
      header: null,
    };

    const valueColumns = (snapshotViewData?.columns ?? []).map(
      (name, index) => ({
        accessorKey: columnKey(index),
        header: $t(m[name]),
      })
    );

    return [headingColumn, ...valueColumns];
  }, [snapshotViewData, $t]);

  const tableData = useMemo(() => {
    return snapshotViewData?.baseNodes.map<SummaryLine>(dto =>
      mapSnapshotViewSummaryLine(
        dto,
        enumValue(SnapshotViewTopic, topic),
        formatNumber,
        snapshotViewData?.columns ?? []
      )
    );
  }, [formatNumber, snapshotViewData, topic]);

  const { snapshotViewExpandedState, setSnapshotViewExpandedState } =
    useSummariesContext();

  const table = useReactTable<SummaryLine>({
    columns,
    data: tableData,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (row: SummaryLine) => {
      return row.children;
    },
    state: {
      expanded: snapshotViewExpandedState,
    },
    onExpandedChange: (newState: ExpandedState) => {
      setSnapshotViewExpandedState(newState);
    },
  });

  return {
    isLoading,
    isFetching,
    isSuccess,
    isError,
    table: tableData ? table : undefined,
  };
}
