import { StoreStructureListItemModel } from 'views/StoreStructure/components/StoreStructureList/types';
import {
  useDeleteStoreStructureSpecialityMutation,
  usePostStoreStructureSpecialityMutation,
  usePutStoreStructureSpecialityMutation,
} from 'views/StoreStructure/redux/structuresApi';
import React, { useMemo } from 'react';
import { SpecialityShopDto } from 'apis/backendApi';
import ItemEditModal, {
  ItemFormValues,
} from 'views/StoreStructure/components/Tabs/ItemEditModal';
import { useGetStoreStructureTreeDivisionQuery } from 'views/StoreStructure/hooks/division';
import { useConfirmDialog } from 'components/Modal/Dialog';
import { useIntl } from 'react-intl';
import ssm from 'views/StoreStructure/storeStructureMessages';
import { useModal } from 'react-modal-hook';
import { StoreStructureType } from 'core/commonTypes';
import { useHasUpdateStoreStructurePrivilege } from 'hooks/privilege';
import { useAsyncFeedback } from 'hooks/feedback';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { selectPendingUpdates } from 'core/redux/pendingUpdatesSlice';
import { findPendingSpecialityShopUpdate } from 'views/StoreStructure/types';
import { MHS_SYNC_POLLING_INTERVAL } from 'core/types';

export function useSpecialityShopListItems(
  divisionId: string
): StoreStructureListItemModel[] {
  const { data } = useGetStoreStructureTreeDivisionQuery(divisionId);

  const canUpdate = useHasUpdateStoreStructurePrivilege();

  return useMemo(
    () =>
      data?.specialityShops.map<StoreStructureListItemModel>(dto => ({
        structureType: StoreStructureType.SpecialityShop,
        divisionId: dto.divId,
        specialityShopId: dto.id,
        label: dto.name,
        hidden: dto.hidden,
        // Hidden Speciality Shops cannot be updated/deleted in MHS, this leads to "internal error".
        editable: canUpdate && dto.type === 'L' && !dto.hidden,
        removable:
          canUpdate &&
          dto.type === 'L' &&
          (dto.rangeGroups ?? []).length === 0 &&
          !dto.hidden,
        hideable: canUpdate,
      })),
    [canUpdate, data]
  );
}

/**
 * Derived query for selecting a Speciality Shop from the Store structure.
 */
export function useGetStoreStructureTreeSpecialityShopQuery(
  divisionId: string | null,
  specialityShopId: string | null
) {
  const query = useGetStoreStructureTreeDivisionQuery(divisionId);

  const data = useMemo<SpecialityShopDto | undefined>(
    () =>
      query.data?.specialityShops
        .filter(specialityShop => specialityShop.id === specialityShopId)
        .map((dto: SpecialityShopDto) => ({ ...dto }))[0],
    [query.data?.specialityShops, specialityShopId]
  );

  const isUninitialized = divisionId === null || specialityShopId === null;
  const isSuccess = query.isSuccess && data !== undefined;
  const isError =
    !query.isLoading && (query.isError || (!isUninitialized && !isSuccess));

  return {
    ...query,
    isUninitialized,
    isSuccess,
    isError,
    data,
  };
}

export function useAddSpecialityShopAction(divisionId: string) {
  const [postStoreStructureSpeciality] =
    usePostStoreStructureSpecialityMutation();

  const { $t } = useIntl();

  const onSubmit = useAsyncFeedback(
    async ({ name }: ItemFormValues) => {
      const dto: SpecialityShopDto = {
        divId: divisionId,
        name,
        type: 'L',
        areaM2: 0,
        plannedMaxDensity: 0,
        plannedFillingRate: 0,
        hidden: false,
      };
      await postStoreStructureSpeciality(dto).unwrap();
    },
    [divisionId, postStoreStructureSpeciality],
    {
      messages: [ssm.useCases.addSpecialityShop, ssm.constraints],
    }
  );

  const [showModal, hideModal] = useModal(
    () => (
      <ItemEditModal
        title={$t(ssm.addSpecialityShopTitle)}
        hideModal={hideModal}
        formValues={{ name: null }}
        onSubmit={onSubmit}
      />
    ),
    [onSubmit]
  );

  return showModal;
}

export function useRemoveSpecialityShopAction(
  divisionId: string,
  specialityShopId: string
) {
  const { data } = useGetStoreStructureTreeSpecialityShopQuery(
    divisionId,
    specialityShopId
  );

  const [deleteStoreStructureSpeciality] =
    useDeleteStoreStructureSpecialityMutation();

  const { $t } = useIntl();

  const body = useMemo(() => {
    return [$t(ssm.removeSpecialityShopBody), data?.name];
  }, [$t, data?.name]);

  const onOutcome = useAsyncFeedback<boolean>(
    async (outcome: boolean) => {
      if (outcome) {
        await deleteStoreStructureSpeciality({
          divisionId,
          specialityShopId,
          name: data?.name,
        }).unwrap();
      }
      return outcome;
    },
    [data, deleteStoreStructureSpeciality, divisionId, specialityShopId],
    {
      messages: ssm.useCases.removeSpecialityShop,
      values: { name: data?.name },
    }
  );

  return useConfirmDialog({
    title: $t(ssm.removeSpecialityShopTitle),
    body,
    onOutcome,
  });
}

export function useEditSpecialityShopAction(
  divisionId: string,
  specialityShopId: string
) {
  const { data } = useGetStoreStructureTreeSpecialityShopQuery(
    divisionId,
    specialityShopId
  );

  const [putStoreStructureSpeciality] =
    usePutStoreStructureSpecialityMutation();

  const onSubmit = useAsyncFeedback(
    async ({ name }: ItemFormValues) => {
      const newDto = { ...data, name };
      await putStoreStructureSpeciality({
        dto: newDto,
        tags: ['name'],
      }).unwrap();
    },
    [data, putStoreStructureSpeciality],
    {
      messages: [ssm.useCases.editSpecialityShop, ssm.constraints],
      values: { currentName: data?.name },
    }
  );

  const { $t } = useIntl();

  const [showModal, hideModal] = useModal(
    () => (
      <ItemEditModal
        title={$t(ssm.editSpecialityShopTitle)}
        hideModal={hideModal}
        formValues={{ name: data?.name }}
        onSubmit={onSubmit}
      />
    ),
    [data?.name]
  );

  return showModal;
}

export function useShowHideSpecialityShopActions(
  divisionId: string,
  specialityShopId: string
) {
  const { data } = useGetStoreStructureTreeSpecialityShopQuery(
    divisionId,
    specialityShopId
  );

  const [putStoreStructureSpeciality] =
    usePutStoreStructureSpecialityMutation();

  const onShow = useAsyncFeedback<void>(
    async () => {
      const newDto = { ...data, hidden: false };
      await putStoreStructureSpeciality({
        dto: newDto,
        tags: ['hidden'],
      }).unwrap();
    },
    [data, putStoreStructureSpeciality],
    {
      messages: ssm.useCases.toggleSpecialityShopVisibility,
      values: { name: data?.name },
    }
  );

  const onHide = useAsyncFeedback<void>(
    async () => {
      const newDto = { ...data, hidden: true };
      await putStoreStructureSpeciality({
        dto: newDto,
        tags: ['hidden'],
      }).unwrap();
    },
    [data, putStoreStructureSpeciality],
    {
      messages: ssm.useCases.toggleSpecialityShopVisibility,
      values: { name: data?.name },
    }
  );

  return [onShow, onHide];
}

export function usePendingSpecialityShopUpdate(
  divisionId: string,
  specialityShopId: string
) {
  const updates = useSelector((state: RootState) =>
    selectPendingUpdates(state.pendingUpdates)
  );

  const update = useMemo(
    () =>
      findPendingSpecialityShopUpdate(updates, divisionId, specialityShopId),
    [divisionId, specialityShopId, updates]
  );

  return {
    update,
    pollingInterval:
      update !== undefined ? MHS_SYNC_POLLING_INTERVAL : undefined,
  };
}
