import { useReducer } from 'react';
import { FeedbackMessage } from './types';

export const ADD_MESSAGE = Symbol();
export const CLOSE = Symbol();
export const TRANSITION_END = Symbol();

export type FeedbackState = {
  isOpen: boolean;
  isClosing: boolean;
  messages: FeedbackMessage[];
};

export type FeedbackAction = {
  type: Symbol;
  payload?: { message: FeedbackMessage };
};

const INITIAL_STATE: FeedbackState = {
  isOpen: false,
  isClosing: false,
  messages: [],
};

export function useFeedbackState() {
  return useReducer((state: FeedbackState, action: FeedbackAction) => {
    const { type, payload } = action;
    switch (type) {
      case ADD_MESSAGE: {
        const { message } = payload;
        const messages = state.messages.filter(
          x => message.id === undefined || x.id !== message.id
        );
        state = {
          isOpen: true,
          isClosing: false,
          // List recent messages first.
          messages: [message, ...messages],
        };
        break;
      }
      case CLOSE:
        state = {
          ...state,
          isOpen: false,
          isClosing: true,
        };
        break;
      case TRANSITION_END:
        if (state.isClosing) {
          state = {
            ...state,
            isClosing: false,
            messages: [],
          };
        }
        break;
    }
    return state;
  }, INITIAL_STATE);
}
