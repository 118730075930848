import React from 'react';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import { useIntl } from 'react-intl';
import messages from 'views/StoreStructure/storeStructureMessages';
import { useTabPathRoute } from 'hooks/routing';
import { Outlet } from 'react-router-dom';
import withPrivilege from 'components/WithPrivilege';
import { Privilege } from 'core/redux/userApi';

export enum StoreStructureTabId {
  DIVISION_SPECIALITY_SHOP = 'division-speciality-shop',
  SPECIALITY_SHOP_RANGE_GROUP = 'speciality-shop-range-group',
  RANGE_GROUP_PA = 'range-group-pa',
  RANGE_GROUP_SLID = 'range-group-slid',
  DEFAULT = DIVISION_SPECIALITY_SHOP,
}

const StoreStructure: React.FC = () => {
  const { $t } = useIntl();

  const [tab, onTabChanged] = useTabPathRoute(
    '/store/:buCode/structure/:tabId',
    'tabId'
  );

  const tabs = [
    <Tab
      key="tab-1"
      text={$t(messages.divisionSpecialityShop)}
      tabPanelId={StoreStructureTabId.DIVISION_SPECIALITY_SHOP}
    />,
    <Tab
      key="tab-2"
      text={$t(messages.specialityShopRangeGroup)}
      tabPanelId={StoreStructureTabId.SPECIALITY_SHOP_RANGE_GROUP}
    />,
    <Tab
      key="tab-3"
      text={$t(messages.rangeGroupPA)}
      tabPanelId={StoreStructureTabId.RANGE_GROUP_PA}
    />,
    <Tab
      key="tab-4"
      text={$t(messages.rangeGroupSLID)}
      tabPanelId={StoreStructureTabId.RANGE_GROUP_SLID}
    />,
  ];

  const tabPanels = [
    <TabPanel
      key="tab-1"
      tabPanelId={StoreStructureTabId.DIVISION_SPECIALITY_SHOP}
    >
      <Outlet />
    </TabPanel>,
    <TabPanel
      key="tab-2"
      tabPanelId={StoreStructureTabId.SPECIALITY_SHOP_RANGE_GROUP}
    >
      <Outlet />
    </TabPanel>,
    <TabPanel key="tab-3" tabPanelId={StoreStructureTabId.RANGE_GROUP_PA}>
      <Outlet />
    </TabPanel>,
    <TabPanel key="tab-4" tabPanelId={StoreStructureTabId.RANGE_GROUP_SLID}>
      <Outlet />
    </TabPanel>,
  ];

  return (
    <Tabs
      tabs={tabs}
      tabPanels={tabPanels}
      defaultActiveTab={tab}
      onTabChanged={onTabChanged}
    />
  );
};

export default withPrivilege(StoreStructure, Privilege.READ_STORE_STRUCTURE);
