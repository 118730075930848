import { defineMessages } from 'react-intl';

const messages = defineMessages({
  appName: {
    id: 'appName',
    defaultMessage: 'Sales Location Management',
  },
  welcomeUser: {
    id: 'welcomeUser',
    defaultMessage: 'Welcome, ',
  },
  loginPrompt: {
    id: 'loginPrompt',
    defaultMessage: 'Please log in to proceed.',
  },
  selectStorePrompt: {
    id: 'selectStorePrompt',
    defaultMessage: 'Please select a store to proceed.',
  },
  keyboardShortcuts: {
    id: 'keyboardShortcuts',
    defaultMessage: 'Keyboard shortcuts',
  },
  menuShortcuts: {
    id: 'menuShortcuts',
    defaultMessage: 'Menu shortcuts',
  },
  screenShortcuts: {
    id: 'screenShortcuts',
    defaultMessage: 'Screen shortcuts',
  },
  screenShortcutsMessage: {
    id: 'screenShortcutsMessage',
    defaultMessage: 'With the menu open, the following shortcuts are enabled:',
  },
  storeSelectionMenu: {
    id: 'storeSelectionMenu',
    defaultMessage: 'Store selection menu',
  },
  proTipTitle: {
    id: 'proTipTitle',
    defaultMessage: 'Pro tip',
  },
  proTipMessage: {
    id: 'proTipMessage',
    defaultMessage:
      'To open a view when the menu is closed, simply combine the two shortcuts in quick succession:' +
      "While holding the Alt key, press 'M' followed by the number of the screen.",
  },
});

export default messages;
