import React from 'react';
import StoreStructureList from 'views/StoreStructure/components/StoreStructureList';
import { StoreStructureType } from 'core/commonTypes';
import { useIntl } from 'react-intl';
import sm from 'views/StoreStructure/storeStructureMessages';
import Help from 'components/Help/Help';
import Button from '@ingka/button';
import {
  useAddSpecialityShopAction,
  useSpecialityShopListItems,
} from 'views/StoreStructure/hooks/specialityShop';
import { useStoreStructureCrudState } from 'hooks/storeStructure';
import { useHasUpdateStoreStructurePrivilege } from 'hooks/privilege';
import { lock } from '@ingka/ssr-icon/icons';

export type SpecialityShopEditorProps = {
  divisionId: string;
};

const SpecialityShopListEditor: React.FC<SpecialityShopEditorProps> = ({
  divisionId,
}) => {
  const items = useSpecialityShopListItems(divisionId);

  const { $t } = useIntl();

  const addSpecialityShop = useAddSpecialityShopAction(divisionId);

  const { isCreating } = useStoreStructureCrudState(
    StoreStructureType.SpecialityShop
  );

  const canUpdate = useHasUpdateStoreStructurePrivilege();

  return (
    <>
      {items && (
        <>
          <Help
            header={$t(sm.specialityShopsHelpHeader)}
            body={$t(sm.specialityShopsHelpBody)}
          />
          <h3>{$t(sm.specialityShopsHeading)}</h3>
          <StoreStructureList items={items} />
          <div className="slm-tae">
            <Button
              onClick={addSpecialityShop}
              text={$t(sm.addSpecialityShopButton)}
              loading={isCreating.item}
              disabled={!canUpdate}
              ssrIcon={!canUpdate ? lock : undefined}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SpecialityShopListEditor;
