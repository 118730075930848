import { ParameterValue } from 'views/Parameters/types';

export type DynamicParameterLimits = {
  name: string;
  min: ParameterValue;
  max: ParameterValue;
};

const FALLBACK_LIMITS = {
  min: 0,
  max: 999,
};

export const F6_PARAMETER_LIMITS: DynamicParameterLimits[] = [
  {
    name: 'SLM_DIR_FLOW_GREEN',
    min: 'SLM_DIR_FLOW_YELLOW',
    max: 100,
  },
  {
    name: 'SLM_DIR_FLOW_YELLOW',
    min: 0,
    max: 'SLM_DIR_FLOW_GREEN',
  },
  {
    name: 'SLM_SPAC_NEED_GREEN',
    min: 'SLM_SPAC_NEED_YELLOW',
    max: 100,
  },
  {
    name: 'SLM_SPAC_NEED_YELLOW',
    min: 0,
    max: 'SLM_SPAC_NEED_GREEN',
  },
  {
    name: 'SLM_SPAC_USE_GREEN',
    min: 'SLM_SPAC_USE_YELLOW',
    max: 100,
  },
  {
    name: 'SLM_SPAC_USE_YELLOW',
    min: 0,
    max: 'SLM_SPAC_USE_GREEN',
  },
  {
    name: 'SLM_MIN_INDEL_AMOUNT',
    min: 1,
    max: 3,
  },
  {
    name: 'SLM_WARN_ASSQ_DAYS',
    min: 0,
    max: 112,
  },
  {
    name: 'SLM_WARN_UNPLAN_DAYS',
    min: 0,
    max: 28,
  },
  // Not specified in source code, so using fallback values
  {
    name: 'SLM_EXCESSIVE_DAYS',
    ...FALLBACK_LIMITS,
  },
  {
    name: 'SLM_EXCESSIVE_LIMIT',
    ...FALLBACK_LIMITS,
  },
  {
    name: 'SLM_NEW_ART_WEEKS',
    ...FALLBACK_LIMITS,
  },
  {
    name: 'SLM_SLID_START_DAYS',
    ...FALLBACK_LIMITS,
  },
  // Booleans
  {
    name: 'ALERT_LOC_LOC_SHORT',
    min: false,
    max: true,
  },
  {
    name: 'ALERT_OVER_MAX',
    min: false,
    max: true,
  },
  {
    name: 'ALERT_UNDER_MIN',
    min: false,
    max: true,
  },
  {
    name: 'ALERT_UNPLANNED_ART',
    min: false,
    max: true,
  },
];
