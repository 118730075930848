import React, { useMemo } from 'react';
import StructureDetailsForm from '../../StructureDetailsForm/StructureDetailsForm';
import StoreStructureItemPicker, {
  useStoreStructureItemPicker,
} from 'components/StoreStructureItemPicker';
import grid from 'components/Grid/style.module.css';
import { HtmlFormIdPrefix, StoreStructureType } from 'core/commonTypes';
import {
  useGetStoreStructureSpecialityQuery,
  usePutStoreStructureSpecialityMutation,
} from 'views/StoreStructure/redux/structuresApi';
import {
  createStructureDetails,
  StructureDetails,
  StructureDetailsUpdateValues,
} from 'views/StoreStructure/types';
import NotFoundMessage from 'components/Message/NotFoundMessage';
import RangeGroupListEditor from 'views/StoreStructure/components/Tabs/SpecialityShop/RangeGroupListEditor';
import {
  useStoreStructureCrudState,
  useStoreStructureSearchParamState,
} from 'hooks/storeStructure';
import {
  useGetStoreStructureTreeSpecialityShopQuery,
  usePendingSpecialityShopUpdate,
} from 'views/StoreStructure/hooks/specialityShop';

import { useAsyncFeedback } from 'hooks/feedback';
import ssm from 'views/StoreStructure/storeStructureMessages';
import CleanPendingUpdates from 'views/Maintain/components/ItemDetails/CleanPendingUpdates';

const SpecialityShopRangeGroupTab = () => {
  const pickerState = useStoreStructureItemPicker();

  const { divisionId, specialityShopId, onStructureItemPick } =
    useStoreStructureSearchParamState();

  const { isSuccess, isError, data } =
    useGetStoreStructureTreeSpecialityShopQuery(divisionId, specialityShopId);

  const structureDetails = useMemo<StructureDetails | null>(() => {
    return data ? createStructureDetails(data) : null;
  }, [data]);

  const [putStoreStructureSpeciality] =
    usePutStoreStructureSpecialityMutation();

  const onSubmit = useAsyncFeedback(
    async (values: StructureDetailsUpdateValues) => {
      const { rangeGroups, ...specialityShop } = data;
      const dto = {
        ...specialityShop,
        ...values,
      };
      await putStoreStructureSpeciality({ dto }).unwrap();
    },
    [data, putStoreStructureSpeciality],
    {
      messages: [ssm.useCases.updateSpecialityShop, ssm.constraints],
      values: { name: data?.name },
    }
  );

  const { isUpdating } = useStoreStructureCrudState(
    StoreStructureType.SpecialityShop,
    specialityShopId
  );

  const { update, pollingInterval } = usePendingSpecialityShopUpdate(
    divisionId,
    specialityShopId
  );

  useGetStoreStructureSpecialityQuery(
    { divId: divisionId, spId: specialityShopId },
    {
      skip: update === undefined,
      pollingInterval,
    }
  );

  return (
    <div className="slm-d-flex-row-150">
      <div className="plate">
        <div className={grid.twoCol}>
          <StoreStructureItemPicker
            {...pickerState}
            selectorPrefix="sprg_"
            depth={StoreStructureType.SpecialityShop}
            divisionId={divisionId}
            specialityShopId={specialityShopId}
            onStructureItemPick={onStructureItemPick}
          />
        </div>
        {isSuccess && (
          <StructureDetailsForm
            structureDetails={structureDetails}
            formIdPrefix={HtmlFormIdPrefix.SpecShop}
            onSubmit={onSubmit}
            isSubmitting={isUpdating.item}
            type={StoreStructureType.SpecialityShop}
          />
        )}
        {isError && <NotFoundMessage />}
      </div>
      <div className="plate">
        {divisionId && specialityShopId && (
          <RangeGroupListEditor
            divisionId={divisionId}
            specialityShopId={specialityShopId}
          />
        )}
      </div>
      <CleanPendingUpdates interval={pollingInterval} />
    </div>
  );
};

export default SpecialityShopRangeGroupTab;
