import { ChangeEvent, useCallback, useState } from 'react';

/**
 * Provides an `Select.onChange` callback that filters out selection of the "hint" option.
 */
export function useSelectOnChange(
  defaultValue: string,
  onChange: (value: string) => void
) {
  const [value, setValue] = useState(defaultValue);

  return useCallback(
    (event: ChangeEvent<HTMLSelectElement>, hintOption: boolean) => {
      const target = event.target;
      if (hintOption) {
        target.value = value;
      } else {
        setValue(target.value);
        onChange(target.value);
      }
    },
    [onChange, value]
  );
}
