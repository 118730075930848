import React from 'react';
import { Outlet } from 'react-router-dom';
import './components/DevTools.scss';

export type DevToolsProps = {};

const DevTools: React.FC<DevToolsProps> = () => {
  return (
    <div className="slm-devtools">
      <Outlet />
    </div>
  );
};

export default DevTools;
