import React, { useState } from 'react';
import PageNumber from './PageNumber';
import style from './style.module.css';

type PaginationProps = {
  onPageSelect: (page: number) => void;
  pageNr: number;
  pageCount: number;
};

const PAGE_OFFSET = 4;
const PAGE_SPAN = PAGE_OFFSET * 2 + 1;

const Pagination = ({
  pageNr,
  pageCount,
  onPageSelect,
  ...restProps
}: PaginationProps) => {
  const [pageFocus, setPageFocus] = useState(pageNr);

  let start, end;
  if (pageCount <= PAGE_SPAN) {
    start = 1;
    end = pageCount;
  } else if (pageFocus - PAGE_OFFSET < 1) {
    start = 1;
    end = PAGE_SPAN;
  } else if (pageCount - pageFocus < PAGE_OFFSET) {
    end = pageCount;
    start = pageCount - PAGE_SPAN + 1;
  } else {
    start = pageFocus - PAGE_OFFSET;
    end = pageFocus + PAGE_OFFSET;
  }

  const pageNumbers: number[] = [];
  for (let i = start; i <= end; i++) {
    pageNumbers.push(i);
  }

  return (
    <div {...restProps}>
      {start > 1 && (
        <PageNumber pageNr={1} onClick={onPageSelect} active={false} />
      )}
      {start > 2 && (
        <div className={style.numberSquare}>
          <span>
            <button
              className={style.linkArea}
              onClick={e => {
                e.preventDefault();
                setPageFocus(Math.max(start - PAGE_OFFSET, 1));
              }}
            >
              &#8230;
            </button>
          </span>
        </div>
      )}
      {pageNumbers.map(i => (
        <PageNumber
          pageNr={i}
          onClick={onPageSelect}
          key={`${i}`}
          active={i === pageNr}
        />
      ))}
      {pageCount - end > 1 && (
        <div className={style.numberSquare}>
          <span>
            <button
              className={style.linkArea}
              onClick={e => {
                e.preventDefault();
                setPageFocus(Math.min(end + PAGE_OFFSET, pageCount));
              }}
            >
              &#8230;
            </button>
          </span>
        </div>
      )}
      {pageCount - end > 0 && (
        <PageNumber pageNr={pageCount} onClick={onPageSelect} active={false} />
      )}
    </div>
  );
};

export default Pagination;
