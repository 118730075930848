import React from 'react';
import grid from 'components/Grid/style.module.css';
import StoreStructureItemPicker from 'components/StoreStructureItemPicker';
import { StoreStructureType } from 'core/commonTypes';
import Assigner, {
  AssignableListItem,
  AssignState,
} from 'views/StoreStructure/components/Assigner/Assigner';
import {
  useDeleteProductAreaMutation,
  useGetProductAreaQuery,
  usePostProductAreaMutation,
} from 'views/StoreStructure/redux/structuresApi';
import { ProductAreaDto } from 'apis/backendApi';
import { useIntl } from 'react-intl';
import sm from 'views/StoreStructure/storeStructureMessages';
import { useStoreStructureSearchParamState } from 'hooks/storeStructure';
import { AssignerCrudPrefix } from 'views/StoreStructure/types';
import { useHasUpdateStoreStructurePrivilege } from 'hooks/privilege';
import { useAsyncFeedback } from 'hooks/feedback';

const paTransformer: (
  canUpdate: boolean
) => (pa: ProductAreaDto) => AssignableListItem = canUpdate => pa => ({
  id: pa.id,
  label: pa.id,
  isAssignable: canUpdate,
  isRemovable: false,
  isHideable: false,
  isHidden: false,
  error: null,
  operationPrefix: AssignerCrudPrefix.PA,
  tooltip: null,
});

const RangePATab = () => {
  const { $t } = useIntl();

  const pickerState = useStoreStructureSearchParamState();
  const isRangeGroupSelected = pickerState.rangeGroupId !== null;

  const {
    currentData: assignedPAs,
    isFetching: assignedLoading,
    error: assignedError,
    isSuccess: assignedLoaded,
  } = useGetProductAreaQuery(pickerState.rangeGroupId, {
    skip: !isRangeGroupSelected,
  });

  const {
    data: unassignedPAs,
    isFetching: unassignedLoading,
    error: unassignedError,
    isSuccess: unassignedLoaded,
  } = useGetProductAreaQuery(''); //query param is required, empty string used for unassigned PA (contrast to unset implies all PA)

  const [assignPa] = usePostProductAreaMutation();
  const [unassignPa] = useDeleteProductAreaMutation();

  const canUpdate =
    useHasUpdateStoreStructurePrivilege() && isRangeGroupSelected;

  const assignedItems: Array<AssignableListItem> = assignedPAs?.map(
    paTransformer(canUpdate)
  );
  const unassignedItems: Array<AssignableListItem> = unassignedPAs?.map(
    paTransformer(canUpdate)
  );

  const onAssign = useAsyncFeedback(
    async ({ id, assignState }) => {
      if (!assignedLoaded) {
        return;
      }
      switch (assignState) {
        case AssignState.ASSIGNED:
          await unassignPa({
            pa: assignedPAs.find(pa => pa.id === id),
            rgid: pickerState.rangeGroupId,
          }).unwrap();
          return;
        case AssignState.UNASSIGNED:
          await assignPa({
            pa: unassignedPAs.find(pa => pa.id === id),
            rgid: pickerState.rangeGroupId,
          }).unwrap();
          return;
        default:
          return;
      }
    },
    [
      assignedLoaded,
      assignPa,
      unassignPa,
      pickerState.rangeGroupId,
      unassignedPAs,
      assignedPAs,
    ]
  );

  return (
    <div className="staticGrid">
      <div className="plate">
        <div className={grid.threeCol}>
          <StoreStructureItemPicker
            {...pickerState}
            selectorPrefix="rgpa_"
            depth={StoreStructureType.RangeGroup}
          />
        </div>
        <Assigner
          onItemAssign={onAssign}
          assignedItems={assignedItems}
          assignedState={{
            isLoading: assignedLoading,
            error: assignedError,
            isSuccess: assignedLoaded,
          }}
          assignedTitle={{
            label: $t(sm.paAssignedListTitle),
            tooltip: $t(sm.paAssignedListTitleTooltip),
          }}
          unassignedItems={unassignedItems}
          unassignedState={{
            isLoading: unassignedLoading,
            error: unassignedError,
            isSuccess: unassignedLoaded,
          }}
          unassignedTitle={{
            label: $t(sm.paUnassignedListTitle),
            tooltip: $t(sm.paUnassignedListTitleTooltip),
          }}
        />
      </div>
    </div>
  );
};

export default RangePATab;
