import { Privilege, useGetPrivilegesQuery } from 'core/redux/userApi';
import { useOptionalUser } from '../contexts/UserContext';
import { useRouteBuCode } from 'hooks/buCode';

export function usePrivilegesQuery() {
  const user = useOptionalUser();
  const buCode = useRouteBuCode();
  const { isLoading, isSuccess, isError, data } = useGetPrivilegesQuery({
    userId: user?.userId,
    buCode,
  });
  return { isLoading, isSuccess, isError, data: data?.privileges };
}

export function useHasPrivilege(privilege: Privilege): boolean | null {
  const { isSuccess, data: privileges } = usePrivilegesQuery();
  return isSuccess ? privileges?.indexOf(privilege) !== -1 : null;
}

export const useHasUpdateParametersPrivilege = () =>
  useHasPrivilege(Privilege.UPDATE_PARAMETERS);

export const useHasUpdateStoreStructurePrivilege = () =>
  useHasPrivilege(Privilege.UPDATE_STORE_STRUCTURE);

export const useHasUpdateItemAssignedSalesLocationPrivilege = () =>
  useHasPrivilege(Privilege.UPDATE_ITEM_ASSIGNED_SALES_LOCATION);

export const useHasBulkUpdateItemAssignedSalesLocation = () =>
  useHasPrivilege(Privilege.BULK_UPDATE_ITEM_ASSIGNED_SALES_LOCATION);
