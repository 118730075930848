import { ParameterDto } from 'apis/backendApi';

export enum HistoryFilter {
  F6,
  Store,
  Division,
  Hfb,
  Item,
}

export type FetchParameterHistoryAction = {
  filter: HistoryFilter;
  filterValue: string;
  parameter?: string;
};

export enum FormId {
  STORE = 'store',
  DIVISION = 'division',
  HFB = 'hfb',
  F6 = 'f6',
  ARTICLE = 'article',
  GROUP = 'group',
}

/**
 * Applies Parameter updates from `source` to `target`.
 */
export function applyUpdates(source: ParameterDto[], target: ParameterDto[]) {
  const updateValues = Object.fromEntries(
    source.map(param => [param.name, param.value])
  );

  target.forEach(targetParameter => {
    const { name } = targetParameter;
    if (updateValues[name] !== undefined) {
      targetParameter.value = updateValues[name];
    }
  });
}
