import { useCallback, useState } from 'react';
import { HistoryFilter } from 'views/Parameters/redux/types';

export type HistorySelection = {
  filter: HistoryFilter;
  filterValue: string;
  parameter?: string;
};

/**
 * Show the history selection dialog.
 */
export type ShowHistoryFn = (selection: HistorySelection | null) => void;

export function useParameterHistory() {
  const [historySelection, setHistorySelection] =
    useState<HistorySelection>(null);

  const showHistory = useCallback<ShowHistoryFn>(
    (selection: HistorySelection) => {
      setHistorySelection(selection);
    },
    []
  );

  const closeHistory = useCallback(() => {
    setHistorySelection(null);
  }, []);

  return { historySelection, showHistory, closeHistory };
}
