/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Possible Sales Steering values
 * @export
 */
export const SearchSalesSteeringEnum = {
    FOUR_ACES_AND_KING: 'fourAcesAndKing',
    FAMILY_PRICE: 'familyPrice',
    LOCAL_PRICE: 'localPrice',
    ACTIVITY: 'activity'
} as const;
export type SearchSalesSteeringEnum = typeof SearchSalesSteeringEnum[keyof typeof SearchSalesSteeringEnum];


export function instanceOfSearchSalesSteeringEnum(value: any): boolean {
    for (const key in SearchSalesSteeringEnum) {
        if (Object.prototype.hasOwnProperty.call(SearchSalesSteeringEnum, key)) {
            if (SearchSalesSteeringEnum[key as keyof typeof SearchSalesSteeringEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SearchSalesSteeringEnumFromJSON(json: any): SearchSalesSteeringEnum {
    return SearchSalesSteeringEnumFromJSONTyped(json, false);
}

export function SearchSalesSteeringEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchSalesSteeringEnum {
    return json as SearchSalesSteeringEnum;
}

export function SearchSalesSteeringEnumToJSON(value?: SearchSalesSteeringEnum | null): any {
    return value as any;
}

