import React from 'react';
import { useIntl } from 'react-intl';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import sm from 'views/StoreStructure/storeStructureMessages';
import Button from '@ingka/button';
import commonMessages from 'core/commonMessages';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import { useForm } from 'react-hook-form';
import { HideModal, useDialogActions } from 'hooks/modal';

/**
 * Defines the model that the dialog displays
 */
export type ItemFormValues = {
  name: string | null;
};

export type ItemEditModalProps = {
  hideModal: HideModal;

  /**
   * The current form values. Changing this will reset the form.
   */
  formValues: ItemFormValues;

  title: string;

  /**
   * Invoked when the user has submitted the form and the modal has closed.
   *
   * @param values
   */
  onSubmit: (values: ItemFormValues) => Promise<void>;
};

const ItemEditModal: React.FC<ItemEditModalProps> = ({
  hideModal,
  formValues,
  title,
  onSubmit,
}) => {
  const { isModalVisible, handleOk, onCancel, onClose, onModalClosed } =
    useDialogActions<ItemFormValues>({
      hideModal,
      onOutcome: async (values: ItemFormValues) => {
        if (values) {
          await onSubmit(values);
        }
      },
    });

  const { register, handleSubmit, formState } = useForm<ItemFormValues>({
    mode: 'onChange',
    defaultValues: formValues,
  });

  const { $t } = useIntl();

  const onValid = (values: ItemFormValues) => {
    handleOk(values);
  };

  const { isDirty, isValid } = formState;

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <Modal
        visible={isModalVisible}
        handleCloseBtn={onClose}
        onModalClosed={onModalClosed}
      >
        <Prompt
          className="slm-ok-cancel"
          title={title}
          header={<ModalHeader />}
          footer={
            <ModalFooter>
              <Button
                type="primary"
                text={$t(commonMessages.ok)}
                htmlType="submit"
                disabled={!(isDirty && isValid)}
              />
              <Button text={$t(commonMessages.cancel)} onClick={onCancel} />
            </ModalFooter>
          }
        >
          <FormField>
            <InputField
              id="storeStructureItemName"
              type="text"
              label={$t(sm.storeStructureItemName)}
              autoFocus
              autoComplete="off"
              {...register('name', {
                required: true,
                minLength: 1,
              })}
            />
          </FormField>
        </Prompt>
      </Modal>
    </form>
  );
};

export default ItemEditModal;
