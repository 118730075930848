import React from 'react';
import Menu from '../Menu/Menu';
import User from '../User/User';
import { LanguageSelector } from 'components/LanguageSelector';
import StoreSelector from 'components/StoreSelector/StoreSelector';
import { useOptionalUser } from '../../contexts/UserContext';
import { useRouteBuCode } from 'hooks/buCode';
import { Outlet } from 'react-router-dom';
import './Frame.scss';
import ActionsSelector from 'views/Actions/components/ActionsSelector/ActionsSelector';
import { useBuCodeIntegration } from 'components/BuCodeIntegration';

const Frame: React.FC = () => {
  const user = useOptionalUser();
  const buCode = useRouteBuCode();

  const { isSuccess } = useBuCodeIntegration();

  return (
    <>
      <header>
        <Menu disabled={!user || !buCode} />
        <ul>
          <li>{isSuccess && <ActionsSelector />}</li>
          <li>
            <LanguageSelector />
          </li>
          <li>
            <User />
          </li>
          <li>
            <StoreSelector buCode={buCode} />
          </li>
        </ul>
      </header>
      <main className="slm">
        <Outlet />
      </main>
    </>
  );
};

export default Frame;
