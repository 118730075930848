/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ParameterDto } from './ParameterDto';
import {
    ParameterDtoFromJSON,
    ParameterDtoFromJSONTyped,
    ParameterDtoToJSON,
} from './ParameterDto';

/**
 * Parameters and their values for a division
 * @export
 * @interface DivisionParameterDto
 */
export interface DivisionParameterDto {
    /**
     * ID of the division
     * @type {string}
     * @memberof DivisionParameterDto
     */
    id?: string;
    /**
     * Name of the division
     * @type {string}
     * @memberof DivisionParameterDto
     */
    name?: string;
    /**
     * Parameters and their values
     * @type {Array<ParameterDto>}
     * @memberof DivisionParameterDto
     */
    parameters?: Array<ParameterDto>;
}

/**
 * Check if a given object implements the DivisionParameterDto interface.
 */
export function instanceOfDivisionParameterDto(value: object): value is DivisionParameterDto {
    return true;
}

export function DivisionParameterDtoFromJSON(json: any): DivisionParameterDto {
    return DivisionParameterDtoFromJSONTyped(json, false);
}

export function DivisionParameterDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DivisionParameterDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'parameters': json['parameters'] == null ? undefined : ((json['parameters'] as Array<any>).map(ParameterDtoFromJSON)),
    };
}

export function DivisionParameterDtoToJSON(value?: DivisionParameterDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'parameters': value['parameters'] == null ? undefined : ((value['parameters'] as Array<any>).map(ParameterDtoToJSON)),
    };
}

