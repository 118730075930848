import React from 'react';
import Select, { Option } from '@ingka/select';
import sm from 'views/Summaries/summariesMessages';
import cm from 'core/commonMessages';
import { useIntl } from 'react-intl';
import {
  useSnapshotViewSelection,
  useSnapshotViewTable,
} from 'views/Summaries/hooks/snapshotView';
import Loading, { LoadingBall } from '@ingka/loading';
import StatusTextBox from 'components/StatusTextBox';
import Help from 'components/Help/Help';
import InputField from '@ingka/input-field';
import { getInputDate } from 'core/util/date';
import SummaryTable from 'views/Summaries/components/SummaryTable';

const SnapshotViewTab: React.FC = () => {
  const {
    topic,
    onChangeTopic,
    topicOptions,
    date,
    isDateRequired,
    onChangeDate,
  } = useSnapshotViewSelection();

  const { isFetching, isError, isSuccess, table } = useSnapshotViewTable(
    topic,
    date
  );

  const { $t } = useIntl();

  return (
    <>
      <Select
        id="topic"
        label={$t(sm.topic)}
        className="slm-d-inline-block slm-me-100"
        defaultValue={topic}
        onChange={onChangeTopic}
        role="topicSelector"
      >
        {topicOptions.map(({ value, name }) => (
          <Option key={value} value={value} name={name} />
        ))}
      </Select>
      <InputField
        type="date"
        id="snapshotDate"
        onChange={onChangeDate}
        onKeyDown={e => e.preventDefault()}
        value={getInputDate(date)}
        label={$t(cm.date)}
        className="slm-d-inline-block"
        readOnly={!isDateRequired}
        min={getInputDate(new Date())}
        disabled={!topic}
        required
        aria-required
      />
      {isFetching && (
        <Loading>
          <LoadingBall />
        </Loading>
      )}
      {!isFetching && isSuccess && table && <SummaryTable table={table} />}
      {isError && <StatusTextBox title="Error" body="ErrorText" type="ERROR" />}
      <Help header={$t(sm.snapshotView)} body={$t(cm.helpText)} />
    </>
  );
};

export default SnapshotViewTab;
