import React, { useEffect } from 'react';
import Table, { TableBody } from '@ingka/table';
import InlineMessage from '@ingka/inline-message';
import infoIcon from '@ingka/ssr-icon/paths/information-circle';
import s from './style.module.css';
import { useOptionalUser } from '../../contexts/UserContext';
import {
  useHasMultipleStoreAccess,
  useSingleAvailableStore,
} from 'hooks/stores';
import { useIntl } from 'react-intl';
import lm from 'views/LandingPage/landingPageMessages';
import cm from 'core/commonMessages';
import { useNavigate } from 'react-router-dom';
import { keyboardShortcut, useMenuItems } from 'hooks/menu';
import { KB_MOD } from 'core/util/keyboard';
import { useBuCodeSelection, useRouteBuCode } from 'hooks/buCode';
import { useGetStoresQuery } from 'core/redux/userApi';

const LandingPage: React.FC = () => {
  const user = useOptionalUser();
  const { lastSelectedBuCode } = useBuCodeSelection();
  const singleAvailableStore = useSingleAvailableStore();

  const redirectToStoreRoute = useRouteBuCode() === null;

  const userId = user?.userId;
  const { isSuccess, data } = useGetStoresQuery({ userId }, { skip: !userId });

  const navigate = useNavigate();

  useEffect(() => {
    if (user && redirectToStoreRoute && isSuccess) {
      if (singleAvailableStore) {
        navigate(
          `/store/${encodeURIComponent(singleAvailableStore.code)}/maintain`
        );
      } else if (data.storeNames[lastSelectedBuCode]) {
        navigate(`/store/${encodeURIComponent(lastSelectedBuCode)}/maintain`);
      }
    }
  }, [
    isSuccess,
    data,
    navigate,
    redirectToStoreRoute,
    lastSelectedBuCode,
    user,
    singleAvailableStore,
  ]);

  const userHasMultipleStores = useHasMultipleStoreAccess();

  const buCode = useRouteBuCode();

  const { $t } = useIntl();

  const menuItems = useMenuItems();

  return (
    <>
      <div className={s.container}>
        <div>
          <h1>{$t(lm.appName)}</h1>
          {user ? (
            <>
              <p title={user.shortName}>
                {$t(lm.welcomeUser)} {user.shortName}.
              </p>
              {!buCode && <p>{$t(lm.selectStorePrompt)}</p>}
            </>
          ) : (
            <span>{$t(lm.loginPrompt)}</span>
          )}
        </div>
        <div className={s.rightCol}>
          <div>
            <h3>{$t(lm.keyboardShortcuts)}</h3>
            <h4>{$t(lm.menuShortcuts)}</h4>
            <Table>
              <TableBody>
                <tr>
                  <td>{$t(cm.mainMenu)}</td>
                  <td>{KB_MOD} + M</td>
                </tr>
                {userHasMultipleStores && (
                  <tr>
                    <td>{$t(lm.storeSelectionMenu)}</td>
                    <td>{KB_MOD} + S</td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </div>
          <div>
            <h4>{$t(lm.screenShortcuts)}</h4>
            <InlineMessage
              body={$t(lm.screenShortcutsMessage)}
              ssrIcon={infoIcon}
              variant="informative"
              style={{ marginBottom: '20px' }}
            />
            <Table>
              <TableBody>
                {menuItems.map((item, index) =>
                  item.isAuthorized ? (
                    <tr key={index}>
                      <td>{$t(item.message)}</td>
                      <td>{keyboardShortcut(item)}</td>
                    </tr>
                  ) : (
                    <tr key={index} />
                  )
                )}
              </TableBody>
            </Table>
            <InlineMessage
              body={$t(lm.proTipMessage)}
              ssrIcon={infoIcon}
              title={$t(lm.proTipTitle)}
              variant="informative"
              style={{ marginTop: '30px' }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
