/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Defines value ranges applicable for a filter criterion
 * @export
 * @interface ParameterLimits
 */
export interface ParameterLimits {
    /**
     * Parameter Name
     * @type {string}
     * @memberof ParameterLimits
     */
    name?: string;
    /**
     * Minimum value allowed of the parameter, type bool, int or double
     * @type {object}
     * @memberof ParameterLimits
     */
    min?: object;
    /**
     * Maximum value allowed of the parameter, type bool, int or double
     * @type {object}
     * @memberof ParameterLimits
     */
    max?: object;
}

/**
 * Check if a given object implements the ParameterLimits interface.
 */
export function instanceOfParameterLimits(value: object): value is ParameterLimits {
    return true;
}

export function ParameterLimitsFromJSON(json: any): ParameterLimits {
    return ParameterLimitsFromJSONTyped(json, false);
}

export function ParameterLimitsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ParameterLimits {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'min': json['min'] == null ? undefined : json['min'],
        'max': json['max'] == null ? undefined : json['max'],
    };
}

export function ParameterLimitsToJSON(value?: ParameterLimits | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'min': value['min'],
        'max': value['max'],
    };
}

