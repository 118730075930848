import { useAsyncFeedback } from 'hooks/feedback';
import mm from 'views/Maintain/maintainMessages';
import { usePostItemCalculationMutation } from 'views/Maintain/redux/itemApi';
import { useConfirmDialog } from 'components/Modal/Dialog';
import { useIntl } from 'react-intl';

export function useStartRecalculationAction(itemNo: string) {
  const [postItemCalculation] = usePostItemCalculationMutation();

  const onOutcome = useAsyncFeedback<boolean>(
    async (outcome: boolean) => {
      if (outcome) {
        await postItemCalculation({ itemNo }).unwrap();
      }
      return outcome;
    },
    [postItemCalculation, itemNo],
    {
      messages: mm.useCases.startRecalculation,
    }
  );

  const { $t } = useIntl();

  return useConfirmDialog({
    title: $t(mm.maintainItemDetailsCalculateTitle),
    body: $t(mm.maintainItemDetailsCalculateBody),
    onOutcome,
  });
}
