import React, { ReactNode } from 'react';
import './loadingOverlay.scss';
import Loading, { LoadingBall } from '@ingka/loading';

type LoadingOverlayProps = {
  loading: boolean;
  children?: ReactNode;
};

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  loading,
  children,
}) => {
  return (
    <div className="slm-loading-overlay-wrapper">
      <div
        className="slm-loading-overlay"
        style={loading ? { display: 'inherit' } : { display: 'none' }}
      >
        <div className="slm-loading-center">
          <Loading>
            <LoadingBall />
          </Loading>
        </div>
      </div>
      {children}
    </div>
  );
};

export default LoadingOverlay;
