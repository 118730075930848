import React, { useEffect } from 'react';
import s from '../Tabs.module.css';
import { useIntl } from 'react-intl';
import Select, { OptGroup, Option } from '@ingka/select';
import pm from 'views/Parameters/parametersMessages';
import {
  FilterGroup,
  useGroupFilterOptions,
  useGroupFilterSearchParamState,
} from 'views/Parameters/hooks/groupParameters';
import { GroupSelection } from 'views/Parameters/redux/groupParametersApi';

export type GroupSearchFormProps = {
  /**
   * Invoked when the user searches for a group or clears an existing query.
   *
   * The callback receives either a complete query or `null`.
   */
  onSearch: (query: GroupSelection | null) => void;
};

const GroupSearchForm: React.FC<GroupSearchFormProps> = ({ onSearch }) => {
  const { $t } = useIntl();

  const {
    parameter,
    onChangeParameter,
    filter,
    onChangeFilter,
    filterValue,
    onChangeFilterValue,
  } = useGroupFilterSearchParamState();

  useEffect(() => {
    if (parameter && filter && filterValue) {
      onSearch({ parameter, filter, filterValue });
    } else {
      onSearch(null);
    }
  }, [parameter, filter, filterValue, onSearch]);

  const { parameterOptions, filterOptions, filterValueOptions } =
    useGroupFilterOptions(filter);

  const isGroupedFilterValues =
    (filterValueOptions[0] as FilterGroup)?.options !== undefined;

  return (
    <form className={s.groupFormGrid}>
      <Select
        id="filter"
        label={$t(pm.filter)}
        hintText={$t(pm.chooseFilter)}
        defaultValue={filter}
        onChange={onChangeFilter}
      >
        {filterOptions.map(item => (
          <Option value={item.id} name={item.label} key={item.id} />
        ))}
      </Select>
      <Select
        id="filterValue"
        label={$t(pm.filterValue)}
        hintText={$t(pm.chooseFilterValue)}
        defaultValue={filterValue}
        onChange={onChangeFilterValue}
      >
        {isGroupedFilterValues
          ? (filterValueOptions as FilterGroup[]).map(item => (
              <OptGroup key={item.id} groupName={item.label}>
                {item.options.map(item => (
                  <Option value={item.id} name={item.label} key={item.id} />
                ))}
              </OptGroup>
            ))
          : filterValueOptions.map(item => (
              <Option
                value={item.id}
                name={item.label}
                key={item?.valueKey ?? item.id}
              />
            ))}
      </Select>
      <Select
        id="parameter"
        label={$t(pm.parameter)}
        hintText={$t(pm.chooseParameter)}
        defaultValue={parameter}
        onChange={onChangeParameter}
      >
        {parameterOptions.map(item => (
          <Option value={item.id} name={item.label} key={item.id} />
        ))}
      </Select>
    </form>
  );
};

export default GroupSearchForm;
