import React from 'react';
import Loading, { LoadingBall } from '@ingka/loading';
import cm from 'core/commonMessages';
import StatusTextBox from 'components/StatusTextBox';
import pm from 'views/Parameters/parametersMessages';
import { useIntl } from 'react-intl';

export type ParameterLoadingMessagesProps = {
  isLoading: boolean;
  isError: boolean;
};

const ParameterLoadingMessages: React.FC<ParameterLoadingMessagesProps> = ({
  isLoading,
  isError,
}) => {
  const { $t } = useIntl();

  return (
    <>
      {isLoading && (
        <Loading text={$t(cm.loading)}>
          <LoadingBall />
        </Loading>
      )}
      {isError && (
        <StatusTextBox
          title={$t(cm.error)}
          body={$t(pm.loadingError)}
          type="ERROR"
        />
      )}
    </>
  );
};

export default ParameterLoadingMessages;
