import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import mm, {
  searchActionFilterOptionMessages,
  searchArticleStatusOptionMessages,
  searchAvailabilityOptionMessages,
  searchFlowTypeOptionMessages,
  searchLifecycleOptionMessages,
  searchPicksOptionMessages,
  searchProblemCodeOptionMessages,
  searchSalesSteeringOptionMessages,
  searchSortTypesMessages,
} from 'views/Maintain/maintainMessages';
import cm from 'core/commonMessages';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import StoreStructureItemPicker, {
  useStoreStructureItemPicker,
} from 'components/StoreStructureItemPicker';
import { StoreStructureType } from 'core/commonTypes';
import Select, { Option } from '@ingka/select';
import { useFlatRangeList } from 'views/StoreStructure/hooks/range';
import Button from '@ingka/button';
import './Search.scss';
import {
  SearchActionFilterEnum,
  SearchArticleStatusEnum,
  SearchAvailabilityEnum,
  SearchFlowTypeEnum,
  SearchLifeCycleEnum,
  SearchProblemCodeEnum,
  SearchSalesSteeringEnum,
  SearchSortOrderEnum,
} from 'apis/backendApi';
import EnumSelector from 'views/Maintain/components/Search/EnumSelector';
import searchApi from 'views/Maintain/redux/searchApi';
import { useAppDispatch } from 'hooks/redux';
import { cross, magnifyingGlass } from '@ingka/ssr-icon/icons';
import Tooltip from '@ingka/tooltip';
import BooleanSelect from 'components/BooleanSelect/BooleanSelect';
import { SearchCriteria } from 'views/Maintain/types';

export type SearchProps = {
  isLoading: boolean;
  searchCriteria: SearchCriteria;
  setSearchCriteria: (newCriteria: SearchCriteria) => void;
  clearSearchCriteria: () => void;
};

const Search: React.FC<SearchProps> = ({
  isLoading,
  searchCriteria,
  setSearchCriteria,
  clearSearchCriteria,
}) => {
  const { $t } = useIntl();

  const [text, setText] = useState(searchCriteria.text);
  const onChangeText = useCallback((e: ChangeEvent<HTMLFormElement>) => {
    setText(e.target.value);
  }, []);

  const rangeElements = useFlatRangeList();
  const [rangeStructureId, setRangeStructureId] = useState(
    searchCriteria.rangeStructureId
  );

  const storeStructureItemPickerState = useStoreStructureItemPicker(
    searchCriteria.divisionId,
    searchCriteria.specialityShopId,
    searchCriteria.rangeGroupId
  );

  const onChangeRangeStructureId = useCallback((e, isdefault) => {
    setRangeStructureId(isdefault ? null : e.target.value);
  }, []);

  const [sortType, setSortType] = useState(searchCriteria.sortType);
  const [flowType, setFlowType] = useState(searchCriteria.flowType);
  const [status, setStatus] = useState(searchCriteria.status);
  const [problemCode, setProblemCode] = useState(searchCriteria.problemCode);
  const [actionFilter, setActionFilter] = useState(searchCriteria.actionFilter);
  const [exceedsExcessiveLimit, setExceedsExcessiveLimit] = useState(
    searchCriteria.exceedsExcessiveLimit
  );
  const [lifecycle, setLifecycle] = useState(searchCriteria.lifecycle);
  const [availability, setAvailability] = useState(searchCriteria.availability);
  const [salesSteering, setSalesSteering] = useState(
    searchCriteria.salesSteering
  );

  const { divisionId, specialityShopId, rangeGroupId } =
    storeStructureItemPickerState;

  const isSearchEnabled =
    (text ||
      rangeStructureId ||
      divisionId ||
      flowType ||
      status ||
      lifecycle ||
      problemCode ||
      availability ||
      salesSteering ||
      actionFilter ||
      exceedsExcessiveLimit) &&
    sortType === SearchSortOrderEnum.ASCENDING_ARTICLE_NAME;

  const dispatch = useAppDispatch();

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      dispatch(searchApi.util.resetApiState());
      setSearchCriteria({
        pageSize: 25,
        pageNr: 1,
        rangeStructureId,
        divisionId,
        specialityShopId,
        rangeGroupId,
        flowType,
        status,
        problemCode,
        lifecycle,
        availability,
        salesSteering,
        actionFilter,
        exceedsExcessiveLimit,
        attributeDate: searchCriteria.attributeDate,
        text,
        sortType,
      });
    },
    [
      actionFilter,
      availability,
      dispatch,
      divisionId,
      exceedsExcessiveLimit,
      flowType,
      lifecycle,
      problemCode,
      rangeGroupId,
      rangeStructureId,
      salesSteering,
      searchCriteria.attributeDate,
      setSearchCriteria,
      sortType,
      specialityShopId,
      status,
      text,
    ]
  );

  const onReset = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setText(null);
      setRangeStructureId(null);
      storeStructureItemPickerState.onStructureItemPick(
        StoreStructureType.Division,
        null
      );
      setFlowType(null);
      setStatus(null);
      setLifecycle(null);
      setProblemCode(null);
      setActionFilter(null);
      setExceedsExcessiveLimit(null);
      setAvailability(null);
      setSalesSteering(null);
      setSortType(SearchSortOrderEnum.ASCENDING_ARTICLE_NAME);
      clearSearchCriteria();
    },
    [clearSearchCriteria, storeStructureItemPickerState]
  );

  return (
    <form className="slm-search" onSubmit={onSubmit} onReset={onReset}>
      <div>
        <FormField style={{ maxWidth: '35rem' }}>
          <InputField
            id="freetext"
            type="text"
            label={$t(mm.maintainFreetextInput)}
            value={text ?? ''}
            onChange={onChangeText}
          />
        </FormField>
      </div>
      <div className="slm-filters">
        <Select
          id="rangeElement"
          label={$t(mm.maintainRangeElementInput)}
          title={$t(mm.maintainRangeElementInputTooltip)}
          onChange={onChangeRangeStructureId}
          value={
            rangeElements.length > 0 && rangeStructureId ? rangeStructureId : ''
          }
          className="slm-range-select"
        >
          {rangeElements.map(r => (
            <Option
              key={r.name}
              value={r.name}
              label={`${r.name} - ${r.description || ''}`}
            />
          ))}
        </Select>
        <StoreStructureItemPicker
          {...storeStructureItemPickerState}
          depth={StoreStructureType.RangeGroup}
          selectorPrefix="o11_"
        />
        <EnumSelector
          id="getItemsFlowType"
          label={$t(mm.maintainFilterFlowTypeLabel)}
          EnumType={SearchFlowTypeEnum}
          value={flowType}
          onChange={setFlowType}
          emptyOption={{ label: '-', value: null }}
          messages={searchFlowTypeOptionMessages}
        />
        <EnumSelector
          id="getItemsArticleStatus"
          label={$t(mm.maintainFilterArticleStatusLabel)}
          EnumType={SearchArticleStatusEnum}
          value={status}
          onChange={setStatus}
          emptyOption={{ label: '-', value: null }}
          messages={searchArticleStatusOptionMessages}
        />
        <EnumSelector
          id="getItemsLifecycle"
          label={$t(mm.maintainFilterLifecycleLabel)}
          EnumType={SearchLifeCycleEnum}
          value={lifecycle}
          onChange={setLifecycle}
          emptyOption={{ label: '-', value: null }}
          messages={searchLifecycleOptionMessages}
        />
        <EnumSelector
          id="getItemsProblemCode"
          label={$t(mm.maintainFilterProblemCodeLabel)}
          EnumType={SearchProblemCodeEnum}
          value={problemCode}
          onChange={setProblemCode}
          emptyOption={{ label: '-', value: null }}
          messages={searchProblemCodeOptionMessages}
        />
        <EnumSelector
          id="getItemsSalesSteering"
          label={$t(mm.maintainFilterSalesSteeringLabel)}
          EnumType={SearchSalesSteeringEnum}
          value={salesSteering}
          onChange={setSalesSteering}
          emptyOption={{ label: '-', value: null }}
          messages={searchSalesSteeringOptionMessages}
        />
        <EnumSelector
          id="getItemsAvailability"
          label={$t(mm.maintainFilterAvailabilityLabel)}
          EnumType={SearchAvailabilityEnum}
          value={availability}
          onChange={setAvailability}
          emptyOption={{ label: '-', value: null }}
          messages={searchAvailabilityOptionMessages}
        />
        <EnumSelector
          id="getItemsActionFilter"
          label={$t(mm.maintainFilterActionFilterLabel)}
          EnumType={SearchActionFilterEnum}
          value={actionFilter}
          onChange={setActionFilter}
          emptyOption={{ label: '-', value: null }}
          messages={searchActionFilterOptionMessages}
        />
        <BooleanSelect
          id={'getItemsActionPickFilter'}
          label={$t(mm.maintainFilterPicksLabel)}
          falseLabel={'-'}
          trueLabel={$t(searchPicksOptionMessages.maintainFilterPicksTooHigh)}
          value={exceedsExcessiveLimit}
          onChange={setExceedsExcessiveLimit}
        />
        <EnumSelector
          id="getItemsSort"
          label={$t(mm.maintainSortTypeInputLabel)}
          onChange={setSortType}
          value={sortType}
          EnumType={SearchSortOrderEnum}
          messages={searchSortTypesMessages}
          className="slm-sort-select"
        />
        <Button
          htmlType="submit"
          type="primary"
          text={$t(cm.search)}
          disabled={!isSearchEnabled}
          ssrIcon={magnifyingGlass}
          loading={isLoading}
        />
        <Tooltip tooltipText={$t(cm.reset)}>
          <Button
            htmlType="reset"
            type="secondary"
            disabled={!isSearchEnabled}
            ssrIcon={cross}
            iconOnly
          />
        </Tooltip>
      </div>
    </form>
  );
};

export default Search;
