/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AlertType = {
    ASSQ_BIGGER_THAN_MAX: '0',
    ASSQ_LESS_THAN_MIN: '1',
    UNPLANNED_ARTICLE: '2',
    LOCAL_LOCAL_SHORTAGE: '3'
} as const;
export type AlertType = typeof AlertType[keyof typeof AlertType];


export function instanceOfAlertType(value: any): boolean {
    for (const key in AlertType) {
        if (Object.prototype.hasOwnProperty.call(AlertType, key)) {
            if (AlertType[key as keyof typeof AlertType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AlertTypeFromJSON(json: any): AlertType {
    return AlertTypeFromJSONTyped(json, false);
}

export function AlertTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): AlertType {
    return json as AlertType;
}

export function AlertTypeToJSON(value?: AlertType | null): any {
    return value as any;
}

