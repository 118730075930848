import { defineMessages } from 'react-intl';

const messages = defineMessages({
  // File
  fileSelectionTitle: {
    id: 'importFileSelectionTitle',
    defaultMessage: 'Select Excel file',
  },
  uploadFileButton: {
    id: 'importUploadFileButton',
    defaultMessage: 'Upload file',
    description: 'Label for Upload File button',
  },

  // Assignments
  assignmentsTitle: {
    id: 'importAssignmentsTitle',
    defaultMessage: 'Assignments',
  },
  rowNumber: {
    id: 'importRowNumber',
    defaultMessage: '#',
    description: 'Excel row number column heading',
  },
  itemNo: {
    id: 'importItemNo',
    defaultMessage: 'Art.No',
    description: 'Excel article number column heading',
  },
  itemName: {
    id: 'importItemName',
    defaultMessage: 'Name',
    description: 'Excel article name column heading',
  },
  fromDate: {
    id: 'importFromDate',
    defaultMessage: 'Date',
    description: 'Excel date heading',
  },
  slid: {
    id: 'importSlid',
    defaultMessage: 'SLID',
    description: 'Excel Sales Location heading',
  },
  quantity: {
    id: 'importQuantity',
    defaultMessage: 'SSQ',
    description: 'Excel Sales Space Quantity heading',
  },
  productDominance: {
    id: 'importProductDominance',
    defaultMessage: 'Prod. Dom.',
    description: 'Excel Product Dominance heading',
  },
  merchandisingSolution: {
    id: 'importMerchandisingSolution',
    defaultMessage: 'Merch. Sol.',
    description: 'Excel Merchandising Solution heading',
  },
  homebase: {
    id: 'importHomebase',
    defaultMessage: 'Homebase',
    description: 'Excel Homebase heading',
  },
  primary: {
    id: 'importPrimary',
    defaultMessage: 'Primary',
    description: 'Excel Primary heading',
  },
  manualPick: {
    id: 'importManualPick',
    defaultMessage: 'Manual',
    description: 'Excel Manual Pick heading',
  },
  action: {
    id: 'importAction',
    defaultMessage: 'Action',
    description: 'Assignment Action heading',
  },
  assignmentAction: {
    id: 'importAssignmentAction',
    defaultMessage: 'Action',
    description: 'Assignment Action heading',
  },
  assignmentError: {
    id: 'importAssignmentError',
    defaultMessage: 'Error',
    description: 'Assignment Error heading',
  },
  assignmentWarning: {
    id: 'importAssignmentWarning',
    defaultMessage: 'Warning',
    description: 'Assignment Warning heading',
  },
  bulkAssignButton: {
    id: 'importBulkAssignButton',
    defaultMessage: 'Apply actions',
    description: 'Label for Bulk Assignments button',
  },

  completeTitle: {
    id: 'importCompleteTitle',
    defaultMessage: 'Complete',
  },
  completeMessage: {
    id: 'importCompleteMessage',
    defaultMessage: 'Assignments applied.',
  },

  tryAgain: {
    id: 'importTryAgain',
    defaultMessage: 'Try again',
  },
});

export default messages;
