import { MessageDescriptorWithEnum } from 'views/Maintain/types';

/**
 * Obtains an enum value for a given string value.
 *
 * @return The matching key or `null` if not found.
 */
export function enumValue<TEnum>(e: any, value: string): TEnum | null {
  const key = Object.keys(e).find(key => e[key] === value);
  return key ? e[key] : null;
}

/**
 * Obtains an enum key for a given value.
 *
 * @return The matching key or `null` if not found.
 */
export function enumKey<TEnum>(e: TEnum, value: any): string | null {
  return Object.keys(e).find(key => e[key] === value) ?? null;
}

/**
 * Finds a MessageDescriptor with a given `enumValue`.
 */
export function findEnumMessage(
  messages: Record<string, MessageDescriptorWithEnum>,
  value: string
): MessageDescriptorWithEnum | null {
  return (
    Object.values(messages).find(s => s.enumValue === value && !s.hidden) ??
    null
  );
}
