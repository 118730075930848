import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import InlineMessage from '@ingka/inline-message';
import messages from 'core/commonMessages';

type NoResultsMessageProps = {
  /**
   * Optional message title.
   *
   * @default the common "no results" title.
   */
  title?: MessageDescriptor;

  /**
   * Optional message body.
   *
   * @default the common "no results" body.
   */
  body?: MessageDescriptor;
};

/**
 * Displays a message indicating a search operation yields no results.
 *
 * @see https://skapa.ikea.net/components/messages/presenting-messages
 */
const NoResultsMessage: React.FC<NoResultsMessageProps> = ({
  title = messages.noResultsTitle,
  body = messages.noResultsBody,
}) => {
  const { $t } = useIntl();
  return (
    <InlineMessage body={$t(body)} title={$t(title)} variant="informative" />
  );
};

export default NoResultsMessage;
