import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery, apiSerializeQueryArgs } from 'core/rtkQuery';
import {
  PaginatedItemParameterDto,
  PaginatedItemParameterDtoFromJSON,
  ParameterDto,
} from 'apis/backendApi';

export type GroupSelection = {
  parameter: string;
  filter: string;
  filterValue: string;
};

export type PageSelection = {
  pageNr: number;
  pageSize: number;
};

export type GroupParametersSelection = {
  groupSelection: GroupSelection | null;
  pageSelection: PageSelection;
};

const groupParametersApi = createApi({
  reducerPath: 'groupParametersApi',
  baseQuery: apiQuery(),
  serializeQueryArgs: apiSerializeQueryArgs,

  tagTypes: ['items'],

  endpoints: builder => ({
    getParametersForItems: builder.query<
      PaginatedItemParameterDto,
      GroupParametersSelection
    >({
      query: ({ groupSelection, pageSelection }) => ({
        url: groupSelection ? '/parameters/v1/{buCode}/items' : null,
        params: { ...groupSelection, ...pageSelection },
      }),

      transformResponse: json => PaginatedItemParameterDtoFromJSON(json),

      providesTags: ['items'],

      keepUnusedDataFor: 0,
    }),

    postParametersForItems: builder.mutation<
      void,
      { groupSelection: GroupSelection; parameter: ParameterDto }
    >({
      query: ({ groupSelection, parameter }) => {
        return {
          url: '/parameters/v1/{buCode}/items',
          params: groupSelection,
          method: 'POST',
          body: parameter,
        };
      },

      invalidatesTags: ['items'],
    }),
  }),
});

export const {
  useGetParametersForItemsQuery,
  usePostParametersForItemsMutation,
} = groupParametersApi;

export default groupParametersApi;
