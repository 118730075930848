import React from 'react';
import propTypes from 'prop-types';
import InlineMessage from '@ingka/inline-message';
import infoCircle from '@ingka/ssr-icon/paths/information-circle.js';

const MODAL_Z_INDEX = 400;

const StatusTextBox = props => {
  const { title, body, type, subtle, zIndex = MODAL_Z_INDEX + 1 } = props;

  let variant, ssrIcon;
  switch (type) {
    case 'OK':
      variant = 'positive';
      break;
    case 'ERROR':
      variant = 'negative';
      break;
    case 'WARN':
      variant = 'cautionary';
      break;
    case 'INFO':
      variant = 'informative';
      ssrIcon = infoCircle;
      break;
    default:
      variant = 'informative';
  }

  const style = {
    width: '450px',
    position: 'fixed',
    top: '100px',
    right: '0',
    zIndex,
  };

  return (
    <InlineMessage
      title={title}
      body={body}
      dismissable
      variant={variant}
      ssrIcon={ssrIcon}
      subtle={subtle}
      style={style}
    />
  );
};

StatusTextBox.propTypes = {
  title: propTypes.string,
  body: propTypes.string,
  type: propTypes.string,
  subtle: propTypes.bool,
  zIndex: propTypes.number,
};

export default StatusTextBox;
