import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ParameterField, ParameterType } from 'views/Parameters/types';

import * as yup from 'yup';
import { Schema } from 'yup';
import { UseFormTrigger } from 'react-hook-form';
import { ParameterFormValues } from 'views/Parameters/components/form/ParameterForm';
import { diff } from 'deep-object-diff';

function useParameterSchema(fields: ParameterField[]) {
  return useMemo(() => {
    const entries = fields
      .filter(field => !field.isComputed)
      .map(field => {
        let schema: Schema<boolean | number>;

        if (field.type === ParameterType.BOOLEAN) {
          schema = yup.boolean().required();
        } else {
          const { min, max } = field;
          const numberSchema = yup.number().required();

          if (typeof min === 'string') {
            if (fields.some(x => x.name === min)) {
              schema = numberSchema.min(yup.ref(min));
            }
          } else if (typeof min === 'number') {
            schema = numberSchema.min(min as number);
          }

          if (typeof max === 'string') {
            if (fields.some(x => x.name === max)) {
              schema = numberSchema.max(yup.ref(max));
            }
          } else if (typeof max === 'number') {
            schema = numberSchema.max(max as number);
          }
        }

        return [field.name, schema];
      });

    return yup.object().shape(Object.fromEntries(entries));
  }, [fields]);
}

type FormErrors = {
  [name: string]: {
    type: string;
    message: string;
  };
};

/**
 * Produces a React Hook Form resolver that validates Parameters.
 *
 * In addition, it provides the error state for use in field state synchronization
 *
 * @param fields
 */
export function useParameterResolver(fields: ParameterField[]) {
  const [errors, setErrors] = useState<FormErrors>({});

  const schema = useParameterSchema(fields);

  const resolver = useMemo(() => {
    return (data: any) => {
      try {
        const values = schema.validateSync(data, {
          abortEarly: false,
        });
        setErrors({});
        return {
          values,
          errors: {},
        };
      } catch (err) {
        const errors = err.inner.reduce(
          (allErrors: any, currentError: any) => ({
            ...allErrors,
            [currentError.path]: {
              type: currentError.type ?? 'validation',
              message: currentError.message,
            },
          }),
          {}
        );
        setErrors(errors);
        return {
          values: {},
          errors,
        };
      }
    };
  }, [schema]);

  return { resolver, errors };
}

/**
 * Synchronizes the React Hook Form field state by comparing error state snapshots and invoking `trigger` as an effect.
 *
 * Error synchronization has performance impact and is only enabled for parameters that have dynamic limits.
 *
 * @param errors The current error state
 * @param trigger `useForm` trigger
 * @param fields Used for determining if parameters have dynamic limits.
 *
 * @return A function for resetting the error state. Used when switching between Parameter forms, such as Division.
 */
export function useFieldStateSynchronization(
  errors: FormErrors,
  trigger: UseFormTrigger<ParameterFormValues>,
  fields: ParameterField[]
) {
  const state = useRef<FormErrors>({});

  const isRequired = useMemo(
    () =>
      fields.some(
        ({ min, max }) => typeof min === 'string' || typeof max === 'string'
      ),
    [fields]
  );

  useEffect(() => {
    if (!isRequired) {
      return;
    }

    const names = Object.keys(diff(state.current, errors));
    state.current = errors;
    if (names.length > 0) {
      void trigger(names);
    }
  }, [isRequired, errors, trigger]);

  return useCallback(() => {
    state.current = {};
  }, []);
}
