import {
  useDeleteSalesLocationMutation,
  useGetSalesLocationQuery,
  usePutSalesLocationMutation,
} from 'views/StoreStructure/redux/structuresApi';
import { useCallback, useMemo } from 'react';
import { SalesLocationDto, SalesLocationDtoTypeEnum } from 'apis/backendApi';
import {
  AssignableListItem,
  AssignState,
} from 'views/StoreStructure/components/Assigner/Assigner';
import { formatSlid } from 'core/util/formatters';
import { AssignerCrudPrefix } from 'views/StoreStructure/types';
import { useHasUpdateStoreStructurePrivilege } from 'hooks/privilege';
import { StoreStructureSelection } from 'core/types';
import { useAsyncFeedback } from 'hooks/feedback';
import sm from 'views/StoreStructure/storeStructureMessages';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';

export function useChangeSalesLocationHideMutation() {
  const [trigger] = usePutSalesLocationMutation();

  return useCallback(
    (dto: SalesLocationDto) => {
      return trigger({
        dto,
        cacheRangeGroupId: dto.rangeGroup,
        tag: 'visibility',
      });
    },
    [trigger]
  );
}

export function useAssignSalesLocationMutation() {
  const [trigger] = usePutSalesLocationMutation();

  return useCallback(
    (dto: SalesLocationDto, currentRangeGroupId: string) => {
      return trigger({
        dto,
        cacheRangeGroupId: currentRangeGroupId,
        tag: 'assign',
      });
    },
    [trigger]
  );
}

type Permissions = {
  canRemove: boolean;
  canHide: boolean;
  canAssign: boolean;
};

const PERMISSIONS = new Map<SalesLocationDtoTypeEnum, Permissions>();
PERMISSIONS.set(SalesLocationDtoTypeEnum.L, {
  canRemove: true,
  canAssign: true,
  canHide: true,
});
PERMISSIONS.set(SalesLocationDtoTypeEnum.C, {
  canRemove: false,
  canAssign: true,
  canHide: true,
});
PERMISSIONS.set(SalesLocationDtoTypeEnum.D, {
  canRemove: false,
  canAssign: false,
  canHide: false,
});
PERMISSIONS.set(SalesLocationDtoTypeEnum.W, {
  canRemove: false,
  canAssign: true,
  canHide: true,
});

function useAssignableListItems(data: SalesLocationDto[], canUpdate: boolean) {
  const { $t } = useIntl();
  return useMemo(() => {
    return (data ?? []).map((dto): AssignableListItem => {
      const { canRemove, canHide, canAssign } = PERMISSIONS.get(dto.type);

      let label = formatSlid(dto.id);
      if (dto.isEmpty) {
        label += ' - ' + $t(cm.empty);
      }

      return {
        id: dto.id,
        isRemovable: canUpdate && canRemove && dto.isEmpty,
        isHideable: canUpdate && canHide && dto.isEmpty,
        isAssignable: canUpdate && canAssign,
        isHidden: dto.hidden,
        isEmpty: dto.isEmpty,
        label,
        operationPrefix: AssignerCrudPrefix.SLID,
        tooltip: dto.description,
        error: null,
      };
    });
  }, [$t, canUpdate, data]);
}

export function useAssignedSlids(selection: StoreStructureSelection) {
  const { data, isSuccess, isFetching } = useGetSalesLocationQuery(
    selection.rangeGroupId,
    {
      skip: !selection.rangeGroupId,
    }
  );

  const canUpdate = useHasUpdateStoreStructurePrivilege();

  const items = useAssignableListItems(data, canUpdate);
  const listItems = useMemo(() => {
    items?.forEach(x => {
      x.isRemovable = false; // Cannot remove assigned items
      x.isAssignable &&= x.isEmpty; // Can only unassign empty items.
    });
    return items;
  }, [items]);

  return { data, isSuccess, isFetching, listItems };
}

export function useToggleSlidVisibilityAction(slids: SalesLocationDto[]) {
  const visibilityChange = useChangeSalesLocationHideMutation();

  return useAsyncFeedback(
    async id => {
      const dtoCopy: SalesLocationDto = {
        ...slids.find(slid => slid.id === id), // Hide currently only possible from the assigned list
      };
      dtoCopy.hidden = !dtoCopy.hidden;
      await visibilityChange(dtoCopy).unwrap();
    },
    [slids, visibilityChange]
  );
}

export function useRemoveSlidAction() {
  const [deleteSlid] = useDeleteSalesLocationMutation();

  return useAsyncFeedback(
    async ({ id }) => {
      await deleteSlid({ id, cacheRangeGroupId: '' }).unwrap(); // Delete only possible from unassigned list
    },
    [deleteSlid],
    {
      messages: sm.useCases.removeSlid,
    }
  );
}

export function useChangeSlidAssignmentAction(
  pickerState: StoreStructureSelection,
  assignedSlid: SalesLocationDto[],
  unassignedSlid: SalesLocationDto[]
) {
  const assignChange = useAssignSalesLocationMutation();

  return useAsyncFeedback(
    async ({ id, assignState }: { id: string; assignState: AssignState }) => {
      const sourceList =
        assignState === AssignState.ASSIGNED ? assignedSlid : unassignedSlid;
      const dtoCopy: SalesLocationDto = {
        ...sourceList.find(slid => slid.id === id),
      };
      const currentRangeGroup = dtoCopy.rangeGroup;
      dtoCopy.rangeGroup =
        dtoCopy.rangeGroup === pickerState.rangeGroupId
          ? ''
          : pickerState.rangeGroupId;
      await assignChange(dtoCopy, currentRangeGroup).unwrap();
    },
    [assignedSlid, unassignedSlid, assignChange, pickerState.rangeGroupId]
  );
}

export function useUnassignedSlids(isRangeGroupSelected: boolean) {
  const { data, isSuccess, isFetching } = useGetSalesLocationQuery('');
  const canUpdate = useHasUpdateStoreStructurePrivilege();

  const items = useAssignableListItems(data, canUpdate);
  const listItems = useMemo(() => {
    items?.forEach(x => {
      x.isHideable = false; // Cannot hide unassigned items
      x.isAssignable = isRangeGroupSelected; // Can only assign if a range group is selected
    });
    return items;
  }, [isRangeGroupSelected, items]);

  return { data, isSuccess, isFetching, listItems };
}
