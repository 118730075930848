import React, { ChangeEvent, useCallback } from 'react';
import Select, { Option } from '@ingka/select';

type BooleanSelectProps = {
  id: string;
  label: string;
  trueLabel: string;
  falseLabel: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

const BooleanSelect: React.FC<BooleanSelectProps> = ({
  id,
  label,
  falseLabel,
  trueLabel,
  value,
  onChange,
}) => {
  const onChangeSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value === 'true';
      if (newValue !== value) {
        onChange(newValue);
      }
    },
    [onChange, value]
  );

  return (
    <Select
      id={id}
      label={label}
      value={value ? 'true' : 'false'}
      hintText={falseLabel}
      onChange={onChangeSelect}
    >
      <Option value="true" label={trueLabel} />
    </Select>
  );
};

export default BooleanSelect;
