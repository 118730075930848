import { TypedUseQueryHookResult } from '@reduxjs/toolkit/dist/query/react';
import { ErrorResponse } from 'apis/backendApi';
import { useEffect, useMemo } from 'react';
import { useAlertDialog } from 'components/Modal/Dialog';
import { BaseQueryFn, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import cm from 'core/commonMessages';
import { MessageDescriptor, useIntl } from 'react-intl';

export type StatusCodeMessages = Record<
  number,
  [MessageDescriptor, MessageDescriptor]
>;

const DEFAULT_MESSAGES: StatusCodeMessages = {
  501: [cm.statusCode501Title, cm.statusCode501Body],
};

/**
 * Wraps RTK query hooks with API error handling.
 *
 * The implementation displays an Alert with status code-specific error messages. In addition, it wraps the `data` with
 * either.
 *
 * @param hookResult The hook output
 * @param messages Custom use case-specific messages. Defaults to predefined status code messages or the raw error messages.
 */
export function useQueryErrorHandling<
  ResultType,
  QueryArg,
  BaseQuery extends BaseQueryFn
>(
  hookResult: TypedUseQueryHookResult<ResultType, QueryArg, BaseQuery>,
  messages: StatusCodeMessages = DEFAULT_MESSAGES
) {
  const error = hookResult.error as FetchBaseQueryError;

  const { $t } = useIntl();

  const [title, body] = useMemo(() => {
    const statusMessages = messages[error?.status];
    const title = statusMessages
      ? $t(statusMessages[0])
      : error?.status?.toString();
    const body = statusMessages
      ? $t(statusMessages[1])
      : (error?.data as ErrorResponse)?.message;
    return [title, body];
  }, [$t, error, messages]);

  const showAlert = useAlertDialog({
    title,
    body,
  });

  useEffect(() => {
    if (error) {
      showAlert();
    }
  }, [error, showAlert]);
  return hookResult;
}
