/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Delivery type reason
 * @export
 */
export const DeliveryTypeReasonEnum = {
    PICK: '0',
    MUPQ_EQ_PALQ: '1',
    MDQ_EQ_PALQ: '2',
    PALLET_FITS: '3',
    DAILY_SALES_GR_PALQ: '4',
    DWP_PALQ_EQ_0: '5',
    DIRECT_DELIVERY: '6',
    ORDER_FULL_PLT_ONLY_EQ_1: '7'
} as const;
export type DeliveryTypeReasonEnum = typeof DeliveryTypeReasonEnum[keyof typeof DeliveryTypeReasonEnum];


export function instanceOfDeliveryTypeReasonEnum(value: any): boolean {
    for (const key in DeliveryTypeReasonEnum) {
        if (Object.prototype.hasOwnProperty.call(DeliveryTypeReasonEnum, key)) {
            if (DeliveryTypeReasonEnum[key as keyof typeof DeliveryTypeReasonEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function DeliveryTypeReasonEnumFromJSON(json: any): DeliveryTypeReasonEnum {
    return DeliveryTypeReasonEnumFromJSONTyped(json, false);
}

export function DeliveryTypeReasonEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeliveryTypeReasonEnum {
    return json as DeliveryTypeReasonEnum;
}

export function DeliveryTypeReasonEnumToJSON(value?: DeliveryTypeReasonEnum | null): any {
    return value as any;
}

