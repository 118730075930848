import React, { useMemo } from 'react';
import pm from 'views/Parameters/parametersMessages';
import { useIntl } from 'react-intl';
import OldConfirmDialog, {
  OnDismiss,
  OnOutcome,
} from 'components/Modal/OldConfirmDialog';
import { ConfirmSubmitState } from 'hooks/form';
import {
  ParameterFormSubmitAction,
  ParameterFormSubmitEvent,
} from 'views/Parameters/components/form/ParameterForm';
import { useFormatParameterValue } from 'views/Parameters/hooks/format';

export type ConfirmSubmitDialogProps = {
  confirm: ConfirmSubmitState<ParameterFormSubmitEvent>;
  onDismiss: OnDismiss;
  onOutcome: OnOutcome;
};

const ConfirmSubmitParametersDialog: React.FC<ConfirmSubmitDialogProps> = ({
  confirm,
  onDismiss,
  onOutcome,
}) => {
  const { $t } = useIntl();

  const formatParameterValue = useFormatParameterValue();

  const isConfirmReset =
    confirm?.event?.action === ParameterFormSubmitAction.RESET_ALL;

  const { confirmTitle, confirmBody } = useMemo(() => {
    const body: any[] = [];
    const { event } = confirm;
    if (event) {
      if (isConfirmReset) {
        body.push($t(pm.confirmResetBody));
      } else {
        body.push($t(pm.confirmUpdateBody));
        const fields =
          event.action === ParameterFormSubmitAction.SUBMIT_DIRTY
            ? event.dirty
            : event.all;
        fields.forEach(parameter => {
          body.push({
            text: `${parameter.name}: ${formatParameterValue(parameter.value)}`,
            className: 'slm-parameter',
          });
        });
      }
    }
    return {
      confirmTitle: $t(
        isConfirmReset ? pm.confirmResetTitle : pm.confirmUpdateTitle
      ),
      confirmBody: body,
    };
  }, [$t, confirm, formatParameterValue, isConfirmReset]);

  return (
    <OldConfirmDialog
      visible={confirm.isVisible}
      title={confirmTitle}
      body={confirmBody}
      onDismiss={onDismiss}
      onOutcome={onOutcome}
      className="slm-confirm-submit"
    />
  );
};

export default ConfirmSubmitParametersDialog;
