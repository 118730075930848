/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Merchandising Solution
 * @export
 */
export const MerchandisingSolution = {
    F_NotRTS: '0',
    F_RTS: '1',
    S_NotRTS: '2',
    S_RTS: '3'
} as const;
export type MerchandisingSolution = typeof MerchandisingSolution[keyof typeof MerchandisingSolution];


export function instanceOfMerchandisingSolution(value: any): boolean {
    for (const key in MerchandisingSolution) {
        if (Object.prototype.hasOwnProperty.call(MerchandisingSolution, key)) {
            if (MerchandisingSolution[key as keyof typeof MerchandisingSolution] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MerchandisingSolutionFromJSON(json: any): MerchandisingSolution {
    return MerchandisingSolutionFromJSONTyped(json, false);
}

export function MerchandisingSolutionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchandisingSolution {
    return json as MerchandisingSolution;
}

export function MerchandisingSolutionToJSON(value?: MerchandisingSolution | null): any {
    return value as any;
}

