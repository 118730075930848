import React, { ComponentType, FC } from 'react';
import { useGetStoreStructureTreeQuery } from 'views/StoreStructure/redux/structuresApi';
import Loading, { LoadingBall } from '@ingka/loading';
import InlineMessage from '@ingka/inline-message';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';

const OnLoading: React.FC = () => (
  <Loading>
    <LoadingBall />
  </Loading>
);

const OnError: React.FC = () => {
  const { $t } = useIntl();
  return (
    <InlineMessage
      title={$t(cm.error)}
      body={$t(cm.storeStructureLoadingError)}
      variant="negative"
    />
  );
};

/**
 * Wraps Route-level components that depend on Store Structure data.
 */
export default function withStoreStructure<P extends object>(
  Component: ComponentType<P>
): FC<P> {
  return function (props: P) {
    const { isLoading, isSuccess, isError } = useGetStoreStructureTreeQuery();

    return (
      <>
        {isLoading && <OnLoading />}
        {isSuccess && <Component {...props} />}
        {isError && <OnError />}
      </>
    );
  };
}
