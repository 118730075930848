import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import {
  mapSearchCriteriaToQuery,
  SearchCriteria,
  Summary,
} from 'views/Maintain/types';
import {
  SearchActionFilterEnumFromJSON,
  SearchArticleStatusEnumFromJSON,
  SearchAvailabilityEnumFromJSON,
  SearchFlowTypeEnumFromJSON,
  SearchLifeCycleEnumFromJSON,
  SearchProblemCodeEnumFromJSON,
  SearchSalesSteeringEnumFromJSON,
  SearchSortOrderEnum,
  SearchSortOrderEnumFromJSON,
} from 'apis/backendApi';
import { getApiDate, parseApiDate } from 'core/util/date';
import { useRouteBuCode } from 'hooks/buCode';
import { useGetItemSearchQuery } from 'views/Maintain/redux/searchApi';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OnPageSelect } from 'components/Pagination/ArticlePagination';
import { useQueryErrorHandling } from 'hooks/rtkQuery';

const queryNames = {
  Division: 'div',
  SpecialityShop: 'sp',
  RangeGroup: 'rg',
  RangeStructure: 'rs',
  ArticleStatus: 'stat',
  FlowType: 'flow',
  ProblemCode: 'pc',
  LifeCycle: 'lfc',
  Availability: 'av',
  SalesSteering: 'st',
  ActionFilter: 'af',
  ExceedsExcessiveLimit: 'el',
  AttributeDate: 'AtrD',
  Text: 'text',
  Sorting: 'sort',
  PageNumber: 'page',
  PageSize: 'size',
} as const;

const defaultPageSize = 25;

function serializeSearch(s: SearchCriteria, tab?: string): URLSearchParamsInit {
  const attributeDateString = getApiDate(s.attributeDate);
  //Don't put current date into the query string, for a re-usable "current date" bookmark
  const serializedDateString =
    getApiDate(new Date()) !== attributeDateString
      ? attributeDateString
      : undefined;
  return Object.entries({
    [queryNames.PageNumber]: s.pageNr > 1 ? '' + s.pageNr : undefined,
    [queryNames.PageSize]:
      s.pageSize !== defaultPageSize ? '' + s.pageSize : undefined,
    [queryNames.Division]: s.divisionId ?? undefined,
    [queryNames.SpecialityShop]: s.specialityShopId ?? undefined,
    [queryNames.RangeGroup]: s.rangeGroupId ?? undefined,
    [queryNames.RangeStructure]: s.rangeStructureId ?? undefined,
    [queryNames.FlowType]: s.flowType ?? undefined,
    [queryNames.ProblemCode]: s.problemCode ?? undefined,
    [queryNames.LifeCycle]: s.lifecycle ?? undefined,
    [queryNames.Availability]: s.availability ?? undefined,
    [queryNames.SalesSteering]: s.salesSteering ?? undefined,
    [queryNames.ActionFilter]: s.actionFilter ?? undefined,
    [queryNames.ExceedsExcessiveLimit]: s.exceedsExcessiveLimit
      ? 'true'
      : undefined,
    [queryNames.ArticleStatus]: s.status ?? undefined,
    [queryNames.AttributeDate]: serializedDateString,
    [queryNames.Text]: s.text ?? undefined,
    [queryNames.Sorting]:
      s.sortType === SearchSortOrderEnum.ASCENDING_ARTICLE_NAME
        ? undefined
        : s.sortType,
    tab,
  }).filter(([k, v]) => v !== undefined); // remove undefined for cleaner query url
}

function deserializeSearch(q: URLSearchParams): SearchCriteria {
  const dateString = q.get(queryNames.AttributeDate);
  const date = dateString ? parseApiDate(dateString) : new Date();
  return {
    pageSize:
      Number.parseInt(q.get(queryNames.PageSize), 10) || defaultPageSize,
    pageNr: Number.parseInt(q.get(queryNames.PageNumber), 10) || 1,
    divisionId: q.get(queryNames.Division) || null,
    specialityShopId: q.get(queryNames.SpecialityShop) || null,
    rangeGroupId: q.get(queryNames.RangeGroup) || null,
    rangeStructureId: q.get(queryNames.RangeStructure) || null,
    status:
      SearchArticleStatusEnumFromJSON(q.get(queryNames.ArticleStatus)) || null,
    flowType: SearchFlowTypeEnumFromJSON(q.get(queryNames.FlowType)) || null,
    problemCode:
      SearchProblemCodeEnumFromJSON(q.get(queryNames.ProblemCode)) || null,
    lifecycle: SearchLifeCycleEnumFromJSON(q.get(queryNames.LifeCycle)) || null,
    availability:
      SearchAvailabilityEnumFromJSON(q.get(queryNames.Availability)) || null,
    salesSteering:
      SearchSalesSteeringEnumFromJSON(q.get(queryNames.SalesSteering)) || null,
    actionFilter:
      SearchActionFilterEnumFromJSON(q.get(queryNames.ActionFilter)) || null,
    exceedsExcessiveLimit: q.has(queryNames.ExceedsExcessiveLimit) || null,
    attributeDate: date,
    text: q.get(queryNames.Text) || null,
    sortType:
      SearchSortOrderEnumFromJSON(q.get(queryNames.Sorting)) ||
      SearchSortOrderEnum.ASCENDING_ARTICLE_NAME,
  };
}

export function useSearchQueriesUrl() {
  const [searchParams, setSearchParams] = useSearchParams();

  const searchCriteria: SearchCriteria = deserializeSearch(searchParams);

  const setSearchCriteria = (newSearchCriteria: SearchCriteria) => {
    const params = Object.fromEntries(searchParams.entries());
    setSearchParams(serializeSearch(newSearchCriteria, params.tab), {
      replace: true,
    });
  };

  const clearSearchCriteria = useCallback(() => {
    setSearchParams({});
  }, [setSearchParams]);

  const query = useMemo(() => {
    const params = Object.fromEntries(searchParams.entries());
    const search = serializeSearch(searchCriteria, params.tab);
    return new URLSearchParams(search as any).toString();
  }, [searchCriteria, searchParams]);

  return {
    searchCriteria,
    setSearchCriteria,
    clearSearchCriteria,
    query,
  };
}

export const isSearchEnabled = (searchCriteria: SearchCriteria) => {
  const {
    text,
    rangeStructureId,
    divisionId,
    flowType,
    status,
    lifecycle,
    problemCode,
    availability,
    salesSteering,
    actionFilter,
    exceedsExcessiveLimit,
  } = searchCriteria;

  return (
    text ||
    rangeStructureId ||
    divisionId ||
    flowType ||
    status ||
    lifecycle ||
    problemCode ||
    availability ||
    salesSteering ||
    actionFilter ||
    exceedsExcessiveLimit
  );
};

export function useItemQueries() {
  const buCode = useRouteBuCode();
  const { searchCriteria, setSearchCriteria } = useSearchQueriesUrl();
  const hasValidSearch = Object.entries(searchCriteria)
    .filter(
      ([sKey, sValue]) =>
        !['attributeDate', 'sortType', 'pageNr', 'pageSize'].includes(sKey)
    )
    .some(([sKey, sVal]) => sVal !== null);

  const query = mapSearchCriteriaToQuery(searchCriteria, buCode);

  const {
    isLoading,
    isFetching,
    data: actualData,
    currentData,
    isError,
  } = useQueryErrorHandling(
    useGetItemSearchQuery(query, {
      skip: !hasValidSearch,
    })
  );
  const data = isError ? currentData : actualData;

  const [summary, setSummary] = useState<Summary>({
    data: undefined,
    isLoading: false,
  });

  useEffect(() => {
    if (data && !isFetching) {
      setSummary({ data: undefined, isLoading: true });
      const tid = setTimeout(
        () => setSummary({ data: { fillingRate: '!' }, isLoading: false }),
        1000
      );
      return () => clearTimeout(tid);
    }
  }, [data, isFetching]);

  const onPageSelect = useCallback<OnPageSelect>(
    (pageNr: number) => {
      const newSearch = { ...searchCriteria, pageNr };
      setSearchCriteria(newSearch);
    },
    [searchCriteria, setSearchCriteria]
  );

  return {
    isLoading,
    isFetching,
    data,
    summary,
    onPageSelect,
  };
}
