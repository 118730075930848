import React, { useEffect } from 'react';
import log from 'loglevel';

export type RecurringTaskProps = {
  milliseconds: number;
  callback: () => void;
  description: string;
};

/**
 * Executes a callback for each interval.
 */
const RecurringTask: React.FC<RecurringTaskProps> = ({
  milliseconds,
  callback,
  description,
}) => {
  useEffect(() => {
    log.debug('Mounting', description, 'task for every', milliseconds, 'ms');
    const id = setInterval(callback, milliseconds);

    return () => {
      log.debug('Unmounting', description, 'task');
      clearInterval(id);
    };
  }, [callback, description, milliseconds]);

  return <></>;
};

export default RecurringTask;
