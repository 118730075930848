import React from 'react';
import Button from '@ingka/button';
import {
  chevronDoubleLeft,
  chevronDoubleRight,
  trashCan,
  visibilityHide,
  visibilityShow,
} from '@ingka/ssr-icon/icons';
import Loading, { LoadingBall } from '@ingka/loading';
import Tooltip from '@ingka/tooltip';
import { useIntl } from 'react-intl';
import sm from 'views/StoreStructure/storeStructureMessages';
import cm from 'core/commonMessages';
import { StoreStructureType } from 'core/commonTypes';
import { useTaggedCrudState } from 'hooks/crud';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { remToPixels } from 'core/util/style';
import './Assigner.scss';

export type AssignableListItem = {
  id: string;
  label: string;
  tooltip?: string;
  isAssignable: boolean;
  isRemovable: boolean;
  isHideable: boolean;
  isHidden?: boolean;
  isEmpty?: boolean;
  operationPrefix: string;
  error?: any;
};
type ListItemProps = AssignableListItem & AssignerInteractions;

export type OnItemAssign = (args: {
  id: string;
  assignState: AssignState;
}) => Promise<void>;

type AssignerInteractions = {
  onItemAssign: OnItemAssign;
  onItemRemove?: OnItemAssign;
  onItemHideToggle?: (
    id: string,
    currentAssignState: AssignState,
    currentHiddenState: boolean
  ) => void;
};

type AssignerModeProps = {
  isAssigned: boolean;
  tooltips: {
    remove: string;
    hidden: string;
    visible: string;
    assign: string;
    unassign: string;
  };
};

export enum AssignState {
  ASSIGNED,
  UNASSIGNED,
}

export type AssignerProps = AssignerInteractions & {
  assignedItems: Array<AssignableListItem>;
  assignedTitle: { label: string; tooltip: string };
  assignedState: { isLoading: boolean; isSuccess: boolean; error: any };
  unassignedItems: Array<AssignableListItem>;
  unassignedTitle: { label: string; tooltip: string };
  unassignedState: { isLoading: boolean; isSuccess: boolean; error: any };

  /**
   * The height of an item.
   *
   * @default 4rem in pixels
   */
  itemHeight?: number;

  /**
   * The maximum number of items to show in the list. The implementation sizes the scrollable area to fit these number of items.
   */
  itemCount?: number;
};
const Item = ({
  id,
  onItemAssign,
  isAssignable,
  onItemRemove,
  isRemovable,
  isHideable,
  onItemHideToggle,
  isHidden,
  operationPrefix,
  label,
  isAssigned,
  tooltips,
}: ListItemProps & AssignerModeProps): JSX.Element => {
  const crudState = useTaggedCrudState(`${operationPrefix}${id}`);
  const assignState: AssignState = isAssigned
    ? AssignState.ASSIGNED
    : AssignState.UNASSIGNED;
  return (
    <div className="slm-list-item">
      <label>{label}</label>
      <span>
        {!isAssigned && onItemRemove && (
          <Tooltip tooltipText={tooltips.remove} position="leading">
            <Button
              small
              iconOnly
              type="tertiary"
              loading={crudState.isDeleting.delete}
              ssrIcon={trashCan}
              disabled={
                !isRemovable ||
                (crudState.isActive && !crudState.isDeleting.delete)
              }
              onClick={() => onItemRemove({ id, assignState })}
            />
          </Tooltip>
        )}
        {isAssigned && onItemHideToggle && (
          <Tooltip
            tooltipText={isHidden ? tooltips.hidden : tooltips.visible}
            position="leading"
          >
            <Button
              small
              iconOnly
              type="tertiary"
              loading={crudState.isUpdating.visibility}
              disabled={
                !isAssigned || // Hiding is not yet possible for unassigned items
                !isHideable ||
                (crudState.isActive && !crudState.isUpdating.visibility)
              }
              ssrIcon={isHidden ? visibilityHide : visibilityShow}
              onClick={() => onItemHideToggle(id, assignState, isHidden)}
            />
          </Tooltip>
        )}
        {isAssigned ? (
          <Tooltip tooltipText={tooltips.unassign} position="leading">
            <Button
              small
              iconOnly
              type="tertiary"
              ssrIcon={chevronDoubleRight}
              loading={crudState.isUpdating.assign}
              disabled={
                !isAssignable ||
                (crudState.isActive && !crudState.isUpdating.assign)
              }
              onClick={() => onItemAssign({ id, assignState })}
            />
          </Tooltip>
        ) : (
          <Tooltip tooltipText={tooltips.assign} position="leading">
            <Button
              small
              iconOnly
              type="tertiary"
              ssrIcon={chevronDoubleLeft}
              loading={crudState.isUpdating.assign}
              disabled={
                !isAssignable ||
                (crudState.isActive && !crudState.isUpdating.assign)
              }
              onClick={() => onItemAssign({ id, assignState })}
            />
          </Tooltip>
        )}
      </span>
    </div>
  );
};

const ItemList: React.FC<
  {
    itemSize: number;
    height: number;
    items: AssignableListItem[];
  } & AssignerInteractions &
    AssignerModeProps
> = ({
  itemSize,
  height,
  items,
  isAssigned,
  tooltips,
  onItemAssign,
  onItemRemove,
  onItemHideToggle,
}) => {
  return (
    <div tabIndex={-1} style={{ height }} className="slm-list">
      <AutoSizer>
        {({ width, height }) => (
          <FixedSizeList
            itemCount={items.length}
            itemSize={itemSize}
            width={width}
            height={height}
            itemData={items}
          >
            {({ index, style, data }) => {
              const item = data[index];
              return (
                <div style={style}>
                  <Item
                    key={item.id}
                    {...item}
                    onItemAssign={onItemAssign}
                    onItemRemove={onItemRemove}
                    onItemHideToggle={onItemHideToggle}
                    isAssigned={isAssigned}
                    tooltips={tooltips}
                  />
                </div>
              );
            }}
          </FixedSizeList>
        )}
      </AutoSizer>
    </div>
  );
};

const Assigner = ({
  assignedItems = [],
  assignedState,
  unassignedItems = [],
  unassignedState,
  onItemAssign,
  onItemHideToggle,
  onItemRemove,
  assignedTitle,
  unassignedTitle,
  itemHeight,
  itemCount = 10,
}: AssignerProps) => {
  const itemSize = itemHeight !== undefined ? itemHeight : remToPixels(3);
  const height = itemSize * itemCount;
  const { $t } = useIntl();
  const tooltips = {
    remove: $t(sm.assignerRemoveTooltip),
    hidden: $t(cm.hiddenStructureItem, {
      type: StoreStructureType.SalesLocation,
    }),
    visible: $t(cm.visibleStructureItem, {
      type: StoreStructureType.SalesLocation,
    }),
    assign: $t(sm.assignerAssignTooltip),
    unassign: $t(sm.assignerUnassignTooltip),
  };

  return (
    <div className="slm-assigner">
      <div>
        <div className="label-wrapper">
          <label>{assignedTitle.label}</label>
        </div>
        {assignedState.isLoading ? (
          <Loading>
            <LoadingBall />
          </Loading>
        ) : (
          <ItemList
            itemSize={itemSize}
            height={height}
            items={assignedItems}
            onItemAssign={onItemAssign}
            onItemRemove={onItemRemove}
            onItemHideToggle={onItemHideToggle}
            isAssigned
            tooltips={tooltips}
          />
        )}
      </div>
      <div>
        <div className="label-wrapper">
          <label>{unassignedTitle.label}</label>
        </div>
        {unassignedState.isLoading ? (
          <Loading>
            <LoadingBall />
          </Loading>
        ) : (
          <ItemList
            itemSize={itemSize}
            height={height}
            items={unassignedItems}
            onItemAssign={onItemAssign}
            onItemRemove={onItemRemove}
            onItemHideToggle={onItemHideToggle}
            isAssigned={false}
            tooltips={tooltips}
          />
        )}
      </div>
    </div>
  );
};

export default Assigner;
