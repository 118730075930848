import React, { ChangeEvent, useCallback, useState } from 'react';
import Switch from '@ingka/switch';

export type OnAsyncSwitchChange = (checked: boolean) => Promise<void>;

type AsyncSwitchProps = {
  id: string;

  /**
   * Controlled checked value.
   */
  checked: boolean;

  /**
   * Triggered when the toggle changes. Should return a Promise for indicating the outcome.
   *
   * While the Promise is pending, the component is disabled.
   */
  onChange: OnAsyncSwitchChange;

  disabled?: boolean;

  /**
   * Optional label
   */
  label?: string;

  /**
   * Optional string value.
   *
   * @default "true"
   */
  value?: string;
};

/**
 * Controlled Switch component for triggering async actions.
 *
 * @constructor
 */
const AsyncSwitch: React.FC<AsyncSwitchProps> = ({
  id,
  checked,
  onChange,
  label,
  disabled,
  value = 'true',
}) => {
  const [displayChecked, setDisplayChecked] = useState<boolean | undefined>();

  const onSwitchChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      setDisplayChecked(checked);
      try {
        await onChange(checked);
      } finally {
        setDisplayChecked(undefined);
      }
    },
    [onChange]
  );

  return (
    <>
      <Switch
        id={id}
        value={value}
        label={label}
        checked={displayChecked !== undefined ? displayChecked : checked}
        onChange={onSwitchChange}
        disabled={disabled || displayChecked !== undefined}
      />
    </>
  );
};

export default AsyncSwitch;
