type Validation = {
  min: number;
  max: number;
};

export const AREA_M2: Validation = {
  min: 0,
  max: 9999.999,
};

export const PLANNED_MAX_DENSITY: Validation = {
  min: 0,
  max: 0.25, // TODO: Verify
};

export const PLANNED_FILLING_RATE: Validation = {
  min: 0,
  max: 100, // TODO: Verify
};
