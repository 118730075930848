const formatDate = (date: Date, separator: string) => {
  return [
    date.getFullYear(),
    String(date.getMonth() + 1).padStart(2, '0'),
    String(date.getDate()).padStart(2, '0'),
  ].join(separator);
};

export const getApiDate = (date: Date) => formatDate(date, '');

export const getInputDate = (date: Date) => formatDate(date, '-');
export const getInputDateFromApi = (apiDate: string) =>
  `${apiDate.substring(0, 4)}-${apiDate.substring(4, 6)}-${apiDate.substring(
    6,
    8
  )}`;
export const getApiDateFromInput = (inputDate: string) =>
  inputDate.replaceAll('-', '');

export const parseApiDate = (dateString: string) =>
  new Date(getInputDateFromApi(dateString));

const leadingZero = (n: number) => (n < 10 ? `0${n}` : n);

export const timestamp = (date: Date, withSeconds: boolean = false) =>
  `${date.getFullYear()}${leadingZero(date.getMonth() + 1)}${leadingZero(
    date.getDate()
  )}-${leadingZero(date.getHours())}${leadingZero(date.getMinutes())}${
    withSeconds ? leadingZero(date.getSeconds()) : ''
  }`;
