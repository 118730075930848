import React from 'react';
import { useItemAlertsTable } from 'views/Actions/hooks/table';
import SlmTable from 'components/Table/SlmTable';
import Loading, { LoadingBall } from '@ingka/loading';
import { useItemAlerts } from 'views/Actions/hooks/actions';
import { ItemAlertMode } from 'views/Actions/types';
import ActionsFilter from 'views/Actions/components/ActionsFilter/ActionsFilter';
import { alertTypeMessages } from 'views/Actions/actionMessages';

const ItemAlertsTab: React.FC<{ mode: ItemAlertMode }> = ({ mode }) => {
  const {
    isLoading,
    data,
    productAreaOptions,
    productArea,
    onChangeProductArea,
    typeOptions,
    type,
    onChangeType,
    onReset,
    itemCount,
  } = useItemAlerts(mode);

  const table = useItemAlertsTable(data, mode);

  return (
    <>
      <ActionsFilter
        productAreaOptions={productAreaOptions}
        productArea={productArea}
        onChangeProductArea={onChangeProductArea}
        typeOptions={typeOptions}
        type={type}
        onChangeType={onChangeType}
        onReset={onReset}
        itemCount={itemCount}
        typeMessages={alertTypeMessages}
      />
      {isLoading && (
        <Loading>
          <LoadingBall />
        </Loading>
      )}
      {!isLoading && <SlmTable table={table} />}
    </>
  );
};

export default ItemAlertsTab;
