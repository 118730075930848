import { useCallback, useRef, useState } from 'react';
import { OnDismiss, OnOutcome } from 'components/Modal/OldConfirmDialog';

export type ConfirmSubmitState<TEvent> = {
  /**
   * Indicates whether the confirmation is visible.
   * This is typically mapped to  modal `visible` prop.
   */
  isVisible: boolean;

  /**
   * Represent the last confirmation outcome. Can be used to synchronize state.
   */
  confirmed?: boolean;

  /**
   * The submit event.
   *
   * Clients can inspect this to provide context-specific feedback, such as a dialog title and body.
   */
  event?: TEvent;
};

/**
 * Represents the state.
 */
export interface UseSubmitConfirm<TEvent> {
  /**
   * Wraps the invocation of a submit callback in a confirmation dialog.
   *
   * @param event The submit event.
   */
  onConfirmSubmit: (event: TEvent) => Promise<void>;

  /**
   * Confirm state.
   */
  confirm: ConfirmSubmitState<TEvent>;

  /**
   * `<ConfirmDialog>` callback
   */
  onDismiss: OnDismiss;

  /**
   * `<ConfirmDialog>` callback
   */
  onOutcome: OnOutcome;
}

type PromiseCallbacks = {
  resolve: () => void;
  reject: (error: Error) => void;
};

/**
 * Confirms a form submit by wrapping its invocation in a confirm dialog.
 *
 * @param onSubmit The submit callback that will be invoked on a "confirmed" outcome.
 */
export function useConfirmSubmit<TEvent>(
  onSubmit: (event: TEvent) => Promise<void>
): UseSubmitConfirm<TEvent> {
  const [confirm, setConfirm] = useState<ConfirmSubmitState<TEvent>>({
    isVisible: false,
  });

  const promiseRef = useRef<PromiseCallbacks>(null);

  const onConfirmSubmit = (event: TEvent) => {
    setConfirm({
      isVisible: true,
      event,
    });

    return new Promise<void>((resolve, reject) => {
      promiseRef.current = {
        resolve,
        reject,
      };
    });
  };

  const onDismiss = useCallback(() => {
    setConfirm({
      ...confirm,
      isVisible: false,
    });
  }, [confirm]);

  const onOutcome = useCallback(
    async (confirmed: boolean) => {
      setConfirm({
        ...confirm,
        confirmed,
      });

      try {
        if (confirmed) {
          await onSubmit(confirm.event);
        }
        promiseRef.current.resolve();
      } catch (err) {
        promiseRef.current.reject(err);
      } finally {
        promiseRef.current = null;

        // This might trigger a warning right now in this version of React.
        // "Can't perform a React state update on an unmounted component"
        // Later React versions no longer warn against this use case.
        // https://github.com/facebook/react/pull/22114
        setConfirm({
          isVisible: false,
        });
      }
    },
    [confirm, onSubmit]
  );

  return {
    onConfirmSubmit,
    confirm,
    onDismiss,
    onOutcome,
  };
}
