import React, { useCallback, useState } from 'react';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import Button from '@ingka/button';
import Table, { TableBody, TableHeader } from '@ingka/table';
import { useModal } from 'react-modal-hook';
import { AvinAndSalesInfoSet } from 'views/Maintain/types';
import { useIntl } from 'react-intl';
import mm from 'views/Maintain/maintainMessages';
import cm from 'core/commonMessages';

type AvinModalProps = AvinAndSalesInfoSet & {
  onModalClosed: () => void;
};

const AvinModal: React.FC<AvinModalProps> = ({
  availability,
  salesInfo,
  salesInfoFromDate,
  onModalClosed,
}) => {
  const { $t } = useIntl();
  const [isVisible, setVisible] = useState(true);
  const doClose = () => setVisible(false);

  return (
    <Modal
      visible={isVisible}
      handleCloseBtn={doClose}
      onModalClosed={onModalClosed}
    >
      <Prompt
        title={null}
        header={<ModalHeader closeBtnClick={doClose} />}
        footer={
          <ModalFooter>
            <Button text={$t(cm.close)} onClick={doClose} />
          </ModalFooter>
        }
      >
        <>
          <h3>{$t(mm.maintainAvinPopupTableTitleAvin)}</h3>
          {availability?.length === 0 ? (
            '-'
          ) : (
            <Table>
              <TableHeader>
                <tr>
                  <th>{$t(mm.maintainAvinPopupTableHeaderMessage)}</th>
                  <th>{$t(mm.maintainAvinPopupTableHeaderFrom)}</th>
                  <th>{$t(mm.maintainAvinPopupTableHeaderTo)}</th>
                </tr>
              </TableHeader>
              <TableBody>
                {availability.map((a, index) => (
                  <tr key={index}>
                    <td>{a.codeDescription}</td>
                    <td>{a.displayDate}</td>
                    <td>{a.toDate}</td>
                  </tr>
                ))}
              </TableBody>
            </Table>
          )}
          <h3>{$t(mm.maintainAvinPopupTableTitleSI)}</h3>
          {!salesInfo ? (
            '-'
          ) : (
            <Table>
              <TableHeader>
                <tr>
                  <th>{$t(mm.maintainAvinPopupTableHeaderMessage)}</th>
                  <th>{$t(mm.maintainAvinPopupTableHeaderFrom)}</th>
                </tr>
              </TableHeader>
              <TableBody>
                <tr>
                  <td>{salesInfo}</td>
                  <td>{salesInfoFromDate}</td>
                </tr>
              </TableBody>
            </Table>
          )}
        </>
      </Prompt>
    </Modal>
  );
};

export default AvinModal;

export function useAvinModal() {
  const [avinState, setAvinState] = useState<AvinAndSalesInfoSet>(null);
  const [showModal, hideModal] = useModal(
    () => <AvinModal {...avinState} onModalClosed={hideModal} />,
    [avinState]
  );
  return useCallback(
    (avinInfoSet: AvinAndSalesInfoSet) => {
      setAvinState(avinInfoSet);
      showModal();
    },
    [showModal]
  );
}
