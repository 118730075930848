import { StoreStructureListItemModel } from 'views/StoreStructure/components/StoreStructureList/types';
import {
  useDeleteStoreStructureRangeGroupMutation,
  usePostStoreStructureRangeGroupMutation,
  usePutStoreStructureRangeGroupMutation,
} from 'views/StoreStructure/redux/structuresApi';
import React, { useMemo } from 'react';
import { RangeGroupDto } from 'apis/backendApi';
import ItemEditModal, {
  ItemFormValues,
} from 'views/StoreStructure/components/Tabs/ItemEditModal';
import { useGetStoreStructureTreeDivisionQuery } from 'views/StoreStructure/hooks/division';
import { useGetStoreStructureTreeSpecialityShopQuery } from 'views/StoreStructure/hooks/specialityShop';
import { useModal } from 'react-modal-hook';
import ssm from 'views/StoreStructure/storeStructureMessages';
import { useIntl } from 'react-intl';
import { useConfirmDialog } from 'components/Modal/Dialog';
import { StoreStructureType } from 'core/commonTypes';
import { useHasUpdateStoreStructurePrivilege } from 'hooks/privilege';
import { useAsyncFeedback } from 'hooks/feedback';

export function useRangeGroupListItems(
  divisionId: string,
  specialityShopId: string
): StoreStructureListItemModel[] {
  const { data } = useGetStoreStructureTreeDivisionQuery(divisionId);

  const canUpdate = useHasUpdateStoreStructurePrivilege();

  return useMemo(
    () =>
      data?.specialityShops
        .filter(specialityShopDto => specialityShopDto.id === specialityShopId)
        .flatMap(specialityShopDto =>
          specialityShopDto.rangeGroups.map(dto => ({
            structureType: StoreStructureType.RangeGroup,
            divisionId: specialityShopDto.divId,
            specialityShopId: specialityShopDto.id,
            rangeGroupId: dto.id,
            label: dto.description,
            hidden: dto.hidden,
            // Hidden Range Groups cannot be updated/deleted in MHS, this leads to "internal error".
            editable: canUpdate && dto.type === 'L' && !dto.hidden,
            removable: canUpdate && dto.canDelete && !dto.hidden,
            hideable: canUpdate && (dto.hidden || dto.canHide),
          }))
        ),
    [canUpdate, data, specialityShopId]
  );
}

function useRangeGroup(
  divisionId: string,
  specialityShopId: string,
  rangeGroupId: string
) {
  const { data } = useGetStoreStructureTreeSpecialityShopQuery(
    divisionId,
    specialityShopId
  );

  const rangeGroup = data?.rangeGroups?.find(
    rangeGroup => rangeGroup.id === rangeGroupId
  );

  return rangeGroup ? { ...rangeGroup } : undefined;
}

export function useAddRangeGroupAction(
  divisionId: string,
  specialityShopId: string
) {
  const [postStoreStructureRangeGroup] =
    usePostStoreStructureRangeGroupMutation();

  const onSubmit = useAsyncFeedback(
    async ({ name }: ItemFormValues) => {
      const dto: RangeGroupDto = {
        specialityShopId: specialityShopId,
        description: name,
        type: 'L',
        hidden: false,
        canDelete: true,
        canHide: true,
      };
      await postStoreStructureRangeGroup({ divisionId, dto }).unwrap();
    },
    [specialityShopId, postStoreStructureRangeGroup, divisionId],
    {
      messages: [ssm.useCases.addRangeGroup, ssm.constraints],
    }
  );

  const { $t } = useIntl();

  const [showModal, hideModal] = useModal(() => (
    <ItemEditModal
      title={$t(ssm.addRangeGroupTitle)}
      hideModal={hideModal}
      formValues={{ name: null }}
      onSubmit={onSubmit}
    />
  ));

  return showModal;
}

export function useRemoveRangeGroupAction(
  divisionId: string,
  specialityShopId: string,
  rangeGroupId: string
) {
  const rangeGroup = useRangeGroup(divisionId, specialityShopId, rangeGroupId);

  const [deleteStoreStructureRangeGroup] =
    useDeleteStoreStructureRangeGroupMutation();

  const { $t } = useIntl();

  const body = useMemo(() => {
    return [$t(ssm.removeRangeGroupBody), rangeGroup?.description];
  }, [$t, rangeGroup?.description]);

  const onOutcome = useAsyncFeedback<boolean>(
    async outcome => {
      if (outcome) {
        await deleteStoreStructureRangeGroup({
          divisionId,
          specialityShopId,
          rangeGroupId,
          description: rangeGroup?.description,
        }).unwrap();
      }
      return outcome;
    },
    [
      rangeGroup,
      deleteStoreStructureRangeGroup,
      divisionId,
      rangeGroupId,
      specialityShopId,
    ],
    {
      messages: [ssm.useCases.removeRangeGroup, ssm.constraints],
      values: { name: rangeGroup?.description },
    }
  );

  return useConfirmDialog({
    title: $t(ssm.removeRangeGroupTitle),
    body,
    onOutcome,
  });
}

export function useEditRangeGroupAction(
  divisionId: string,
  specialityShopId: string,
  rangeGroupId: string
) {
  const data = useRangeGroup(divisionId, specialityShopId, rangeGroupId);

  const [putStoreStructureRangeGroup] =
    usePutStoreStructureRangeGroupMutation();

  const onSubmit = useAsyncFeedback(
    async ({ name }: ItemFormValues) => {
      const newDto = { ...data, description: name };
      await putStoreStructureRangeGroup({
        divisionId,
        dto: newDto,
        tags: ['name'],
      }).unwrap();
    },
    [data, divisionId, putStoreStructureRangeGroup],
    {
      messages: [ssm.useCases.editRangeGroup, ssm.constraints],
      values: { currentName: data?.description },
    }
  );

  const { $t } = useIntl();

  const [showModal, hideModal] = useModal(
    () => (
      <ItemEditModal
        title={$t(ssm.editRangeGroupTitle)}
        hideModal={hideModal}
        formValues={{ name: data?.description }}
        onSubmit={onSubmit}
      />
    ),
    [data?.description]
  );

  return showModal;
}

export function useShowHideRangeGroupActions(
  divisionId: string,
  specialityShopId: string,
  rangeGroupId: string
) {
  const data = useRangeGroup(divisionId, specialityShopId, rangeGroupId);

  const [putStoreStructureRangeGroup] =
    usePutStoreStructureRangeGroupMutation();

  const onShow = useAsyncFeedback<void>(
    async () => {
      const newDto = { ...data, hidden: false };
      await putStoreStructureRangeGroup({
        divisionId,
        dto: newDto,
        tags: ['hidden'],
      }).unwrap();
    },
    [data, divisionId, putStoreStructureRangeGroup],
    {
      messages: [ssm.useCases.toggleRangeGroupVisibility, ssm.constraints],
      values: { name: data?.description },
      id: data?.id,
    }
  );

  const onHide = useAsyncFeedback<void>(
    async () => {
      const newDto = { ...data, hidden: true };
      await putStoreStructureRangeGroup({
        divisionId,
        dto: newDto,
        tags: ['hidden'],
      }).unwrap();
    },
    [data, divisionId, putStoreStructureRangeGroup],
    {
      messages: [ssm.useCases.toggleRangeGroupVisibility, ssm.constraints],
      values: { name: data?.description },
      id: data?.id,
    }
  );

  return [onShow, onHide];
}
