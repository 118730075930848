import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  acceptChange,
  ApprovalState,
  rejectChange,
  selectApproval,
} from 'views/Parameters/redux/parameterApprovalSlice';
import { RootState } from 'core/store';
import { useAppDispatch } from 'hooks/redux';
import { UseFormResetField } from 'react-hook-form';
import { FormId } from 'views/Parameters/redux/types';
import { useModal } from 'react-modal-hook';
import { HideModal, useBooleanDialogActions } from 'hooks/modal';
import { useIntl } from 'react-intl';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import pm from 'views/Parameters/parametersMessages';
import Button from '@ingka/button';
import commonMessages from 'core/commonMessages';
import { NumericFormValues } from 'components/Input/NumericInputField';

type ParameterApprovalModalProps = {
  formId: FormId;
  parameter: string;
  hideModal: HideModal;
  resetField: UseFormResetField<NumericFormValues>;
};

const ApproveParameterModal: React.FC<ParameterApprovalModalProps> = ({
  formId,
  parameter,
  hideModal,
  resetField,
}) => {
  const approval = useSelector<RootState, ApprovalState>(state =>
    selectApproval(state, formId)
  );

  const dispatch = useAppDispatch();

  const onOutcome = useCallback(
    outcome => {
      if (outcome === undefined) {
        return;
      }

      if (outcome) {
        dispatch(acceptChange({ formId, name: parameter }));
      } else {
        dispatch(rejectChange({ formId, name: parameter }));
        resetField(parameter);
      }
    },
    [dispatch, formId, parameter, resetField]
  );

  const { isModalVisible, onOk, onCancel, onClose, onModalClosed } =
    useBooleanDialogActions({
      hideModal,
      onOutcome,
    });

  const { $t } = useIntl();

  return (
    <Modal
      visible={isModalVisible}
      handleCloseBtn={onClose}
      onModalClosed={onModalClosed}
    >
      <Prompt
        title={$t(pm.approveParameterTitle)}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button
              type="primary"
              text={$t(commonMessages.yes)}
              onClick={onOk}
            />
            <Button text={$t(commonMessages.no)} onClick={onCancel} />
          </ModalFooter>
        }
      >
        <p>{$t(pm.approveParameterBodyStart)}</p>
        <p>
          {parameter}: {approval.dirtyValues[parameter]}
        </p>
        <p>{$t(pm.approveParameterBodyEnd)}</p>
      </Prompt>
    </Modal>
  );
};

export function useApproveParameterAction(
  formId: FormId,
  parameter: string,
  resetField: UseFormResetField<NumericFormValues>
) {
  const [showModal, hideModal] = useModal(
    () => (
      <ApproveParameterModal
        formId={formId}
        parameter={parameter}
        hideModal={hideModal}
        resetField={resetField}
      />
    ),
    [formId, parameter, resetField]
  );

  return showModal;
}
