/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const CalcSellOutDateStateEnum = {
    PastEndDateSale: 0,
    BeforeEndDateSale: 1
} as const;
export type CalcSellOutDateStateEnum = typeof CalcSellOutDateStateEnum[keyof typeof CalcSellOutDateStateEnum];


export function instanceOfCalcSellOutDateStateEnum(value: any): boolean {
    for (const key in CalcSellOutDateStateEnum) {
        if (Object.prototype.hasOwnProperty.call(CalcSellOutDateStateEnum, key)) {
            if (CalcSellOutDateStateEnum[key as keyof typeof CalcSellOutDateStateEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function CalcSellOutDateStateEnumFromJSON(json: any): CalcSellOutDateStateEnum {
    return CalcSellOutDateStateEnumFromJSONTyped(json, false);
}

export function CalcSellOutDateStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalcSellOutDateStateEnum {
    return json as CalcSellOutDateStateEnum;
}

export function CalcSellOutDateStateEnumToJSON(value?: CalcSellOutDateStateEnum | null): any {
    return value as any;
}

