import React, { useCallback } from 'react';
import ArticleSearchForm from 'views/Parameters/components/tabs/Article/ArticleSearchForm';
import ParameterForm from 'views/Parameters/components/form/ParameterForm';
import { useArticleParametersQuery } from 'views/Parameters/hooks/articleParameters';
import { FormId, HistoryFilter } from 'views/Parameters/redux/types';
import { useIntl } from 'react-intl';
import s from 'views/Parameters/components/tabs/Tabs.module.css';
import pm from 'views/Parameters/parametersMessages';
import cm from 'core/commonMessages';
import SingleArticleTable from 'views/Parameters/components/tabs/Article/SingleArticleTable';
import ParameterLoadingMessages from 'views/Parameters/components/messages/ParameterLoadingMessages';
import NoResultsMessage from 'components/Message/NoResultsMessage';
import { useParameterHistory } from 'views/Parameters/hooks/history';
import ChangeHistory from 'views/Parameters/components/modal/ChangeHistory';
import { usePostParametersForSingleItemMutation } from 'views/Parameters/redux/articleParametersApi';
import { ParameterDto } from 'apis/backendApi';
import { useAsyncFeedback } from 'hooks/feedback';

const ArticleParametersTab: React.FC = () => {
  const {
    isFetching,
    isSuccess,
    isError,
    isNotFound,
    articleNumber,
    article,
    onSearch,
  } = useArticleParametersQuery();

  const [postParametersForSingleItem] =
    usePostParametersForSingleItemMutation();

  const onSubmit = useAsyncFeedback(
    async ({ dirty, all, resetAll }) => {
      const parameters = (resetAll ? all : dirty) as unknown as ParameterDto[];
      await postParametersForSingleItem({
        itemNo: articleNumber,
        parameters,
        refetch: resetAll,
      }).unwrap();
    },
    [articleNumber, postParametersForSingleItem],
    {
      messages: [pm.useCases.updateArticleParameters, pm.constraints],
      values: { articleNumber },
      id: `/articleParameters/${articleNumber}`,
    }
  );

  const { historySelection, showHistory, closeHistory } = useParameterHistory();

  const onShowArticleHistory = useCallback(() => {
    showHistory({
      filter: HistoryFilter.Item,
      filterValue: articleNumber,
    });
  }, [articleNumber, showHistory]);

  const onShowParameterHistory = useCallback(
    parameter => {
      showHistory({
        filter: HistoryFilter.Item,
        filterValue: articleNumber,
        parameter,
      });
    },
    [articleNumber, showHistory]
  );

  const { $t } = useIntl();

  return (
    <>
      <ArticleSearchForm articleNumber={articleNumber} onSearch={onSearch} />
      <ParameterLoadingMessages
        isLoading={isFetching}
        isError={isError && !isNotFound}
      />
      {!isFetching && isSuccess && article && (
        <div className={s.grid}>
          <fieldset>
            <legend>{$t(pm.article)}</legend>
            <SingleArticleTable
              article={article}
              onShowHistory={onShowArticleHistory}
            />
          </fieldset>
          <fieldset>
            <legend>{$t(pm.parameterValues)}</legend>
            <ParameterForm
              fields={article.fields}
              formId={FormId.ARTICLE}
              onShowHistory={onShowParameterHistory}
              onSubmit={onSubmit}
              showReset
            />
          </fieldset>
        </div>
      )}
      {isNotFound && (
        <NoResultsMessage title={cm.noArticleTitle} body={cm.noArticleBody} />
      )}
      {historySelection && (
        <ChangeHistory
          selection={historySelection}
          closeHistory={closeHistory}
        />
      )}
    </>
  );
};

export default ArticleParametersTab;
