import React from 'react';
import style from './style.module.css';

type PageNumberProps = {
  pageNr: number;
  onClick: (pageNr: number) => void;
  active: boolean;
};

const PageNumber = ({ pageNr, onClick, active }: PageNumberProps) => {
  if (active) {
    return (
      <div className={style.numberSquare}>
        <span className={style.currentPage}>{pageNr}</span>
      </div>
    );
  }

  const click: (e: React.MouseEvent<HTMLButtonElement>) => void = e => {
    e.preventDefault();
    onClick(pageNr);
  };
  return (
    <div className={style.numberSquare}>
      <span>
        <button onClick={click} className={style.linkArea}>
          {pageNr}
        </button>
      </span>
    </div>
  );
};

export default PageNumber;
