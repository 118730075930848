import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 * Maintains user preferences. Preferences are saved to Local Storage.
 */
export type UserPreferencesState = {
  /**
   * Indicates whether History dialog shows the application events.
   */
  showApplicationEvents: boolean;

  /**
   * Font settings in query param format.
   */
  excelExportFont: string;
};

/**
 * Maintains Search Parameter state across Routes.
 */
const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState: {
    showApplicationEvents: false,
    excelExportFont: 'Calibri.14',
  } as UserPreferencesState,

  reducers: {
    toggleShowApplicationEvents(state) {
      state.showApplicationEvents = !state.showApplicationEvents;
    },

    setExcelExportFont(state, action: PayloadAction<string>) {
      state.excelExportFont = action.payload;
    },
  },
});

export const { toggleShowApplicationEvents, setExcelExportFont } =
  userPreferencesSlice.actions;

export default userPreferencesSlice;
