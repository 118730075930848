import { useCallback, useMemo, useState } from 'react';
import {
  useGetDefaultParametersQuery,
  usePostDivisionDefaultParametersMutation,
  usePostHfbDefaultParametersMutation,
  usePostStoreDefaultParametersMutation,
} from 'views/Parameters/redux/defaultParametersApi';
import { ParameterFormSubmitEvent } from 'views/Parameters/components/form/ParameterForm';
import { createParameterFields, ParameterField } from 'views/Parameters/types';
import { DivisionDto, HfbParameterDto, ParameterDto } from 'apis/backendApi';
import { FormChangeEvent } from 'core/commonTypes';
import { useGetParameterValueLimitsQuery } from 'views/Parameters/redux/parameterLimitsApi';
import { useGetStoreStructureTreeQuery } from 'views/StoreStructure/redux/structuresApi';
import { useRouteBuCode } from 'hooks/buCode';
import { useAsyncFeedback } from 'hooks/feedback';
import pm from 'views/Parameters/parametersMessages';
import { useGetStoreStructureTreeDivisionQuery } from 'views/StoreStructure/hooks/division';
import { SelectOption } from 'core/types';

export function useQueries({
  divisionId,
  hfbId,
}: {
  divisionId: string | null;
  hfbId: string | null;
}) {
  const getDefaultParametersQuery = useGetDefaultParametersQuery();
  const buCode = useRouteBuCode();

  const getStoreParameterValueLimitsQuery = useGetParameterValueLimitsQuery({
    buCode: '',
    filter: 'store',
    filterValue: buCode,
  });

  const getDivisionParameterValueLimitsQuery = useGetParameterValueLimitsQuery({
    buCode: '',
    filter: 'division',
    filterValue: divisionId,
  });

  const getHfbParameterValueLimitsQuery = useGetParameterValueLimitsQuery({
    buCode: '',
    filter: 'hfb',
    filterValue: hfbId,
  });

  return {
    getDefaultParametersQuery,
    getStoreParameterValueLimitsQuery,
    getDivisionParameterValueLimitsQuery,
    getHfbParameterValueLimitsQuery,
    isLoading:
      getDefaultParametersQuery.isLoading ||
      getStoreParameterValueLimitsQuery.isLoading ||
      getDivisionParameterValueLimitsQuery.isLoading ||
      getHfbParameterValueLimitsQuery.isLoading,
    isError:
      getDefaultParametersQuery.isError ||
      getStoreParameterValueLimitsQuery.isError ||
      getDivisionParameterValueLimitsQuery.isError ||
      getHfbParameterValueLimitsQuery.isError,
    isSuccess:
      getDefaultParametersQuery.isSuccess &&
      getStoreParameterValueLimitsQuery.isSuccess &&
      getDivisionParameterValueLimitsQuery.isSuccess &&
      getHfbParameterValueLimitsQuery.isSuccess,
  };
}

type UseQueries = ReturnType<typeof useQueries>;

export function useMutations() {
  const [postStoreDefaultParameters, postStoreDefaultParametersMutation] =
    usePostStoreDefaultParametersMutation();

  const [postDivisionDefaultParameters, postDivisionDefaultParametersMutation] =
    usePostDivisionDefaultParametersMutation();

  const [postHfbDefaultParameters, postHfbDefaultParametersMutation] =
    usePostHfbDefaultParametersMutation();

  return {
    postStoreDefaultParameters,
    postStoreDefaultParametersMutation,
    postDivisionDefaultParameters,
    postDivisionDefaultParametersMutation,
    postHfbDefaultParameters,
    postHfbDefaultParametersMutation,
    isLoading:
      postStoreDefaultParametersMutation.isLoading ||
      postDivisionDefaultParametersMutation.isLoading ||
      postHfbDefaultParametersMutation.isLoading,
    isError:
      postStoreDefaultParametersMutation.isError ||
      postDivisionDefaultParametersMutation.isError ||
      postHfbDefaultParametersMutation.isError,
    isSuccess:
      postStoreDefaultParametersMutation.isSuccess ||
      postDivisionDefaultParametersMutation.isSuccess ||
      postHfbDefaultParametersMutation.isSuccess,
  };
}

type UseMutations = ReturnType<typeof useMutations>;

export function useStoreDefaults(queries: UseQueries, mutations: UseMutations) {
  const { getDefaultParametersQuery, getStoreParameterValueLimitsQuery } =
    queries;

  const defaults = useMemo(() => {
    const { storeDefaults: parameters = [] } =
      getDefaultParametersQuery.data ?? {};
    const limits = getStoreParameterValueLimitsQuery.data ?? [];
    return createParameterFields(parameters, limits);
  }, [getDefaultParametersQuery.data, getStoreParameterValueLimitsQuery.data]);

  const { postStoreDefaultParameters } = mutations;

  const onSubmit = useAsyncFeedback(
    async ({ dirty }: ParameterFormSubmitEvent) => {
      await postStoreDefaultParameters(
        dirty as unknown as ParameterDto[]
      ).unwrap();
    },
    [postStoreDefaultParameters],
    {
      messages: [pm.useCases.updateStoreParameters, pm.constraints],
      id: 'storeDefaultParameters',
    }
  );

  return { defaults, onSubmit };
}

function useFormSubmit() {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const onFormChange = useCallback(
    (event: FormChangeEvent) => {
      if (isFormSubmitting !== event.isSubmitting) {
        setIsFormSubmitting(event.isSubmitting);
      }
    },
    [isFormSubmitting]
  );

  return { isFormSubmitting, onFormChange };
}

export function useDefaultDivision(): DivisionDto {
  const { data } = useGetStoreStructureTreeQuery();
  const [defaultDivision] = data?.divisions ?? [];
  if (defaultDivision === undefined) {
    throw new Error(
      'Store structure not found. This hook should not be invoked if the store structure is not available.'
    );
  }
  return defaultDivision;
}

export function useDivisionDefaults(
  queries: UseQueries,
  mutations: UseMutations,
  divisionId: string
) {
  const { getDefaultParametersQuery, getDivisionParameterValueLimitsQuery } =
    queries;

  const defaults = useMemo(() => {
    const { divisions = [] } = getDefaultParametersQuery.data ?? {};
    const parameters: ParameterDto[] =
      divisions.find(division => division.id === divisionId)?.parameters ?? [];
    const limits = getDivisionParameterValueLimitsQuery.data ?? [];
    return createParameterFields(parameters, limits);
  }, [
    getDefaultParametersQuery.data,
    getDivisionParameterValueLimitsQuery.data,
    divisionId,
  ]);

  const { isFormSubmitting, onFormChange } = useFormSubmit();

  const { postDivisionDefaultParameters } = mutations;

  const { data } = useGetStoreStructureTreeDivisionQuery(divisionId);

  const onSubmit = useAsyncFeedback(
    async ({ dirty }: ParameterFormSubmitEvent) => {
      await postDivisionDefaultParameters({
        divisionId,
        parameters: dirty as unknown as ParameterDto[],
      }).unwrap();
    },
    [postDivisionDefaultParameters, divisionId],
    {
      messages: [pm.useCases.updateDivisionParameters, pm.constraints],
      id: `divisionParameters/${divisionId}`,
      values: {
        name: data?.name,
      },
    }
  );

  return {
    defaults,
    isFormSubmitting,
    onFormChange,
    onSubmit,
  };
}

export function useHfbDefaults(
  queries: UseQueries,
  mutations: UseMutations,
  hfbId: string
) {
  const { getDefaultParametersQuery, getHfbParameterValueLimitsQuery } =
    queries;

  const hfbOptions = useMemo<SelectOption[]>(() => {
    const { hfb = [] } = getDefaultParametersQuery.data ?? {};
    return hfb.map(({ id, name }) => ({
      value: id,
      name: id ?? name,
    }));
  }, [getDefaultParametersQuery.data]);

  const defaults = useMemo<ParameterField[]>(() => {
    const { hfb: hfbItems = [] } = getDefaultParametersQuery.data ?? {};
    const hfb: HfbParameterDto = hfbItems.find(hfb => hfb.id === hfbId) ?? {};

    let parameters: ParameterDto[] = Object.keys(hfb)
      .filter(name => name !== 'id' && name !== 'name')
      .map(name => ({
        name,
        value: hfb[name],
      }));
    if (parameters.length === 2) {
      parameters = [
        parameters[0],
        // Insert computed parameter
        {
          name: 'turnOverMarkDftp',
        },
        parameters[1],
      ];
    }
    const limits = getHfbParameterValueLimitsQuery.data ?? [];
    return createParameterFields(parameters, limits, false);
  }, [
    getDefaultParametersQuery.data,
    getHfbParameterValueLimitsQuery.data,
    hfbId,
  ]);

  const { isFormSubmitting, onFormChange } = useFormSubmit();

  const { postHfbDefaultParameters } = mutations;

  const onSubmit = useAsyncFeedback(
    async ({ all }: ParameterFormSubmitEvent) => {
      const parameters = Object.fromEntries(
        all.map(param => [param.name, param.value as number])
      );
      const { highTurnoverMark, lowTurnoverMark } = parameters;
      const hfb: HfbParameterDto = {
        id: hfbId,
        highTurnoverMark,
        lowTurnoverMark,
      };
      await postHfbDefaultParameters(hfb).unwrap();
    },
    [postHfbDefaultParameters, hfbId],
    {
      messages: [pm.useCases.updateHfbParameters, pm.constraints],
      values: { name: hfbId },
    }
  );

  return {
    defaults,
    hfbOptions,
    isFormSubmitting,
    onFormChange,
    onSubmit,
  };
}
