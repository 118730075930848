import React, { useCallback } from 'react';
import GroupSearchForm from 'views/Parameters/components/tabs/Group/GroupSearchForm';
import s from '../Tabs.module.css';
import GroupSearchResults from 'views/Parameters/components/tabs/Group/GroupSearchResults';
import { HistoryFilter } from 'views/Parameters/redux/types';
import {
  useGroupParameterQuery,
  useGroupParameterSelection,
} from 'views/Parameters/hooks/groupParameters';
import pm from 'views/Parameters/parametersMessages';
import Loading, { LoadingBall } from '@ingka/loading';
import NotImplementedMessage from 'components/Message/NotImplementedMessage';
import NoResultsMessage from 'components/Message/NoResultsMessage';
import { useParameterHistory } from 'views/Parameters/hooks/history';
import ChangeHistory from 'views/Parameters/components/modal/ChangeHistory';
import { usePostParametersForItemsMutation } from 'views/Parameters/redux/groupParametersApi';
import { useAsyncFeedback } from 'hooks/feedback';

const GroupParametersTab: React.FC = () => {
  const { selection, onSearch, onPageSelect } = useGroupParameterSelection(500);

  const query = useGroupParameterQuery(selection);

  const [postParametersForItems] = usePostParametersForItemsMutation();

  const { groupSelection } = selection;

  const onSubmit = useAsyncFeedback(
    async parameter => {
      await postParametersForItems({
        groupSelection: groupSelection,
        parameter,
      }).unwrap();
    },
    [groupSelection, postParametersForItems],
    {
      messages: [pm.useCases.updateGroupParameter, pm.constraints],
      values: {
        parameter: groupSelection?.parameter,
      },
      id: `groupParameters/${groupSelection?.parameter}`,
    }
  );

  const { historySelection, showHistory, closeHistory } = useParameterHistory();

  const onShowHistory = useCallback(
    itemNo => {
      showHistory({
        filter: HistoryFilter.Item,
        filterValue: itemNo,
      });
    },
    [showHistory]
  );

  return (
    <>
      <GroupSearchForm onSearch={onSearch} />
      <div className={s.groupSearchResult}>
        {query.isFetching && (
          <Loading>
            <LoadingBall />
          </Loading>
        )}
        {!query.isFetching &&
          query.isSuccess &&
          query.items?.content.length > 0 && (
            <>
              <GroupSearchResults
                items={query.items}
                fields={query.parameterFields}
                onSubmit={onSubmit}
                onShowHistory={onShowHistory}
                onPageSelect={onPageSelect}
              />
            </>
          )}
        {!query.isFetching &&
          query.isSuccess &&
          query.items?.content.length === 0 && (
            <NoResultsMessage
              title={pm.noArticlesTitle}
              body={pm.noArticlesBody}
            />
          )}
        {!query.isFetching && query.error?.status === 501 && (
          <NotImplementedMessage body={pm.serviceClassFilterNotImplemented} />
        )}
        {historySelection && (
          <ChangeHistory
            selection={historySelection}
            closeHistory={closeHistory}
          />
        )}
      </div>
    </>
  );
};

export default GroupParametersTab;
