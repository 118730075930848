import React, { ChangeEvent, useCallback, useMemo } from 'react';
import Table, { TableBody, TableHeader } from '@ingka/table';
import {
  AssqTimeViewValue,
  PaginatedItemSearchResultDto,
  RssqTimeViewValue,
  SearchResultTimeViewDto,
} from 'apis/backendApi';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useIntl } from 'react-intl';
import mm from 'views/Maintain/maintainMessages';
import './TimeViewTable.scss';
import Select, { Option } from '@ingka/select';
import { useSingleSearchParamState } from 'hooks/routing';
import { useItemColumnDefs } from 'views/Maintain/hooks/columns';
import { useFormatNumber } from 'hooks/locale';
import Tooltip from '@ingka/tooltip';

type View = 'assq' | 'rssq';

const ViewSelect: React.FC<{ view: View; onChange: (view: View) => void }> = ({
  view,
  onChange,
}) => {
  const { $t } = useIntl();

  const onChangeView = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value as View);
    },
    [onChange]
  );

  return (
    <Select
      id="view"
      onChange={onChangeView}
      value={view}
      hintText={null}
      className="slm-table-size"
    >
      <Option
        value="assq"
        name={$t(mm.maintainResultTabLabelTimeViewAssq)}
        key="assq"
      />
      <Option
        value="rssq"
        name={$t(mm.maintainResultTabLabelTimeViewRssq)}
        key="rssq"
      />
    </Select>
  );
};

function useTimeViewColumns(data: PaginatedItemSearchResultDto, view: View) {
  const { itemNo, itemName } = useItemColumnDefs(
    mm.maintainResultDirectFlowSummary
  );
  const formatNumber = useFormatNumber();
  return useMemo<ColumnDef<SearchResultTimeViewDto>[]>(() => {
    const weeks: ColumnDef<SearchResultTimeViewDto>[] = data.weekNumbers.map(
      (weekNumber, index) => ({
        header: `${weekNumber}`,
        accessorFn: (timeView: SearchResultTimeViewDto) =>
          view === 'assq' ? timeView.assq[index] : timeView.rssq[index],
        id: `week-${weekNumber}-${view}`,
        cell: props => {
          const value: AssqTimeViewValue | RssqTimeViewValue | null =
            props.getValue();
          if (value?.value !== null) {
            return formatNumber(value.value, {
              maximumFractionDigits: 1,
            });
          }
        },
        footer: () => {
          const summary = data.directFlowSummaries[index];
          return summary?.value;
        },
      })
    );

    return [itemNo, itemName, ...weeks];
  }, [
    data.directFlowSummaries,
    data.weekNumbers,
    formatNumber,
    itemName,
    itemNo,
    view,
  ]);
}

function isAssqTimeValue(obj: any): obj is AssqTimeViewValue {
  return (obj as AssqTimeViewValue)?.status !== undefined;
}

function isRssqTimeValue(obj: any): obj is RssqTimeViewValue {
  return (obj as RssqTimeViewValue)?.value !== undefined;
}

export type TimeViewTableProps = {
  data: PaginatedItemSearchResultDto;
};

const TimeViewTable: React.FC<TimeViewTableProps> = ({ data }) => {
  const [view, setView] = useSingleSearchParamState<View>('view', 'assq');

  const table = useReactTable<SearchResultTimeViewDto>({
    columns: useTimeViewColumns(data, view),
    data: data.timeViewContent,
    getCoreRowModel: getCoreRowModel(),
    /* eslint-disable react/prop-types */
    defaultColumn: {
      header: props => {
        return (
          <Tooltip
            tooltipText={props.column.columnDef.meta.headerMessages.tooltip}
            position="bottom"
          >
            <span>{props.column.columnDef.meta.headerMessages.text}</span>
          </Tooltip>
        );
      },
    },
    /* eslint-enable react/prop-types */
  });

  const { $t } = useIntl();

  return (
    <Table fullWidth className={`slm-table slm-time-view slm-${view}`}>
      <TableHeader>
        <tr>
          <th colSpan={2}>
            <ViewSelect view={view} onChange={setView} />
          </th>
          <th className="slm-weeks" colSpan={16}>
            {$t(mm.maintainResultTabLabelTimeViewWeeks)}
          </th>
        </tr>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const className = header.id.startsWith('week')
                ? 'slm-number'
                : `slm-${header.id}`;
              return (
                <th key={header.id} className={className}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows.map(row => {
          return (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => {
                let value = cell.getValue();
                let className = `slm-${cell.getContext().column.id}`;
                if (isAssqTimeValue(value)) {
                  className = `slm-${value.status} slm-number`;
                } else if (isRssqTimeValue(value)) {
                  className = `slm-number`;
                }
                return (
                  <td key={cell.id} className={className}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </TableBody>
      <tfoot>
        {table.getFooterGroups().map(footerGroup => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map(header => {
              const summary =
                header.index > 1
                  ? data.directFlowSummaries[header.index - 2]
                  : undefined;
              const className = summary
                ? `slm-${summary?.status} slm-number`
                : '';
              return (
                <td key={header.id} className={className}>
                  {flexRender(
                    header.column.columnDef.footer,
                    header.getContext()
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tfoot>
    </Table>
  );
};

export default TimeViewTable;
