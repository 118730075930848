import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useViewDialog } from 'components/Modal/Dialog';
import { useIntl } from 'react-intl';
import em from 'views/Import/exportMessages';
import cm from 'core/commonMessages';
import Button from '@ingka/button';
import { SearchCriteria } from 'views/Maintain/types';
import { useUserPreferences } from 'hooks/userPreferences';
import { useAppDispatch } from 'hooks/redux';
import {
  convertSearchResults,
  ExportStage,
  reset,
  selectExportState,
} from 'views/Import/redux/exportSlice';
import { useSelector } from 'react-redux';
import { FontSelect } from 'views/Import/components/export/FontSelect';

const ExportDialog: React.FC<{ search: SearchCriteria }> = ({ search }) => {
  const dispatch = useAppDispatch();

  const [generating, setGenerating] = useState(false);
  const { excelExportFont: font } = useUserPreferences();

  const { $t } = useIntl();

  const messages = useMemo<Record<string, string>>(() => {
    const entries = Object.entries(em.excel).map(([key, value]) => [
      key,
      $t(value),
    ]);
    return Object.fromEntries(entries);
  }, [$t]);

  const onGenerateFile = useCallback(async () => {
    setGenerating(true);
    try {
      await dispatch(convertSearchResults({ search, font, messages }));
    } finally {
      setGenerating(false);
    }
  }, [dispatch, font, messages, search]);

  useLayoutEffect(
    () => () => {
      dispatch(reset());
    },
    [dispatch]
  );

  const { stage, objectUrl, filename, error } = useSelector(selectExportState);

  return (
    <>
      {stage === ExportStage.CONFIGURE && (
        <>
          <FontSelect disabled={generating} />
          <Button
            onClick={onGenerateFile}
            type="primary"
            text={$t(em.generateFileButton)}
            loading={generating}
          />
        </>
      )}
      {stage === ExportStage.DOWNLOAD && (
        <a href={objectUrl} download={filename}>
          {filename}
        </a>
      )}
      {stage === ExportStage.EMPTY && $t(em.errorEmpty)}
      {stage === ExportStage.ERROR &&
        $t(cm.unknownError, { message: error.message })}
    </>
  );
};

export function useExportDialog(search: SearchCriteria) {
  const { $t } = useIntl();
  return useViewDialog({
    title: $t(em.exportTitle),
    body: <ExportDialog search={search} />,
  });
}
