import React from 'react';
import Select, { Option } from '@ingka/select';
import Loading, { LoadingBall } from '@ingka/loading';
import StatusTextBox from 'components/StatusTextBox';
import {
  useTimeViewSelection,
  useTimeViewTable,
} from 'views/Summaries/hooks/timeView';
import { useIntl } from 'react-intl';
import sm from 'views/Summaries/summariesMessages';
import cm from 'core/commonMessages';
import Help from 'components/Help/Help';
import SummaryTable from 'views/Summaries/components/SummaryTable';

const TimeViewTab: React.FC = () => {
  const { topic, onChangeTopic, topicOptions } = useTimeViewSelection();

  const { isFetching, isError, isSuccess, table } = useTimeViewTable(topic);

  const { $t } = useIntl();

  return (
    <>
      <Select
        id="topic"
        className="slm-d-inline-block"
        label={$t(sm.topic)}
        defaultValue={topic}
        onChange={onChangeTopic}
        data-anchor="timeViewKeyFigureSelector"
      >
        {topicOptions.map(({ value, name }) => (
          <Option key={value} value={value} name={name} />
        ))}
      </Select>
      {isFetching && (
        <Loading>
          <LoadingBall />
        </Loading>
      )}
      {!isFetching && isSuccess && table && <SummaryTable table={table} />}
      {isError && <StatusTextBox title="Error" body="ErrorText" type="ERROR" />}
      <Help header={$t(sm.timeView)} body={$t(cm.helpText)} />
    </>
  );
};

export default TimeViewTab;
