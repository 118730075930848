import React, { CSSProperties } from 'react';
import {
  flexRender,
  HeaderGroup,
  Row,
  Table as TanstackTable,
} from '@tanstack/react-table';
import Table, { TableBody, TableHeader } from '@ingka/table';
import chevronRight from '@ingka/ssr-icon/paths/chevron-right-small';
import chevronDown from '@ingka/ssr-icon/paths/chevron-down-small';
import chevronDoubleDown from '@ingka/ssr-icon/paths/chevron-double-down-small';
import chevronDoubleRight from '@ingka/ssr-icon/paths/chevron-double-right-small';
import SSRIcon from '@ingka/ssr-icon';
import Nowrap from 'components/Text/Nowrap';
import m from 'views/Summaries/summariesMessages';
import { useIntl } from 'react-intl';
import { COL_HEADING, SummaryLine, SummaryValue } from 'views/Summaries/types';
import './SummaryTable.scss';

const SummaryTableHeadingRow: React.FC<{
  headerGroup: HeaderGroup<SummaryLine>;
  table: TanstackTable<SummaryLine>;
}> = ({ headerGroup, table }) => {
  const { $t } = useIntl();
  const isExpanded = table.getIsAllRowsExpanded();
  return (
    <tr>
      {headerGroup.headers.map(header => {
        if (header.id === COL_HEADING) {
          return (
            <th
              key={header.id}
              role="button"
              onClick={() => {
                table.toggleAllRowsExpanded(!isExpanded);
              }}
            >
              <Nowrap value={$t(isExpanded ? m.collapseAll : m.expandAll)}>
                <SSRIcon
                  paths={isExpanded ? chevronDoubleDown : chevronDoubleRight}
                />
              </Nowrap>
            </th>
          );
        } else {
          return (
            <th key={header.id}>
              {flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          );
        }
      })}
    </tr>
  );
};

/**
 * Indention for each row level in the tree table, expressed in REMs.
 */
const INDENTATION = 0.75;

/**
 * Extra indentation needed for rows that are not collapsible.
 */
const EXTRA_INDENTATION = 0.5;

const SummaryTableBodyRow: React.FC<{
  row: Row<SummaryLine>;
}> = ({ row }) => {
  const className = row.depth > 0 ? `slm-level-${row.depth}` : undefined;
  return (
    <tr className={className}>
      {row.getVisibleCells().map(cell => {
        const style: CSSProperties = {};
        if (row.depth > 0) {
          const indentation =
            row.depth * INDENTATION +
            (row.getCanExpand() ? 0 : EXTRA_INDENTATION);
          style.paddingLeft = `${indentation}rem`;
        }

        const summaryValue = cell.getValue() as SummaryValue;

        if (cell.column.id === COL_HEADING) {
          return (
            <th
              key={cell.id}
              style={style}
              onClick={() => row.toggleExpanded()}
              role={row.getCanExpand() ? 'button' : undefined}
            >
              <Nowrap
                value={summaryValue.text}
                tokens={row.depth === 0 ? 4 : 1}
              >
                {row.getCanExpand() && (
                  <SSRIcon
                    paths={row.getIsExpanded() ? chevronDown : chevronRight}
                  />
                )}
              </Nowrap>
            </th>
          );
        } else {
          return (
            <td key={cell.id} className={summaryValue.statusColor?.toString()}>
              {summaryValue.text}
            </td>
          );
        }
      })}
    </tr>
  );
};

export type SummaryTableProps = {
  table: TanstackTable<SummaryLine>;
};

const SummaryTable: React.FC<SummaryTableProps> = ({ table }) => {
  return (
    <Table className="slm-summary slm-table" fullWidth>
      <TableHeader sticky>
        {table.getHeaderGroups().map(headerGroup => (
          <SummaryTableHeadingRow
            key={headerGroup.id}
            headerGroup={headerGroup}
            table={table}
          />
        ))}
      </TableHeader>
      <TableBody>
        {table.getRowModel().rows.map(row => (
          <SummaryTableBodyRow key={row.id} row={row} />
        ))}
      </TableBody>
    </Table>
  );
};

export default SummaryTable;
