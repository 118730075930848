import React from 'react';
import InputField from '@ingka/input-field';
import FormField from '@ingka/form-field';
import { InputFieldProps } from './types';
import { useFormatNumber } from 'hooks/locale';
import { FormatNumberOptions } from '@formatjs/intl/src/types';

export type NumericReadOnlyFieldProps = InputFieldProps & {
  value?: number;
  placeholder?: string;
  formatOptions?: FormatNumberOptions;
};

const NumericReadOnlyField: React.FC<NumericReadOnlyFieldProps> = ({
  id,
  label,
  className,
  disabled = false,
  value,
  placeholder,
  formatOptions,
}) => {
  const formatNumber = useFormatNumber();
  const text =
    typeof value === 'number'
      ? formatNumber(value, formatOptions)
      : placeholder;

  return (
    <FormField>
      <InputField
        id={id}
        label={label}
        className={`slm-neutral ${className}`}
        title={label}
        disabled={disabled}
        readOnly
        value={text}
        type="text"
        autoComplete="off"
      />
    </FormField>
  );
};

export default NumericReadOnlyField;
