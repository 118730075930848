import React from 'react';
import { ItemAssignmentsDto, ItemDetailsDto } from 'apis/backendApi';
import Table, { TableBody, TableHeader } from '@ingka/table';
import { useIntl } from 'react-intl';
import Tooltip from '@ingka/tooltip';
import {
  commercialInfoMessages,
  ssqInfoMessages,
} from 'views/Maintain/tableMessages';
import mm from 'views/Maintain/maintainMessages';
import cm from 'core/commonMessages';
import AssignmentTableBody from './AssignmentTableBody';

const RecommendationTableBody: React.FC<{
  data: ItemDetailsDto;
}> = ({ data }) => {
  const { $t, formatNumber } = useIntl();

  const ltv = data.longTermRecommendations;
  return (
    <TableBody striped>
      <tr>
        <th>
          <Tooltip tooltipText={$t(ssqInfoMessages.minSsqTooltip)}>
            <>{$t(ssqInfoMessages.minSsq)}</>
          </Tooltip>
        </th>
        <td>{ltv ? formatNumber(ltv.minSSQ) : undefined}</td>
        {data.recommendations.map((rec, i) => (
          <td key={i}>{rec ? formatNumber(rec.minSSQ) : undefined}</td>
        ))}
      </tr>
      <tr>
        <th>
          <Tooltip tooltipText={$t(ssqInfoMessages.dtfpTooltip)}>
            <>{$t(ssqInfoMessages.dtfp)}</>
          </Tooltip>
        </th>
        <td>{ltv ? formatNumber(ltv.dtfp) : undefined}</td>
        {data.recommendations.map((rec, i) => (
          <td key={i}>{rec ? formatNumber(rec.dtfp) : undefined}</td>
        ))}
      </tr>
      <tr>
        <th>
          <Tooltip tooltipText={$t(ssqInfoMessages.dtfpPlusTooltip)}>
            <>{$t(ssqInfoMessages.dtfpPlus)}</>
          </Tooltip>
        </th>
        <td>{ltv ? formatNumber(ltv.dtfpPlus) : undefined}</td>
        {data.recommendations.map((rec, i) => (
          <td key={i}>{rec ? formatNumber(rec.dtfpPlus) : undefined}</td>
        ))}
      </tr>
      <tr>
        <th>
          <Tooltip tooltipText={$t(ssqInfoMessages.maxSsqTooltip)}>
            <>{$t(ssqInfoMessages.maxSsq)}</>
          </Tooltip>
        </th>
        <td>{ltv ? formatNumber(ltv.maxSSQ) : undefined}</td>
        {data.recommendations.map((rec, i) => (
          <td key={i}>{rec ? formatNumber(rec.maxSSQ) : undefined}</td>
        ))}
      </tr>
      <tr>
        <th>
          <Tooltip tooltipText={$t(ssqInfoMessages.rssqTooltip)}>
            <>{$t(ssqInfoMessages.rssq)}</>
          </Tooltip>
        </th>
        <td>{ltv ? formatNumber(ltv.rssq) : undefined}</td>
        {data.recommendations.map((rec, i) => (
          <td key={i}>{rec ? formatNumber(rec.rssq) : undefined}</td>
        ))}
      </tr>
      <tr>
        {/*TODO maybe better forecast term*/}
        <th>
          <Tooltip tooltipText={$t(commercialInfoMessages.forecastTooltip)}>
            <>{$t(commercialInfoMessages.forecast)}</>
          </Tooltip>
        </th>
        <td />
        {data.recommendations.map((rec, i) => (
          <td key={i}>
            {rec && typeof rec.forecast === 'number'
              ? formatNumber(rec.forecast)
              : undefined}
          </td>
        ))}
      </tr>
      <tr>
        <th>
          <Tooltip tooltipText={$t(ssqInfoMessages.deliveryTypeTooltip)}>
            <>{$t(ssqInfoMessages.deliveryType)}</>
          </Tooltip>
        </th>
        <td />
        {data.recommendations.map((rec, i) => (
          <td key={i}>{rec?.deliveryType}</td>
        ))}
      </tr>
      <tr>
        <th>
          <Tooltip
            tooltipText={$t(commercialInfoMessages.replenishmentPointTooltip)}
          >
            <>{$t(commercialInfoMessages.replenishmentPoint)}</>
          </Tooltip>
        </th>
        <td />
        {data.recommendations.map((rec, i) => (
          <td key={i}>
            {rec ? formatNumber(rec.replenishmentPoint) : undefined}
          </td>
        ))}
      </tr>
      <tr>
        <th>
          <Tooltip tooltipText={$t(mm.maintainItemDetailsMaxOnHandTooltip)}>
            <>{$t(mm.maintainItemDetailsMaxOnHand)}</>
          </Tooltip>
        </th>
        <td />
        {data.recommendations.map((rec, i) => (
          <td key={i}>{rec ? formatNumber(rec.maxOnHand) : undefined}</td>
        ))}
      </tr>
    </TableBody>
  );
};

export type WeekTableProps = {
  data: ItemDetailsDto;
  assignments: ItemAssignmentsDto;
};

const WeekTable: React.FC<WeekTableProps> = ({ data, assignments }) => {
  const { $t, formatNumber } = useIntl();
  return (
    <Table fullWidth className="slm-table slm-weeks">
      <TableHeader>
        <tr>
          <td />
          <th>
            <Tooltip tooltipText={$t(mm.maintainItemDetailsLTVTooltip)}>
              <>{$t(mm.maintainItemDetailsLTV)}</>
            </Tooltip>
          </th>
          {data.weeks.map(week => (
            <th key={week.weekNumber}>
              {$t(cm.weekPrefix)}
              {week.weekNumber}
            </th>
          ))}
        </tr>
      </TableHeader>
      <RecommendationTableBody data={data} />
      <TableBody striped>
        <tr>
          <th>
            <Tooltip tooltipText={$t(ssqInfoMessages.assqTooltip)}>
              <>{$t(ssqInfoMessages.assq)}</>
            </Tooltip>
          </th>
          <td />
          {assignments.assqs.map((assq, i) => (
            <td key={i} className={`slm-${assq.status}`}>
              {assq.value ? formatNumber(assq.value) : ''}
            </td>
          ))}
        </tr>
      </TableBody>
      <AssignmentTableBody data={data} assignments={assignments} />
    </Table>
  );
};

export default WeekTable;
