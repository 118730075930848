import { GetStoresResponse, useGetStoresQuery } from 'core/redux/userApi';
import { useOptionalUser } from '../contexts/UserContext';
import { SelectedStore } from 'core/types';

function hasSingleStore(response?: GetStoresResponse) {
  return (
    response?.regions?.length === 1 &&
    response.regions[0].countries?.length === 1 &&
    response.regions[0].countries[0].stores?.length === 1
  );
}

/**
 * Indicates if the user has access to multiple stores.
 */
export function useHasMultipleStoreAccess(): boolean {
  const user = useOptionalUser();
  const { data } = useGetStoresQuery({ userId: user?.userId });
  return !hasSingleStore(data);
}

/**
 * Obtains the single available store.
 *
 * Returns `null` if the user has multiple stores available.
 */
export function useSingleAvailableStore(): SelectedStore {
  const user = useOptionalUser();
  const { data } = useGetStoresQuery({ userId: user?.userId });
  return data && hasSingleStore(data)
    ? data.regions[0].countries[0].stores[0]
    : null;
}
