import React, { useCallback } from 'react';
import { useAppDispatch } from 'hooks/redux';
import { cleanPendingUpdates } from 'core/redux/pendingUpdatesSlice';
import RecurringTask from 'components/RecurringTask/RecurringTask';
import { MHS_SYNC_TIMEOUT } from 'core/types';

export type CleanPendingUpdatesProps = {
  /**
   * The interval (in milliseconds) for executing the cleanup job.
   */
  interval?: number;

  /**
   * The timeout (in milliseconds) after which pending updates are considered expired.
   *
   * @default MHS_SYNC_TIMEOUT
   */
  timeout?: number;
};

const CleanPendingUpdates: React.FC<CleanPendingUpdatesProps> = ({
  interval,
  timeout = MHS_SYNC_TIMEOUT,
}) => {
  const dispatch = useAppDispatch();
  const callback = useCallback(() => {
    dispatch(cleanPendingUpdates({ timeout }));
  }, [dispatch, timeout]);

  if (interval === undefined) {
    return <></>;
  }

  return (
    <RecurringTask
      milliseconds={interval}
      callback={callback}
      description="Clean pending updates"
    />
  );
};

export default CleanPendingUpdates;
