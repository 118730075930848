/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  OverallStoreStructureDto,
  ProductAreaDto,
  RangeNodeDto,
  SalesLocationDto,
} from '../models/index';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    OverallStoreStructureDtoFromJSON,
    OverallStoreStructureDtoToJSON,
    ProductAreaDtoFromJSON,
    ProductAreaDtoToJSON,
    RangeNodeDtoFromJSON,
    RangeNodeDtoToJSON,
    SalesLocationDtoFromJSON,
    SalesLocationDtoToJSON,
} from '../models/index';

export interface GetProductAreaRequest {
    buCode: string;
    rgid: string;
}

export interface GetRangeStructureTreeRequest {
    buCode: string;
}

export interface GetSalesLocationRequest {
    buCode: string;
    rgid: string;
}

export interface GetStoreStructureTreeRequest {
    buCode: string;
}

/**
 * 
 */
export class SLMStructuresApi extends runtime.BaseAPI {

    /**
     * Gets the product areas connected to a range group or all the unassigned product areas if the query parameter rgid is an empty string
     * Get the product areas connected to a range group or all the unassigned product areas
     */
    async getProductAreaRaw(requestParameters: GetProductAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductAreaDto>>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling getProductArea().'
            );
        }

        if (requestParameters['rgid'] == null) {
            throw new runtime.RequiredError(
                'rgid',
                'Required parameter "rgid" was null or undefined when calling getProductArea().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['rgid'] != null) {
            queryParameters['rgid'] = requestParameters['rgid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/structures/v1/{buCode}/productArea`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductAreaDtoFromJSON));
    }

    /**
     * Gets the product areas connected to a range group or all the unassigned product areas if the query parameter rgid is an empty string
     * Get the product areas connected to a range group or all the unassigned product areas
     */
    async getProductArea(requestParameters: GetProductAreaRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductAreaDto>> {
        const response = await this.getProductAreaRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get SLM range structure tree for a business unit
     * Fetches the range structure tree
     */
    async getRangeStructureTreeRaw(requestParameters: GetRangeStructureTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<RangeNodeDto>>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling getRangeStructureTree().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/structures/v1/{buCode}/rangestructuretree`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RangeNodeDtoFromJSON));
    }

    /**
     * Get SLM range structure tree for a business unit
     * Fetches the range structure tree
     */
    async getRangeStructureTree(requestParameters: GetRangeStructureTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<RangeNodeDto>> {
        const response = await this.getRangeStructureTreeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets the sales locations connected to a range group or all the available sales locations if rgid is an empty string
     * Get the sales locations connected to a range group or all the available sales locations
     */
    async getSalesLocationRaw(requestParameters: GetSalesLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SalesLocationDto>>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling getSalesLocation().'
            );
        }

        if (requestParameters['rgid'] == null) {
            throw new runtime.RequiredError(
                'rgid',
                'Required parameter "rgid" was null or undefined when calling getSalesLocation().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['rgid'] != null) {
            queryParameters['rgid'] = requestParameters['rgid'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/structures/v1/{buCode}/salesLocation`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SalesLocationDtoFromJSON));
    }

    /**
     * Gets the sales locations connected to a range group or all the available sales locations if rgid is an empty string
     * Get the sales locations connected to a range group or all the available sales locations
     */
    async getSalesLocation(requestParameters: GetSalesLocationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SalesLocationDto>> {
        const response = await this.getSalesLocationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get SLM store structure tree for a business unit
     * Fetches the store structure tree
     */
    async getStoreStructureTreeRaw(requestParameters: GetStoreStructureTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OverallStoreStructureDto>> {
        if (requestParameters['buCode'] == null) {
            throw new runtime.RequiredError(
                'buCode',
                'Required parameter "buCode" was null or undefined when calling getStoreStructureTree().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("slmUserBearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/structures/v1/{buCode}/storestructuretree`.replace(`{${"buCode"}}`, encodeURIComponent(String(requestParameters['buCode']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OverallStoreStructureDtoFromJSON(jsonValue));
    }

    /**
     * Get SLM store structure tree for a business unit
     * Fetches the store structure tree
     */
    async getStoreStructureTree(requestParameters: GetStoreStructureTreeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OverallStoreStructureDto> {
        const response = await this.getStoreStructureTreeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
