import { OnStructureItemPick } from 'components/StoreStructureItemPicker/StoreStructureItemPicker';
import { useCallback } from 'react';
import {
  CrudState,
  storeStructureOperationId,
  StoreStructureType,
} from 'core/commonTypes';
import { SearchParamValue, useSearchParamState } from 'hooks/routing';
import { useTaggedCrudState } from 'hooks/crud';

const DIVISION_PARAM = 'division';
const SPECIALITY_SHOP_PARAM = 'speciality-shop';
const RANGE_GROUP_PARAM = 'range-group';

/**
 * Synchronizes Router search parameters with a `StoreStructureItemPicker`.
 */
export function useStoreStructureSearchParamState({
  defaultDivisionId = null,
  defaultSpecialityShopId = null,
  defaultRangeGroupId = null,
}: {
  defaultDivisionId?: SearchParamValue;
  defaultSpecialityShopId?: SearchParamValue;
  defaultRangeGroupId?: SearchParamValue;
} = {}) {
  const [values, setValues] = useSearchParamState({
    [DIVISION_PARAM]: defaultDivisionId,
    [SPECIALITY_SHOP_PARAM]: defaultSpecialityShopId,
    [RANGE_GROUP_PARAM]: defaultRangeGroupId,
  });

  const {
    [DIVISION_PARAM]: divisionId,
    [SPECIALITY_SHOP_PARAM]: specialityShopId,
    [RANGE_GROUP_PARAM]: rangeGroupId,
  } = values;

  const onStructureItemPick = useCallback<OnStructureItemPick>(
    (type, id) => {
      if (id === null) {
        // Reject selection of the 'blank' options.
        return;
      }

      switch (type) {
        case StoreStructureType.Division:
          if (divisionId !== id) {
            setValues({
              [DIVISION_PARAM]: id,
              [SPECIALITY_SHOP_PARAM]: null,
              [RANGE_GROUP_PARAM]: null,
            });
          }
          break;
        case StoreStructureType.SpecialityShop:
          if (specialityShopId !== id) {
            setValues({
              [SPECIALITY_SHOP_PARAM]: id,
              [RANGE_GROUP_PARAM]: null,
            });
          }
          break;
        case StoreStructureType.RangeGroup:
          setValues({
            [RANGE_GROUP_PARAM]: id,
          });
          break;
      }
    },
    [divisionId, specialityShopId, setValues]
  );

  return {
    divisionId,
    specialityShopId,
    rangeGroupId,
    onStructureItemPick,
  };
}

/**
 * Obtains the CRUD state for a given store structure item.
 */
export function useStoreStructureCrudState(
  storeStructureType: StoreStructureType,
  id?: string
): CrudState {
  return useTaggedCrudState(storeStructureOperationId(storeStructureType, id));
}
