import React, { useCallback, useState } from 'react';
import Button from '@ingka/button';
import grid from 'components/Grid/style.module.css';
import StoreStructureItemPicker from 'components/StoreStructureItemPicker';
import { StoreStructureType } from 'core/commonTypes';
import Assigner from 'views/StoreStructure/components/Assigner/Assigner';
import { useStoreStructureSearchParamState } from 'hooks/storeStructure';
import { usePostSalesLocationMutation } from 'views/StoreStructure/redux/structuresApi';
import {
  useAssignedSlids,
  useChangeSlidAssignmentAction,
  useRemoveSlidAction,
  useToggleSlidVisibilityAction,
  useUnassignedSlids,
} from 'views/StoreStructure/hooks/slid';
import NewSLIDModal from 'views/StoreStructure/components/Tabs/Slid/NewSLIDModal';
import { useIntl } from 'react-intl';
import sm from '../../../storeStructureMessages';
import { useHasUpdateStoreStructurePrivilege } from 'hooks/privilege';
import { lock } from '@ingka/ssr-icon/icons';
import { useAsyncFeedback } from 'hooks/feedback';

const RangeSlidTab = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const openNewSlidModal = useCallback(
    () => setModalOpen(true),
    [setModalOpen]
  );
  const closeNewSlidModal = useCallback(
    () => setModalOpen(false),
    [setModalOpen]
  );

  const { $t } = useIntl();

  const pickerState = useStoreStructureSearchParamState();
  const isRangeGroupSelected = pickerState.rangeGroupId !== null;

  const {
    data: assignedSlid,
    isSuccess: isAssignedSuccess,
    isFetching: isAssignedLoading,
    listItems: assignedItems,
  } = useAssignedSlids(pickerState);

  const {
    data: unassignedSlid,
    isSuccess: isUnassignedSuccess,
    isFetching: isUnassignedLoading,
    listItems: unassignedItems,
  } = useUnassignedSlids(isRangeGroupSelected);

  const changeAssignment = useChangeSlidAssignmentAction(
    pickerState,
    assignedSlid,
    unassignedSlid
  );

  const toggleVisibility = useToggleSlidVisibilityAction(assignedSlid);

  const remove = useRemoveSlidAction();

  const [createSlid, { isLoading: createLoading }] =
    usePostSalesLocationMutation();

  const canAdd = useHasUpdateStoreStructurePrivilege();

  const addSlid = useAsyncFeedback(
    async ({ id, description }) => {
      setModalOpen(false);
      await createSlid({
        id,
        description,
        hidden: false,
        type: 'L',
        rangeGroup: '',
        isEmpty: true,
      }).unwrap();
    },
    [createSlid],
    {
      id: 'add-slid',
      messages: sm.useCases.addSlid,
      throwError: true,
    }
  );

  return (
    <div className="staticGrid">
      <div>
        <div className="plate">
          <div className={grid.threeCol}>
            <StoreStructureItemPicker
              {...pickerState}
              selectorPrefix="rgslid_"
              depth={StoreStructureType.RangeGroup}
            />
          </div>
          <div>
            <div>
              <Assigner
                onItemAssign={changeAssignment}
                onItemRemove={remove}
                onItemHideToggle={toggleVisibility}
                assignedItems={assignedItems}
                assignedState={{
                  isLoading: isAssignedLoading,
                  error: null,
                  isSuccess: isAssignedSuccess,
                }}
                assignedTitle={{
                  label: $t(sm.slidAssignedListTitle),
                  tooltip: $t(sm.slidAssignedListTitleTooltip),
                }}
                unassignedItems={unassignedItems}
                unassignedState={{
                  isLoading: isUnassignedLoading,
                  error: null,
                  isSuccess: isUnassignedSuccess,
                }}
                unassignedTitle={{
                  label: $t(sm.slidUnassignedListTitle),
                  tooltip: $t(sm.slidUnassignedListTitleTooltip),
                }}
              />
              <div className="slm-tae slm-mt-100">
                <Button
                  type="secondary"
                  loading={createLoading}
                  onClick={openNewSlidModal}
                  text={$t(sm.newSlidButton)}
                  disabled={!isRangeGroupSelected || !canAdd}
                  ssrIcon={!canAdd ? lock : undefined}
                />
              </div>
            </div>
          </div>
        </div>
        <NewSLIDModal
          visible={modalOpen}
          addSlid={addSlid}
          onClose={closeNewSlidModal}
        />
      </div>
    </div>
  );
};

export default RangeSlidTab;
