import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery, apiSerializeQueryArgs } from 'core/rtkQuery';
import {
  GetItemSearchRequest,
  GetItemSearchNavigationInfoRequest,
  PaginatedItemSearchResultDto,
  ItemSearchNavigationInfoDto,
} from 'apis/backendApi';

export const tagTypes = ['attributes', 'time', 'summary'];

const queryValuesWithoutNull = (args: GetItemSearchRequest) => {
  return Object.entries(args)
    .filter(([k, v]) => k !== 'buCode' && !!v)
    .reduce((acc, [k, v]) => {
      acc[k] = v;
      return acc;
    }, {});
};
const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: apiQuery(),
  serializeQueryArgs: apiSerializeQueryArgs,
  tagTypes,

  endpoints: build => ({
    getItemSearch: build.query<
      PaginatedItemSearchResultDto,
      GetItemSearchRequest
    >({
      providesTags: [tagTypes[0]],
      keepUnusedDataFor: 1200,

      query: args => {
        return {
          url: '/items/v1/{buCode}/search',
          params: queryValuesWithoutNull(args),
        };
      },
    }),

    getItemSearchNavigationInfo: build.query<
      ItemSearchNavigationInfoDto,
      GetItemSearchNavigationInfoRequest
    >({
      keepUnusedDataFor: 1200,

      query: args => {
        return {
          url: '/items/v1/{buCode}/search-navigation-info',
          params: queryValuesWithoutNull(args),
        };
      },
    }),

    // summary: TODO
  }),
});

export const { useGetItemSearchQuery, useGetItemSearchNavigationInfoQuery } =
  searchApi;

export default searchApi;
