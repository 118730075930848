import React, { useMemo } from 'react';
import { useGetStoreStructureTreeQuery } from 'views/StoreStructure/redux/structuresApi';

export function useLocationHierarchy(rangeId: string, slid?: string) {
  const { data } = useGetStoreStructureTreeQuery();
  return useMemo(() => {
    let items = [slid];
    outer: for (let div of data?.divisions ?? []) {
      if (!div.specialityShops) continue;
      for (let sp of div.specialityShops) {
        if (!sp.rangeGroups) continue;
        for (let rg of sp.rangeGroups) {
          if (rg.id === rangeId) {
            items = [div.name, sp.name, rg.description, ...items];
            break outer;
          }
        }
      }
    }
    return items.filter(x => x !== undefined).join(' / ');
  }, [data?.divisions, rangeId, slid]);
}

const LocationHierarchy: React.FC<{
  rangeId: string;
  slid?: string;
}> = ({ rangeId, slid }) => {
  const items = useLocationHierarchy(rangeId, slid);
  return <>{items}</>;
};

export default LocationHierarchy;
