import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { mapSearchCriteriaToQuery, SearchCriteria } from 'views/Maintain/types';
import {
  getApiAccessToken,
  getApiBuCode,
  getApiLanguage,
  getApiRoot,
} from 'core/apiConfig';
import { RootState } from 'core/store';
import { timestamp } from 'core/util/date';

export enum ExportStage {
  CONFIGURE = 'configure',
  DOWNLOAD = 'download',
  EMPTY = 'empty',
  ERROR = 'error',
}

export type ExportState = {
  stage: ExportStage;
  objectUrl?: string;
  filename?: string;
  error?: {
    message: string;
  };
};

const exportSlice = createSlice({
  name: 'export',

  initialState: {
    stage: ExportStage.CONFIGURE,
  } as ExportState,

  reducers: {
    reset(state) {
      const { objectUrl } = state;

      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }

      delete state.objectUrl;
      delete state.filename;
      state.stage = ExportStage.CONFIGURE;
    },

    emptyResult(state) {
      state.stage = ExportStage.EMPTY;
    },

    completeConversion(
      state,
      action: PayloadAction<{ blob: Blob; filename: string }>
    ) {
      const { blob, filename } = action.payload;
      state.objectUrl = URL.createObjectURL(blob);
      state.filename = filename;
      state.stage = ExportStage.DOWNLOAD;
    },

    failConversion(state, action: PayloadAction<Error>) {
      state.error = { message: action.payload.message };
    },
  },
});

export const convertSearchResults = createAsyncThunk(
  'export/convertSearchResults',
  async (
    {
      search,
      font,
      messages,
    }: {
      search: SearchCriteria;
      font: string;
      messages: Record<string, string>;
    },
    { dispatch }
  ) => {
    const { completeConversion, failConversion, emptyResult } =
      exportSlice.actions;

    const buCode = getApiBuCode();
    const query = Object.fromEntries(
      Object.entries(mapSearchCriteriaToQuery(search, buCode)).filter(
        ([name, value]) =>
          name !== 'pageNr' &&
          name !== 'pageSize' &&
          name !== 'buCode' &&
          name !== 'attributeDate' &&
          value !== null
      )
    );
    query.font = font;
    const apiUrl = new URL(
      `${getApiRoot()}/slm/backend/items/v1/${buCode}/excel-export`
    );
    apiUrl.search = new URLSearchParams(query).toString();

    try {
      const response = await fetch(apiUrl.toString(), {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getApiAccessToken()}`,
          'Accept-Language': getApiLanguage(),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(messages),
      });
      if (response.status === 204) {
        dispatch(emptyResult());
        return;
      }

      const blob = await response.blob();
      const filename = `slm-${buCode}-${timestamp(new Date())}.xlsx`;
      dispatch(completeConversion({ blob, filename }));
    } catch (err) {
      dispatch(failConversion(err));
    }
  }
);

export const selectExportState = (root: RootState) => root.export;

export const { reset } = exportSlice.actions;

export default exportSlice;
