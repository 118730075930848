import React, { useMemo } from 'react';
import { CommonTableProps } from 'views/Maintain/types';
import { useIntl } from 'react-intl';
import { SearchResultItemDto } from 'apis/backendApi';
import { dimensionsMessages } from 'views/Maintain/tableMessages';
import AttributesTable from 'views/Maintain/components/Result/AttributesTable';
import { useIntegerCell } from 'views/Maintain/hooks/cells';

const DimensionsTable: React.FC<CommonTableProps> = ({ data }) => {
  const { $t } = useIntl();
  const integerCell = useIntegerCell();

  const columns = useMemo(
    () => [
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.dimensions?.consumerPackHeight,
        id: 'dimensions_cpHeight',
        meta: {
          headerMessages: {
            text: $t(dimensionsMessages.consumerPackHeight),
            tooltip: $t(dimensionsMessages.consumerPackHeightTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.dimensions?.consumerPackLength,
        id: 'dimensions_cpLength',
        meta: {
          headerMessages: {
            text: $t(dimensionsMessages.consumerPackLength),
            tooltip: $t(dimensionsMessages.consumerPackLengthTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.dimensions?.consumerPackWidth,
        id: 'dimensions_cpWidth',
        meta: {
          headerMessages: {
            text: $t(dimensionsMessages.consumerPackWidth),
            tooltip: $t(dimensionsMessages.consumerPackWidthTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.dimensions?.consumerPackDiameter,
        id: 'dimensions_cpDiameter',
        meta: {
          headerMessages: {
            text: $t(dimensionsMessages.consumerPackDiameter),
            tooltip: $t(dimensionsMessages.consumerPackDiameterTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.dimensions?.consumerPackGrossWeight,
        id: 'dimensions_cpWeight',
        meta: {
          headerMessages: {
            text: $t(dimensionsMessages.consumerPackGrossWeight),
            tooltip: $t(dimensionsMessages.consumerPackGrossWeightTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.dimensions?.unitLoadHeight,
        id: 'dimensions_ulHeight',
        meta: {
          headerMessages: {
            text: $t(dimensionsMessages.unitLoadHeight),
            tooltip: $t(dimensionsMessages.unitLoadHeightTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.dimensions?.unitLoadLength,
        id: 'dimensions_ulLength',
        meta: {
          headerMessages: {
            text: $t(dimensionsMessages.unitLoadLength),
            tooltip: $t(dimensionsMessages.unitLoadLengthTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.dimensions?.unitLoadWidth,
        id: 'dimensions_ulWidth',
        meta: {
          headerMessages: {
            text: $t(dimensionsMessages.unitLoadWidth),
            tooltip: $t(dimensionsMessages.unitLoadWidthTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.dimensions?.unitLoadDiameter,
        id: 'dimensions_ulDiameter',
        meta: {
          headerMessages: {
            text: $t(dimensionsMessages.unitLoadDiameter),
            tooltip: $t(dimensionsMessages.unitLoadDiameterTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.dimensions?.supplyChainNetVolume,
        id: 'dimensions_supplyChainNetM3',
        meta: {
          headerMessages: {
            text: $t(dimensionsMessages.supplyChainNetVolume),
            tooltip: $t(dimensionsMessages.supplyChainNetVolumeTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.dimensions?.numberOfPacks,
        id: 'dimensions_noPacks',
        meta: {
          headerMessages: {
            text: $t(dimensionsMessages.numberOfPackages),
            tooltip: $t(dimensionsMessages.numberOfPackagesTooltip),
          },
        },
        cell: integerCell,
      },
    ],
    [$t, integerCell]
  );
  return <AttributesTable data={data} columns={columns} footer />;
};

export default DimensionsTable;
