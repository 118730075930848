import s from '../tabs/Tabs.module.css';
import Button from '@ingka/button';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { lock, warningTriangle } from '@ingka/ssr-icon/icons';
import {
  Parameter,
  ParameterField,
  ParameterValue,
} from 'views/Parameters/types';
import { ParameterFieldRow } from 'views/Parameters/components/form/ParameterFieldRow';
import { useHasUpdateParametersPrivilege } from 'hooks/privilege';
import {
  ApprovalState,
  resetApprovals,
  selectApproval,
  setDirtyFormValues,
} from 'views/Parameters/redux/parameterApprovalSlice';
import {
  reset as resetComputations,
  setFormValues as setComputationFormValues,
} from 'views/Parameters/redux/parameterComputationSlice';
import { useAppDispatch } from 'hooks/redux';
import { FormId } from 'views/Parameters/redux/types';
import { FormChangeEvent } from 'core/commonTypes';
import cm from 'core/commonMessages';
import { useIntl } from 'react-intl';
import { useConfirmSubmit } from 'hooks/form';
import ConfirmSubmitParametersDialog from 'views/Parameters/components/form/ConfirmSubmitParametersDialog';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import {
  useFieldStateSynchronization,
  useParameterResolver,
} from 'views/Parameters/hooks/validation';
import { useFormatNumber } from 'hooks/locale';

export type ParameterFormValues = { [name: string]: ParameterValue };

export enum ParameterFormSubmitAction {
  SUBMIT_DIRTY = 'submit-dirty',
  SUBMIT_ALL = 'submit-all',
  RESET_ALL = 'reset-all',
}

export type ParameterFormSubmitEvent = {
  dirty: Parameter[];
  all: Parameter[];
  action: ParameterFormSubmitAction;
};

export type OnParameterFormSubmit = (
  event: ParameterFormSubmitEvent
) => Promise<void>;

/**
 * Show the history for a particular parameter or all parameters for a particular item.
 */
export type ShowHistoryCallback = (parameterOrItemId: string) => void;

type ParameterFormProps = {
  /**
   * The fields maintained by the form. The form inputs are rendered in the order specified.
   *
   * When this value changes, the component automatically resets the underlying form. Client should therefore provide
   * memoized values.
   */
  fields: ParameterField[];

  formId: FormId;

  /**
   * Optional show history callback.
   *
   * If provided, the form shows a button for opening the parameter history.
   */
  onShowHistory?: ShowHistoryCallback;

  /**
   * Callback that receives modified parameters after a submit. Should return a Promise indicating success or failure.
   */
  onSubmit: OnParameterFormSubmit;

  /**
   * Indicates whether submitting non-dirty values is enabled.
   *
   * By default, submitting is only enabled when the form is in a dirty state.
   *
   * @default false
   */
  isNonDirtySubmitEnabled?: boolean;

  /**
   * Indicates whether to show the reset button.
   *
   * The reset button generates an `ParameterFormSubmitEvent` with all parameter values set to `null`.
   *
   * @default false
   */
  showReset?: boolean;

  /**
   * Optional submit action.
   *
   * @default ParameterFormSubmitAction.SUBMIT_DIRTY
   */
  submitAction?: ParameterFormSubmitAction;

  /**
   * Optional callback for receiving changes to the form state.
   */
  onChange?: (event: FormChangeEvent) => void;
};

const ParameterForm: React.FC<ParameterFormProps> = ({
  fields,
  formId,
  onShowHistory,
  onSubmit,
  showReset = false,
  submitAction = ParameterFormSubmitAction.SUBMIT_DIRTY,
  onChange = () => undefined,
  isNonDirtySubmitEnabled,
}) => {
  const { resolver, errors } = useParameterResolver(fields);

  const {
    register,
    control,
    getFieldState,
    handleSubmit,
    formState,
    reset,
    resetField,
    trigger,
    watch,
  } = useForm<ParameterFormValues>({
    mode: 'onChange',
    resolver,
  });

  const formatNumber = useFormatNumber();

  const defaultValues: ParameterFormValues = useMemo(
    () =>
      Object.fromEntries(
        fields.map(field => {
          let value = field.value;
          if (typeof value === 'number') {
            value = formatNumber(value as number);
          }
          return [field.name, value];
        })
      ),
    [fields, formatNumber]
  );

  const resetErrorState = useFieldStateSynchronization(errors, trigger, fields);

  useEffect(() => {
    reset(defaultValues);
    resetErrorState();
  }, [resetErrorState, reset, defaultValues]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetApprovals({ formId, fields }));
    dispatch(resetComputations({ formId, fields, defaultValues }));
  }, [dispatch, fields, formId, defaultValues]);

  useEffect(() => {
    // Use one-shot 'watch' subscription to obtain the form values.
    // Note that this effect fires on every form change due to 'watch' returning a new reference every time.
    // Background: https://www.youtube.com/watch?v=3qLd69WMqKk (around 7:50)
    const subscription = watch((values: ParameterFormValues) => {
      const { dirtyFields } = formState;

      const dirtyValues = Object.fromEntries(
        Object.keys(dirtyFields).map(name => [name, values[name]])
      );

      // The following workaround was necessary for an older version of React Hook Form.
      // We keep the code around for the time being, in case it turns out to be necesssary anyway.
      // const dirtyValues = Object.fromEntries(
      //     Object.keys(dirtyFields)
      //         .filter(name => {
      //           const value = values[name];
      //           return (
      //               !Number.isNaN(value) &&
      //               (typeof value === 'number' || typeof value === 'boolean') &&
      //               // Workaround for RHF flagging fields as dirty after a call to resetField()
      //               value !== defaultValues[name]
      //           );
      //         })
      //         .map(name => [name, values[name]])
      // );

      dispatch(setDirtyFormValues({ formId, values: dirtyValues }));
      dispatch(setComputationFormValues({ formId, values }));
    });

    return () => subscription.unsubscribe();
  }, [defaultValues, dispatch, formId, formState, watch]);

  const { confirm, onConfirmSubmit, onDismiss, onOutcome } =
    useConfirmSubmit(onSubmit);

  const onValid = async (values: ParameterFormValues) => {
    const all: Parameter[] = Object.keys(values).map(name => ({
      name,
      value: values[name] as ParameterValue,
    }));
    const dirty = all.filter(param => formState.dirtyFields[param.name]);
    await onConfirmSubmit({
      dirty,
      all,
      action: submitAction,
    });
  };

  const onReset = useCallback(async () => {
    const all = Object.entries(defaultValues).map<Parameter>(([name]) => ({
      name,
      value: null,
    }));
    await onConfirmSubmit({
      dirty: [],
      all,
      action: ParameterFormSubmitAction.RESET_ALL,
    });
  }, [defaultValues, onConfirmSubmit]);

  useEffect(() => {
    const { isDirty, isValid, isSubmitting } = formState;
    onChange({ isDirty, isValid, isSubmitting });
  }, [formState, onChange]);

  // Note: destructuring is required due to FormState being a proxy
  // See: https://react-hook-form.com/docs/useform/formstate
  const { isDirty, isValid, isSubmitting } = formState;

  const isAuthorized = useHasUpdateParametersPrivilege();

  const approval = useSelector<RootState, ApprovalState>(state =>
    selectApproval(state, formId)
  );

  const submitIcon = !isAuthorized
    ? lock
    : approval.hasPendingApprovals
    ? warningTriangle
    : undefined;

  const isResetting =
    confirm?.confirmed &&
    confirm?.event?.action === ParameterFormSubmitAction.RESET_ALL;

  const isSubmitEnabled =
    isAuthorized &&
    (isDirty || isNonDirtySubmitEnabled) &&
    isValid &&
    !approval.hasPendingApprovals &&
    !isResetting;

  const { $t } = useIntl();

  return (
    <>
      <form onSubmit={handleSubmit(onValid)}>
        <table className={s.paramListTable}>
          <tbody>
            {fields.map(field => (
              <ParameterFieldRow
                key={field.name}
                field={field}
                formId={formId}
                getFieldState={getFieldState}
                isLocked={isSubmitting || isResetting}
                control={control}
                register={register}
                resetField={resetField}
                onShowHistory={onShowHistory}
              />
            ))}
          </tbody>
        </table>
        <p className={s.saveBtnContainer}>
          <Button
            text={$t(cm.update)}
            type="primary"
            disabled={!isSubmitEnabled}
            loading={isSubmitting && confirm.confirmed}
            htmlType="submit"
            ssrIcon={submitIcon}
          />
          {showReset && (
            <Button
              text={$t(cm.resetToDefault)}
              type="secondary"
              disabled={isSubmitting}
              loading={isResetting}
              onClick={onReset}
            />
          )}
        </p>
      </form>
      {/* TODO: Refactor to useConfirmSubmit hook */}
      <ConfirmSubmitParametersDialog
        confirm={confirm}
        onDismiss={onDismiss}
        onOutcome={onOutcome}
      />
    </>
  );
};

export default ParameterForm;
