import React, { useCallback, useMemo } from 'react';
import Modal, { ModalFooter, ModalHeader, Theatre } from '@ingka/modal';
import Button from '@ingka/button';
import Table, { TableBody, TableHeader } from '@ingka/table';
import s from 'views/Parameters/components/tabs/Tabs.module.css';
import {
  FetchParameterHistoryAction,
  HistoryFilter,
} from 'views/Parameters/redux/types';
import Loading, { LoadingBall } from '@ingka/loading';
import { MessageDescriptor, useIntl } from 'react-intl';
import pm from 'views/Parameters/parametersMessages';
import cm from 'core/commonMessages';
import {
  GetParameterHistoryFilterEnum,
  ParameterHistory,
} from 'apis/backendApi';
import { useGetParameterHistoryQuery } from 'views/Parameters/redux/parameterHistoryApi';
import {
  useFieldLabel,
  useFormatParameterValue,
} from 'views/Parameters/hooks/format';

const modalTitle = new Map<HistoryFilter, MessageDescriptor>([
  [HistoryFilter.F6, pm.f6Parameter],
  [HistoryFilter.Hfb, pm.hfbParameter],
  [HistoryFilter.Division, pm.divisionParameter],
  [HistoryFilter.Store, pm.storeParameter],
  [HistoryFilter.Item, pm.itemParameter],
]);

type HistoryItem = {
  dateTime: string;
  userName: string;
  parameter: string;
  oldValue: string;
  newValue: string;
};

function useHistoryItems(items: ParameterHistory[]) {
  const formatParameterValue = useFormatParameterValue();

  return useMemo<HistoryItem[]>(() => {
    return (items ?? []).map(
      (item): HistoryItem => ({
        dateTime: item.timestamp,
        userName: item.user,
        parameter: item.parameter,
        oldValue: formatParameterValue(item.oldValue),
        newValue: formatParameterValue(item.newValue),
      })
    );
  }, [items, formatParameterValue]);
}

export type ChangeHistoryProps = {
  selection: FetchParameterHistoryAction;
  closeHistory: () => void;
};

const HistoryItemRow: React.FC<{ data: HistoryItem }> = ({ data }) => {
  return (
    <tr>
      <td>{data.dateTime}</td>
      <td>{data.userName}</td>
      <td>{useFieldLabel(data.parameter)}</td>
      <td>{data.oldValue}</td>
      <td>{data.newValue}</td>
    </tr>
  );
};

const ChangeHistory = ({ selection, closeHistory }: ChangeHistoryProps) => {
  const { $t } = useIntl();

  const title = $t(modalTitle.get(selection.filter));

  const filter = GetParameterHistoryFilterEnum[HistoryFilter[selection.filter]];
  const { filterValue, parameter } = selection;

  const { isLoading, isError, isSuccess, data } = useGetParameterHistoryQuery({
    buCode: '',
    filter,
    filterValue,
    paramId: parameter,
  });

  const items = useHistoryItems(data);

  const onCloseHistory = useCallback(() => {
    closeHistory();
  }, [closeHistory]);

  const onPrint = useCallback(() => {
    window.print();
  }, []);

  return (
    <Modal visible escapable handleCloseBtn={onCloseHistory}>
      <Theatre title={null} header={<ModalHeader title={title} />}>
        <div className={s.theatreContainer}>
          {isLoading && (
            <Loading>
              <LoadingBall />
            </Loading>
          )}
          {isError && <p>{$t(cm.historyLoadingError)}</p>}
          {isSuccess && (
            <Table style={{ width: '100%', marginBottom: '40px' }}>
              <TableHeader>
                <tr>
                  <th>{$t(cm.dateTime)}</th>
                  <th>{$t(cm.userName)}</th>
                  <th>{$t(pm.parameter)}</th>
                  <th>{$t(pm.oldValue)}</th>
                  <th>{$t(pm.newValue)}</th>
                </tr>
              </TableHeader>
              <TableBody striped>
                {items.map((item, i) => (
                  <HistoryItemRow key={`item_${i}`} data={item} />
                ))}
              </TableBody>
            </Table>
          )}
        </div>
        <ModalFooter className={s.footer}>
          <Button text={$t(cm.print)} type="primary" onClick={onPrint} />
          <Button
            text={$t(cm.close)}
            type="secondary"
            onClick={onCloseHistory}
          />
        </ModalFooter>
      </Theatre>
    </Modal>
  );
};

export default ChangeHistory;
