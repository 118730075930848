import { AlertType, NotificationType } from 'apis/backendApi';
import React, { ChangeEvent, useCallback } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import Select, { Option } from '@ingka/select';
import am from 'views/Actions/actionMessages';
import Button from '@ingka/button';
import { cross } from '@ingka/ssr-icon/icons';
import { ItemCount } from 'core/types';
import './ActionsFilter.scss';
import { SelectOption } from 'core/types';

export type ActionsFilterProps = {
  productAreaOptions: SelectOption[];
  productArea: string;
  onChangeProductArea: (productArea: string) => void;
  typeOptions: AlertType[] | NotificationType[];
  typeMessages: Record<number, MessageDescriptor>;
  type: string;
  onChangeType: (type: string) => void;
  onReset: () => void;
  itemCount: ItemCount;
};

const ActionsFilter: React.FC<ActionsFilterProps> = ({
  productAreaOptions,
  productArea,
  onChangeProductArea,
  typeOptions,
  typeMessages,
  type,
  onChangeType,
  onReset,
  itemCount,
}) => {
  const onChangeProductAreaSelect = useCallback(
    (event: ChangeEvent<HTMLSelectElement>, hintOption: boolean) => {
      const value = hintOption ? null : event.target.value;
      onChangeProductArea(value);
    },
    [onChangeProductArea]
  );

  const onChangeTypeSelect = useCallback(
    (event: ChangeEvent<HTMLSelectElement>, hintOption: boolean) => {
      const value = hintOption ? null : event.target.value;
      onChangeType(value);
    },
    [onChangeType]
  );

  const { $t } = useIntl();

  return (
    <div className="slm-actions-filter">
      <Select
        id="productAreaSelect"
        label={$t(am.productAreaSelect)}
        value={productArea ?? ''}
        onChange={onChangeProductAreaSelect}
      >
        {productAreaOptions.map(productArea => (
          <Option
            key={productArea.value}
            value={productArea.value}
            name={productArea.name}
          />
        ))}
      </Select>
      <Select
        id="typeSelect"
        label={$t(am.type)}
        value={type ?? ''}
        onChange={onChangeTypeSelect}
      >
        {typeOptions.map(type => (
          <Option
            // Use composite key to have the list refresh properly on PA selection
            key={`${productArea}-${type}`}
            value={type}
            label={$t(typeMessages[type])}
          />
        ))}
      </Select>
      <Button
        type="secondary"
        ssrIcon={cross}
        iconOnly
        disabled={!productArea && !type}
        onClick={onReset}
      />
      {itemCount.filter !== itemCount.total && (
        <span>{$t(am.filterMessage, itemCount)}</span>
      )}
    </div>
  );
};

export default ActionsFilter;
