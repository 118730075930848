/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AssignLocationConflict = {
    FIRE_CLASS: 'fireClass',
    ALREADY_EXISTS: 'alreadyExists'
} as const;
export type AssignLocationConflict = typeof AssignLocationConflict[keyof typeof AssignLocationConflict];


export function instanceOfAssignLocationConflict(value: any): boolean {
    for (const key in AssignLocationConflict) {
        if (Object.prototype.hasOwnProperty.call(AssignLocationConflict, key)) {
            if (AssignLocationConflict[key as keyof typeof AssignLocationConflict] === value) {
                return true;
            }
        }
    }
    return false;
}

export function AssignLocationConflictFromJSON(json: any): AssignLocationConflict {
    return AssignLocationConflictFromJSONTyped(json, false);
}

export function AssignLocationConflictFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssignLocationConflict {
    return json as AssignLocationConflict;
}

export function AssignLocationConflictToJSON(value?: AssignLocationConflict | null): any {
    return value as any;
}

