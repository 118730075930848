import React, { useCallback, useEffect, useState } from 'react';
import im from 'views/Import/importMessages';
import FileInput from 'components/FileInput/FileInput';
import Button from '@ingka/button';
import { excelImport, reset } from 'views/Import/redux/importSlice';
import { useIntl } from 'react-intl';
import { useAppDispatch } from 'hooks/redux';

const FileSelection: React.FC = () => {
  const [file, setFile] = useState<File>();
  const onFileInputChange = useCallback(([file]) => {
    setFile(file);
  }, []);

  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const onUploadClick = useCallback(async () => {
    setLoading(true);
    try {
      await dispatch(excelImport(file));
    } finally {
      setLoading(false);
    }
  }, [dispatch, file]);

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  const { $t } = useIntl();

  return (
    <>
      <h2>{$t(im.fileSelectionTitle)}</h2>
      <FileInput extension=".xlsx" onChange={onFileInputChange} />
      <p>{file?.name}</p>
      <p>
        <Button
          text={$t(im.uploadFileButton)}
          onClick={onUploadClick}
          loading={loading}
          disabled={!file}
        />
      </p>
    </>
  );
};

export default FileSelection;
