import { createApi } from '@reduxjs/toolkit/query/react';
import {
  GetParameterValueLimitsRequest,
  ParameterLimits,
  ParameterLimitsFromJSON,
} from 'apis/backendApi';
import { apiQuery, apiSerializeQueryArgs } from 'core/rtkQuery';
import { DynamicParameterLimits, F6_PARAMETER_LIMITS } from 'core/f6Limits';

function configureDynamicLimits(parameterLimits: ParameterLimits[]) {
  const limitsByName: { [name: string]: DynamicParameterLimits } =
    Object.fromEntries(
      parameterLimits.map(limits => [
        limits.name,
        limits as unknown as DynamicParameterLimits,
      ])
    );
  const names = Object.keys(limitsByName);

  if (!names.includes('COV_DAYS_DIRECT')) {
    return parameterLimits;
  }

  // The minimum value of the following fields is equal to COV_DAYS_DIRECT:
  ['COMM_QTY_LIMIT_DAYS', 'COV_DAYS_INDIRECT', 'COV_DAYS_DTFPP'].forEach(
    name => {
      if (!names.includes(name)) {
        return;
      }

      limitsByName[name] = {
        ...limitsByName[name],
        min: 'COV_DAYS_DIRECT',
      };
    }
  );

  return Object.entries(limitsByName).map(([_, limits]) => limits);
}

export const parameterLimitsApi = createApi({
  reducerPath: 'parameterLimitsApi',
  baseQuery: apiQuery(),
  serializeQueryArgs: apiSerializeQueryArgs,

  endpoints: builder => ({
    getParameterValueLimits: builder.query<
      ParameterLimits[],
      GetParameterValueLimitsRequest
    >({
      query: ({ filter, filterValue }) => ({
        url: filter && filterValue ? '/parameters/v1/{buCode}/limits' : null,
        params: {
          filter,
          filterValue,
        },
      }),

      transformResponse: (json: any) => {
        let parameterLimits = json?.map(ParameterLimitsFromJSON) ?? [];
        parameterLimits = configureDynamicLimits(parameterLimits);
        return parameterLimits;
      },
    }),

    /**
     * In-memory endpoint that provides predefined limits for F6 parameters.
     */
    getF6ParameterValueLimits: builder.query<DynamicParameterLimits[], void>({
      query: () => ({
        url: null,
        data: F6_PARAMETER_LIMITS,
      }),
    }),
  }),
});

export const {
  useGetParameterValueLimitsQuery,
  useGetF6ParameterValueLimitsQuery,
} = parameterLimitsApi;

export default parameterLimitsApi;
