import { createApi } from '@reduxjs/toolkit/query/react';
import {
  apiQuery,
  apiSerializeQueryArgs,
  resolveBuCode,
  RtkQueryConfig,
} from 'core/rtkQuery';
import {
  ItemAssignmentsDto,
  ItemDetailsDto,
  ItemHistoryDto,
  UpdateAssignmentDto,
} from 'apis/backendApi';
import {
  startPendingUpdate,
  syncPendingUpdates,
} from 'core/redux/pendingUpdatesSlice';
import { encodePendingAssignment } from 'views/Maintain/types';
import { OperationType } from 'core/commonTypes';
import { useCallback } from 'react';

type AssignmentRequest = {
  itemNo: string;
  slid: string;
  date: string;
  body: UpdateAssignmentDto;
  lastUpdated: string;
};

const itemApi = createApi({
  reducerPath: 'itemApi',
  baseQuery: apiQuery(),
  serializeQueryArgs: apiSerializeQueryArgs,
  tagTypes: ['assignments', 'history'],

  endpoints: builder => ({
    getItemDetails: builder.query<ItemDetailsDto, { itemNo: string }>({
      query: ({ itemNo }) => ({
        url: '/items/v1/{buCode}/{itemNo}',
        pathVariables: {
          itemNo,
        },
      }),

      keepUnusedDataFor: 0,
    }),

    getItemAssignments: builder.query<ItemAssignmentsDto, { itemNo: string }>({
      query: ({ itemNo }) => ({
        url: '/items/v1/{buCode}/{itemNo}/assignments',
        pathVariables: {
          itemNo,
        },
      }),

      providesTags: (result, error, { itemNo }) => [
        { type: 'assignments', id: itemNo },
      ],

      async onQueryStarted({ itemNo }, { dispatch, queryFulfilled }) {
        const buCode = resolveBuCode();
        const { data } = await queryFulfilled;

        const lastUpdated = Object.fromEntries(
          data.locations.flatMap(({ salesLocation, assignments }) =>
            assignments
              .flat(2)
              .filter(x => x !== null)
              .map(({ fromDate, lastUpdated }) => [
                encodePendingAssignment({
                  buCode,
                  itemNo,
                  slid: salesLocation.id,
                  fromDate,
                }),
                lastUpdated,
              ])
          )
        );
        dispatch(syncPendingUpdates({ lastUpdated, prefix: 'assignment' }));
      },

      keepUnusedDataFor: 0,
    }),

    postItemAssignment: builder.mutation<
      void,
      {
        itemNo: string;
        slid: string;
        date: string;
        fireClassOverride?: boolean;
        body: UpdateAssignmentDto;
      }
    >({
      query: ({ itemNo, slid, date, fireClassOverride = false, body }) => ({
        method: 'POST',
        url: '/items/v1/{buCode}/{itemNo}/assignments/{slId}/{date}',
        pathVariables: { itemNo, slId: slid, date },
        params: { confirm: fireClassOverride },
        body,
      }),

      invalidatesTags: (result, error, { itemNo }) =>
        RtkQueryConfig.instance.isCloudEnabled
          ? [
              { type: 'assignments', id: itemNo },
              { type: 'history', id: itemNo },
            ]
          : [],

      async onQueryStarted(
        { itemNo, slid, date },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;

        if (RtkQueryConfig.instance.isMhsEnabled) {
          dispatch(
            startPendingUpdate({
              id: encodePendingAssignment({
                buCode: resolveBuCode(),
                itemNo,
                slid,
                fromDate: date,
              }),
              type: OperationType.CREATE,
            })
          );
        }
      },
    }),

    // Cloud
    putItemAssignments: builder.mutation<
      void,
      {
        itemNo: string;
        slid: string;
        assignments: UpdateAssignmentDto[];
      }
    >({
      query: ({ itemNo, slid, assignments }) => ({
        method: 'PUT',
        url: '/items/v1/{buCode}/{itemNo}/assignments/{slId}',
        pathVariables: { itemNo, slId: slid },
        body: {
          assignments,
        },
      }),

      invalidatesTags: (result, error, { itemNo }) =>
        RtkQueryConfig.instance.isCloudEnabled
          ? [
              { type: 'assignments', id: itemNo },
              { type: 'history', id: itemNo },
            ]
          : [],
    }),

    // MHS
    putItemAssignment: builder.mutation<void, AssignmentRequest>({
      query: ({ itemNo, slid, date, body }) => ({
        method: 'PUT',
        url: '/items/v1/{buCode}/{itemNo}/assignments/{slId}/{date}',
        pathVariables: { itemNo, slId: slid, date },
        body,
      }),

      invalidatesTags: (result, error, { itemNo }) =>
        RtkQueryConfig.instance.isCloudEnabled
          ? [
              { type: 'assignments', id: itemNo },
              { type: 'history', id: itemNo },
            ]
          : [],

      async onQueryStarted(
        { itemNo, slid, date, lastUpdated },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;

        if (RtkQueryConfig.instance.isMhsEnabled) {
          dispatch(
            startPendingUpdate({
              id: encodePendingAssignment({
                buCode: resolveBuCode(),
                itemNo,
                slid,
                fromDate: date,
              }),
              type: OperationType.UPDATE,
              lastUpdated,
            })
          );
        }
      },
    }),

    deleteItemAssignment: builder.mutation<
      void,
      {
        itemNo: string;
        slid: string;
        date: string;
        confirm?: boolean;
      }
    >({
      query: ({ itemNo, slid, date, confirm }) => ({
        method: 'DELETE',
        url: '/items/v1/{buCode}/{itemNo}/assignments/{slId}/{date}',
        pathVariables: { itemNo, slId: slid, date },
        params: { confirm },
      }),

      invalidatesTags: (result, error, { itemNo }) =>
        RtkQueryConfig.instance.isCloudEnabled
          ? [
              { type: 'assignments', id: itemNo },
              { type: 'history', id: itemNo },
            ]
          : [],

      async onQueryStarted(
        { itemNo, slid, date },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;

        if (RtkQueryConfig.instance.isMhsEnabled) {
          dispatch(
            startPendingUpdate({
              id: encodePendingAssignment({
                buCode: resolveBuCode(),
                itemNo,
                slid,
                fromDate: date,
              }),
              type: OperationType.DELETE,
            })
          );
        }
      },
    }),

    postItemCalculation: builder.mutation<void, { itemNo: string }>({
      query: ({ itemNo }) => ({
        method: 'POST',
        url: '/items/v1/{buCode}/{itemNo}/calculation',
        pathVariables: { itemNo },
      }),
    }),

    getItemHistory: builder.query<ItemHistoryDto, { itemNo: string }>({
      query: ({ itemNo }) => ({
        url: '/items/v1/{buCode}/{itemNo}/history',
        pathVariables: {
          itemNo,
        },
      }),

      providesTags: (result, error, { itemNo }) => [
        { type: 'history', id: itemNo },
      ],
    }),

    putItemParameters: builder.mutation<
      void,
      {
        itemNo: string;
        flexibilityPercentage?: number;
        useCommercialQuantity?: boolean;
      }
    >({
      query: ({ itemNo, flexibilityPercentage, useCommercialQuantity }) => ({
        method: 'PUT',
        url: '/items/v1/{buCode}/{itemNo}/parameters',
        pathVariables: { itemNo },
        body: {
          flexibilityPercentage,
          useCommercialQuantity,
        },
      }),

      async onQueryStarted(
        { itemNo, flexibilityPercentage, useCommercialQuantity },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;

        const { updateQueryData } = itemApi.util;
        dispatch(
          updateQueryData('getItemDetails', { itemNo }, draft => {
            if (flexibilityPercentage !== undefined) {
              draft.flexibilityPercentage = flexibilityPercentage;
            }
            if (useCommercialQuantity !== undefined) {
              draft.useCommercialQuantity = useCommercialQuantity;
            }
          })
        );
      },

      invalidatesTags: (result, error, { itemNo }) => [
        { type: 'history', id: itemNo },
      ],
    }),

    putItemNotifications: builder.mutation<
      void,
      { itemNo: string; slid: string; handled: boolean }
    >({
      query: ({ itemNo, slid, handled }) => ({
        method: 'PUT',
        url: '/items/v1/{buCode}/{itemNo}/notifications/{slId}',
        pathVariables: { itemNo, slId: slid },
        body: {
          handled,
        },
      }),

      async onQueryStarted(
        { itemNo, slid, handled },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;

        const { updateQueryData } = itemApi.util;
        dispatch(
          updateQueryData('getItemDetails', { itemNo }, draft => {
            if (handled) {
              draft.notifications = draft.notifications.filter(
                x => x.slid !== slid
              );
            }
          })
        );
      },
    }),
  }),
});

/**
 * Wrapper mutation for updating assignments.
 */
export function useUpdateAssignmentsMutation() {
  const [putItemAssignment, putItemAssignmentResult] =
    itemApi.usePutItemAssignmentMutation();
  const [putItemAssignments, putItemAssignmentsResult] =
    itemApi.usePutItemAssignmentsMutation();

  const mutation = useCallback(
    async (requests: AssignmentRequest[]) => {
      if (RtkQueryConfig.instance.isMhsEnabled) {
        for (const request of requests) {
          const update = async () => await putItemAssignment(request).unwrap();
          await update();
        }
      } else {
        // Cloud
        if (requests.length === 0) {
          return;
        }

        const [{ itemNo, slid }] = requests;
        const assignments = requests.map(x => ({ date: x.date, ...x.body }));
        await putItemAssignments({
          itemNo,
          slid,
          assignments,
        }).unwrap();
      }
    },
    [putItemAssignment, putItemAssignments]
  );

  // TypeScript cannot infer the correct type of the mutation when returning 2-element array, so return object instead.
  return {
    mutation,
    result: RtkQueryConfig.instance.isMhsEnabled
      ? putItemAssignmentResult
      : putItemAssignmentsResult,
  };
}

export const {
  useGetItemDetailsQuery,
  useGetItemAssignmentsQuery,
  usePostItemAssignmentMutation,
  useDeleteItemAssignmentMutation,
  usePostItemCalculationMutation,
  useGetItemHistoryQuery,
  usePutItemParametersMutation,
  usePutItemNotificationsMutation,
} = itemApi;
export default itemApi;
