import React, { ChangeEvent, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';
import Button from '@ingka/button';
import cm from 'core/commonMessages';

export type FileInputProps = {
  /**
   * File extension. E.g. `.xlsx`.
   */
  extension: string;

  /**
   * Receives the selected files.
   *
   * @param files
   */
  onChange: (files: File[]) => void;

  /**
   * Indicates whether to allow selecting multiple files.
   *
   * @default false
   */
  multiple?: boolean;
};

const FileInput: React.FC<FileInputProps> = ({
  extension,
  onChange,
  multiple = false,
}) => {
  const onInputChange = useCallback(
    function (ev: ChangeEvent<HTMLInputElement>) {
      const values: File[] = [];

      const { files } = ev.target;
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file.name.endsWith(extension)) {
          values.push(file);
        }
      }

      if (values.length > 0) {
        onChange(values);
      }
    },
    [extension, onChange]
  );

  const inputRef = useRef<HTMLInputElement>();
  const onClick = useCallback(() => {
    inputRef.current.click();
  }, []);

  const { $t } = useIntl();

  return (
    <>
      <input
        type="file"
        onChange={onInputChange}
        style={{ display: 'none' }}
        ref={inputRef}
        multiple={multiple}
      />
      <Button
        onClick={onClick}
        text={$t(
          multiple ? cm.selectMultipleFilesButton : cm.selectSingleFileButton,
          { extension }
        )}
      />
    </>
  );
};

export default FileInput;
