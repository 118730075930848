import { createParameterFields } from 'views/Parameters/types';
import { useGetF6ParametersQuery } from 'views/Parameters/redux/f6ParametersApi';
import { useMemo } from 'react';
import { ParameterLimits } from 'apis/backendApi';
import { useGetF6ParameterValueLimitsQuery } from 'views/Parameters/redux/parameterLimitsApi';

export function useF6ParametersQuery() {
  const parametersQuery = useGetF6ParametersQuery();
  const limitsQuery = useGetF6ParameterValueLimitsQuery();

  const fields = useMemo(
    () =>
      createParameterFields(
        parametersQuery.data ?? [],
        (limitsQuery.data ?? []) as unknown as ParameterLimits[]
      ),
    [parametersQuery, limitsQuery]
  );

  return {
    fields,
    isLoading: parametersQuery.isLoading || limitsQuery.isLoading,
    isError: parametersQuery.isError || limitsQuery.isError,
    isSuccess: parametersQuery.isSuccess && limitsQuery.isSuccess,
  };
}
