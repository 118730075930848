import React, { useCallback, useState } from 'react';
import userIcon from '@ingka/ssr-icon/paths/person';
import Button from '@ingka/button';
import { useAuth0 } from '../Auth';
import { useOptionalUser } from '../../contexts/UserContext';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';
import UserDetailsModal from 'components/User/UserDetails';

const User = () => {
  const { loginWithRedirect } = useAuth0();
  const user = useOptionalUser();
  const [userDetailsVisible, setUserDetailsVisible] = useState(false);
  const onOpenUserDetails = useCallback(() => setUserDetailsVisible(true), []);
  const onCloseUserDetails = useCallback(
    () => setUserDetailsVisible(false),
    []
  );

  const onLoginClick = useCallback(async () => {
    await loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    });
  }, [loginWithRedirect]);

  const { $t } = useIntl();

  return (
    <>
      <UserDetailsModal
        isVisible={userDetailsVisible}
        onCloseModal={onCloseUserDetails}
      />
      {user && (
        <Button
          data-anchor="userButton"
          onClick={onOpenUserDetails}
          type={'plain'}
          ssrIcon={userIcon}
          text={user.shortName}
          title={user.fullName}
        />
      )}
      {!user && (
        <Button
          data-anchor="loginButton"
          onClick={onLoginClick}
          type={'plain'}
          ssrIcon={userIcon}
          text={$t(cm.loginButton)}
        />
      )}
    </>
  );
};

export default User;
