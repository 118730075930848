import { StoreStructureType } from 'core/commonTypes';
import { useCallback, useReducer } from 'react';

type StoreStructureItemPickerState = {
  divisionId: string | null;
  specialityShopId?: string | null | undefined;
  rangeGroupId?: string | null | undefined;
};

type StoreStructureItemPickerAction = {
  type: StoreStructureType;
  payload: string | null;
};

function storeStructureItemPickerReducer(
  state: StoreStructureItemPickerState,
  action: StoreStructureItemPickerAction
): StoreStructureItemPickerState {
  switch (action.type) {
    case StoreStructureType.Division: {
      const newState: StoreStructureItemPickerState = {
        divisionId: action.payload,
      };
      if (state.specialityShopId !== undefined) {
        newState.specialityShopId = null;
      }
      if (state.rangeGroupId !== undefined) {
        newState.rangeGroupId = null;
      }
      return newState;
    }
    case StoreStructureType.SpecialityShop: {
      const newState: StoreStructureItemPickerState = {
        divisionId: state.divisionId,
        specialityShopId: action.payload,
      };
      if (state.rangeGroupId !== undefined) {
        newState.rangeGroupId = null;
      }
      return newState;
    }
    case StoreStructureType.RangeGroup:
      return { ...state, rangeGroupId: action.payload };
    default:
      return state;
  }
}

export default function useStoreStructureItemPicker(
  initialDivisionId = null,
  initialSpShopId = null,
  initialRgId = null
): {
  onStructureItemPick: (type: StoreStructureType, id: string) => void;
} & StoreStructureItemPickerState {
  const [state, dispatch] = useReducer(storeStructureItemPickerReducer, {
    divisionId: initialDivisionId,
    specialityShopId: initialSpShopId,
    rangeGroupId: initialRgId,
  });

  const onStructureItemPick = useCallback(
    (type: StoreStructureType, id: string) => {
      dispatch({ type, payload: id });
    },
    []
  );

  return {
    onStructureItemPick,
    ...state,
  };
}
