import { useIntl } from 'react-intl';
import { useFormatNumber } from 'hooks/locale';
import { useMemo } from 'react';
import cm from 'core/commonMessages';
import pm from 'views/Parameters/parametersMessages';

export function useFormatParameterValue() {
  const { $t } = useIntl();

  const formatNumber = useFormatNumber();

  return useMemo(
    () => (value?: any) =>
      typeof value === 'number'
        ? formatNumber(value as number)
        : typeof value === 'boolean'
        ? $t((value as boolean) ? cm.on : cm.off)
        : $t(pm.blankValue),
    [$t, formatNumber]
  );
}

const TURNOVER_FIELDS = [
  'highTurnoverMark',
  'lowTurnoverMark',
  'turnOverMarkDftp',
];

export function useFieldLabel(name: string) {
  const { $t } = useIntl();
  return useMemo(() => {
    return TURNOVER_FIELDS.indexOf(name) !== -1 ? $t(pm[name]) : name;
  }, [name, $t]);
}
