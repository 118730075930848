import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { allExpanded, defaultStyles, JsonView } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import InputField from '@ingka/input-field';
import { useSingleSearchParamState } from 'hooks/routing';
import Button from '@ingka/button';
import utilityApi, {
  useLazyGetCalculationsItemMetadataQuery,
  useLazyGetCalculationsStoreMetadataQuery,
  usePostCalculationsTriggerItemMutation,
  usePostCalculationsTriggerStoreMutation,
} from 'views/DevTools/redux/utilityApi';
import { CalculationMetaDataDto } from 'apis/backendApi';
import { Col, Container, Row } from 'react-bootstrap';
import { useAppDispatch } from 'hooks/redux';
import CopyToClipboardButton from 'components/Clipboard/CopyToClipboardButton';
import TimeAgo from 'react-timeago';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { getApiDate, getInputDate } from 'core/util/date';
import { useOnChangeDate } from 'hooks/date';

export type ItemComputerProps = {};

const HeadingText: React.FC<{
  data: CalculationMetaDataDto;
}> = ({ data }) => (
  <>
    {data.buCode} {data.itemNo ? `/ ${data.itemNo}` : ''} / {data.status}
  </>
);

type Output = {
  json: string;
  data?: object;
  error?: Error;
};

const CalculationDetails: React.FC<{ data: CalculationMetaDataDto }> = ({
  data,
}) => {
  const [validation, explain] = useMemo(() => {
    const validation: Output = {
      json: data.validation,
    };
    try {
      validation.data = JSON.parse(validation.json);
    } catch (error) {
      validation.error = error;
    }
    const explain: Output = {
      json: data.explain,
    };
    try {
      explain.data = JSON.parse(explain.json);
    } catch (error) {
      explain.error = error;
    }
    return [validation, explain];
  }, [data]);

  return (
    <>
      <Row>
        <Col>
          <h2>
            <HeadingText data={data} />
          </h2>
        </Col>
      </Row>
      {validation && explain && (
        <Row>
          <Col>
            <Row>
              <Col md={4}>
                <h3>Validation</h3>
              </Col>
              <Col>
                <CopyToClipboardButton data={validation.json} />
              </Col>
            </Row>
            <Row className="slm-mt-100">
              <Col>
                {validation.data && (
                  <JsonView
                    data={validation.data}
                    shouldExpandNode={allExpanded}
                    style={defaultStyles}
                  />
                )}
                {validation.error && (
                  <>
                    <p className="slm-error">{validation.error.message}</p>
                    <div className="slm-json">{validation.json}</div>
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="slm-mb-100">
              <Col md={4}>
                <h3>Explain</h3>
              </Col>
              <Col>
                <CopyToClipboardButton data={explain.json} />
              </Col>
            </Row>
            <Row>
              <Col>
                {explain.data && (
                  <JsonView
                    data={explain.data}
                    shouldExpandNode={allExpanded}
                    style={defaultStyles}
                  />
                )}
                {explain.error && (
                  <>
                    <p className="slm-error">{explain.error.message}</p>
                    <div className="slm-json">{explain.json}</div>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

const CalculationMetadata: React.FC<{ data: CalculationMetaDataDto }> = ({
  data,
}) => {
  const { timestamp, status, version, date } = data;

  return (
    <>
      <Row>
        <Col md={4}>Status</Col>
        <Col md={8}>{status}</Col>
      </Row>
      <Row>
        <Col md={4}>BU / Item No</Col>
        <Col md={8}>
          <HeadingText data={data} />
        </Col>
      </Row>
      <Row>
        <Col md={4}>Date / Timestamp</Col>
        <Col md={8}>
          {date.toString()} / {timestamp.toString()}
        </Col>
      </Row>
      <Row>
        <Col md={4}>Version</Col>
        <Col md={8}>{version}</Col>
      </Row>
      <Row>
        <Col md={4}>When</Col>
        <Col md={8}>
          <TimeAgo date={timestamp} />
        </Col>
      </Row>
    </>
  );
};

export type CalculationParams = {
  itemNo?: string;
  date?: Date;
};

function CalculationForm({
  defaultValue,
  isMetadataLoading,
  isTriggerLoading,
  onChange,
  onLoadMetadata,
  onTriggerCalculation,
  isEnabled,
}: {
  defaultValue: CalculationParams;
  onChange: (values: CalculationParams) => void;
  isEnabled: boolean;
  onTriggerCalculation: () => void;
  isTriggerLoading: boolean;
  onLoadMetadata: () => void;
  isMetadataLoading: boolean;
}) {
  const [itemNo, setItemNo] = useState(defaultValue.itemNo);
  const [date, setDate] = useState(defaultValue.date);

  const onChangeItemNo = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const newItemNo = ev.target.value;
      setItemNo(newItemNo);
      onChange({ itemNo: newItemNo, date });
    },
    [date, onChange]
  );

  const onChangeDate = useOnChangeDate(
    newDate => {
      setDate(newDate);
      onChange({ itemNo, date: newDate });
    },
    null,
    [itemNo]
  );

  return (
    <form>
      <InputField
        id="freetext"
        type="text"
        defaultValue={itemNo}
        onChange={onChangeItemNo}
        autoComplete={'off'}
      />
      <InputField
        type="date"
        id="date"
        onChange={onChangeDate}
        onKeyDown={e => e.preventDefault()}
        defaultValue={date ? getInputDate(date) : undefined}
      />
      <Button
        type="primary"
        text="Calculate"
        disabled={!isEnabled}
        onClick={onTriggerCalculation}
        loading={isTriggerLoading}
      />
      <Button
        type="secondary"
        text="Load"
        onClick={onLoadMetadata}
        disabled={!isEnabled || isTriggerLoading}
        loading={isMetadataLoading}
      />
    </form>
  );
}

type Calculation = 'none' | 'item' | 'store';

const ItemComputer: React.FC<ItemComputerProps> = () => {
  const [itemNo, setItemNo] = useSingleSearchParamState<string>('itemNo', null);

  const [currentItemNo, setCurrentItemNo] = useState<string>(itemNo ?? '');

  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  const onChange = useCallback((params: CalculationParams) => {
    const { itemNo, date } = params;
    setCurrentItemNo(itemNo ?? '');
    setCurrentDate(date);
  }, []);

  const [calculation, setCalculation] = useState<Calculation>('none');

  const apiDate = currentDate ? getApiDate(currentDate) : undefined;

  const dispatch = useAppDispatch();
  const [postCalculationsTriggerItem, { isLoading: isTriggerItemLoading }] =
    usePostCalculationsTriggerItemMutation();
  const [postCalculationsTriggerStore, { isLoading: isTriggerStoreLoading }] =
    usePostCalculationsTriggerStoreMutation();
  const onTriggerCalculation = useCallback(async () => {
    if (currentItemNo.length === 0) {
      await postCalculationsTriggerStore({
        date: apiDate,
      }).unwrap();
      setCalculation('store');
      setItemNo(null);
    } else {
      await postCalculationsTriggerItem({
        itemNo: currentItemNo,
        date: apiDate,
      }).unwrap();
      setCalculation('item');
      setItemNo(currentItemNo);
    }
    dispatch(utilityApi.util.resetApiState());
  }, [
    currentItemNo,
    dispatch,
    postCalculationsTriggerStore,
    apiDate,
    setItemNo,
    postCalculationsTriggerItem,
  ]);

  const isEnabled =
    currentItemNo.length === 0 || currentItemNo.match(/\d{8}/) !== null;

  const [isStoreMetadata, setIsStoreMetadata] = useState(false);
  const [
    getCalculationsItemMetadata,
    {
      data: itemData,
      isFetching: isItemMetadataLoading,
      isError: isItemMetadataError,
      error: itemMetadataError,
    },
  ] = useLazyGetCalculationsItemMetadataQuery();
  const [
    getCalculationsStoreMetadata,
    {
      data: storeData,
      isFetching: isStoreMetadataLoading,
      isError: isStoreMetadataError,
      error: storeMetadataError,
    },
  ] = useLazyGetCalculationsStoreMetadataQuery();

  const onLoadMetadata = useCallback(() => {
    setCalculation('none');
    if (currentItemNo.length === 0) {
      setIsStoreMetadata(true);
      setItemNo(null);
      getCalculationsStoreMetadata(undefined, false);
    } else {
      setIsStoreMetadata(false);
      setItemNo(currentItemNo);
      getCalculationsItemMetadata({ itemNo: currentItemNo }, false);
    }
  }, [
    setItemNo,
    currentItemNo,
    getCalculationsStoreMetadata,
    getCalculationsItemMetadata,
  ]);

  const { data, isLoading, isError, error } = useMemo(() => {
    return {
      data: isStoreMetadata ? storeData : itemData,
      isLoading: isStoreMetadata
        ? isStoreMetadataLoading
        : isItemMetadataLoading,
      isError: isStoreMetadata ? isStoreMetadataError : isItemMetadataError,
      error: (isStoreMetadata
        ? storeMetadataError
        : itemMetadataError) as FetchBaseQueryError,
    };
  }, [
    isStoreMetadata,
    storeData,
    itemData,
    isStoreMetadataLoading,
    isItemMetadataLoading,
    isStoreMetadataError,
    isItemMetadataError,
    storeMetadataError,
    itemMetadataError,
  ]);

  return (
    <Container className="slm-item-computer" fluid>
      <Row>
        <Col>
          <CalculationForm
            defaultValue={{ itemNo: currentItemNo, date: currentDate }}
            onChange={onChange}
            isEnabled={isEnabled}
            onTriggerCalculation={onTriggerCalculation}
            isTriggerLoading={isTriggerItemLoading || isTriggerStoreLoading}
            onLoadMetadata={onLoadMetadata}
            isMetadataLoading={isLoading}
          />
        </Col>
        <Col>
          {data && <CalculationMetadata data={data} />}
          {calculation === 'store' && <>Store Calculation Triggered</>}
          {calculation === 'item' && <>Item Calculation Triggered</>}
          {error && <>Error loading calculation metadata: {error.status}</>}
        </Col>
      </Row>
      {data && !isError && <CalculationDetails data={data} />}
    </Container>
  );
};

export default ItemComputer;
