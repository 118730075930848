import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '@ingka/button';
import {
  arrowDown,
  arrowLeft,
  arrowUp,
  calculatorFinancial,
  cross,
  database,
  dataChart,
  magnifyingGlassList,
  plus,
  printer,
} from '@ingka/ssr-icon/icons';
import {
  useGetItemAssignmentsQuery,
  useGetItemDetailsQuery,
} from 'views/Maintain/redux/itemApi';
import { useRouteBuCode } from 'hooks/buCode';
import AttributeGrid from 'views/Maintain/components/ItemDetails/AttributeGrid';
import './ItemDetails.scss';
import InputField from '@ingka/input-field';
import WeekTable from 'views/Maintain/components/ItemDetails/WeekTable';
import Tooltip from '@ingka/tooltip';
import cm from 'core/commonMessages';
import mm from 'views/Maintain/maintainMessages';
import { useIntl } from 'react-intl';
import Image from '@ingka/image';
import AspectRatioBox from '@ingka/aspect-ratio-box';
import Loading, { LoadingBall } from '@ingka/loading';
import withStoreStructure from 'components/WithStoreStructure';
import NoResultsMessage from 'components/Message/NoResultsMessage';
import {
  useAddAssignLocationAction,
  usePendingAssignments,
} from 'views/Maintain/hooks/assignments';
import CleanPendingUpdates from 'views/Maintain/components/ItemDetails/CleanPendingUpdates';
import {
  ArticleAssignmentStatus,
  GetItemSearchNavigationInfoRequest,
  OverallStoreStructureDto,
} from 'apis/backendApi';
import { useGetStoreStructureTreeQuery } from 'views/StoreStructure/redux/structuresApi';
import { StoreStructurePath } from 'core/commonTypes';
import { getApiDate } from 'core/util/date';
import { useStartRecalculationAction } from 'views/Maintain/hooks/calculation';
import useItemHistoryModal from 'views/Maintain/components/ItemDetails/ItemHistoryModal';
import {
  useHasUpdateItemAssignedSalesLocationPrivilege,
  useHasUpdateParametersPrivilege,
} from 'hooks/privilege';
import NotificationInlineMessage from 'views/Maintain/components/ItemDetails/NotificationInlineMessage';
import { MHS_SYNC_POLLING_INTERVAL } from 'core/types';
import { useGetItemSearchNavigationInfoQuery } from 'views/Maintain/redux/searchApi';
import {
  isSearchEnabled,
  useSearchQueriesUrl,
} from 'views/Maintain/hooks/searchQueries';
import { mapSearchCriteriaToQuery } from 'views/Maintain/types';

function findRangeGroupHierarchy(
  storeStructure: OverallStoreStructureDto,
  recRgid: string
): StoreStructurePath | null {
  if (!storeStructure || !recRgid) {
    return null;
  }

  for (let div of storeStructure.divisions) {
    if (!div.specialityShops) continue;
    for (let sp of div.specialityShops) {
      if (!sp.rangeGroups) continue;
      for (let rg of sp.rangeGroups) {
        if (rg.id === recRgid) {
          return {
            division: div,
            specialityShop: sp,
            rangeGroup: rg,
          };
        }
      }
    }
  }
  return null;
}

export type ItemAssignmentConstraints = {
  minDate: string;
  maxDate: string;
  recommendedStructure: StoreStructurePath;
  rssq: number;
  startDateSale: string;
  articleAssignmentStatus: ArticleAssignmentStatus;
};

export const ItemAssignmentConstraintsContext =
  createContext<ItemAssignmentConstraints>(null);

const AddLocationButton: React.FC<{
  itemNo: string;
  suggestedSlid?: string;
  label?: string;
}> = ({ itemNo, suggestedSlid, label }) => {
  const showAddNewLocationModal = useAddAssignLocationAction(
    itemNo,
    suggestedSlid
  );

  return (
    <Button
      type="primary"
      ssrIcon={plus}
      onClick={showAddNewLocationModal}
      text={label}
      iconOnly={!label}
    />
  );
};

const ItemDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const buCode = useRouteBuCode();
  const { $t } = useIntl();
  const { itemNo: pathItemNo } = useParams();
  const [editableItemNo, setEditableItemNo] = useState(pathItemNo);
  const onItemNoFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditableItemNo(e.target.value);
  };
  const goBack = useCallback(() => {
    navigate(`../maintain${location.search}`);
  }, [location, navigate]);

  const { data: storeStructure } = useGetStoreStructureTreeQuery();

  const skipLoading = !/[0-9]{8}/.test(editableItemNo);
  const {
    isLoading: isItemLoading,
    currentData: itemDetailsData,
    error: itemDetailsError,
  } = useGetItemDetailsQuery({ itemNo: editableItemNo }, { skip: skipLoading });

  const { searchCriteria } = useSearchQueriesUrl();

  const navigationInfoRequest: GetItemSearchNavigationInfoRequest = useMemo(
    () => mapSearchCriteriaToQuery(searchCriteria, buCode),
    [buCode, searchCriteria]
  );

  const { data: navigationInfo } = useGetItemSearchNavigationInfoQuery(
    navigationInfoRequest,
    {
      skip: !isSearchEnabled(searchCriteria),
    }
  );

  const [previousItem, nextItem] = useMemo(() => {
    const items = navigationInfo?.itemNumbers ?? [];
    const index = items.indexOf(pathItemNo);
    const previousItem = index > 0 ? items[index - 1] : null;
    const nextItem = index < items.length - 1 ? items[index + 1] : null;
    return [previousItem, nextItem];
  }, [navigationInfo, pathItemNo]);

  const goPrevious = useCallback(() => {
    const href = `/store/${encodeURIComponent(
      buCode
    )}/maintain/item/${previousItem}${location.search}`;
    navigate(href, { replace: true });
    setEditableItemNo(previousItem);
  }, [buCode, location.search, navigate, previousItem]);

  const goNext = useCallback(() => {
    const href = `/store/${encodeURIComponent(
      buCode
    )}/maintain/item/${nextItem}${location.search}`;
    navigate(href, { replace: true });
    setEditableItemNo(nextItem);
  }, [buCode, location.search, navigate, nextItem]);

  const showItemHistory = useItemHistoryModal(itemDetailsData?.itemNo);

  const pendingAssignments = usePendingAssignments(
    itemDetailsData?.itemNo ?? ''
  );
  const pollingInterval =
    pendingAssignments.length > 0 ? MHS_SYNC_POLLING_INTERVAL : undefined;

  const { isLoading: isAssignmentLoading, data: assignment } =
    useGetItemAssignmentsQuery(
      { itemNo: editableItemNo },
      {
        skip: skipLoading,
        pollingInterval,
      }
    );

  const assignmentConstraints: ItemAssignmentConstraints = useMemo(() => {
    const minDate = getApiDate(new Date()); // TODO: replace with current store date
    const maxDate = !itemDetailsData
      ? getApiDate(new Date())
      : itemDetailsData.weeks[itemDetailsData.weeks.length - 1].dates[6];

    return {
      minDate,
      maxDate,
      recommendedStructure: findRangeGroupHierarchy(
        storeStructure,
        itemDetailsData?.recommendedRangeId
      ),
      rssq: itemDetailsData?.longTermRecommendations.rssq,
      startDateSale: itemDetailsData?.startDateSale,
      articleAssignmentStatus: itemDetailsData?.articleAssignmentStatus,
    };
  }, [itemDetailsData, storeStructure]);

  // Effect interferes with Previous/Next navigation, is it still necessary for other use cases?
  useEffect(() => {
    if (
      itemDetailsData &&
      itemDetailsData.itemNo !== pathItemNo &&
      editableItemNo !== pathItemNo
    ) {
      const href = `/store/${encodeURIComponent(buCode)}/maintain/item/${
        itemDetailsData.itemNo
      }`;
      navigate(href, { replace: true });
    }
  }, [editableItemNo, buCode, itemDetailsData, pathItemNo, navigate]);

  const onO14ParametersClick = useCallback(() => {
    const href = `/store/${encodeURIComponent(
      buCode
    )}/parameters/article?articleNumber=${itemDetailsData?.itemNo}`;
    navigate(href);
  }, [buCode, itemDetailsData?.itemNo, navigate]);

  const canUpdateParameters = useHasUpdateParametersPrivilege();
  const canUpdateLocations = useHasUpdateItemAssignedSalesLocationPrivilege();

  return (
    <ItemAssignmentConstraintsContext.Provider value={assignmentConstraints}>
      <div>
        <div className="slm-item-details-header">
          <div className="slm-item-nav-buttons">
            <Tooltip tooltipText={$t(cm.close)} position="trailing">
              <Button
                type="primary"
                small
                iconOnly
                ssrIcon={location.search !== '' ? arrowLeft : cross}
                onClick={goBack}
              />
            </Tooltip>
            <Tooltip
              tooltipText={previousItem ? previousItem : $t(cm.atStart)}
              position="trailing"
            >
              <Button
                type="secondary"
                small
                disabled={!previousItem}
                iconOnly
                ssrIcon={arrowUp}
                onClick={goPrevious}
              />
            </Tooltip>
            <Tooltip
              tooltipText={nextItem ? nextItem : $t(cm.atEnd)}
              position="trailing"
            >
              <Button
                type="secondary"
                small
                disabled={!nextItem}
                iconOnly
                ssrIcon={arrowDown}
                onClick={goNext}
              />
            </Tooltip>
          </div>
          <div className="item-small-image">
            <AspectRatioBox tagType="div" ratio="square">
              <Image
                key={itemDetailsData?.itemNo}
                src={itemDetailsData?.smallImage?.url}
                alt={`${itemDetailsData?.itemNo}`}
              />
            </AspectRatioBox>
          </div>
          <div className="item-description">
            <h1>
              {
                itemDetailsData
                  ? itemDetailsData.itemName ?? $t(cm.noDescription)
                  : '\u00A0' /* nbsp while loading*/
              }
            </h1>
            <div className="item-no">
              <InputField
                id="detailsEditableItemNo"
                type="text"
                onChange={onItemNoFieldChange}
                value={editableItemNo}
                label={$t(cm.articleNumber)}
              />
            </div>
            {itemDetailsError &&
              'data' in itemDetailsError &&
              itemDetailsError.status === 404 && (
                <NoResultsMessage
                  title={cm.noArticleTitle}
                  body={cm.noArticleBody}
                />
              )}
          </div>
          <div className="extra-buttons">
            <Tooltip
              tooltipText={$t(mm.maintainItemDetailsAvailabilityTooltip)}
            >
              <Button
                type="plain"
                small
                disabled // static disable until availability information is implemented
                iconOnly
                ssrIcon={dataChart}
                onClick={() => {}}
              />
            </Tooltip>
            <Tooltip tooltipText={$t(mm.maintainItemDetailsCalculateTooltip)}>
              <Button
                type="plain"
                small
                iconOnly
                ssrIcon={calculatorFinancial}
                onClick={useStartRecalculationAction(pathItemNo)}
              />
            </Tooltip>
            <Tooltip tooltipText={$t(cm.showHistoryButtonTitle)}>
              <Button
                type="plain"
                small
                disabled={!itemDetailsData?.itemNo}
                iconOnly
                ssrIcon={magnifyingGlassList}
                onClick={showItemHistory}
              />
            </Tooltip>
            {canUpdateParameters && (
              <Tooltip
                tooltipText={$t(mm.maintainItemDetailsO14ParameterTooltip)}
              >
                <Button
                  type="plain"
                  small
                  iconOnly
                  ssrIcon={database}
                  onClick={onO14ParametersClick}
                />
              </Tooltip>
            )}
            <Tooltip tooltipText={$t(cm.print)}>
              <Button
                type="plain"
                small
                disabled // static disable until print media presentation is implemented
                iconOnly
                ssrIcon={printer}
                onClick={() => {}}
              />
            </Tooltip>
          </div>
        </div>
        <AttributeGrid
          data={itemDetailsData}
          recommendedStructure={assignmentConstraints.recommendedStructure}
        />
        {itemDetailsData?.notifications.map(notification => (
          <NotificationInlineMessage
            key={notification.slid}
            data={notification}
            itemNo={itemDetailsData.itemNo}
          />
        ))}
        <>
          {isItemLoading ||
            (isAssignmentLoading && (
              <Loading>
                <LoadingBall />
              </Loading>
            ))}
          {itemDetailsData && assignment && (
            <WeekTable data={itemDetailsData} assignments={assignment} />
          )}
        </>
        {itemDetailsData && assignment && canUpdateLocations && (
          <AddLocationButton
            itemNo={itemDetailsData.itemNo}
            label={$t(mm.maintainAssignmentNewLocationButton)}
            // Regular case for unplanned is one pre-assigned slid, but need to handle the irregular cases
            suggestedSlid={
              itemDetailsData.articleAssignmentStatus ===
                ArticleAssignmentStatus.UNPLANNED &&
              assignment.locations.length === 1
                ? assignment.locations[0].salesLocation.id
                : undefined
            }
          />
        )}
        <CleanPendingUpdates interval={pollingInterval} />
      </div>
    </ItemAssignmentConstraintsContext.Provider>
  );
};

export default withStoreStructure(ItemDetails);
