import React, { useEffect, useMemo } from 'react';
import grid from 'components/Grid/style.module.css';
import Button from '@ingka/button';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ssm from 'views/StoreStructure/storeStructureMessages';
import cm from 'core/commonMessages';
import { HtmlFormFields, StoreStructureType } from 'core/commonTypes';
import {
  StructureDetails,
  StructureDetailsFormValues,
  StructureDetailsUpdateValues,
} from 'views/StoreStructure/types';
import NumericInputField from 'components/Input/NumericInputField';
import NumericReadOnlyField from 'components/Input/NumericReadOnlyField';
import { useFormattedValues } from 'hooks/locale';
import {
  AREA_M2,
  PLANNED_FILLING_RATE,
  PLANNED_MAX_DENSITY,
} from 'views/StoreStructure/constants';
import { toFraction, toPercentage } from 'core/util/percentage';
import { useHasPrivilege } from 'hooks/privilege';
import { Privilege } from 'core/redux/userApi';
import { lock } from '@ingka/ssr-icon/icons';

type StructureDetailsFormProps = {
  structureDetails: StructureDetails;
  onSubmit: (values: StructureDetailsUpdateValues) => Promise<void>;
  isSubmitting: boolean;
  disabled?: boolean;
  type: StoreStructureType;
} & HtmlFormFields;

const StructureDetailsForm: React.FC<StructureDetailsFormProps> = ({
  onSubmit,
  structureDetails,
  formIdPrefix,
  isSubmitting,
  disabled,
  type,
}) => {
  const { register, reset, handleSubmit, getFieldState, formState } = useForm({
    mode: 'onChange',
  });

  let defaultValues: StructureDetailsFormValues = useMemo(
    () => ({
      areaM2: structureDetails.areaM2,
      plannedFillingRate: toPercentage(structureDetails.plannedFillingRate),
      plannedMaxDensity: structureDetails.plannedMaxDensity,
    }),
    [structureDetails]
  );

  defaultValues = useFormattedValues(defaultValues);

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const onValid = async (values: StructureDetailsUpdateValues) => {
    await onSubmit({
      ...values,
      plannedFillingRate: toFraction(values.plannedFillingRate),
    });
  };

  const canUpdate = useHasPrivilege(Privilege.UPDATE_STORE_STRUCTURE);

  const { $t } = useIntl();

  // Note: destructuring is required due to FormState being a proxy
  // See: https://react-hook-form.com/docs/useform/formstate
  const { isDirty, isValid } = formState;

  const { salesSpaceQuantityVolume, maxDensity, plannedDensity } =
    structureDetails;

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <fieldset disabled={disabled} className="slm-pending-update">
        <div className={grid.twoCol}>
          <NumericReadOnlyField
            id={`${formIdPrefix}salesSpaceQuantityVolume`}
            label={$t(ssm.salesSpaceQuantityVolume)}
            value={salesSpaceQuantityVolume}
            placeholder={$t(cm.calculating)}
            formatOptions={{
              minimumFractionDigits:
                type === StoreStructureType.Division ? 0 : 1,
              maximumFractionDigits:
                type === StoreStructureType.Division ? 0 : 1,
            }}
          />
          <NumericInputField
            field="plannedMaxDensity"
            id={`${formIdPrefix}plannedMaxDensity`}
            label={$t(ssm.plannedMaxDensity)}
            register={register}
            getFieldState={getFieldState}
            min={PLANNED_MAX_DENSITY.min}
            max={PLANNED_MAX_DENSITY.max}
            readOnly={!canUpdate}
          />
          <NumericInputField
            field="areaM2"
            id={`${formIdPrefix}areaM2`}
            label={$t(ssm.areaM2)}
            register={register}
            getFieldState={getFieldState}
            min={AREA_M2.min}
            max={AREA_M2.max}
            readOnly={!canUpdate}
          />
          <NumericInputField
            field="plannedFillingRate"
            id={`${formIdPrefix}plannedFillingRate`}
            label={$t(ssm.plannedFillingRate)}
            register={register}
            getFieldState={getFieldState}
            min={PLANNED_FILLING_RATE.min}
            max={PLANNED_FILLING_RATE.max}
            isInteger
            readOnly={!canUpdate}
          />
          <NumericReadOnlyField
            id={`${formIdPrefix}maxDensity`}
            label={$t(ssm.maxDensity)}
            value={maxDensity}
            placeholder={$t(cm.calculating)}
          />
          <NumericReadOnlyField
            id={`${formIdPrefix}plannedDensity`}
            label={$t(ssm.plannedDensity)}
            value={plannedDensity}
            placeholder={$t(cm.calculating)}
          />
        </div>
        <div className="slm-mt-100 slm-tae">
          <Button
            text={$t(cm.update)}
            type="primary"
            htmlType="submit"
            loading={isSubmitting}
            disabled={!canUpdate || (!(isDirty && isValid) && !isSubmitting)}
            ssrIcon={!canUpdate ? lock : undefined}
          />
        </div>
      </fieldset>
    </form>
  );
};

export default StructureDetailsForm;
