import React, { useCallback, useState } from 'react';
import Button from '@ingka/button';
import pinIcon from '@ingka/ssr-icon/paths/location-pin';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';
import { useGetStoresQuery } from 'core/redux/userApi';
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal';
import Loading, { LoadingBall } from '@ingka/loading';
import { useOptionalUser } from '../../contexts/UserContext';
import StoreList from 'components/StoreSelector/StoreList';
import useKeyboardShortcut from 'use-keyboard-shortcut';
import { useHasMultipleStoreAccess } from 'hooks/stores';
import { useLocation, useNavigate } from 'react-router-dom';
import { KB_MOD } from 'core/util/keyboard';
import { useBuCodeSelection } from 'hooks/buCode';

export type StoreSelectorProps = {
  buCode: string | null;
};

const StoreSelector: React.FC<StoreSelectorProps> = ({ buCode }) => {
  const user = useOptionalUser();

  const { data, isLoading, isSuccess, isError } = useGetStoresQuery({
    userId: user?.userId,
  });

  const name = data?.storeNames[buCode];

  const { $t } = useIntl();

  const buttonText = buCode && name ? `${buCode} - ${name}` : $t(cm.storeMenu);
  const buttonTitle = $t(cm.storeMenuTitle);

  const [modalOpen, setModalOpen] = useState(false);

  const onClick = useCallback(() => {
    setModalOpen(true);
  }, []);

  const onDismiss = useCallback(() => {
    setModalOpen(false);
  }, []);

  const navigate = useNavigate();

  const location = useLocation();

  const { saveSelectedBuCode } = useBuCodeSelection();

  const onSelectStore = useCallback(
    store => {
      setModalOpen(false);
      saveSelectedBuCode(store.code);

      let path = `/store/${store.code}`;
      const { pathname } = location;
      const regex = /^\/store\/\d+(\/?.*$)/;
      if (pathname.match(regex)) {
        path = pathname.replace(regex, `${path}$1`);
      } else {
        path += '/maintain';
      }
      navigate(path);
    },
    [location, navigate, saveSelectedBuCode]
  );

  useKeyboardShortcut([KB_MOD, 'S'], () => setModalOpen(true), {
    overrideSystem: true,
    ignoreInputFields: true,
    repeatOnHold: false,
  });

  const hasMultipleStoreAccess = useHasMultipleStoreAccess();

  return (
    <nav>
      <Button
        disabled={!user || !hasMultipleStoreAccess}
        data-anchor="storeListButton"
        type="plain"
        ssrIcon={pinIcon}
        text={buttonText}
        title={buttonTitle}
        onClick={onClick}
      />
      {isSuccess && (
        <Modal
          id="storeSelectorModal"
          visible={modalOpen}
          handleCloseBtn={onDismiss}
        >
          <Sheets
            alignment="right"
            footer={<></>}
            header={<ModalHeader title={$t(cm.chooseStore)} floating={false} />}
          >
            <ModalBody>
              {isLoading && (
                <Loading>
                  <LoadingBall />
                </Loading>
              )}
              {isSuccess && (
                <StoreList
                  storeOptions={data?.storeOptions}
                  selectStore={onSelectStore}
                />
              )}
              {isError && <p>{$t(cm.storeListLoadError)}</p>}
            </ModalBody>
          </Sheets>
        </Modal>
      )}
    </nav>
  );
};

export default StoreSelector;
