import React, { FocusEvent, useCallback } from 'react';
import InputField from '@ingka/input-field';
import {
  UseFormGetFieldState,
  UseFormRegister,
} from 'react-hook-form/dist/types/form';
import FormField from '@ingka/form-field';
import { useValidationMessage } from 'hooks/validation';
import { useFormatNumber, useParseNumber } from 'hooks/locale';
import { InputFieldProps } from './types';

export type NumericFormValues = {
  [name: string]: number;
};

export type NumericInputFieldProps = InputFieldProps & {
  /**
   * The field in the form's model.
   */
  field: string;

  /**
   * References `useForm.register`.
   */
  register: UseFormRegister<NumericFormValues>;

  /**
   * References `useForm.getFieldState`.
   */
  getFieldState: UseFormGetFieldState<NumericFormValues>;

  /**
   * Minimum allowed value.
   *
   * @default 0
   */
  min?: number;

  /**
   * Optional maximum allowed value.
   */
  max?: number;

  /**
   * Indicates if the value is treated as an integer. Integer values will have their fractional part discarded.
   *
   * @default false
   */
  isInteger?: boolean;

  /**
   * Indicates if the field is required.
   *
   * @default true
   */
  required?: boolean;

  /**
   * Indicates if the field is read-only.
   *
   * @default false
   */
  readOnly?: boolean;

  /**
   * Optional suffix to display during editing.
   */
  suffix?: string;
};

/**
 * Wraps an `InputField` configured with application-specific numeric input behaviour.
 *
 * Numbers are handled with a decimal scale of 3 digits. I.e. they are parsed using a maximum of 3 digits after the
 * decimal separator. The decimal separator is locale-dependent.
 *
 * As specified by the Skapa guidelines, users are free to enter any input, with data-specific formatting being handled
 * after the field loses focus. Since the codebase uses uncontrolled components, this behaviour is implemented by
 * attaching a `change` event listener to the underlying `HTMLInputElement`.
 *
 * This implementation overrides `onBlur` to provide number formatting when the field loses focus.
 *
 * @see https://skapa.ikea.net/components/inputs-and-controls/input-field#data-format
 */
// TODO: Consider extracting this to a more reusable component that does not depend on React Hook Form.
const NumericInputField: React.FC<NumericInputFieldProps> = ({
  field,
  disabled = false,
  readOnly = false,
  isInteger = false,
  id,
  label,
  title,
  className,
  register,
  getFieldState,
  min = 0,
  max,
  required = true,
  suffix,
  ...restProps
}) => {
  const parseNumber = useParseNumber();

  const { name, onBlur, onChange, ref } = register(field, {
    setValueAs: (value: any): number => {
      return typeof value === 'string'
        ? parseNumber(value as string, isInteger ? 0 : 3)
        : value;
    },
    required,
    min,
    max,
  });

  const formatNumber = useFormatNumber();

  const onBlurFormat = useCallback(
    (event: FocusEvent) => {
      const target = event.target as HTMLInputElement;
      let value = parseNumber(target.value);
      if (value) {
        if (isInteger) {
          value = Math.floor(value);
        }
        target.value = formatNumber(value);
      }
      return onBlur(event);
    },
    [formatNumber, isInteger, onBlur, parseNumber]
  );

  const { invalid, isDirty, error } = getFieldState(field);

  const validation = {
    msg: useValidationMessage(error?.type),
    id: `validation-error-${field}`,
    type: 'error',
  };

  return (
    <FormField
      valid={!invalid}
      shouldValidate={isDirty || invalid}
      validation={validation}
      className={className}
    >
      <InputField
        name={name}
        onBlur={onBlurFormat}
        onChange={onChange}
        ref={ref}
        id={id}
        label={label}
        title={title}
        disabled={disabled}
        readOnly={readOnly}
        req={required}
        type="text"
        autoComplete="off"
        suffixLabel={suffix}
        {...restProps}
      />
    </FormField>
  );
};

export default NumericInputField;
