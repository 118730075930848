import { defineMessages } from 'react-intl';

const messages = defineMessages({
  exportButton: {
    id: 'exportButton',
    defaultMessage: 'Export',
    description: 'Label for Export to Excel button',
  },
  exportTitle: {
    id: 'exportTitle',
    defaultMessage: 'Export Excel sheet',
    description: 'Title for Export to Excel dialog',
  },
  generateFileButton: {
    id: 'generateFileButton',
    defaultMessage: 'Generate Excel sheet',
    description: 'Label for Generate Excel sheet button',
  },
  fontLabel: {
    id: 'exportFontLabel',
    defaultMessage: 'Font',
    description: 'Label for font selection',
  },
  fontOption: {
    id: 'exportFontOption',
    defaultMessage: '{name} {size} pt',
    description:
      'Font option, with {name} being the font name and {size} the point size',
  },
  errorEmpty: {
    id: 'exportErrorEmpty',
    defaultMessage:
      'Export is empty. Articles may not have Sales Locations assigned.',
    description: 'Notification shown when the Excel export is empty.',
  },
});

const excelMessages = defineMessages({
  // Keys must match the column IDs defined in the backend.
  itemNo: {
    id: 'exportExcelItemNo',
    defaultMessage: 'Art. No.',
    description: 'Excel column label for article number',
  },
  name: {
    id: 'exportExcelName',
    defaultMessage: 'Name',
    description: 'Excel column label for article name',
  },
  fromDate: {
    id: 'exportExcelFromDate',
    defaultMessage: 'Date',
    description: 'Excel column label for date',
  },
  assq: {
    id: 'exportExcelQuantity',
    defaultMessage: 'SSQ',
    description: 'Excel column label for quantity/SSQ',
  },
  slid: {
    id: 'exportExcelSlid',
    defaultMessage: 'SLID',
    description: 'Excel column label for SLID',
  },
  rangeGroup: {
    id: 'exportExcelRangeGroup',
    defaultMessage: 'Range Group',
    description: 'Excel column label for Range Group',
  },
  productDominance: {
    id: 'exportExcelProductDominance',
    defaultMessage: 'Prod. Dom.',
    description: 'Excel column label for Product Dominance',
  },
  merchandisingSolution: {
    id: 'exportExcelMerchandisingSolution',
    defaultMessage: 'Merchandising Solution',
    description: 'Excel column label for Merchandising Solution',
  },
  homebase: {
    id: 'exportExcelHomebase',
    defaultMessage: 'Homebase',
    description: 'Excel column label for Homebase',
  },
  primary: {
    id: 'exportExcelPrimary',
    defaultMessage: 'Primary',
    description: 'Excel column label for Primary',
  },
  manualPick: {
    id: 'exportExcelManualPick',
    defaultMessage: 'Manual',
    description: 'Excel column label for Manual Pick',
  },

  // Booleans
  true: {
    id: 'exportExcelTrue',
    defaultMessage: 'On',
    description: 'Option for on/true/yes value in Excel sheet',
  },
  false: {
    id: 'exportExcelFalse',
    defaultMessage: 'Off',
    description: 'Option for off/false/no value in Excel sheet',
  },

  // MerchandisingSolution keys
  S_RTS: {
    id: 'exportExcelMerchandisingSRTS',
    defaultMessage: 'Shelf - Ready to Sell',
  },
  F_RTS: {
    id: 'exportExcelMerchandisingFRTS',
    defaultMessage: 'Floor - Ready to Sell',
  },
  S_NotRTS: {
    id: 'exportExcelMerchandisingSNRTS',
    defaultMessage: 'Shelf - NOT Ready to Sell',
  },
  F_NotRTS: {
    id: 'exportExcelMerchandisingFNRTS',
    defaultMessage: 'Floor - NOT Ready to Sell',
  },
});

const exportMessages = { ...messages, ...{ excel: excelMessages } };
export default exportMessages;
