import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { OperationType } from 'core/commonTypes';

type CrudOperationEntity = {
  id: string;
  type: OperationType;
  tags: string[];
  count: number;
};

const entityAdapter = createEntityAdapter<CrudOperationEntity>();

/**
 * Reserved tag.
 */
const ITEM_TAG = 'item';

/**
 * Maintains the state of active CRUD operations.
 *
 * Each operation consists of a Use Case-specific ID, a type and associated tags. The tags are used to distinguish
 * between different actions. For example, different properties of the same object. The tag `item` marks "any" operation
 * and is added automatically.
 *
 * The implementation allows for concurrent operations of the same type. It is an error to start an operation of a
 * different type from the one that is already active.
 */
const crudOperationsSlice = createSlice({
  name: 'crudOperations',
  initialState: entityAdapter.getInitialState(),
  reducers: {
    /**
     * Signals that a particular operation has started.
     */
    operationStarted(
      state,
      action: PayloadAction<{
        id: string;
        type: OperationType;
        tags?: string[];
      }>
    ) {
      const { id, type, tags = [] } = action.payload;

      if (tags.includes(ITEM_TAG)) {
        throw new Error(
          `Cannot use reserved tag '${ITEM_TAG}', tags given: ${tags}`
        );
      }

      const {
        type: currentType,
        tags: currentTags,
        count,
      } = state.entities[id] ?? {
        type,
        count: 0,
        tags: [],
      };

      if (currentType !== undefined && currentType !== type) {
        throw new Error(
          `Concurrent operation should be of type ${type}, but is of type ${currentType}`
        );
      }

      entityAdapter.upsertOne(state, {
        id,
        type,
        count: count + 1,
        tags: [...currentTags, ...tags, ITEM_TAG],
      });
    },

    /**
     * Signals that an operation has ended.
     */
    operationEnded(
      state,
      action: PayloadAction<{ id: string; tags?: string[] }>
    ) {
      const { id, tags = [] } = action.payload;

      if (tags.includes(ITEM_TAG)) {
        throw new Error(
          `Cannot use reserved tag '${ITEM_TAG}', tags given: ${tags}`
        );
      }

      const entity = state.entities[id];
      if (entity === undefined) {
        throw new Error(`There is no active operation for '${id}'`);
      }

      const { count, tags: currentTags } = entity;

      if (count === 1) {
        entityAdapter.removeOne(state, id);
      } else {
        entityAdapter.updateOne(state, {
          id,
          changes: {
            count: count - 1,
            tags: currentTags.filter(tag => !tags.includes(tag)),
          },
        });
      }
    },
  },
});

const { operationStarted, operationEnded } = crudOperationsSlice.actions;

export const startCreate = (id: string, tags?: string[]) =>
  operationStarted({
    id,
    type: OperationType.CREATE,
    tags,
  });

export const startRead = (id: string, tags?: string[]) =>
  operationStarted({
    id,
    type: OperationType.READ,
    tags,
  });

export const startUpdate = (id: string, tags?: string[]) =>
  operationStarted({
    id,
    type: OperationType.UPDATE,
    tags,
  });

export const startDelete = (id: string, tags?: string[]) =>
  operationStarted({
    id,
    type: OperationType.DELETE,
    tags,
  });

export const endOperation = (id: string, tags?: string[]) =>
  operationEnded({
    id,
    tags,
  });

export const selectCrudOperation = entityAdapter.getSelectors().selectById;

export default crudOperationsSlice;
