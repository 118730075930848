import React, { useState } from 'react';
import Modal, { ModalBody, ModalHeader, Sheets } from '@ingka/modal';
import { questionMarkCircle } from '@ingka/ssr-icon/icons';
import Button from '@ingka/button';
import SSRIcon from '@ingka/ssr-icon';
import cm from 'core/commonMessages';
import { useIntl } from 'react-intl';

export type HelpProps = {
  header?: string;
  body?: string;
};

const Help: React.FC<HelpProps> = ({ header, body }) => {
  let showInlineHelp = false;
  if (process.env.NODE_ENV === 'development') {
    showInlineHelp = true;
  }

  const [modalOpen, setModalOpen] = useState(false);

  const { $t } = useIntl();

  return showInlineHelp ? (
    <>
      <Button
        role="helpButton"
        className="helpBtn"
        type="plain"
        small
        iconOnly
        ssrIcon={questionMarkCircle}
        title="Help"
        onClick={() => setModalOpen(!modalOpen)}
      />
      <Modal
        id="helpModal"
        visible={modalOpen}
        handleCloseBtn={() => setModalOpen(false)}
      >
        <Sheets
          alignment={'right'}
          size={'small'}
          header={<ModalHeader title={$t(cm.helpTitle)} floating={false} />}
          footer={<></>}
        >
          <ModalBody>
            <h2>{header}</h2>
            <p>{body}</p>
            <SSRIcon
              paths={questionMarkCircle}
              style={{ position: 'absolute', top: '85px', right: '10px' }}
            />
          </ModalBody>
        </Sheets>
      </Modal>
    </>
  ) : null;
};

export default Help;
