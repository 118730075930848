import React from 'react';
import { useNotificationsTable } from 'views/Actions/hooks/table';
import SlmTable from 'components/Table/SlmTable';
import Loading, { LoadingBall } from '@ingka/loading';
import { useItemNotifications } from 'views/Actions/hooks/actions';
import ActionsFilter from 'views/Actions/components/ActionsFilter/ActionsFilter';
import { notificationTypeMessages } from 'views/Actions/actionMessages';

const NotificationsTab: React.FC = () => {
  const {
    isLoading,
    data,
    productAreaOptions,
    productArea,
    onChangeProductArea,
    typeOptions,
    type,
    onChangeType,
    onReset,
    itemCount,
  } = useItemNotifications();

  const table = useNotificationsTable(data);

  return (
    <>
      <ActionsFilter
        productAreaOptions={productAreaOptions}
        productArea={productArea}
        onChangeProductArea={onChangeProductArea}
        typeOptions={typeOptions}
        typeMessages={notificationTypeMessages}
        type={type}
        onChangeType={onChangeType}
        onReset={onReset}
        itemCount={itemCount}
      />
      {isLoading && (
        <Loading>
          <LoadingBall />
        </Loading>
      )}
      {!isLoading && <SlmTable table={table} />}
    </>
  );
};

export default NotificationsTab;
