import { createApi } from '@reduxjs/toolkit/query/react';
import { apiQuery, apiSerializeQueryArgs } from 'core/rtkQuery';
import { ItemParameterDto, ParameterDto } from 'apis/backendApi';
import { applyUpdates } from 'views/Parameters/redux/types';

const GET_PARAMETERS_FOR_SINGLE_ITEM = 'getParametersForSingleItem';

type ItemParameters = {
  itemNo: string;
  parameters: ParameterDto[];
  refetch: boolean;
};

const articleParametersApi = createApi({
  reducerPath: 'articleParametersApi',
  baseQuery: apiQuery(),
  serializeQueryArgs: apiSerializeQueryArgs,

  tagTypes: ['article'],

  endpoints: builder => ({
    [GET_PARAMETERS_FOR_SINGLE_ITEM]: builder.query<
      ItemParameterDto,
      string | null
    >({
      query: itemNo => ({
        url: itemNo !== null ? '/parameters/v1/{buCode}/item/{itemNo}' : null,
        pathVariables: { itemNo },
      }),

      keepUnusedDataFor: 0,

      providesTags: (result, error, itemNo) => [
        { type: 'article', id: itemNo },
      ],
    }),

    postParametersForSingleItem: builder.mutation<void, ItemParameters>({
      query: ({ itemNo, parameters }) => ({
        url: '/parameters/v1/{buCode}/item/{itemNo}',
        pathVariables: { itemNo },
        method: 'POST',
        body: parameters,
      }),

      async onQueryStarted(
        { itemNo, parameters, refetch },
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;

        const { updateQueryData, invalidateTags } = articleParametersApi.util;
        if (refetch) {
          dispatch(invalidateTags([{ type: 'article', id: itemNo }]));
        } else {
          dispatch(
            updateQueryData(GET_PARAMETERS_FOR_SINGLE_ITEM, itemNo, draft => {
              applyUpdates(parameters, draft.parameters);
            })
          );
        }
      },
    }),
  }),
});

export const {
  useGetParametersForSingleItemQuery,
  usePostParametersForSingleItemMutation,
} = articleParametersApi;

export default articleParametersApi;
