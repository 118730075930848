/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ArticleAssignmentStatus = {
    UNPLANNED: '0',
    PLANNED: '1'
} as const;
export type ArticleAssignmentStatus = typeof ArticleAssignmentStatus[keyof typeof ArticleAssignmentStatus];


export function instanceOfArticleAssignmentStatus(value: any): boolean {
    for (const key in ArticleAssignmentStatus) {
        if (Object.prototype.hasOwnProperty.call(ArticleAssignmentStatus, key)) {
            if (ArticleAssignmentStatus[key as keyof typeof ArticleAssignmentStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ArticleAssignmentStatusFromJSON(json: any): ArticleAssignmentStatus {
    return ArticleAssignmentStatusFromJSONTyped(json, false);
}

export function ArticleAssignmentStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleAssignmentStatus {
    return json as ArticleAssignmentStatus;
}

export function ArticleAssignmentStatusToJSON(value?: ArticleAssignmentStatus | null): any {
    return value as any;
}

