import { useMemo } from 'react';
import { DivisionDto } from 'apis/backendApi';
import { useGetStoreStructureTreeQuery } from 'views/StoreStructure/redux/structuresApi';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { selectPendingUpdates } from 'core/redux/pendingUpdatesSlice';
import { findPendingDivisionUpdate } from 'views/StoreStructure/types';
import { MHS_SYNC_POLLING_INTERVAL } from 'core/types';

/**
 * Derived query for selecting a Division from the Store structure.
 */
export function useGetStoreStructureTreeDivisionQuery(
  divisionId: string | null
) {
  const query = useGetStoreStructureTreeQuery();

  const data = useMemo<DivisionDto | undefined>(
    () =>
      query.data?.divisions
        .filter(division => division.id === divisionId)
        .map((dto: DivisionDto) => ({ ...dto }))[0],
    [divisionId, query.data?.divisions]
  );

  const isUninitialized = divisionId === null;
  const isSuccess = query.isSuccess && data !== undefined;
  const isError =
    !query.isLoading && (query.isError || (!isUninitialized && !isSuccess));

  return {
    ...query,
    isUninitialized,
    isSuccess,
    isError,
    data,
  };
}

export function usePendingDivisionUpdate(divisionId: string) {
  const updates = useSelector((state: RootState) =>
    selectPendingUpdates(state.pendingUpdates)
  );

  const update = useMemo(
    () => findPendingDivisionUpdate(updates, divisionId),
    [divisionId, updates]
  );

  return {
    update,
    pollingInterval:
      update !== undefined ? MHS_SYNC_POLLING_INTERVAL : undefined,
  };
}
