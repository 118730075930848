import React, { useCallback } from 'react';
import { StoreStructureListItemModel } from './types';
import Tooltip from '@ingka/tooltip';
import {
  pencil,
  trashCan,
  visibilityHide,
  visibilityShow,
} from '@ingka/ssr-icon/icons';
import Button from '@ingka/button';
import { useIntl } from 'react-intl';
import messages from 'core/commonMessages';
import { StoreStructureType } from 'core/commonTypes';
import { useStoreStructureCrudState } from 'hooks/storeStructure';
import {
  useEditSpecialityShopAction,
  useRemoveSpecialityShopAction,
  useShowHideSpecialityShopActions,
} from 'views/StoreStructure/hooks/specialityShop';
import {
  useEditRangeGroupAction,
  useRemoveRangeGroupAction,
  useShowHideRangeGroupActions,
} from 'views/StoreStructure/hooks/rangeGroup';

function useStoreStructureItemActions(
  structureType:
    | StoreStructureType.SpecialityShop
    | StoreStructureType.RangeGroup,
  divisionId: string,
  specialityShopId: string,
  rangeGroupId: string,
  hidden: boolean
) {
  const removeSpecialityShop = useRemoveSpecialityShopAction(
    divisionId,
    specialityShopId
  );
  const editSpecialityShop = useEditSpecialityShopAction(
    divisionId,
    specialityShopId
  );
  const [showSpecialityShop, hideSpecialityShop] =
    useShowHideSpecialityShopActions(divisionId, specialityShopId);

  const removeRangeGroup = useRemoveRangeGroupAction(
    divisionId,
    specialityShopId,
    rangeGroupId
  );
  const editRangeGroup = useEditRangeGroupAction(
    divisionId,
    specialityShopId,
    rangeGroupId
  );
  const [showRangeGroup, hideRangeGroup] = useShowHideRangeGroupActions(
    divisionId,
    specialityShopId,
    rangeGroupId
  );

  const onRemoveClick = useCallback(() => {
    if (structureType === StoreStructureType.SpecialityShop) {
      removeSpecialityShop();
    } else {
      removeRangeGroup();
    }
  }, [removeRangeGroup, removeSpecialityShop, structureType]);

  const onEditClick = useCallback(() => {
    if (structureType === StoreStructureType.SpecialityShop) {
      editSpecialityShop();
    } else {
      editRangeGroup();
    }
  }, [editSpecialityShop, editRangeGroup, structureType]);

  const onHiddenClick = useCallback(async () => {
    if (structureType === StoreStructureType.SpecialityShop) {
      await (hidden ? showSpecialityShop() : hideSpecialityShop());
    } else {
      await (hidden ? showRangeGroup() : hideRangeGroup());
    }
  }, [
    hidden,
    hideRangeGroup,
    hideSpecialityShop,
    showRangeGroup,
    showSpecialityShop,
    structureType,
  ]);
  return { onRemoveClick, onEditClick, onHiddenClick };
}

const StoreStructureListItem = ({
  structureType,
  divisionId,
  specialityShopId,
  rangeGroupId,
  label,
  editable,
  removable,
  hideable,
  hidden,
}: StoreStructureListItemModel) => {
  const { onRemoveClick, onEditClick, onHiddenClick } =
    useStoreStructureItemActions(
      structureType,
      divisionId,
      specialityShopId,
      rangeGroupId,
      hidden
    );

  const { $t } = useIntl();

  const { isActive, isDeleting, isUpdating } = useStoreStructureCrudState(
    structureType,
    rangeGroupId ?? specialityShopId
  );

  return (
    <li>
      <span>{label}</span>
      <span>
        <Tooltip tooltipText={$t(messages.deleteButton)}>
          <Button
            className="slm-pe-100"
            small
            iconOnly
            type="tertiary"
            disabled={!removable || (isActive && !isDeleting.item)}
            ssrIcon={trashCan}
            onClick={onRemoveClick}
            loading={isDeleting.item}
          />
        </Tooltip>
        <Tooltip tooltipText={$t(messages.editButton)} className="slm-ps-125">
          <Button
            className="slm-pe-50"
            small
            iconOnly
            type="tertiary"
            disabled={
              !editable ||
              (isActive && !isUpdating.name && !isUpdating.description)
            }
            ssrIcon={pencil}
            onClick={onEditClick}
            loading={isUpdating.name || isUpdating.description}
          />
        </Tooltip>
        <Tooltip
          tooltipText={
            hidden
              ? $t(messages.hiddenStructureItem, {
                  type: structureType,
                })
              : $t(messages.visibleStructureItem, {
                  type: structureType,
                })
          }
          className="slm-ps-125"
        >
          <Button
            small
            iconOnly
            type="tertiary"
            ssrIcon={hidden ? visibilityHide : visibilityShow}
            disabled={!hideable || (isActive && !isUpdating.hidden)}
            onClick={onHiddenClick}
            loading={isUpdating.hidden}
          />
        </Tooltip>
      </span>
    </li>
  );
};

export default StoreStructureListItem;
