import { StatusColor } from 'core/types';
import { FormatNumberOptions } from '@formatjs/intl/src/types';

export enum TimeViewTopic {
  DIRECT_FLOW = 'directflow',
  SPACE_NEED = 'spaceneed',
  EXPECTED_TURNOVER_REVENUE = 'expectedturnoverrevenue',
  EXPECTED_TURNOVER_REVENUE_M2 = 'expectedturnoverrevenuem2',
  MAX_DENSITY = 'maxdensity',
}

export enum SnapshotViewTopic {
  SPACE_NEED = 'spaceneed',
  COMMERCIAL_EFFICIENCY = 'commercialefficiency',
  DENSITY_AND_FILLING_RATE = 'densityandfillingrate',
}

export const columnKey = (index: string | number) => `col_${index}`;

export const HEADING = 'heading';

export const COL_HEADING = `col_${HEADING}`;

export type SummaryValue = {
  text: string;
  statusColor?: StatusColor;
};

export type SummaryLine = {
  children: SummaryLine[];
} & {
  [columnName: string]: SummaryValue;
};

export type NumberFormatter = (
  value: number,
  options?: FormatNumberOptions
) => string;

export type StatusColorResolver = (value: number) => StatusColor | null;
