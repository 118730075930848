import React from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import InlineMessage from '@ingka/inline-message';
import m from 'core/commonMessages';

type NotFoundMessageProps = {
  /**
   * Optional message title.
   *
   * @default the common "forbidden" title.
   */
  title?: MessageDescriptor;

  /**
   * Optional message body.
   *
   * @default the common "forbidden" body.
   */
  body?: MessageDescriptor;
};

/**
 * Displays a "403 Forbidden"-like message.
 */
const ForbiddenMessage: React.FC<NotFoundMessageProps> = ({
  title = m.forbiddenTitle,
  body = m.forbiddenBody,
}) => {
  const { $t } = useIntl();
  return <InlineMessage body={$t(body)} title={$t(title)} variant="negative" />;
};

export default ForbiddenMessage;
