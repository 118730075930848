/**
 * Core type representing the selected Ikea store.
 */
export type SelectedStore = {
  code: string;
  name: string;
};

/**
 * Color codes for representing "stoplight-like" state.
 *
 * The enum values are intended to be used as CSS classes.
 */
export enum StatusColor {
  RED = 'slm-red',
  GREEN = 'slm-green',
  YELLOW = 'slm-yellow',
}

export type Primitive = string | boolean | number;

export type StoreStructureSelection = {
  divisionId: string | null;
  specialityShopId?: string | null;
  rangeGroupId?: string | null;
};

export type ItemCount = {
  /**
   * The number of items filtered.
   */
  filter: number;
  /**
   * The total number of items,
   */
  total: number;
};

/**
 * Interval for polling the state of MHS synchronization.
 */
export const MHS_SYNC_POLLING_INTERVAL = 5 * 1000;

/**
 * Timeout for MHS synchronization.
 */
export const MHS_SYNC_TIMEOUT = 30 * 1000;

export type SelectOption = {
  value: string;
  name: string;
};
