import s from 'views/Parameters/components/tabs/Tabs.module.css';
import Button from '@ingka/button';
import React from 'react';
import historyIcon from '@ingka/ssr-icon/paths/history';
import { Article } from 'views/Parameters/types';
import { ShowHistoryCallback } from 'views/Parameters/components/form/ParameterForm';
import { useIntl } from 'react-intl';
import pm from 'views/Parameters/parametersMessages';
import cm from 'core/commonMessages';

export type SingleArticleTableProps = {
  onShowHistory: ShowHistoryCallback;
  article: Article;
};

const SingleArticleTable: React.FC<SingleArticleTableProps> = ({
  article,
  onShowHistory,
}) => {
  const { $t } = useIntl();

  return (
    <table className={s.singleArtTable}>
      <thead>
        <tr>
          <th>{$t(pm.articleNumber)}</th>
          <th>{$t(cm.description)}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{article.articleNumber}</td>
          <td>
            <Button
              ssrIcon={historyIcon}
              type="plain"
              title={$t(cm.showHistoryButtonTitle)}
              onClick={onShowHistory}
              style={{ display: 'inline-block', marginTop: '-3px' }}
            />
            <p style={{ display: 'inline-block' }}>{article.name}</p>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SingleArticleTable;
