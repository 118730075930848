import { useGetRangeStructureTreeQuery } from 'views/StoreStructure/redux/structuresApi';
import { RangeNodeDto } from 'apis/backendApi';
import { useMemo } from 'react';

function nodeFlattener(
  acc: { name: string; description: string }[],
  n: RangeNodeDto
) {
  acc.push({ name: n.name, description: n.description });
  if (!!n.subNodes) {
    n.subNodes.reduce(nodeFlattener, acc);
  }
  return acc;
}
export function useFlatRangeList() {
  const { data } = useGetRangeStructureTreeQuery();

  const flatData: { name: string; description: string }[] = useMemo(() => {
    return !data ? [] : data.reduce(nodeFlattener, []);
  }, [data]);

  return flatData;
}
