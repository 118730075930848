import React, { useCallback, useMemo, useState } from 'react';
import { useModal } from 'react-modal-hook';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import Table, { TableBody, TableHeader } from '@ingka/table';
import Button from '@ingka/button';
import { useIntl } from 'react-intl';
import cm from 'core/commonMessages';
import mm from 'views/Maintain/maintainMessages';
import { useGetItemHistoryQuery } from 'views/Maintain/redux/itemApi';
import Loading, { LoadingBall } from '@ingka/loading';
import Tooltip from '@ingka/tooltip';
import { LocationType, UpdateAction } from 'apis/backendApi';
import { useMerchandisingTerms } from 'views/Maintain/hooks/language';
import './ItemHistoryModal.scss';
import Checkbox from '@ingka/checkbox';
import { toggleShowApplicationEvents } from 'core/redux/userPreferencesSlice';
import { useAppDispatch } from 'hooks/redux';
import { useUserPreferences } from 'hooks/userPreferences';
import TimeZoneDate from 'components/FormattedDate/TimeZoneDate';

type ItemHistoryModalProps = {
  itemNo: string;
  onModalClosed: () => void;
};

const historyModalId = 'slm-item-history-modal';

function trinaryCellString(
  $t,
  trinaryValue: boolean | null,
  mode: 'yes_no' | 'on_off'
) {
  if (trinaryValue === true) {
    return $t(mode === 'yes_no' ? cm.yes : cm.on);
  } else if (trinaryValue === false) {
    return $t(mode === 'yes_no' ? cm.no : cm.off);
  } else {
    return '';
  }
}

function actionCellString($t, value: UpdateAction) {
  switch (value) {
    case UpdateAction.CREATE:
      return $t(mm.maintainItemHistoryActionCreate);
    case UpdateAction.DELETE:
      return $t(mm.maintainItemHistoryActionDelete);
    case UpdateAction.UPDATE:
    case UpdateAction.DELETE_INSERT_UPDATED:
      return $t(mm.maintainItemHistoryActionUpdate);
    default:
      return value;
  }
}

const ItemHistoryModal: React.FC<ItemHistoryModalProps> = ({
  itemNo,
  onModalClosed,
}) => {
  const { $t } = useIntl();
  const merchString = useMerchandisingTerms('normal');
  const [visible, setVisible] = useState(true);
  const onClose = useCallback(() => setVisible(false), []);

  const { data, isLoading, error } = useGetItemHistoryQuery(
    { itemNo },
    {
      skip: !itemNo,
    }
  );

  const dispatch = useAppDispatch();
  const onShowChangeApplicationEvents = useCallback(() => {
    dispatch(toggleShowApplicationEvents());
  }, [dispatch]);

  const { showApplicationEvents } = useUserPreferences();
  const salesLocations = useMemo(
    () =>
      (data?.salesLocations ?? []).filter(
        ({ user }) =>
          showApplicationEvents ||
          (!user.match(/^slm-.+$/) && !user.match(/^ISLM.+$/))
      ),
    [data, showApplicationEvents]
  );

  return (
    <Modal
      handleCloseBtn={onClose}
      visible={visible}
      onModalClosed={onModalClosed}
    >
      <Prompt
        title={$t(mm.maintainItemHistoryHeaderArticle)}
        className="slm-item-history-prompt"
        footer={
          <ModalFooter>
            <Button text={$t(cm.close)} onClick={onClose} />
          </ModalFooter>
        }
        header={<ModalHeader closeBtnClick={onClose} />}
        id={historyModalId}
      >
        {/*todo sticky and border-collapse: collapse does not play nice*/}
        <Table className="slm-table slm-article-level-table">
          <TableHeader sticky>
            <tr>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainItemDetailsCommercialQtyTooltip)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainItemDetailsCommercialQty)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainItemDetailsFlexTooltip)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainItemDetailsFlex)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainItemDetailsAutoTooltip)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainItemDetailsAuto)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(cm.userName)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(cm.userName)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(cm.dateTime)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(cm.dateTime)}</span>
                </Tooltip>
              </th>
            </tr>
          </TableHeader>
          <TableBody>
            {error && (
              <tr>
                <td colSpan={5}>{$t(cm.historyLoadingError)}</td>
              </tr>
            )}
            {data && data.articleLevel.length === 0 && (
              <tr>
                <td colSpan={5}>{$t(cm.emptyHistory)}</td>
              </tr>
            )}
            {data &&
              data.articleLevel?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>
                    {trinaryCellString($t, row.useCommercialQuantity, 'yes_no')}
                  </td>
                  <td>
                    {trinaryCellString(
                      $t,
                      row.flexibilityPercentage !== null
                        ? row.flexibilityPercentage > 0
                        : null,
                      'yes_no'
                    )}
                  </td>
                  <td>{trinaryCellString($t, row.auto, 'on_off')}</td>
                  <td>{row.user}</td>
                  <td>
                    <TimeZoneDate value={row.lastUpdated} />
                  </td>
                </tr>
              ))}
          </TableBody>
        </Table>
        <div className="slm-d-flex-row-ac-150">
          <h2>{$t(mm.maintainItemHistoryHeaderSalesLocation)}</h2>
          <Checkbox
            id="showApplicationEvents"
            value="true"
            checked={showApplicationEvents}
            subtle
            onChange={onShowChangeApplicationEvents}
            label={$t(mm.maintainItemHistoryShowApplicationEvents)}
          />
        </div>
        <Table className="slm-table">
          <TableHeader sticky>
            <tr>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainItemHistorySlidTooltip)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainItemHistorySlid)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainItemHistoryFromDateTooltip)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainItemHistoryFromDate)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainItemHistoryActionTooltip)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainItemHistoryAction)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainItemDetailsQuantityTooltip)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainItemDetailsQuantity)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(
                    mm.maintainItemDetailsProductDominanceTooltip
                  )}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainItemDetailsProductDominance)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainItemDetailsMerchSolutionTooltip)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainItemDetailsMerchSolution)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainAssignmentPrimary)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainAssignmentPrimary)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainAssignmentHomebase)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainAssignmentHomebase)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainAssignmentManualPick)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainAssignmentManualPick)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(mm.maintainItemDetailsAutoTooltip)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(mm.maintainItemDetailsAuto)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(cm.userName)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(cm.userName)}</span>
                </Tooltip>
              </th>
              <th>
                <Tooltip
                  tooltipText={$t(cm.dateTime)}
                  boundaryContainerID={historyModalId}
                  position="bottom"
                >
                  <span>{$t(cm.dateTime)}</span>
                </Tooltip>
              </th>
            </tr>
          </TableHeader>
          <TableBody>
            {isLoading && (
              <tr>
                <td>
                  <Loading>
                    <LoadingBall />
                  </Loading>
                </td>
              </tr>
            )}
            {error && (
              <tr>
                <td colSpan={12}>{$t(cm.historyLoadingError)}</td>
              </tr>
            )}
            {salesLocations.length === 0 && (
              <tr>
                <td colSpan={12}>{$t(cm.emptyHistory)}</td>
              </tr>
            )}
            {salesLocations.map((sl, slindex) => {
              return (
                <tr key={slindex}>
                  <td>{sl.slid}</td>
                  <td>{sl.fromDate}</td>
                  <td>{actionCellString($t, sl.updateAction)}</td>
                  <td>{sl.quantity}</td>
                  {/* TODO Type 2 */}
                  <td>{sl.productDominance}</td>
                  <td>{merchString(sl.merchandisingSolution)}</td>
                  <td>
                    {sl.locationType === LocationType.HOMEBASE_PRIMARY ||
                    sl.locationType === LocationType.PRIMARY
                      ? 'x'
                      : ''}
                  </td>
                  <td>
                    {sl.locationType === LocationType.HOMEBASE_PRIMARY ||
                    sl.locationType === LocationType.HOMEBASE
                      ? 'x'
                      : ''}
                  </td>
                  <td>{sl.manualPick === true ? 'x' : ''}</td>
                  <td>{sl.autoPick === true ? 'x' : ''}</td>
                  <td>{sl.user}</td>
                  <td>
                    <TimeZoneDate value={sl.lastUpdated} />
                  </td>
                </tr>
              );
            })}
          </TableBody>
        </Table>
      </Prompt>
    </Modal>
  );
};

function useItemHistoryModal(itemNo: string) {
  const [showModal, hideModal] = useModal(
    () => <ItemHistoryModal itemNo={itemNo} onModalClosed={hideModal} />,
    [itemNo]
  );
  return showModal;
}

export default useItemHistoryModal;
