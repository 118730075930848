/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Defines column names used in the getSnapshotView report. Due to lack of codegen support for 'oneOf', this type cannot be referenced directly.
 * @export
 */
export const SpaceNeedColumn = {
    BackpanelNeed: 'backpanelNeed',
    BackpanelUtilization: 'backpanelUtilization',
    HalfPalletNeed: 'halfPalletNeed',
    HalfPalletUtilization: 'halfPalletUtilization',
    FullPalletNeed: 'fullPalletNeed',
    FullPalletUtilization: 'fullPalletUtilization',
    LongPalletNeed: 'longPalletNeed',
    LongPalletUtilization: 'longPalletUtilization',
    HandstackLocationNeed: 'handstackLocationNeed',
    HandstackLocationUtilization: 'handstackLocationUtilization',
    SpaceNeed: 'spaceNeed',
    SpaceUtilization: 'spaceUtilization'
} as const;
export type SpaceNeedColumn = typeof SpaceNeedColumn[keyof typeof SpaceNeedColumn];


export function instanceOfSpaceNeedColumn(value: any): boolean {
    for (const key in SpaceNeedColumn) {
        if (Object.prototype.hasOwnProperty.call(SpaceNeedColumn, key)) {
            if (SpaceNeedColumn[key as keyof typeof SpaceNeedColumn] === value) {
                return true;
            }
        }
    }
    return false;
}

export function SpaceNeedColumnFromJSON(json: any): SpaceNeedColumn {
    return SpaceNeedColumnFromJSONTyped(json, false);
}

export function SpaceNeedColumnFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpaceNeedColumn {
    return json as SpaceNeedColumn;
}

export function SpaceNeedColumnToJSON(value?: SpaceNeedColumn | null): any {
    return value as any;
}

