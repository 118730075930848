import { defineMessages } from 'react-intl';

const messages = defineMessages({
  min: { id: 'min', defaultMessage: 'Value is lower than permitted' },
  max: { id: 'max', defaultMessage: 'Value is higher than permitted' },
  required: { id: 'required', defaultMessage: 'A value is required' },
  unknown: { id: 'unknown', defaultMessage: 'Error' },
  isInteger: {
    id: 'isInteger',
    defaultMessage: 'Value must be a whole number (integer)',
  },
});

export default messages;
