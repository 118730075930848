import React, { ComponentType, FC } from 'react';
import { usePrivilegesQuery } from 'hooks/privilege';
import { Privilege } from 'core/redux/userApi';
import ForbiddenMessage from 'components/Message/ForbiddenMessage';
import Loading, { LoadingBall } from '@ingka/loading';

function useHasPrivilegeQuery(privilege: Privilege) {
  const { isLoading, isSuccess, data: privileges } = usePrivilegesQuery();
  return {
    isLoading,
    data: isSuccess ? privileges?.indexOf(privilege) !== -1 : null,
  };
}

/**
 * Wraps Route-level components that require a privilege.
 */
export default function withPrivilege<P extends object>(
  Component: ComponentType<P>,
  privilege: Privilege
): FC<P> {
  return function (props: P) {
    const { isLoading, data: hasPrivilege } = useHasPrivilegeQuery(privilege);
    return (
      <>
        {isLoading && (
          <Loading>
            <LoadingBall />
          </Loading>
        )}
        {!isLoading && hasPrivilege ? (
          <Component {...props} />
        ) : (
          <ForbiddenMessage />
        )}
      </>
    );
  };
}
