import React from 'react';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import InputField from '@ingka/input-field';
import { ParameterFormValues } from 'views/Parameters/components/form/ParameterForm';
import { UseFormResetField } from 'react-hook-form';
import s from 'views/Parameters/components/tabs/Tabs.module.css';
import FormField from '@ingka/form-field';
import { useHasUpdateParametersPrivilege } from 'hooks/privilege';
import warningTriangle from '@ingka/ssr-icon/paths/warning-triangle';
import checkmark from '@ingka/ssr-icon/paths/checkmark';
import { ParameterFieldProps } from 'views/Parameters/components/form/ParameterFieldRow';
import {
  Approval,
  ApprovalState,
  selectApproval,
} from 'views/Parameters/redux/parameterApprovalSlice';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { selectComputedValues } from 'views/Parameters/redux/parameterComputationSlice';
import { useApproveParameterAction } from 'views/Parameters/components/form/parameterApproval';
import { useIntl } from 'react-intl';
import pm from 'views/Parameters/parametersMessages';
import NumericInputField, {
  NumericFormValues,
} from 'components/Input/NumericInputField';

type NumericParameterFieldProps = ParameterFieldProps & {
  register: UseFormRegister<NumericFormValues>;
  resetField: UseFormResetField<NumericFormValues>;
};

const CRITICAL_ICONS = {
  [Approval.REQUIRED]: warningTriangle,
  [Approval.ACCEPTED]: checkmark,
};

const NumericParameterField: React.FC<NumericParameterFieldProps> = ({
  field,
  register,
  getFieldState,
  isLocked,
  formId,
  resetField,
}) => {
  const { name, isReadonly, isComputed, min, max } = field;

  const { invalid, isDirty } = getFieldState(field.name);

  const isAuthorized = useHasUpdateParametersPrivilege();

  const approval = useSelector<RootState, ApprovalState>(state =>
    selectApproval(state, formId)
  );

  const approveParameter = useApproveParameterAction(
    formId,
    field.name,
    resetField
  );

  let trailingIcon: any;
  if (field.isCritical && isDirty && !invalid) {
    const status = approval.approvalValues[field.name] ?? Approval.REQUIRED;
    trailingIcon = CRITICAL_ICONS[status];
  }

  const computedValues = useSelector<RootState, ParameterFormValues>(state =>
    selectComputedValues(state, formId)
  );

  const { $t } = useIntl();

  return (
    <div className={s.inputWidth}>
      {!isComputed ? (
        <NumericInputField
          id={`${formId}_${name}`}
          field={field.name}
          register={register}
          getFieldState={getFieldState}
          readOnly={isReadonly || !isAuthorized || isLocked}
          title={`min: ${min}\nmax: ${max}`}
          // Critical parameters
          iconPosition="trailing"
          ssrIcon={trailingIcon}
          iconClickDescription={$t(pm.approveParameterTooltip)}
          iconOnClick={approveParameter}
          className={s.autoMarginBottom}
        />
      ) : (
        // Computed field
        <FormField className={s.autoMarginBottom}>
          <InputField
            id={`${formId}_${name}`}
            title={name}
            type="text"
            autoComplete="off"
            // Note: must initialize with a value to avoid React thinking that the component is uncontrolled.
            value={(computedValues[name] as any) ?? 0}
            disabled={isLocked}
            readOnly
          />
        </FormField>
      )}
    </div>
  );
};

export default NumericParameterField;
