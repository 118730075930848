import React, { useState } from 'react';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import { useIntl } from 'react-intl';
import sm from 'views/Summaries/summariesMessages';
import { useTabPathRoute } from 'hooks/routing';
import { Outlet } from 'react-router-dom';
import { useOutletContext } from 'react-router';
import { ExpandedState } from '@tanstack/react-table';
import withPrivilege from 'components/WithPrivilege';
import withStoreStructure from 'components/WithStoreStructure';
import { Privilege } from 'core/redux/userApi';

export enum SummariesTabId {
  TIME_VIEW = 'time-view',
  SNAPSHOT_VIEW = 'snapshot-view',
}

export type SummariesContext = {
  // Time view
  timeViewExpandedState: ExpandedState;
  setTimeViewExpandedState: (state: ExpandedState) => void;
  // Snapshot view
  snapshotViewExpandedState: ExpandedState;
  setSnapshotViewExpandedState: (state: ExpandedState) => void;
  date: Date;
  setDate: (date: Date) => void;
};

const Summaries: React.FC = () => {
  const { $t } = useIntl();

  const [timeViewExpandedState, setTimeViewExpandedState] =
    useState<ExpandedState>({});
  const [snapshotViewExpandedState, setSnapshotViewExpandedState] =
    useState<ExpandedState>({});
  const [date, setDate] = useState(new Date());

  const tabs = [
    <Tab
      key="tab-1"
      text={$t(sm.timeView)}
      tabPanelId={SummariesTabId.TIME_VIEW}
    />,
    <Tab
      key="tab-2"
      text={$t(sm.snapshotView)}
      tabPanelId={SummariesTabId.SNAPSHOT_VIEW}
    />,
  ];

  const context: SummariesContext = {
    timeViewExpandedState,
    setTimeViewExpandedState,
    snapshotViewExpandedState,
    setSnapshotViewExpandedState,
    date,
    setDate,
  };

  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId={SummariesTabId.TIME_VIEW}>
      {/*
      Note: you have to pass in the same context instance across all sibling Outlets.
      Using a different context instance produces intermittent undefined.
      */}
      <Outlet context={context} />
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId={SummariesTabId.SNAPSHOT_VIEW}>
      <Outlet context={context} />
    </TabPanel>,
  ];

  const [tab, onTabChanged] = useTabPathRoute(
    '/store/:buCode/summaries/:tabId',
    'tabId'
  );

  return (
    <Tabs
      tabs={tabs}
      tabPanels={tabPanels}
      defaultActiveTab={tab}
      onTabChanged={onTabChanged}
    />
  );
};

export function useSummariesContext() {
  return useOutletContext<SummariesContext>();
}

export default withPrivilege(
  withStoreStructure(Summaries),
  Privilege.READ_REPORTS
);
