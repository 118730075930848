import {
  Configuration as BackendConfiguration,
  FetchParams,
  GETParametersApi,
  Middleware,
  POSTParametersApi,
  RequestContext,
  SLMStructuresApi,
} from 'apis/backendApi';
import { API_TARGET } from 'components/integration/types';
import { Config } from 'core/commonTypes';
import {
  getApiAccessToken,
  getApiLanguage,
  hasApiAccessToken,
} from 'core/apiConfig';

const setApiRoot = (apiRoot: string, _apiType: API_TARGET) => {
  api = apiRoot;
  apiType = _apiType;
  const backendConfiguration = new BackendConfiguration({
    fetchApi: window.fetch,
    accessToken: getApiAccessToken,
    basePath: apiRoot + '/slm/backend',
    middleware: [LanguageMiddleware],
  });
  API.parametersGet = new GETParametersApi(backendConfiguration);
  API.parametersPost = new POSTParametersApi(backendConfiguration);
  API.slmStructures = new SLMStructuresApi(backendConfiguration);
};
let api: string = null;
let apiType: API_TARGET;
let apiTargets: Record<API_TARGET, string> = undefined;

const setApiTargets = async (config: Config) => {
  apiTargets = {
    [API_TARGET.INTEGRATION]: config.integrationRoot,
    [API_TARGET.CLOUD]: config.apiRoot,
  };
  setApiRoot(config.apiRoot, API_TARGET.CLOUD);
  return null;
};

const changeApiTarget = async (target: API_TARGET) => {
  if (apiType === target) {
    return;
  }
  if (target === API_TARGET.INTEGRATION) {
    const canUseIntegration = true;
    if (!canUseIntegration) {
      throw new Error('Integration layer unreachable');
    }
  }
  setApiRoot(apiTargets[target], target);
};

const API: {
  parametersGet: GETParametersApi | null;
  parametersPost: POSTParametersApi | null;
  slmStructures: SLMStructuresApi | null;
} = {
  parametersGet: null,
  parametersPost: null,
  slmStructures: null,
};

export const getApiType = () => {
  return apiType;
};

const LanguageMiddleware: Middleware = {
  async pre(context: RequestContext): Promise<FetchParams | void> {
    context.init.headers['Accept-Language'] = getApiLanguage();
    return { url: context.url, init: context.init };
  },
};

type requestFunction = (
  endpoint: string,
  body?: any,
  options?: {
    noAuth?: boolean;
    method?: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH';
  }
) => Promise<any>;

const request: requestFunction = async (endpoint, body, options) => {
  if (api === null) {
    throw new Error('API not configured');
  }
  const target = `${api}${endpoint}`;
  const fetchOptions: any = {
    method: options?.method || 'GET',
    headers: {
      Accept: 'application/json',
    },
  };
  if (body) {
    fetchOptions.body = JSON.stringify(body);
    fetchOptions.headers['Content-Type'] = 'application/json';
  }
  if (!options?.noAuth) {
    if (!hasApiAccessToken()) {
      throw new Error('Auth token missing');
    }
    fetchOptions.headers['Authorization'] = 'Bearer ' + getApiAccessToken();
  }

  const response = await fetch(target, fetchOptions);
  if (response.status < 200 || response.status > 299) {
    throw new Error(await response.text());
  }
  return await response.json();
};

export { request, API, setApiTargets, changeApiTarget };
