import { useSearchParamState } from 'hooks/routing';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import {
  createParameterFields,
  GROUP_PARAMETERS,
  GroupFilter,
  ParameterField,
  ParameterType,
} from 'views/Parameters/types';
import { useSelectOnChange } from 'hooks/select';
import pm from 'views/Parameters/parametersMessages';
import cm from 'core/commonMessages';
import { MessageDescriptor, useIntl } from 'react-intl';
import {
  useGetRangeStructureTreeQuery,
  useGetStoreStructureTreeQuery,
} from 'views/StoreStructure/redux/structuresApi';
import { enumValue } from 'core/util/enum';
import {
  GroupParametersSelection,
  GroupSelection,
  useGetParametersForItemsQuery,
} from 'views/Parameters/redux/groupParametersApi';
import { useDebounce } from 'hooks/debounce';
import { useGetParameterValueLimitsQuery } from 'views/Parameters/redux/parameterLimitsApi';
import { ParameterDto } from 'apis/backendApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

const PARAMETER_PARAM = 'parameter';
const FILTER_PARAM = 'filter';
const FILTER_VALUE_PARAM = 'filter-value';

export function useGroupFilterSearchParamState() {
  const [values, setValues] = useSearchParamState({
    [PARAMETER_PARAM]: null,
    [FILTER_PARAM]: null,
    [FILTER_VALUE_PARAM]: null,
  });

  const {
    [PARAMETER_PARAM]: parameter,
    [FILTER_PARAM]: filter,
    [FILTER_VALUE_PARAM]: filterValue,
  } = values;

  const onChangeParameter = useSelectOnChange(parameter, value => {
    setValues({ [PARAMETER_PARAM]: value });
  });

  const onChangeFilter = useSelectOnChange(filter, value => {
    setValues({
      [FILTER_PARAM]: value,
      [FILTER_VALUE_PARAM]: null,
    });
  });

  const onChangeFilterValue = useSelectOnChange(filterValue, value => {
    setValues({
      [FILTER_VALUE_PARAM]: value,
    });
  });

  return {
    parameter,
    onChangeParameter,
    filter,
    onChangeFilter,
    filterValue,
    onChangeFilterValue,
  };
}

const FILTER_LABELS = new Map<GroupFilter, MessageDescriptor>();
FILTER_LABELS.set(GroupFilter.DIVISION, pm.divisionFilter);
FILTER_LABELS.set(GroupFilter.SPECIALITY_SHOP, pm.specialityShopFilter);
FILTER_LABELS.set(GroupFilter.HFB, pm.hfbFilter);
FILTER_LABELS.set(GroupFilter.RANGE_AREA, pm.rangeAreaFilter);
FILTER_LABELS.set(GroupFilter.PRODUCT_AREA, pm.productAreaFilter);
FILTER_LABELS.set(GroupFilter.SALES_METHOD, pm.salesMethodFilter);
FILTER_LABELS.set(GroupFilter.SERVICE_CLASS, pm.serviceClassFilter);

const SALES_METHOD_MESSAGES: MessageDescriptor[] = [
  pm.salesMethod0,
  pm.salesMethod1,
  pm.salesMethod2,
];

const SERVICE_CLASS_MESSAGES: MessageDescriptor[] = [
  pm.serviceClass1,
  pm.serviceClass2,
  pm.serviceClass3,
  pm.serviceClass4,
  pm.serviceClass5,
];

export type FilterOption = {
  id: string;
  label: string;
  /**
   * Optional React item key for distinguishing between options with overlapping values used by different filters.
   *
   * In this case, sales_method and service_class, which have overlapping values of `1` and `2`.
   */
  valueKey?: string;
};

export type FilterGroup = FilterOption & {
  options: FilterOption[];
};

export function useGroupFilterOptions(filter: string | null): {
  parameterOptions: FilterOption[];
  filterOptions: FilterOption[];
  filterValueOptions: FilterOption[] | FilterGroup[];
} {
  const parameterOptions = useMemo(
    () =>
      GROUP_PARAMETERS.sort((a, b) => a.localeCompare(b)).map(param => ({
        id: param,
        label: param,
      })),
    []
  );

  const { $t } = useIntl();

  const filterOptions = useMemo(() => {
    return Object.entries(GroupFilter).map(([key, value]) => ({
      id: value,
      label: $t(FILTER_LABELS.get(GroupFilter[key])),
    }));
  }, [$t]);

  const { data: storeStructure } = useGetStoreStructureTreeQuery();

  const { data: rangeStructure } = useGetRangeStructureTreeQuery();

  const filterValueOptions = useMemo<FilterOption[] | FilterGroup[]>(() => {
    switch (enumValue<GroupFilter>(GroupFilter, filter)) {
      case GroupFilter.DIVISION:
        return storeStructure.divisions.map(division => ({
          id: division.id,
          label: division.name,
        }));
      case GroupFilter.SPECIALITY_SHOP:
        // Grouped by Division
        return storeStructure.divisions.map(division => {
          return {
            id: division.id,
            label: division.name,
            options: division.specialityShops
              .filter(sp => !sp.hidden)
              .map(specialityShop => ({
                id: specialityShop.id,
                label: `${division.name} / ${specialityShop.name}`,
              })),
          };
        });
      case GroupFilter.HFB:
        return rangeStructure.map(({ name: hfbId, description }) => ({
          id: hfbId,
          label: description
            ? `${hfbId} - ${description}`
            : `${hfbId} - ${$t(cm.noDescription)}`,
        }));
      case GroupFilter.RANGE_AREA:
        return rangeStructure.flatMap(({ subNodes: rangeGroups }) =>
          rangeGroups.map(({ name: rangeGroupId, description }) => ({
            id: rangeGroupId,
            label: description
              ? `${rangeGroupId} - ${description}`
              : `${rangeGroupId} - ${$t(cm.noDescription)}`,
          }))
        );
      case GroupFilter.PRODUCT_AREA:
        return rangeStructure.flatMap(({ subNodes: rangeGroups }) =>
          rangeGroups.flatMap(({ subNodes: productAreas }) =>
            productAreas.map(({ name: productAreaId, description }) => ({
              id: productAreaId,
              label: description
                ? `${productAreaId} - ${description}`
                : `${productAreaId} - ${$t(cm.noDescription)}`,
            }))
          )
        );
      case GroupFilter.SALES_METHOD:
        return SALES_METHOD_MESSAGES.map((message, index) => ({
          id: `${index}`,
          label: $t(message),
          valueKey: `${GroupFilter.SALES_METHOD}${index}`,
        }));
      case GroupFilter.SERVICE_CLASS: {
        return SERVICE_CLASS_MESSAGES.map((message, index) => ({
          id: `${index + 1}`,
          label: $t(message),
          valueKey: `${GroupFilter.SERVICE_CLASS}${index + 1}`,
        }));
      }
      case null:
        return [];
      default:
        throw new Error(`Unhandled filter: ${filter}`);
    }
  }, [filter, storeStructure.divisions, rangeStructure, $t]);

  return { parameterOptions, filterOptions, filterValueOptions };
}

const SET_FILTER = Symbol();
const SET_PAGE = Symbol();

type QueryAction = {
  type: Symbol;
  payload: GroupSelection | number;
};

export function useGroupParameterSelection(delay: number) {
  const [selection, dispatch] = useReducer(
    (state: GroupParametersSelection, { type, payload }: QueryAction) => {
      switch (type) {
        case SET_FILTER:
          return {
            groupSelection: payload as GroupSelection,
            pageSelection: { pageNr: 1, pageSize: 20 },
          };
        case SET_PAGE:
          return {
            ...state,
            pageSelection: { pageNr: payload as number, pageSize: 20 },
          };
      }
    },
    {
      groupSelection: null,
      pageSelection: { pageNr: 1, pageSize: 20 },
    }
  );

  const [params, setParams] = useState<GroupSelection>(null);
  const debouncedParams = useDebounce(params, params === null ? 0 : delay);

  const onSearch = useCallback((params: GroupSelection | null) => {
    setParams(params);
  }, []);

  useEffect(() => {
    dispatch({ type: SET_FILTER, payload: debouncedParams });
  }, [dispatch, debouncedParams]);

  const onPageSelect = useCallback(
    (pageNr: number) => {
      dispatch({ type: SET_PAGE, payload: pageNr });
    },
    [dispatch]
  );

  return { selection, onSearch, onPageSelect };
}

/**
 * Derived query for fetching the search results for Group Parameter items.
 */
export function useGroupParameterQuery({
  groupSelection,
  pageSelection,
}: GroupParametersSelection) {
  const itemsQuery = useGetParametersForItemsQuery({
    groupSelection,
    pageSelection,
  });

  const limitsQuery = useGetParameterValueLimitsQuery({
    buCode: '',
    filter: enumValue<GroupFilter>(GroupFilter, groupSelection?.filter),
    filterValue: groupSelection?.filterValue,
  });

  const parameterFields = useMemo<ParameterField[]>(() => {
    const { data: limits } = limitsQuery;
    if (groupSelection?.parameter && limits) {
      const parameters: ParameterDto[] = [
        { name: groupSelection.parameter, value: null },
      ];
      return createParameterFields(parameters, limits).map(field =>
        field.type === ParameterType.BOOLEAN ? { ...field, value: true } : field
      );
    } else {
      return [];
    }
  }, [groupSelection, limitsQuery]);

  return {
    items: itemsQuery.data,
    parameterFields,
    error: itemsQuery.error as FetchBaseQueryError,
    isLoading:
      groupSelection !== null &&
      (itemsQuery.isLoading || limitsQuery.isLoading),
    isFetching:
      groupSelection !== null &&
      (itemsQuery.isFetching || limitsQuery.isFetching),
    isSuccess: itemsQuery.isSuccess && limitsQuery.isSuccess,
    isError: itemsQuery.isError || limitsQuery.isError,
  };
}

export type OnGroupParameterSubmit = (parameter: ParameterDto) => Promise<void>;
