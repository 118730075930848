import { useCallback, useMemo } from 'react';
import { useGetActionsQuery } from 'views/Actions/redux/actionsApi';
import { useParams } from 'react-router';
import { useSearchParamState } from 'hooks/routing';
import { ItemAlertMode } from 'views/Actions/types';
import { useGetRangeStructureTreeQuery } from 'views/StoreStructure/redux/structuresApi';
import { SelectOption } from 'core/types';
import log from 'loglevel';

function useFilterSearchParams() {
  const [{ pa, type }, setValues] = useSearchParamState({
    pa: null,
    type: null,
  });

  const onChangeProductArea = useCallback(
    (pa: string) => {
      setValues({ pa, type: null });
    },
    [setValues]
  );

  const onChangeType = useCallback(
    (type: string) => {
      setValues({ pa: pa, type });
    },
    [setValues, pa]
  );

  const onReset = useCallback(() => {
    setValues({ pa: null, type: null });
  }, [setValues]);

  return {
    productArea: pa,
    onChangeProductArea,
    type,
    onChangeType,
    onReset,
  };
}

export function useItemAlerts(mode: ItemAlertMode) {
  const { divId } = useParams();
  const { isLoading, data } = useGetActionsQuery(divId);

  const { productArea, type, onChangeProductArea, onChangeType, onReset } =
    useFilterSearchParams();

  const items = data?.[mode];

  const { data: rangeStructure } = useGetRangeStructureTreeQuery();

  const productAreaOptions = useMemo(() => {
    const hfbs = Object.fromEntries(
      rangeStructure?.map(({ name, description }) => [name, description]) ?? []
    );
    const optionsByHfb: Record<string, string[]> = {};
    [...new Set(items?.map(x => x.productArea))].forEach(option => {
      const hfb = option.substring(0, 2);
      optionsByHfb[hfb] = optionsByHfb[hfb] ?? [];
      optionsByHfb[hfb].push(option);
    });

    const options: SelectOption[] = [];
    for (const hfb in optionsByHfb) {
      options.push({ value: hfb, name: `${hfb} - ${hfbs[hfb]}` });
      optionsByHfb[hfb].forEach(x => options.push({ value: x, name: x }));
    }

    return options.sort((a, b) => a.name.localeCompare(b.name));
  }, [items, rangeStructure]);

  const typeOptions = useMemo(() => {
    return [
      ...new Set(
        items
          ?.filter(x => !productArea || x.productArea.startsWith(productArea))
          .map(x => x.type)
      ),
    ].sort();
  }, [items, productArea]);

  const filteredData = useMemo(() => {
    return items?.filter(x => {
      return (
        (!productArea || x.productArea.startsWith(productArea)) &&
        (!type || x.type === type)
      );
    });
  }, [items, productArea, type]);

  return {
    isLoading,
    data: filteredData,
    productAreaOptions,
    productArea,
    onChangeProductArea,
    typeOptions,
    type,
    onChangeType,
    onReset,
    itemCount: {
      filter: filteredData?.length,
      total: items?.length,
    },
  };
}

export function useItemNotifications() {
  const { divId } = useParams();
  const { isLoading, data } = useGetActionsQuery(divId);
  const { productArea, type, onChangeProductArea, onChangeType, onReset } =
    useFilterSearchParams();

  const productAreaOptions = useMemo(() => {
    return [...new Set(data?.notifications?.map(x => x.productArea))]
      .sort()
      .map(x => ({ value: x, name: x }));
  }, [data]);

  const typeOptions = useMemo(() => {
    return [
      ...new Set(
        data?.notifications
          ?.filter(x => !productArea || x.productArea === productArea)
          .map(x => x.type)
      ),
    ].sort();
  }, [data, productArea]);

  const filteredData = useMemo(() => {
    return data?.notifications?.filter(
      x =>
        (!productArea || x.productArea === productArea) &&
        (!type || x.type === type)
    );
  }, [data, productArea, type]);

  return {
    isLoading,
    data: filteredData,
    productAreaOptions,
    productArea,
    onChangeProductArea,
    typeOptions,
    type,
    onChangeType,
    onReset,
    itemCount: {
      filter: filteredData?.length,
      total: data?.notifications?.length,
    },
  };
}
