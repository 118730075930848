import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useGetStoreStructureTreeDivisionQuery } from 'views/StoreStructure/hooks/division';
import withStoreStructure from 'components/WithStoreStructure';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import am from 'views/Actions/actionMessages';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { useTabPathRoute } from 'hooks/routing';
import CountBadge from 'components/NumberBadge/CountBadge';
import { useGetActionsSummaryQuery } from 'views/Actions/redux/actionsApi';
import Loading, { LoadingBall } from '@ingka/loading';
import { ActionsDivisionSummary } from 'apis/backendApi';

export enum ActionsTabId {
  DEFAULT = 'alerts',
  ALERTS = 'alerts',
  WARNINGS = 'warnings',
  NOTIFICATIONS = 'notifications',
}

const ActionsTabs: React.FC<{ division: ActionsDivisionSummary }> = ({
  division,
}) => {
  const { $t } = useIntl();

  const tabs = [
    <Tab
      key="tab-1"
      text={
        <>
          {$t(am.alertsTitle)}
          <CountBadge total={division.alerts} colour="red" />
        </>
      }
      tabPanelId={ActionsTabId.ALERTS}
    />,
    <Tab
      key="tab-2"
      text={
        <>
          {$t(am.warningsTitle)}
          <CountBadge total={division.warnings} colour="orange" />
        </>
      }
      tabPanelId={ActionsTabId.WARNINGS}
    />,
    <Tab
      key="tab-3"
      text={
        <>
          {$t(am.notificationsTitle)}
          <CountBadge total={division.notifications} colour="blue" />
        </>
      }
      tabPanelId={ActionsTabId.NOTIFICATIONS}
    />,
  ];

  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId={ActionsTabId.ALERTS}>
      <Outlet />
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId={ActionsTabId.WARNINGS}>
      <Outlet />
    </TabPanel>,
    <TabPanel key="tab-3" tabPanelId={ActionsTabId.NOTIFICATIONS}>
      <Outlet />
    </TabPanel>,
  ];

  const [tab, onTabChanged] = useTabPathRoute(
    `/store/:buCode/actions/${division.id}/:tabId`,
    'tabId'
  );

  return (
    <Tabs
      tabs={tabs}
      tabPanels={tabPanels}
      activeTab={tab}
      onTabChanged={onTabChanged}
    />
  );
};

const Actions: React.FC = () => {
  const { divId } = useParams();
  const { data: divisionData } = useGetStoreStructureTreeDivisionQuery(divId);
  const { isLoading: isSummaryLoading, data: summaryData } =
    useGetActionsSummaryQuery();

  const division = useMemo(() => {
    return summaryData?.divisions?.find(x => x.id === divId) ?? {};
  }, [divId, summaryData]);

  return (
    <div className="slm-actions">
      <h1>{divisionData.name}</h1>
      {isSummaryLoading && (
        <Loading>
          <LoadingBall />
        </Loading>
      )}
      {!isSummaryLoading && <ActionsTabs division={division} />}
    </div>
  );
};

export default withStoreStructure(Actions);
