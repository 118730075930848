import Auth0Provider from 'components/Auth/Auth0Provider';
import {
  useAuth0 as useOfficialAuth0,
  withAuthenticationRequired as withOfficialAuthenticationRequired,
} from '@auth0/auth0-react';
import { useOurAuth0 } from 'components/Auth/mock/hooks';
import withMockAuthenticationRequired from './mock/with-mock-authentication-required';

const useAuth0 = () => {
  // @ts-ignore
  if (window.__useMockSSO === true) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useOurAuth0();
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useOfficialAuth0();
  }
};

const withAuthenticationRequired = (...args) => {
  // @ts-ignore
  if (window.__useMockSSO === true) {
    // @ts-ignore
    return withMockAuthenticationRequired(...args);
  } else {
    // @ts-ignore
    return withOfficialAuthenticationRequired(...args);
  }
};

export { Auth0Provider, useAuth0, withAuthenticationRequired };
