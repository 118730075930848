import React from 'react';
import style from './style.module.css';
import Pagination from './Pagination';

export type OnPageSelect = (pageNr: number) => void;

type ArticlePaginationProps = {
  onPageSelect: OnPageSelect;
  pageNr: number;
  pageSize: number;
  totalElements: number;
};

const ArticlePagination = ({
  pageNr,
  pageSize,
  onPageSelect,
  totalElements,
}: ArticlePaginationProps) => {
  const pageCount: number = Math.ceil(totalElements / pageSize);
  return (
    <div className={style.articlePagination}>
      <Pagination
        onPageSelect={onPageSelect}
        pageNr={pageNr}
        pageCount={pageCount}
      />
      <div>{`Total number of articles: ${totalElements}`}</div>
    </div>
  );
};

export default ArticlePagination;
