import React, { useCallback, useState } from 'react';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import Button from '@ingka/button';

import messages from 'core/commonMessages';
import { useIntl } from 'react-intl';

export type OnDismiss = () => void;

export type OnOutcome = (boolean) => void;

export type Paragraph = {
  text: string;
  className?: string;
};

type ConfirmDialogProps = {
  /**
   * Modal title
   */
  title: string;

  /**
   * Modal body. Can be a string or an array, with each item formatted as a separate `<p>` element.
   */
  body: string | string[] | Paragraph[];

  /**
   * The condition for which the dialog is made visible.
   */
  visible: boolean;

  /**
   * Invoked when the user dismisses the dialog.
   *
   * Clients should update their state to make the `visible` condition become `false`, so that the underlying Modal
   * is actually closed. Closing the Modal starts a CSS animation, with `onOutcome` being triggered after this animation
   * completes.
   */
  onDismiss: OnDismiss;

  /**
   * Callback indicating the outcome of the dialog, with `true` representing confirmation.
   *
   * This callback is invoked after the modal has closed.
   *
   * @param boolean
   */
  onOutcome: OnOutcome;

  /**
   * Optional confirm button label.
   *
   * @default "OK" translation
   */
  confirmLabel?: string;

  /**
   * Optional cancel button label.
   *
   * @default "Cancel" translation
   */
  cancelLabel?: string;

  /**
   * Optional CSS class.
   */
  className?: string;
};

/**
 * @deprecated
 */
const OldConfirmDialog: React.FC<ConfirmDialogProps> = ({
  visible,
  onDismiss,
  title,
  body,
  confirmLabel,
  cancelLabel,
  onOutcome,
  className,
}) => {
  const { $t } = useIntl();
  confirmLabel = confirmLabel ?? $t(messages.ok);
  cancelLabel = cancelLabel ?? $t(messages.cancel);

  const [confirmed, setConfirmed] = useState<boolean | null>(null);

  const onConfirm = useCallback(() => {
    setConfirmed(true);
    onDismiss();
  }, [onDismiss]);

  const onCancel = useCallback(() => {
    setConfirmed(false);
    onDismiss();
  }, [onDismiss]);

  const onModalClosed = useCallback(() => {
    onOutcome(confirmed);
  }, [onOutcome, confirmed]);

  body = Array.isArray(body) ? body : [body];

  return (
    <Modal
      visible={visible}
      handleCloseBtn={onCancel}
      onModalClosed={onModalClosed}
      className={className}
    >
      <Prompt
        className="slm-ok-cancel"
        title={title}
        header={<ModalHeader />}
        footer={
          <ModalFooter>
            <Button type="primary" text={confirmLabel} onClick={onConfirm} />
            <Button text={cancelLabel} onClick={onCancel} />
          </ModalFooter>
        }
      >
        {body.map((paragraph, index) => (
          <p key={index} className={paragraph?.className}>
            {paragraph?.text ?? paragraph}
          </p>
        ))}
      </Prompt>
    </Modal>
  );
};

export default OldConfirmDialog;
