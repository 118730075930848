import React, { forwardRef } from 'react';
import SSRIcon from '@ingka/ssr-icon';
import chevronDownSmall from '@ingka/ssr-icon/paths/chevron-down-small';

type SkapaSelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  label?: string;
  children: React.ReactNode;
  id: string;
};

const SkapaSelect: React.FC<SkapaSelectProps> = forwardRef<
  HTMLSelectElement,
  SkapaSelectProps
>((props, ref) => {
  const { label, children, id, ...restProps } = props;
  return (
    <div
      className={`select ${
        label ? 'label-wrapper label-wrapper--text-input' : ''
      }`}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <div className="select__wrapper">
        <select id={id} ref={ref} {...restProps}>
          {children}
        </select>
        <SSRIcon className={`select__chevron-down`} paths={chevronDownSmall} />
        <span className="select__border" />
      </div>
    </div>
  );
});

export default SkapaSelect;
