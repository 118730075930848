import React, { useMemo } from 'react';
import { NotificationMessage } from 'apis/backendApi';
import InlineMessage from '@ingka/inline-message';
import NotificationTypeText from 'views/Actions/components/NotificationTypeText';
import { useIntl } from 'react-intl';
import mm from 'views/Maintain/maintainMessages';
import { useConfirmDialog } from 'components/Modal/Dialog';
import { usePutItemNotificationsMutation } from 'views/Maintain/redux/itemApi';
import { useAsyncFeedback } from 'hooks/feedback';

const NotificationInlineMessage: React.FC<{
  data: NotificationMessage;
  itemNo: string;
}> = ({ data, itemNo }) => {
  const { $t } = useIntl();

  const [putItemNotifications] = usePutItemNotificationsMutation();

  const { slid } = data;

  const onOutcome = useAsyncFeedback<boolean>(
    async outcome => {
      if (outcome) {
        await putItemNotifications({
          itemNo,
          slid,
          handled: true,
        }).unwrap();
      }
      return outcome;
    },
    [slid, itemNo, putItemNotifications],
    { messages: mm.useCases.markNotificationHandled, values: { slid } }
  );

  const onConfirm = useConfirmDialog({
    title: $t(mm.useCases.markNotificationHandled.title, { slid }),
    body: $t(mm.useCases.markNotificationHandled.body),
    onOutcome,
  });

  const actions = useMemo(() => {
    return [
      {
        onClick: onConfirm,
        text: $t(mm.useCases.markNotificationHandled.action),
      },
    ];
  }, [onConfirm, $t]);

  return (
    <InlineMessage
      className="slm-mb-100 slm-mt-100"
      variant="cautionary"
      title={$t(mm.useCases.markNotificationHandled.header, {
        slid: data.slid,
      })}
      body={
        <span>
          {data.user} - {data.fromDate} - {data.slid} -{' '}
          <NotificationTypeText data={data} />
        </span>
      }
      actions={actions}
    />
  );
};

export default NotificationInlineMessage;
