import React from 'react';
import Modal, { ModalFooter, ModalHeader, Prompt } from '@ingka/modal';
import Button from '@ingka/button';
import InputField from '@ingka/input-field';
import { useForm } from 'react-hook-form';
import FormField from '@ingka/form-field';
import { useIntl } from 'react-intl';
import sm from '../../../storeStructureMessages';
import cm from 'core/commonMessages';

export type NewSLIDModalProps = {
  visible: boolean;
  addSlid: (slid: { id: string; description: string }) => Promise<any>;
  onClose: () => void;
};

type SlidForm = {
  slid: string;
  slidDescription: string;
};

const slidValidator = (value: string): boolean => {
  if (value.length !== 6) {
    return false;
  }
  const leading = value.substring(0, 2).toUpperCase();
  if (['SR', 'MH', 'SF', 'WH', 'OT'].indexOf(leading) !== -1) {
    // Division prefixed ids protected
    return false;
  }
  return !/^\d{6}$/.test(value); // All digits not allowed for now
};

const NewSLIDModal = ({ visible, addSlid, onClose }: NewSLIDModalProps) => {
  const { $t } = useIntl();
  const { register, handleSubmit, formState, reset } = useForm<SlidForm>();

  const { isDirty, isValid } = formState;
  const onSubmit = handleSubmit(async (data: SlidForm) => {
    await addSlid({ id: data.slid, description: data.slidDescription });
    // Reset form on successful submit.
    reset();
  });

  return (
    <form onSubmit={onSubmit} id="newSlidForm">
      <Modal visible={visible} escapable handleCloseBtn={onClose}>
        <Prompt
          title={$t(sm.newSlidModalTitle)}
          header={<ModalHeader />}
          footer={
            <ModalFooter>
              <Button
                text={$t(cm.ok)}
                type="primary"
                form="newSlidForm"
                htmlType="button"
                disabled={!isDirty || !isValid}
                onClick={onSubmit}
              />
              <Button text={$t(cm.cancel)} type="secondary" />
            </ModalFooter>
          }
        >
          <FormField>
            <InputField
              type="text"
              id="slid_new"
              label={$t(sm.newSlidModalInputId)}
              autoFocus
              {...register('slid', {
                required: true,
                validate: slidValidator,
              })}
            />
          </FormField>
          <FormField>
            <InputField
              type="text"
              id="slid_description"
              label={$t(sm.newSlidModalInputDescription)}
              {...register('slidDescription', {
                required: false,
                maxLength: 120,
              })}
            />
          </FormField>
        </Prompt>
      </Modal>
    </form>
  );
};

export default NewSLIDModal;
