import { defineMessages } from 'react-intl';

const messages = defineMessages({
  divisionSpecialityShop: {
    id: 'divisionSpecialityShop',
    defaultMessage: 'Division/Speciality Shop',
  },
  specialityShopRangeGroup: {
    id: 'specialityShopRangeGroup',
    defaultMessage: 'Speciality Shop/Range Group',
  },
  rangeGroupPA: {
    id: 'rangeGroupPA',
    defaultMessage: 'Range Group/PA',
  },
  rangeGroupSLID: {
    id: 'rangeGroupSLID',
    defaultMessage: 'Range Group/SL-ID',
  },
  division: {
    id: 'division',
    defaultMessage: 'Division',
  },
  specialityShop: {
    id: 'specialityShop',
    defaultMessage: 'Speciality Shop',
  },
  rangeGroup: {
    id: 'rangeGroup',
    defaultMessage: 'Range Group',
  },
  salesSpaceQuantityVolume: {
    id: 'salesSpaceQuantityVolume',
    defaultMessage: 'SSQ Volume (m3)',
  },
  plannedMaxDensity: {
    id: 'plannedMaxDensity',
    defaultMessage: 'Planned Max Density',
  },
  areaM2: {
    id: 'areaM2',
    defaultMessage: 'Area (m2)',
  },
  plannedFillingRate: {
    id: 'plannedFillingRate',
    defaultMessage: 'Planned Filling Rate (%)',
  },
  maxDensity: {
    id: 'maxDensity',
    defaultMessage: 'Max Density',
  },
  plannedDensity: {
    id: 'plannedDensity',
    defaultMessage: 'Planned Density',
  },
  selectDivisionTitle: {
    id: 'selectDivisionTitle',
    defaultMessage: 'Select Division',
  },
  selectDivisionBody: {
    id: 'selectDivisionBody',
    defaultMessage: 'Please select a Division.',
  },
  selectSpecialityShopTitle: {
    id: 'selectSpecialityShopTitle',
    defaultMessage: 'Select Speciality Shop',
  },
  selectSpecialityShopBody: {
    id: 'selectSpecialityShopBody',
    defaultMessage: 'Please select a Division and Speciality Shop.',
  },

  storeStructureItemName: {
    id: 'storeStructureItemName',
    defaultMessage: 'Name',
  },

  specialityShopsHeading: {
    id: 'specialityShopsHeading',
    defaultMessage: 'Connected Speciality Shops',
  },
  specialityShopsHelpHeader: {
    id: 'specialityShopsHelpHeading',
    defaultMessage: 'Connected Speciality Shops',
  },
  specialityShopsHelpBody: {
    id: 'specialityShopsHelpBody',
    defaultMessage: 'Help text for speciality shops.',
  },
  editSpecialityShopTitle: {
    id: 'editSpecialityShopTitle',
    defaultMessage: 'Edit Speciality Shop',
  },
  removeSpecialityShopTitle: {
    id: 'removeSpecialityShopTitle',
    defaultMessage: 'Remove Speciality Shop',
  },
  removeSpecialityShopBody: {
    id: 'removeSpecialityShopBody',
    defaultMessage: 'Are you sure you want to remove this Speciality Shop?',
  },
  addSpecialityShopTitle: {
    id: 'addSpecialityShopTitle',
    defaultMessage: 'Add Speciality Shop',
  },
  addSpecialityShopButton: {
    id: 'addSpecialityShop',
    defaultMessage: 'Add Speciality Shop',
  },

  rangeGroupsHeading: {
    id: 'rangeGroupsHeading',
    defaultMessage: 'Connected Range Groups',
  },
  rangeGroupsHelpHeader: {
    id: 'rangeGroupsHelpHeading',
    defaultMessage: 'Connected Range Groups',
  },
  rangeGroupsHelpBody: {
    id: 'rangeGroupsHelpBody',
    defaultMessage: 'Help text for speciality shops.',
  },
  editRangeGroupTitle: {
    id: 'editRangeGroupTitle',
    defaultMessage: 'Edit Range Group',
  },
  removeRangeGroupTitle: {
    id: 'removeRangeGroupTitle',
    defaultMessage: 'Remove Range Group',
  },
  removeRangeGroupBody: {
    id: 'removeRangeGroupBody',
    defaultMessage: 'Are you sure you want to remove this Range Group?',
  },
  addRangeGroupTitle: {
    id: 'addRangeGroupTitle',
    defaultMessage: 'Add Range Group',
  },
  addRangeGroupButton: {
    id: 'addRangeGroup',
    defaultMessage: 'Add Range Group',
  },

  paAssignedListTitle: {
    id: 'paAssignedListTitle',
    defaultMessage: 'Connected PAs',
  },
  paAssignedListTitleTooltip: {
    id: 'paAssignedListTitleTooltip',
    defaultMessage: 'Connected Product Areas',
  },
  paUnassignedListTitle: {
    id: 'paUnassignedListTitle',
    defaultMessage: 'Remaining PAs',
  },
  paUnassignedListTitleTooltip: {
    id: 'paUnassignedListTitleTooltip',
    defaultMessage: 'Remaining Product Areas',
  },

  slidAssignedListTitle: {
    id: 'slidAssignedListTitle',
    defaultMessage: 'Connected SL-IDs',
  },
  slidAssignedListTitleTooltip: {
    id: 'slidAssignedListTitleTooltip',
    defaultMessage: 'Connected Sales Location IDs',
  },
  slidUnassignedListTitle: {
    id: 'slidUnassignedListTitle',
    defaultMessage: 'Remaining SL-IDs',
  },
  slidUnassignedListTitleTooltip: {
    id: 'slidUnassignedListTitleTooltip',
    defaultMessage: 'Remaining Sales Location IDs',
  },
  newSlidButton: {
    id: 'newSlidButton',
    defaultMessage: 'New SL-ID',
  },
  newSlidModalTitle: {
    id: 'newSlidModalTitle',
    defaultMessage: 'SL-ID',
  },
  newSlidModalInputId: {
    id: 'newSlidModalInputId',
    defaultMessage: 'SL-ID',
  },
  newSlidModalInputDescription: {
    id: 'newSlidModalInputDescription',
    defaultMessage: 'SL-ID Description',
  },

  assignerAssignTooltip: {
    id: 'assignerAssignTooltip',
    defaultMessage: 'Add',
  },
  assignerUnassignTooltip: {
    id: 'assignerUnassignTooltip',
    defaultMessage: 'Remove',
  },
  assignerRemoveTooltip: {
    id: 'assignerRemoveTooltip',
    defaultMessage: 'Delete',
  },
});

const constraintMessages = defineMessages({
  DivisionAreaM2: {
    id: 'DivisionAreaM2',
    defaultMessage:
      'The Division area of {divisionAreaM2} m2 cannot be less than the sum of the areas of the Speciality Shops {specialityShopsAreaM2} m2',
  },
  UniqueName: {
    id: 'UniqueName',
    defaultMessage: 'The name {name} is already in use.',
  },
});

const useCaseMessages = {
  updateDivision: defineMessages({
    success: {
      id: 'updateDivisionSuccess',
      defaultMessage: 'Updated Division: {name}',
    },
    error: {
      id: 'updateDivisionError',
      defaultMessage: 'Error updating Division: {name}',
    },
  }),
  addSpecialityShop: defineMessages({
    success: {
      id: 'addSpecialityShopSuccess',
      defaultMessage: 'Added Speciality Shop: {name}',
    },
    error: {
      id: 'addSpecialityShopError',
      defaultMessage: 'Error adding Speciality Shop: {name}',
    },
  }),
  editSpecialityShop: defineMessages({
    success: {
      id: 'editSpecialityShopSuccess',
      defaultMessage: 'Renamed Speciality Shop: {name}',
    },
    error: {
      id: 'editSpecialityShopError',
      defaultMessage: 'Error renaming Speciality Shop: {currentName}',
    },
  }),
  removeSpecialityShop: defineMessages({
    success: {
      id: 'removeSpecialityShopSuccess',
      defaultMessage: 'Removed Speciality Shop: {name}',
    },
    error: {
      id: 'removeSpecialityShopError',
      defaultMessage: 'Error removing Speciality Shop: {name}',
    },
  }),
  toggleSpecialityShopVisibility: defineMessages({
    error: {
      id: 'toggleSpecialityShopVisibilityError',
      defaultMessage: 'Error hiding/showing Speciality Shop: {name}',
    },
  }),
  updateSpecialityShop: defineMessages({
    success: {
      id: 'updateSpecialityShop',
      defaultMessage: 'Updated Speciality Shop: {name}',
    },
    error: {
      id: 'updateSpecialityShopError',
      defaultMessage: 'Error updating Speciality Shop: {name}',
    },
  }),
  updateRangeGroup: defineMessages({
    success: {
      id: 'updateRangeGroupSuccess',
      defaultMessage: 'Updated Range Group: {name}',
    },
    error: {
      id: 'updateRangeGroupError',
      defaultMessage: 'Error updating Range Group: {name}',
    },
  }),
  addRangeGroup: defineMessages({
    success: {
      id: 'addRangeGroupSuccess',
      defaultMessage: 'Added Range Group: {name}',
    },
    error: {
      id: 'addRangeGroupError',
      defaultMessage: 'Error adding Range Group: {name}',
    },
  }),
  editRangeGroup: defineMessages({
    success: {
      id: 'editRangeGroupSuccess',
      defaultMessage: 'Renamed Range Group: {name}',
    },
    error: {
      id: 'editRangeGroupError',
      defaultMessage: 'Error renaming Range Group: {currentName}',
    },
  }),
  removeRangeGroup: defineMessages({
    success: {
      id: 'removeRangeGroupSuccess',
      defaultMessage: 'Removed Range Group: {name}',
    },
    error: {
      id: 'removeRangeGroupError',
      defaultMessage: 'Error removing Range Group: {name}',
    },
    Integrity: {
      id: 'removeRangeGroupIntegrity',
      defaultMessage:
        'Cannot hide Range Group {name}, because it has a Product Area or Sales Location connected to it.',
    },
  }),
  toggleRangeGroupVisibility: defineMessages({
    error: {
      id: 'toggleRangeGroupVisibilityError',
      defaultMessage: 'Error hiding/showing Range Group: {name}',
    },
    Integrity: {
      id: 'Integrity',
      defaultMessage:
        'Cannot show/hide Range Group, because it has a Product Area or Sales Location connected to it.',
    },
  }),
  addSlid: defineMessages({
    success: {
      id: 'addSlidSuccess',
      defaultMessage: 'Added SL-ID: {id}',
    },
    error: {
      id: 'addSlidError',
      defaultMessage: 'Error adding SL-ID: {id}',
    },
    UniqueName: {
      id: 'addSlidErrorUniqueName',
      defaultMessage: 'The SL-ID {id} is already in use.',
    },
  }),
  removeSlid: defineMessages({
    success: {
      id: 'removeSlidSuccess',
      defaultMessage: 'Removed SL-ID: {id}',
    },
    error: {
      id: 'removeSlidError',
      defaultMessage: 'Error removing SL-ID: {id}',
    },
  }),
};

const storeStructureMessages = {
  ...messages,
  ...{ constraints: constraintMessages },
  ...{ useCases: useCaseMessages },
};

export default storeStructureMessages;
