import { OperationType } from 'core/commonTypes';
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import log from 'loglevel';

export type PendingUpdateEntity = {
  id: string;
  type: OperationType;
  lastUpdated?: string;
  started: string;
};

const entityAdapter = createEntityAdapter<PendingUpdateEntity>();

const { selectAll } = entityAdapter.getSelectors();

const pendingUpdatesSlice = createSlice({
  name: 'pendingUpdates',
  initialState: entityAdapter.getInitialState(),

  reducers: {
    /**
     * Signals that a pending update has started.
     */
    startPendingUpdate(
      state,
      action: PayloadAction<{
        id: string;
        type: OperationType;
        lastUpdated?: string;
      }>
    ) {
      const { id, type, lastUpdated } = action.payload;
      entityAdapter.upsertOne(state, {
        id,
        type,
        lastUpdated,
        started: new Date().toISOString(),
      });

      log.debug('Added', type, 'operation:', id);
    },

    /**
     * Synchronizes state by comparing the value of `lastUpdated` for each pending update.
     */
    syncPendingUpdates(
      state,
      action: PayloadAction<{
        lastUpdated: Record<string, string>;
        prefix?: 'assignment' | 'division' | 'specialityShop';
      }>
    ) {
      const entities = selectAll(state);
      if (entities.length === 0) {
        return;
      }

      log.trace('Synchronizing pending updates');

      const { lastUpdated, prefix } = action.payload;

      const matches = entities.filter(
        ({ id, type, lastUpdated: pendingLastUpdated }) => {
          const currentLastUpdated = lastUpdated[id];
          if (currentLastUpdated === undefined && prefix) {
            const match = id.match(/^(\w+?):.+$/);
            if (match) {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const [_, idPrefix] = match;
              // This will match all pending Delete operations for the given prefix.
              return idPrefix === prefix && type === OperationType.DELETE;
            }
            return false;
          }

          log.debug(
            'Comparing',
            type,
            'operation:',
            id,
            pendingLastUpdated,
            currentLastUpdated
          );
          switch (type) {
            case OperationType.CREATE:
              return typeof currentLastUpdated === 'string';
            case OperationType.UPDATE:
            case OperationType.DELETE:
              return pendingLastUpdated !== currentLastUpdated;
            default:
              return false;
          }
        }
      );

      if (matches.length > 0) {
        matches.forEach(({ id, type }) => {
          log.debug('Synchronized', type, 'operation:', id);
          entityAdapter.removeOne(state, id);
        });
      }
    },

    /**
     * Cleans up any pending updates with expired timeouts.
     */
    cleanPendingUpdates(state, action: PayloadAction<{ timeout: number }>) {
      log.trace('Cleaning up pending updates');

      const { timeout } = action.payload;

      const matches = selectAll(state).filter(({ started }) => {
        const diff = new Date().getTime() - new Date(started).getTime();
        return diff > timeout;
      });

      if (matches.length > 0) {
        matches.forEach(({ id, type }) => {
          log.debug('Expired', type, 'operation:', id);
          entityAdapter.removeOne(state, id);
        });
      }
    },
  },
});

export const { startPendingUpdate, syncPendingUpdates, cleanPendingUpdates } =
  pendingUpdatesSlice.actions;

export const selectPendingUpdates = selectAll;

export default pendingUpdatesSlice;
