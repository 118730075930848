import React from 'react';
import Tabs, { Tab, TabPanel } from '@ingka/tabs';
import { useTabPathRoute } from 'hooks/routing';
import pm from 'views/Parameters/parametersMessages';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';
import withStoreStructure from 'components/WithStoreStructure';

export enum ParametersTabId {
  DEFAULT = 'default',
  GROUP = 'group',
  ARTICLE = 'article',
  F6 = 'f6',
}

const Parameters: React.FC = () => {
  const { $t } = useIntl();

  const tabs = [
    <Tab
      key="tab-1"
      text={$t(pm.defaultParameters)}
      tabPanelId={ParametersTabId.DEFAULT}
    />,
    <Tab
      key="tab-2"
      text={$t(pm.groupParameters)}
      tabPanelId={ParametersTabId.GROUP}
    />,
    <Tab
      key="tab-3"
      text={$t(pm.articleParameters)}
      tabPanelId={ParametersTabId.ARTICLE}
    />,
    <Tab
      key="tab-4"
      text={$t(pm.f6Parameters)}
      tabPanelId={ParametersTabId.F6}
    />,
  ];

  const tabPanels = [
    <TabPanel key="tab-1" tabPanelId={ParametersTabId.DEFAULT}>
      <Outlet />
    </TabPanel>,
    <TabPanel key="tab-2" tabPanelId={ParametersTabId.GROUP}>
      <Outlet />
    </TabPanel>,
    <TabPanel key="tab-3" tabPanelId={ParametersTabId.ARTICLE}>
      <Outlet />
    </TabPanel>,
    <TabPanel key="tab-4" tabPanelId={ParametersTabId.F6}>
      <Outlet />
    </TabPanel>,
  ];

  const [tab, onTabChanged] = useTabPathRoute(
    '/store/:buCode/parameters/:tabId',
    'tabId'
  );

  return (
    <Tabs
      tabs={tabs}
      tabPanels={tabPanels}
      defaultActiveTab={tab}
      onTabChanged={onTabChanged}
    />
  );
};

export default withStoreStructure(Parameters);
