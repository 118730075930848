import { defineMessages } from 'react-intl';

const messages = defineMessages({
  defaultParameters: {
    id: 'defaultParameters',
    defaultMessage: 'Default Parameters',
  },
  groupParameters: {
    id: 'groupParameters',
    defaultMessage: 'Group Parameters',
  },
  articleParameters: {
    id: 'articleParameters',
    defaultMessage: 'Parameters per article',
  },
  f6Parameters: {
    id: 'f6Parameters',
    defaultMessage: 'F6 Parameters',
  },
  storeLevelDefaultsTitle: {
    id: 'storeLevelDefaultsTitle',
    defaultMessage: 'Store Level Default Values',
  },
  divisionLevelDefaultValues: {
    id: 'divisionLevelDefaultValues',
    defaultMessage: 'Division Level Default Values',
  },
  hfbLevelDefaultValues: {
    id: 'hfbLevelDefaultValues',
    defaultMessage: 'HFB Level Default Values',
  },
  hfb: {
    id: 'hfb',
    defaultMessage: 'HFB',
  },
  highTurnoverMark: {
    id: 'highTurnoverMark',
    defaultMessage: 'Turnover rank DTFP+',
  },
  lowTurnoverMark: {
    id: 'lowTurnoverMark',
    defaultMessage: 'Turnover rank MIN',
  },
  turnOverMarkDftp: {
    id: 'turnOverMarkDftp',
    defaultMessage: 'Turnover rank DTFP',
  },
  approveParameterTooltip: {
    id: 'approveParameterTooltip',
    defaultMessage: 'Order critical parameter, click to confirm',
  },
  approveParameterTitle: {
    id: 'approveParameterTitle',
    defaultMessage: 'Approve change',
  },
  approveParameterBodyStart: {
    id: 'approveParameterBodyStart',
    defaultMessage:
      'This is an order critical parameter! A change must be approved by the responsible function.',
  },
  approveParameterBodyEnd: {
    id: 'approveParameterBodyEnd',
    defaultMessage: 'Do you want to continue?',
  },
  loadingError: {
    id: 'loadingError',
    defaultMessage: 'Failed to load parameters',
  },
  saving: {
    id: 'saving',
    defaultMessage: 'Saving updated parameters...',
  },
  /**
   * @deprecated
   */
  savingError: {
    id: 'savingError',
    defaultMessage: 'Failed to save updated parameters',
  },
  /**
   * @deprecated
   */
  savingSuccess: {
    id: 'savingSuccess',
    defaultMessage: 'Successfully updated parameters',
  },
  articleNumber: {
    id: 'articleNumber',
    defaultMessage: 'Art. No.',
  },
  parameterValues: {
    id: 'parameterValues',
    defaultMessage: 'Parameter Values',
  },
  parameterValue: {
    id: 'parameterValue',
    defaultMessage: 'Parameter Value',
  },
  article: {
    id: 'article',
    defaultMessage: 'Article',
  },
  parameter: {
    id: 'parameter',
    defaultMessage: 'Parameter',
  },
  filter: {
    id: 'filter',
    defaultMessage: 'Filter',
  },
  filterValue: {
    id: 'filterValue',
    defaultMessage: 'Filter value',
  },
  chooseParameter: {
    id: 'chooseParameter',
    defaultMessage: 'Choose parameter',
  },
  chooseFilter: {
    id: 'chooseFilter',
    defaultMessage: 'Choose filter',
  },
  chooseFilterValue: {
    id: 'chooseFilterValue',
    defaultMessage: 'Choose filter value',
  },
  divisionFilter: {
    id: 'divisionFilter',
    defaultMessage: 'Division',
  },
  specialityShopFilter: {
    id: 'specialityShopFilter',
    defaultMessage: 'Speciality shop',
  },
  hfbFilter: {
    id: 'hfb',
    defaultMessage: 'HFB',
  },
  rangeAreaFilter: {
    id: 'rangeAreaFilter',
    defaultMessage: 'Range area',
  },
  productAreaFilter: {
    id: 'productAreaFilter',
    defaultMessage: 'Product area',
  },

  salesMethodFilter: {
    id: 'salesMethodFilter',
    defaultMessage: 'Sales method',
  },
  salesMethod0: {
    id: 'salesMethod0',
    defaultMessage: '0 - Satellite',
  },
  salesMethod1: {
    id: 'salesMethod1',
    defaultMessage: '1 - Self serve',
  },
  salesMethod2: {
    id: 'salesMethod2',
    defaultMessage: '2 - Full Serve',
  },

  serviceClassFilter: {
    id: 'serviceClassFilter',
    defaultMessage: 'Service class',
  },
  serviceClass1: {
    id: 'serviceClass1',
    defaultMessage: 'Service class 1',
  },
  serviceClass2: {
    id: 'serviceClass2',
    defaultMessage: 'Service class 2',
  },
  serviceClass3: {
    id: 'serviceClass3',
    defaultMessage: 'Service class 3',
  },
  serviceClass4: {
    id: 'serviceClass4',
    defaultMessage: 'Service class 4',
  },
  serviceClass5: {
    id: 'serviceClass5',
    defaultMessage: 'Service class 5',
  },
  serviceClassFilterNotImplemented: {
    id: 'serviceClassFilterNotImplemented',
    defaultMessage: 'Filtering by Service class is not yet implemented.',
  },

  openChangeHistory: {
    id: 'openChangeHistory',
    defaultMessage: 'Open change history',
  },

  noArticlesTitle: {
    id: 'noArticlesTitle',
    defaultMessage: 'No articles',
  },
  noArticlesBody: {
    id: 'noArticlesBody',
    defaultMessage: 'No articles found for this filter.',
  },

  newParameterValue: {
    id: 'newParameterValue',
    defaultMessage: 'New parameter value',
  },

  confirmUpdateTitle: {
    id: 'confirmUpdateTitle',
    defaultMessage: 'Confirm Parameter update',
  },
  confirmUpdateBody: {
    id: 'confirmUpdateBody',
    defaultMessage:
      'Are you sure you want to update the Parameters to their new values?',
  },

  confirmResetTitle: {
    id: 'confirmResetTitle',
    defaultMessage: 'Confirm Parameter reset',
  },
  confirmResetBody: {
    id: 'confirmResetBody',
    defaultMessage:
      'Are you sure you want to reset the Parameters to their default values?',
  },

  oldValue: {
    id: 'oldValue',
    defaultMessage: 'Old value',
  },
  newValue: {
    id: 'newValue',
    defaultMessage: 'New value',
  },
  blankValue: {
    id: 'blankValue',
    defaultMessage: '-',
    description: 'Indicates a blank value',
  },

  f6Parameter: {
    id: 'f6Parameter',
    defaultMessage: 'F6 Parameter',
  },
  hfbParameter: {
    id: 'hfbParameter',
    defaultMessage: 'HFB Parameter',
  },
  divisionParameter: {
    id: 'divisionParameter',
    defaultMessage: 'Default Division Parameter',
  },
  storeParameter: {
    id: 'storeParameter',
    defaultMessage: 'Default Parameter',
  },
  itemParameter: {
    id: 'itemParameter',
    defaultMessage: 'Item Parameter',
  },
});

const constraintMessages = {
  // Placeholder, pending O14 validation refactor on backend
};

const useCaseMessages = {
  updateStoreParameters: defineMessages({
    error: {
      defaultMessage: 'Error updating Store Level Default Values',
      id: 'updateStoreParametersError',
    },
    success: {
      defaultMessage: 'Updated Store Level Default Values',
      id: 'updateStoreParametersSuccess',
    },
  }),
  updateDivisionParameters: defineMessages({
    error: {
      defaultMessage: 'Error updating Division Level Default Values: {name}',
      id: 'updateDivisionParametersError',
    },
    success: {
      defaultMessage: 'Updated Division Level Default Values: {name}',
      id: 'updateDivisionParametersSuccess',
    },
  }),
  updateHfbParameters: defineMessages({
    error: {
      defaultMessage: 'Error updating HFB Level Default Values: {name}',
      id: 'updateHfbParametersError',
    },
    success: {
      defaultMessage: 'Updated HFB Level Default Values: {name}',
      id: 'updateHfbParametersSuccess',
    },
  }),
  updateGroupParameter: defineMessages({
    error: {
      defaultMessage: 'Error updating Group parameter: {parameter}',
      id: 'updateGroupParametersError',
    },
    success: {
      defaultMessage: 'Updated Group parameter: {parameter}',
      id: 'updateGroupParametersSuccess',
    },
  }),
  updateArticleParameters: defineMessages({
    error: {
      defaultMessage: 'Error updating parameters for Article: {articleNumber}',
      id: 'updateArticleParametersError',
    },
    success: {
      defaultMessage: 'Updated parameters for Article: {articleNumber}',
      id: 'updateArticleParametersSuccess',
    },
  }),
  updateF6Parameters: defineMessages({
    error: {
      defaultMessage: 'Error updating F6 Parameters',
      id: 'updateF6ParametersError',
    },
    success: {
      defaultMessage: 'Updated F6 Parameters',
      id: 'updateF6ParametersSuccess',
    },
  }),
};

const parametersMessages = {
  ...messages,
  ...{ constraints: constraintMessages },
  ...{ useCases: useCaseMessages },
};

export default parametersMessages;
