import { DependencyList, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useFeedback } from '../contexts/feedback/FeedbackContext';
import {
  FeedbackMessageDescriptors,
  MessageDescriptors,
} from '../contexts/feedback/types';
import cm from 'core/commonMessages';
import { Primitive } from 'core/types';
import { RtkQueryConfig } from 'core/rtkQuery';

export type AsyncFeedbackOptions = {
  /**
   * The MessageDescriptors for translation messages.
   *
   * The implementation looks up the `error`, `success` and constraint keys, in the order specified.
   */
  messages?: MessageDescriptors;

  /**
   * Values passed to `formatMessage`.
   *
   * Use this to provide context information that may not be present in the backend `ErrorResponse`. For example, when
   * deleting an item the backend may not provide the name.
   */
  values?: Record<string, Primitive>;

  /**
   * Optional entity ID, used to prevent displaying multiple messages for the same entity.
   */
  id?: Primitive;

  /**
   * Throws the underlying Error. By default, the implementation considers an error to be "handled", by displaying an
   * error message.
   *
   * This setting is intended for use cases that require custom success/error handling.
   *
   * @default false
   */
  throwError?: boolean;
};

/**
 * Wraps async operations in a callback that provides user feedback.
 *
 * Callback can return a `boolean`, with `false` indicating that the operation was cancelled.
 *
 * To generate a callback that does not take any arguments, use `void` as the generic type.
 * E.g. `useAsyncFeedback<void>(() => { ... }, [...])`
 *
 * The eslint rule `react-hooks/exhaustive-deps` should be configured with the name of this function.
 */
export function useAsyncFeedback<T>(
  callback: (arg: T) => Promise<void | boolean>,
  deps: DependencyList,
  {
    messages = { error: cm.errorOccurred },
    values = {},
    id,
    throwError = false,
  }: AsyncFeedbackOptions = {}
) {
  const { showFeedback } = useFeedback();

  const { $t } = useIntl();

  return useCallback(
    async (arg: T) => {
      let translationValues: any = values;
      if (typeof arg === 'object') {
        translationValues = {
          ...translationValues,
          ...arg,
        };
      }
      const feedbackMessageDescriptors = Array.isArray(messages)
        ? messages[0]
        : (messages as FeedbackMessageDescriptors);

      try {
        const result = await callback(arg);
        if (typeof result === 'boolean' && !result) {
          return;
        }

        if (
          RtkQueryConfig.instance.isMhsEnabled &&
          feedbackMessageDescriptors?.pendingSuccess
        ) {
          const summary = $t(
            feedbackMessageDescriptors.pendingSuccess,
            translationValues
          );
          showFeedback({ isError: false, summary, id });
        } else if (feedbackMessageDescriptors?.success) {
          const summary = $t(
            feedbackMessageDescriptors.success,
            translationValues
          );
          showFeedback({ isError: false, summary, id });
        }
      } catch (error: any) {
        const summary = $t(feedbackMessageDescriptors.error, translationValues);
        showFeedback({
          isError: true,
          summary,
          id,
          details: {
            error: error.data ?? error,
            status: error.status,
            messageDescriptors: messages,
            values: translationValues,
          },
        });
        if (throwError) {
          throw error;
        }
      }
    },
    // callback() is not in the list of dependencies, because that is the function that is memoized.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...deps, showFeedback]
  );
}
