import React from 'react';
import StoreStructureList from 'views/StoreStructure/components/StoreStructureList';
import { StoreStructureType } from 'core/commonTypes';
import { useIntl } from 'react-intl';
import sm from 'views/StoreStructure/storeStructureMessages';
import Help from 'components/Help/Help';
import Button from '@ingka/button';
import {
  useAddRangeGroupAction,
  useRangeGroupListItems,
} from 'views/StoreStructure/hooks/rangeGroup';
import { useStoreStructureCrudState } from 'hooks/storeStructure';
import { useHasUpdateStoreStructurePrivilege } from 'hooks/privilege';
import { lock } from '@ingka/ssr-icon/icons';

export type SpecialityShopEditorProps = {
  divisionId: string;
  specialityShopId: string;
};

const RangeGroupListEditor: React.FC<SpecialityShopEditorProps> = ({
  divisionId,
  specialityShopId,
}) => {
  const items = useRangeGroupListItems(divisionId, specialityShopId);

  const addRangeGroup = useAddRangeGroupAction(divisionId, specialityShopId);

  const { isCreating } = useStoreStructureCrudState(
    StoreStructureType.RangeGroup
  );

  const canUpdate = useHasUpdateStoreStructurePrivilege();

  const { $t } = useIntl();

  return (
    <>
      {items && (
        <>
          <Help
            header={$t(sm.rangeGroupsHelpHeader)}
            body={$t(sm.rangeGroupsHelpBody)}
          />
          <h3>{$t(sm.rangeGroupsHeading)}</h3>
          <StoreStructureList items={items} />
          <div className="slm-tae">
            <Button
              text={$t(sm.addRangeGroupButton)}
              onClick={addRangeGroup}
              loading={isCreating.item}
              disabled={!canUpdate}
              ssrIcon={!canUpdate ? lock : undefined}
            />
          </div>
        </>
      )}
    </>
  );
};

export default RangeGroupListEditor;
