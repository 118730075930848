import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { cross } from '@ingka/ssr-icon/icons';
import './consentPopup.scss';
import Button from '@ingka/button';
import m from 'core/commonMessages';

type ConsentPopupProps = {
  hasSetConsent: boolean;
  givesConsent: boolean;
  setConsent: (boolean) => void;
};

const ConsentPopup: React.FC<ConsentPopupProps> = ({
  hasSetConsent,
  givesConsent,
  setConsent,
}) => {
  const { $t } = useIntl();
  const [showPopup, setShowPopup] = useState(
    () =>
      !(
        hasSetConsent ||
        window.sessionStorage.getItem('slm_ignored_consent_popup') === 'T'
      )
  );
  useEffect(() => {
    if (hasSetConsent) {
      setShowPopup(false);
    }
  }, [hasSetConsent]);

  const closePopup = useCallback(() => {
    setShowPopup(false);
    window.sessionStorage.setItem('slm_ignored_consent_popup', 'T');
  }, []);
  const onGiveConsent = useCallback(
    e => {
      setShowPopup(false);
      setConsent(true);
    },
    [setConsent]
  );
  const onDenyConsent = useCallback(
    e => {
      setShowPopup(false);
      setConsent(false);
    },
    [setConsent]
  );

  if (!showPopup) {
    return <></>;
  }

  return (
    <div className="slm-cookie-consent-popup">
      <div className="content">
        <h4>{$t(m.cookieConsentPopupTitle)}</h4>
        <p>{$t(m.cookieConsentPopupBodyText)}</p>
        <Button
          onClick={closePopup}
          ssrIcon={cross}
          type={'plain'}
          small
          className="close-btn"
        />
        <Button
          onClick={onDenyConsent}
          text={$t(m.cookieConsentPopupEssentialButton)}
          type={'secondary'}
        />
        <Button
          onClick={onGiveConsent}
          text={$t(m.cookieConsentPopupAllowStatisticsButton)}
          type={'primary'}
        />
      </div>
    </div>
  );
};

export default ConsentPopup;
