import React, { useMemo } from 'react';
import { CommonTableProps } from 'views/Maintain/types';
import AttributesTable from 'views/Maintain/components/Result/AttributesTable';
import { useIntl } from 'react-intl';
import {
  deliveryTypeReasonMessages,
  ssqInfoMessages,
} from 'views/Maintain/tableMessages';
import { SearchResultItemDto } from 'apis/backendApi';
import { useIntegerCell, useEnumArrayCell } from 'views/Maintain/hooks/cells';
import { useMerchandisingTerms } from 'views/Maintain/hooks/language';

const SsqInfoTable: React.FC<CommonTableProps> = ({ data }) => {
  const { $t } = useIntl();
  const integerCell = useIntegerCell();
  const deliveryTypeReasonCell = useEnumArrayCell({
    tooltipMessages: deliveryTypeReasonMessages,
  });
  const recMerchCell = useMerchandisingTerms('normal');

  const columns = useMemo(
    () => [
      {
        accessorFn: (data: SearchResultItemDto) => data.ssqInfo?.minSSQ,
        id: 'ssqInfo_minSsq',
        meta: {
          headerMessages: {
            text: $t(ssqInfoMessages.minSsq),
            tooltip: $t(ssqInfoMessages.minSsqTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) => data.ssqInfo?.dtfp,
        id: 'ssqInfo_dtfp',
        meta: {
          headerMessages: {
            text: $t(ssqInfoMessages.dtfp),
            tooltip: $t(ssqInfoMessages.dtfpTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) => data.ssqInfo?.dtfpPlus,
        id: 'ssqInfo_dtfpPlus',
        meta: {
          headerMessages: {
            text: $t(ssqInfoMessages.dtfpPlus),
            tooltip: $t(ssqInfoMessages.dtfpPlusTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) => data.ssqInfo?.maxSSQ,
        id: 'ssqInfo_maxSsq',
        meta: {
          headerMessages: {
            text: $t(ssqInfoMessages.maxSsq),
            tooltip: $t(ssqInfoMessages.maxSsqTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) => data.ssqInfo?.rssq,
        id: 'ssqInfo_rssq',
        meta: {
          headerMessages: {
            text: $t(ssqInfoMessages.rssq),
            tooltip: $t(ssqInfoMessages.rssqTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) => data.ssqInfo?.assq,
        id: 'ssqInfo_assq',
        meta: {
          headerMessages: {
            text: $t(ssqInfoMessages.assq),
            tooltip: $t(ssqInfoMessages.assqTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) => data.ssqInfo?.deliveryType,
        id: 'ssqInfo_deliveryType',
        meta: {
          headerMessages: {
            text: $t(ssqInfoMessages.deliveryType),
            tooltip: $t(ssqInfoMessages.deliveryTypeTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.ssqInfo?.deliveryTypeReason,
        id: 'ssqInfo_deliveryTypeReason',
        meta: {
          headerMessages: {
            text: $t(ssqInfoMessages.deliveryTypeReason),
            tooltip: $t(ssqInfoMessages.deliveryTypeReasonTooltip),
          },
        },
        cell: deliveryTypeReasonCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) => data.ssqInfo?.mdq,
        id: 'ssqInfo_mdq',
        meta: {
          headerMessages: {
            text: $t(ssqInfoMessages.mdq),
            tooltip: $t(ssqInfoMessages.mdqTooltip),
          },
        },
        cell: integerCell,
      },
      {
        accessorFn: (data: SearchResultItemDto) => data.ssqInfo?.recMerch,
        id: 'ssqInfo_recMerch',
        meta: {
          headerMessages: {
            text: $t(ssqInfoMessages.recMerch),
            tooltip: $t(ssqInfoMessages.recMerchTooltip),
          },
        },
        cell: props => recMerchCell(props.getValue()),
      },
    ],
    [$t, deliveryTypeReasonCell, integerCell, recMerchCell]
  );
  return <AttributesTable data={data} columns={columns} footer />;
};

export default SsqInfoTable;
