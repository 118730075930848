import { defineMessages } from 'react-intl';
import { AlertType, NotificationType } from 'apis/backendApi';

const messages = defineMessages({
  actionsTitle: {
    id: 'actionsTitle',
    defaultMessage: 'Alerts & Warnings',
  },
  alertsTitle: {
    id: 'actionsAlertsTitle',
    defaultMessage: 'Alerts',
  },
  warningsTitle: {
    id: 'actionsWarningsTitle',
    defaultMessage: 'Warnings',
  },
  notificationsTitle: {
    id: 'actionsNotificationsTitle',
    defaultMessage: 'Notifications',
  },
  division: {
    id: 'actionsDivision',
    defaultMessage: 'Division',
  },
  alertsCount: {
    id: 'actionsAlertsCount',
    defaultMessage: 'Alerts',
  },
  warningsCount: {
    id: 'actionsWarningsCount',
    defaultMessage: 'Warnings',
  },
  notificationsCount: {
    id: 'actionsNotificationsCount',
    defaultMessage: 'Notif.',
  },
  productArea: {
    id: 'actionsProductArea',
    defaultMessage: 'PA',
  },
  productAreaSelect: {
    id: 'actionsProductAreaSelect',
    defaultMessage: 'Product Area',
  },
  type: {
    id: 'actionsType',
    defaultMessage: 'Type',
  },
  daysOpen: {
    id: 'actionsAlertDays',
    defaultMessage: '{days} days open',
    description:
      'Indicates how long an Alert or Notification is open, where {days} is the number of days.',
  },
  daysUntil: {
    id: 'actionsDaysUntil',
    defaultMessage: '{days} days until',
    description:
      'Indicates when a Warning changes into an Alert, where {days} is the number of days.',
  },
  filterMessage: {
    id: 'actionsFilterMessage',
    defaultMessage: 'Showing {filter} of {total} items',
    description:
      'Indicates the number of items when applying a filter to Alerts, Warnings or Notifications. {filter} is the number of items displayed, {total} is the total number of items.',
  },
});

export default messages;

const alertTypeMessages = defineMessages({
  [AlertType.ASSQ_BIGGER_THAN_MAX]: {
    id: 'actionsAlertAssqBiggerThanMax',
    defaultMessage: 'ASSQ bigger than max',
  },
  [AlertType.ASSQ_LESS_THAN_MIN]: {
    id: 'actionsAlertAssqBiggerThanMax',
    defaultMessage: 'ASSQ less than min',
  },
  [AlertType.UNPLANNED_ARTICLE]: {
    id: 'actionsAlertUnplannedArticle',
    defaultMessage: 'Unplanned article',
  },
  [AlertType.LOCAL_LOCAL_SHORTAGE]: {
    id: 'actionsAlertLocalLocalShortage',
    defaultMessage: 'Local local shortage',
  },
});

const notificationTypeMessages = defineMessages({
  [NotificationType.PRODUCT]: {
    id: 'actionsNotificationProduct',
    defaultMessage: 'Product',
  },
  [NotificationType.PRICE]: {
    id: 'actionsNotificationPrice',
    defaultMessage: 'Price',
  },
  [NotificationType.DISPLAY]: {
    id: 'actionsNotificationDisplay',
    defaultMessage: 'Display',
  },
  [NotificationType.FLEXIBILITY]: {
    id: 'actionsNotificationFlexibility',
    defaultMessage: 'Flexibility',
  },
  [NotificationType.SALES_SPACE_SIZE]: {
    id: 'actionsNotificationSalesSpaceSize',
    defaultMessage: 'Sales Space Size',
  },
  [NotificationType.OVERFILL]: {
    id: 'actionsNotificationOverfill',
    defaultMessage: 'Overfill',
  },
  [NotificationType.PLACEMENT]: {
    id: 'actionsNotificationPlacement',
    defaultMessage: 'Placement',
  },
  [NotificationType.EFFICIENT_REPL]: {
    id: 'actionsNotificationEfficientRepl',
    defaultMessage: 'Efficient Repl.',
  },
  [NotificationType.FILLING_RATE]: {
    id: 'actionsNotificationFillingRate',
    defaultMessage: 'Filling Rate',
  },
  [NotificationType.OTHER]: {
    id: 'actionsNotificationOther',
    defaultMessage: 'Other',
  },
});

export { alertTypeMessages, notificationTypeMessages };
