import { defineMessages } from 'react-intl';

const messages = defineMessages({
  timeView: {
    id: 'timeView',
    defaultMessage: 'Time View',
  },
  snapshotView: {
    id: 'snapshotView',
    defaultMessage: 'Snapshot View',
  },
  topic: {
    id: 'topic',
    defaultMessage: 'Topic',
  },
  dataPromptTitle: {
    id: 'dataPromptTitle',
    defaultMessage: 'Pick data',
  },
  dataPromptBody: {
    id: 'dataPromptBody',
    defaultMessage: 'Pick what data to view',
  },
  directflowOption: {
    id: 'directflowOption',
    defaultMessage: 'Direct Flow (%)',
  },
  spaceneedOption: {
    id: 'spaceneedOption',
    defaultMessage: 'Space Need',
  },
  expectedturnoverrevenueOption: {
    id: 'expectedturnoverrevenueOption',
    defaultMessage: 'Expected Net Turnover Revenue',
  },
  expectedturnoverrevenuem2Option: {
    id: 'expectedturnoverrevenuem2Option',
    defaultMessage: 'Expected Net Turnover Revenue / m²',
  },
  maxdensityOption: {
    id: 'maxdensityOption',
    defaultMessage: 'Max Density',
  },
  commercialefficiencyOption: {
    id: 'commercialefficiencyOption',
    defaultMessage: 'Commercial Efficiency',
  },
  densityandfillingrateOption: {
    id: 'densityandfillingrateOption',
    defaultMessage: 'Density & Filling Rate',
  },
  expandAll: {
    id: 'expandAll',
    defaultMessage: 'Expand all',
  },
  collapseAll: {
    id: 'collapseAll',
    defaultMessage: 'Collapse all',
  },

  // Space Need
  backpanelNeed: {
    id: 'backpanelNeed',
    defaultMessage: 'Backpanel Need',
  },
  backpanelUtilization: {
    id: 'backpanelUtilization',
    defaultMessage: 'Backpanel Utilization',
  },
  halfPalletNeed: {
    id: 'halfPalletNeed',
    defaultMessage: 'Half Pallet Need',
  },
  halfPalletUtilization: {
    id: 'halfPalletUtilization',
    defaultMessage: 'Half Pallet Utilization',
  },
  fullPalletNeed: {
    id: 'fullPalletNeed',
    defaultMessage: 'Full Pallet Need',
  },
  fullPalletUtilization: {
    id: 'fullPalletUtilization',
    defaultMessage: 'Full Pallet Utilization',
  },
  longPalletNeed: {
    id: 'longPalletNeed',
    defaultMessage: 'Long Pallet Need',
  },
  longPalletUtilization: {
    id: 'longPalletUtilization',
    defaultMessage: 'Long Pallet Utilization',
  },
  handstackLocationNeed: {
    id: 'handstackLocationNeed',
    defaultMessage: 'Handstack Location Need',
  },
  handstackLocationUtilization: {
    id: 'handstackLocationUtilization',
    defaultMessage: 'Handstack Location Utilization',
  },
  spaceNeed: {
    id: 'spaceNeed',
    defaultMessage: 'Space Need',
  },
  spaceUtilization: {
    id: 'spaceUtilization',
    defaultMessage: 'Space Utilization',
  },

  // Commercial effiency
  directFlowPercentage: {
    id: 'directFlowPercentage',
    defaultMessage: 'Direct Flow (%)',
  },
  rssqFfPercentage: {
    id: 'rssqFfPercentage',
    defaultMessage: 'RSSQ FF (%)',
  },
  movesSoldM3: {
    id: 'movesSoldM3',
    defaultMessage: 'Moves / Sold m3',
  },
  unplanned: {
    id: 'unplanned',
    defaultMessage: 'Unplanned',
  },
  underMinSsq: {
    id: 'underMinSsq',
    defaultMessage: 'Under Min SSQ',
  },
  overMaxSsq: {
    id: 'overMaxSsq',
    defaultMessage: 'Over Max SSQ',
  },
  numLocalLocal: {
    id: 'numLocalLocal',
    defaultMessage: 'Num Local Local',
  },
  overstock: {
    id: 'overstock',
    defaultMessage: 'Overstock',
  },
  limitedSupply: {
    id: 'limitedSupply',
    defaultMessage: 'Limited Supply',
  },
  noSupply: {
    id: 'noSupply',
    defaultMessage: 'No Supply',
  },
  rtsImpl: {
    id: 'rtsImpl',
    defaultMessage: 'RTS Impl.',
  },
  flexAvg: {
    id: 'flexAvg',
    defaultMessage: 'Flex Avg.',
  },
  flexShare: {
    id: 'flexShare',
    defaultMessage: 'Flex Share',
  },
  expNetTurnRev: {
    id: 'expNetTurnRev',
    defaultMessage: 'Exp.Net Turn Rev.',
  },
  expNetTurnRevM2: {
    id: 'expNetTurnRevM2',
    defaultMessage: 'Exp.Net Turn Rev.m2',
  },

  // Density and filling rate
  area: {
    id: 'area',
    defaultMessage: 'Area (m2)',
  },
  plannedVolume: {
    id: 'plannedVolume',
    defaultMessage: 'Planned Volume (m3)',
  },
  ssqVolume: {
    id: 'ssqVolume',
    defaultMessage: 'SSQ Volume',
  },
  actualVolume: {
    id: 'actualVolume',
    defaultMessage: 'Actual Volume (m3)',
  },
  plannedMaxDensity: {
    id: 'plannedMaxDensity',
    defaultMessage: 'Planned Max Density',
  },
  maxDensity: {
    id: 'maxDensity',
    defaultMessage: 'Max Density',
  },
  plannedFillingRate: {
    id: 'plannedFillingRate',
    defaultMessage: 'Planned Filling Rate (%)',
  },
  fillingRate: {
    id: 'fillingRate',
    defaultMessage: 'Filling Rate (%)',
  },
  plannedDensity: {
    id: 'plannedDensity',
    defaultMessage: 'Planned Density',
  },
  actualDensity: {
    id: 'actualDensity',
    defaultMessage: 'Actual Density',
  },
});

export default messages;
