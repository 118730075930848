import React, { useMemo } from 'react';

type NowrapProps = {
  /**
   * The value to display. Will be converted to a string.
   */
  value: any | null | undefined;

  /**
   * The minimum number of tokens around which to assign `nowrap`.
   *
   * @default 1
   */
  tokens?: number;
  children?: React.ReactNode;
};

/**
 * Inserts `white-space: nowrap` style around a configurable number of tokens in a value.
 */
const Nowrap: React.FC<NowrapProps> = ({ value, tokens = 1, children }) => {
  const { nowrap, rest } = useMemo(() => {
    const text = value?.toString() ?? '';
    const parts = text.split(/\s+/);
    const nowrap = parts.slice(0, tokens).join(' ');
    const rest = (
      parts.length > tokens ? parts.slice(tokens, parts.length) : []
    ).join(' ');
    return { nowrap, rest: rest.length > 0 ? ` ${rest}` : undefined };
  }, [value, tokens]);

  return (
    <>
      <span style={{ whiteSpace: 'nowrap' }}>
        {children} {nowrap}
      </span>
      {rest}
    </>
  );
};

export default Nowrap;
