import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import em from 'views/Import/exportMessages';
import Select, { Option } from '@ingka/select';
import { useSelectOnChange } from 'hooks/select';
import { useUserPreferences } from 'hooks/userPreferences';
import { useAppDispatch } from 'hooks/redux';
import { setExcelExportFont } from 'core/redux/userPreferencesSlice';

type FontOption = {
  name: string;
  size: number;
};

const FONT_OPTIONS: FontOption[] = [
  { name: 'Calibri', size: 12 },
  { name: 'Calibri', size: 14 },
  { name: 'Calibri', size: 16 },
  { name: 'Calibri', size: 18 },
  { name: 'Aptos', size: 12 },
  { name: 'Aptos', size: 14 },
  { name: 'Aptos', size: 16 },
  { name: 'Aptos', size: 18 },
];

export const FontSelect: React.FC<{ disabled: boolean }> = ({ disabled }) => {
  const { excelExportFont } = useUserPreferences();
  const index = FONT_OPTIONS.findIndex(
    x => `${x.name}.${x.size}` === excelExportFont
  );
  const [selectedFont, setSelectedFont] = useState(index !== -1 ? index : 0);

  const dispatch = useAppDispatch();

  const onFontSizeChange = useSelectOnChange('0', newValue => {
    const index = Number.parseInt(newValue);
    setSelectedFont(index);
    const font = FONT_OPTIONS[index];

    const value = `${font.name}.${font.size}`;
    dispatch(setExcelExportFont(value));
  });

  const { $t } = useIntl();

  return (
    <Select
      id="font"
      className="slm-mb-100"
      onChange={onFontSizeChange}
      value={selectedFont.toString()}
      hintText={null}
      label={$t(em.fontLabel)}
      disabled={disabled}
    >
      {FONT_OPTIONS.map((option, index) => (
        <Option
          key={index}
          name={$t(em.fontOption, {
            name: option.name,
            size: option.size,
          })}
          value={index.toString()}
        />
      ))}
    </Select>
  );
};
