import { defineMessages } from 'react-intl';
import {
  LocationType,
  MerchandisingSolution,
  SearchActionFilterEnum,
  SearchArticleStatusEnum,
  SearchAvailabilityEnum,
  SearchFlowTypeEnum,
  SearchLifeCycleEnum,
  SearchProblemCodeEnum,
  SearchSalesSteeringEnum,
  SearchSortOrderEnum,
} from 'apis/backendApi';

const messages = defineMessages({
  maintainRangeElementInput: {
    id: 'maintainRangeElementInput',
    defaultMessage: 'HFB/RA/PA',
  },
  maintainRangeElementInputTooltip: {
    id: 'maintainRangeElementInputTooltip',
    defaultMessage: 'Home Furnishing business, Range Area or Product Area',
  },
  maintainFreetextInput: {
    id: 'maintainFreetextInput',
    defaultMessage: 'Article Number/Name / SL-ID',
  },
  maintainSortTypeInputLabel: {
    id: 'maintainSortTypeInputLabel',
    defaultMessage: 'Sort type',
  },
  maintainFilterFlowTypeLabel: {
    id: 'maintainFilterFlowTypeLabel',
    defaultMessage: 'Flow Type',
  },
  maintainFilterArticleStatusLabel: {
    id: 'maintainFilterArticleStatusLabel',
    defaultMessage: 'Article Status',
  },
  maintainFilterLifecycleLabel: {
    id: 'maintainFilterLifecycleLabel',
    defaultMessage: 'Lifecycle',
  },
  maintainFilterProblemCodeLabel: {
    id: 'maintainFilterProblemCodeLabel',
    defaultMessage: 'Problem Code',
  },
  maintainFilterActionFilterLabel: {
    id: 'maintainFilterActionFilterLabel',
    defaultMessage: 'Alerts & Warnings',
  },
  maintainFilterPicksLabel: {
    id: 'maintainFilterPicksLabels',
    defaultMessage: 'Picks',
  },
  maintainFilterAvailabilityLabel: {
    id: 'maintainFilterAvailabilityLabel',
    defaultMessage: 'Availability',
  },
  maintainFilterSalesSteeringLabel: {
    id: 'maintainFilterSalesSteeringLabel',
    defaultMessage: 'Sales Steering',
  },

  maintainResultTabLabelTimeView: {
    id: 'maintainResultTabLabelTimeView',
    defaultMessage: 'Time View',
  },

  maintainResultDirectFlowSummary: {
    id: 'maintainResultDirectFlowSummary',
    defaultMessage: 'Direct Flow %',
  },

  maintainResultTabLabelTimeViewAssq: {
    id: 'maintainResultTabLabelTimeViewAssq',
    defaultMessage: 'ASSQ',
  },
  maintainResultTabLabelTimeViewRssq: {
    id: 'maintainResultTabLabelTimeViewRssq',
    defaultMessage: 'RSSQ',
  },
  maintainResultTabLabelTimeViewWeeks: {
    id: 'maintainResultTabLabelTimeViewWeeks',
    defaultMessage: 'Weeks',
  },

  maintainResultTabLabelCommercialInfo: {
    id: 'maintainResultTabLabelCommercialInfo',
    defaultMessage: 'Commercial Info',
  },
  maintainResultTabLabelSpaceNeed: {
    id: 'maintainResultTabLabelSpaceNeed',
    defaultMessage: 'Space Need',
  },
  maintainResultTabLabelGoodsFlow: {
    id: 'maintainResultTabLabelGoodsFlow',
    defaultMessage: 'Goods Flow',
  },
  maintainResultTabLabelInternalRepl: {
    id: 'maintainResultTabLabelInternalRepl',
    defaultMessage: 'Internal Replenishment',
  },
  maintainResultTabLabelProductInfo: {
    id: 'maintainResultTabLabelProductInfo',
    defaultMessage: 'Product Info',
  },
  maintainResultTabLabelSsqInfo: {
    id: 'maintainResultTabLabelSsqInfo',
    defaultMessage: 'SSQ Info',
  },
  maintainResultTabLabelDimensions: {
    id: 'maintainResultTabLabelDimensions',
    defaultMessage: 'Dimensions',
  },
  maintainResultTabLabelSellOutInfo: {
    id: 'maintainResultTabLabelSellOutInfo',
    defaultMessage: 'Sell Out Info',
  },

  maintainAvinPopupTableTitleAvin: {
    id: 'maintainAvinPopupTableTitleAvin',
    defaultMessage: 'Availability',
    description: 'Title for table of availability information in AVIN popup.',
  },
  maintainAvinPopupTableTitleSI: {
    id: 'maintainAvinPopupTableTitleSI',
    defaultMessage: 'Local Sales Information',
    description: 'Title for table of local sales information in AVIN popup.',
  },
  maintainAvinPopupTableHeaderMessage: {
    id: 'maintainAvinPopupTableHeaderMessage',
    defaultMessage: 'Message',
    description:
      'Header on table column for message in AVIN popup. Both for Availability and Sales info.',
  },
  maintainAvinPopupTableHeaderFrom: {
    id: 'maintainAvinPopupTableHeaderFrom',
    defaultMessage: 'From',
    description:
      'Header on table column for from/starting date in AVIN popup. Both for Availability and Sales info.',
  },
  maintainAvinPopupTableHeaderTo: {
    id: 'maintainAvinPopupTableHeaderTo',
    defaultMessage: 'To',
    description:
      'Header on table column for end/to date in AVIN popup. For Availability.',
  },

  maintainMissingDataTooltip: {
    id: 'maintainMissingDataTooltip',
    defaultMessage: 'This field is under development and may be missing',
    description:
      'Tooltip on data tables for any cell that is marked with missing data',
  },

  maintainAssignmentQuantity: {
    id: 'maintainAssignmentQuantity',
    defaultMessage: 'Quantity',
    description: 'Header on Quantity field',
  },
  maintainAssignmentProductDominance: {
    id: 'maintainAssignmentProductDominance',
    defaultMessage: 'Prod. Dom.',
    description: 'Header on Product Dominance field',
  },
  maintainAssignmentMerchandisingSolution: {
    id: 'maintainAssignmentMerchandisingSolution',
    defaultMessage: 'Merch. Sol.',
    description: 'Header on Merchandising Solution field',
  },
  maintainAssignmentHomebase: {
    id: 'maintainAssignmentHomebase',
    defaultMessage: 'Homebase',
    description: 'Header on Homebase field',
  },
  maintainAssignmentPrimary: {
    id: 'maintainAssignmentPrimary',
    defaultMessage: 'Primary',
    description: 'Header on Primary field',
  },
  maintainAssignmentManualPick: {
    id: 'maintainAssignmentManualPick',
    defaultMessage: 'Manual Pick',
    description: 'Header on Manual Pick Location field',
  },
  maintainAssignmentSalesLocation: {
    id: 'maintainAssignmentSalesLocation',
    defaultMessage: 'Sales Location ID',
    description: 'Header on Sales Location selection field',
  },
  maintainAssignmentValidFrom: {
    id: 'maintainAssignmentValidFrom',
    defaultMessage: 'Valid From',
    description: 'Header on Assigned sales location valid from date field',
  },

  maintainAssignmentNewLocationTitle: {
    id: 'maintainAssignmentNewLocationTitle',
    defaultMessage: 'Assign Location',
    description: 'Modal title for assigning to new sales location',
  },
  maintainAssignmentNewLocationButton: {
    id: 'maintainAssignmentNewLocationButton',
    defaultMessage: 'Add Location',
    description: 'Button text for creating a new assigned sales location',
  },
  maintainAssignmentWarehouseWarningTitle: {
    id: 'maintainAssignmentWarehouseWarningTitle',
    defaultMessage: 'Warehouse location',
    description: 'Warning title for Warehouse selection',
  },
  maintainAssignmentWarehouseWarningBody: {
    id: 'maintainAssignmentWarehouseWarningBody',
    defaultMessage: 'Location in Warehouse, are you sure?',
    description: 'Warning message for Warehouse selection',
  },
  maintainAssignmentFireclassTitle: {
    id: 'maintainAssignmentFireclassTitle',
    defaultMessage: 'Fire class',
    description:
      'Modal title text to notify user, that article fire class is insufficient for the location',
  },
  maintainAssignmentFireclassBody: {
    id: 'maintainAssignmentFireclassBody',
    defaultMessage: 'Too low fire class! Continue?',
    description:
      'Modal body text to notify user, that article fire class is insufficient for the location',
  },
  maintainAssignmentAlreadyExistsTitle: {
    id: 'maintainAssignmentAlreadyExistsTitle',
    defaultMessage: 'Already exists',
    description:
      'Modal title text to notify user, that sales location already exists',
  },
  maintainAssignmentAlreadyExistsBody: {
    id: 'maintainAssignmentAlreadyExistsBody',
    defaultMessage: 'Sales Location already exists.',
    description:
      'Modal body text to notify user, that sales location already exists',
  },

  maintainUnassignTitle: {
    id: 'maintainUnassignTitle',
    defaultMessage: 'Unassign Location',
  },
  maintainUnassignLocation: {
    id: 'maintainUnassignLocation',
    defaultMessage: 'Location',
  },
  maintainUnassignDate: {
    id: 'maintainUnassignDate',
    defaultMessage: 'Unassign Valid From',
  },
  maintainUnassignConfirmSgfTitle: {
    id: 'maintainUnassignConfirmSgfTitle',
    defaultMessage: 'Unassign Location',
  },
  maintainUnassignConfirmSgfBody: {
    id: 'maintainUnassignConfirmSgfBody',
    defaultMessage:
      'Do you want to delete the replenishment task and unassign the location?',
  },

  maintainItemDetailsAvailabilityTooltip: {
    id: 'maintainItemDetailsAvailabilityTooltip',
    defaultMessage: 'Availability Information',
    description:
      'Tooltip for button to open window for item availability information',
  },
  maintainItemDetailsCalculateTooltip: {
    id: 'maintainItemDetailsCalculateTooltip',
    defaultMessage: 'Recalculate',
    description: 'Tooltip for button start a recalculation of item information',
  },
  maintainItemDetailsCalculateTitle: {
    id: 'maintainItemDetailsCalculateTitle',
    defaultMessage: 'Recalculate',
    description: 'Title for modal to start a recalculation of item information',
  },
  maintainItemDetailsCalculateBody: {
    id: 'maintainItemDetailsCalculateBody',
    defaultMessage: 'Start recalculation?',
    description:
      'Body text for modal to start a recalculation of item information',
  },

  maintainItemDetailsO14ParameterTooltip: {
    id: 'maintainItemDetailsO14ParameterTooltip',
    defaultMessage: 'O14 Parameters',
    description: 'Tooltip for button to go to O14 Parameters',
  },

  maintainItemDetailsProductArea: {
    id: 'maintainItemDetailsProductArea',
    defaultMessage: 'PA',
    description: 'Field header for Product Area',
  },
  maintainItemDetailsProductAreaTooltip: {
    id: 'maintainItemDetailsProductAreaTooltip',
    defaultMessage: 'Product Area',
    description: 'Tooltip on field header for Product Area',
  },
  maintainSalesStartDateShort: {
    id: 'maintainSalesStartDateShort',
    defaultMessage: 'SSD',
    description: 'Field header for Sales start date',
  },
  maintainSalesStartDate: {
    id: 'maintainSalesStartDate',
    defaultMessage: 'Sales start date',
    description: 'Field header for Sales start date',
  },
  maintainSalesStartDateTooltip: {
    id: 'maintainSalesStartDateTooltip',
    defaultMessage: 'Sales start date',
    description: 'Tooltip on field header for Sales start date',
  },
  maintainEndSalesDateShort: {
    id: 'maintainSalesEndDateShort',
    defaultMessage: 'EDS',
    description: 'Field/table header for Sales End Date',
  },
  maintainEndSalesDate: {
    id: 'maintainSalesEndDate',
    defaultMessage: 'Sales End Date',
    description: 'Field/table header for Sales End Date',
  },
  maintainEndSalesDateTooltip: {
    id: 'maintainSalesEndDateTooltip',
    defaultMessage: 'Sales End Date',
    description: 'Tooltip on field/table header for Sales End Date',
  },
  maintainItemDetailsFlexQty: {
    id: 'maintainItemDetailsFlexQty',
    defaultMessage: 'Flex Qty',
    description: 'Field header for Flex quantity',
  },
  maintainItemDetailsFlexQtyTooltip: {
    id: 'maintainItemDetailsFlexQtyTooltip',
    defaultMessage: 'Flex quantity',
    description: 'Tooltip on field header for Flex quantity',
  },
  maintainItemDetailsFlex: {
    id: 'maintainItemDetailsFlex',
    defaultMessage: 'Flex.',
    description: 'Field header for toggleable flexible space allocation',
  },
  maintainItemDetailsFlexTooltip: {
    id: 'maintainItemDetailsFlexTooltip',
    defaultMessage: 'Flexibility',
    description:
      'Tooltip on field header for toggleable flexible space allocation',
  },
  maintainItemDetailsAuto: {
    id: 'maintainItemDetailsAuto',
    defaultMessage: 'Auto',
    description:
      'Field header for flexible toggleable automation based refilling',
  },
  maintainItemDetailsAutoTooltip: {
    id: 'maintainItemDetailsAutoTooltip',
    defaultMessage: 'Automatic refilling',
    description:
      'Tooltip on field header for toggleable automation based refilling',
  },
  maintainItemDetailsCommercialQty: {
    id: 'maintainItemDetailsCommercialQty',
    defaultMessage: 'Comm.Qty',
    description: 'Field header for toggleable commercial quantity setting',
  },
  maintainItemDetailsCommercialQtyTooltip: {
    id: 'maintainItemDetailsCommercialQtyTooltip',
    defaultMessage: 'Commercial Quantity',
    description:
      'Tooltip on field header for toggleable commercial quantity setting',
  },
  maintainItemDetailsTotalAndSgfStock: {
    id: 'maintainItemDetailsTotalAndSgfStock',
    defaultMessage: 'Total Stock (SGF)',
    description: 'Field header for both total stock and store goods flow stock',
  },
  maintainItemDetailsTotalAndSgfStockTooltip: {
    id: 'maintainItemDetailsTotalAndSgfStockTooltip',
    defaultMessage: 'Total Stock and (SGF stock)',
    description:
      'Tooltip on field header for both total stock and store goods flow stock',
  },
  maintainItemDetailsPalletQty: {
    id: 'maintainItemDetailsPalletQty',
    defaultMessage: 'Pal.Qty.',
    description: 'Field header for Pallet Quantity',
  },
  maintainItemDetailsPalletQtyTooltip: {
    id: 'maintainItemDetailsPalletQtyTooltip',
    defaultMessage: 'Pallet Quantity',
    description: 'Tooltip on field header for Pallet Quantity',
  },
  maintainItemDetailsSoldAndAverage: {
    id: 'maintainItemDetailsSoldAndAverage',
    defaultMessage: 'Sold LW / Avg',
    description: 'Field header for combined sold last week and average sales',
  },
  maintainItemDetailsSoldAndAverageTooltip: {
    id: 'maintainItemDetailsSoldAndAverageTooltip',
    defaultMessage: 'Quantity Sold Last Week / Average sales',
    description:
      'Tooltip on field header for combined sold last week and average sales',
  },
  maintainItemDetailsLastInventoryDate: {
    id: 'maintainItemDetailsLastInventoryDate',
    defaultMessage: 'Last Inv. Date',
    description: 'Field header for Last Inventory Date',
  },
  maintainItemDetailsLastInventoryDateTooltip: {
    id: 'maintainItemDetailsLastInventoryDateTooltip',
    defaultMessage: 'Last Inventory Date',
    description: 'Tooltip on field header for Last Inventory Date',
  },
  maintainItemDetailsWeekNumber: {
    id: 'maintainItemDetailsWeekNumber',
    defaultMessage: 'Week No',
    description: 'Header for week table data, Week Number',
  },
  maintainItemDetailsWeekNumberTooltip: {
    id: 'maintainItemDetailsWeekNumberTooltip',
    defaultMessage: 'Week Number',
    description: 'Tooltip on header for week table data, Week Number',
  },
  maintainItemDetailsLTV: {
    id: 'maintainItemDetailsLTV',
    defaultMessage: 'LTV',
    description: 'Header for week table data, Long Term Values',
  },
  maintainItemDetailsLTVTooltip: {
    id: 'maintainItemDetailsLTVTooltip',
    defaultMessage: 'Long Term Values',
    description: 'Tooltip on header for week table data, Long Term Values',
  },
  maintainItemDetailsMaxOnHand: {
    id: 'maintainItemDetailsMaxOnHand',
    defaultMessage: 'MaxOH',
    description: 'Header for week table data, Max On Hand',
  },
  maintainItemDetailsMaxOnHandTooltip: {
    id: 'maintainItemDetailsMaxOnHandTooltip',
    defaultMessage: 'Max on Hand',
    description: 'Tooltip on header for week table data, Max on Hand',
  },

  maintainItemDetailsQuantity: {
    id: 'maintainItemDetailsQuantity',
    defaultMessage: 'Qty',
    description: 'Row header for sales location assignment, Quantity',
  },
  maintainItemDetailsQuantityTooltip: {
    id: 'maintainItemDetailsQuantityTooltip',
    defaultMessage: 'Quantity',
    description:
      'Tooltip on row header for sales location assignment, Quantity',
  },
  maintainItemDetailsProductDominance: {
    id: 'maintainItemDetailsProductDominance',
    defaultMessage: 'Prod. Dom',
    description: 'Row header for sales location assignment, Product Dominance',
  },
  maintainItemDetailsProductDominanceTooltip: {
    id: 'maintainItemDetailsProductDominanceTooltip',
    defaultMessage: 'Product Dominance',
    description:
      'Tooltip on row header for sales location assignment, Product Dominance',
  },
  maintainItemDetailsMerchSolution: {
    id: 'maintainItemDetailsMerchSolution',
    defaultMessage: 'Merch. Sol.',
    description:
      'Row header for sales location assignment, Merchandising Solution',
  },
  maintainItemDetailsMerchSolutionTooltip: {
    id: 'maintainItemDetailsMerchSolutionTooltip',
    defaultMessage: 'Merchandising Solution',
    description:
      'Tooltip on row header for sales location assignment, Merchandising Solution',
  },
  maintainItemDetailsAllocRatio: {
    id: 'maintainItemDetailsAllocRatio',
    defaultMessage: 'Alloc. Ratio',
    description: 'Row header for sales location assignment, Allocation Ratio',
  },
  maintainItemDetailsAllocRatioTooltip: {
    id: 'maintainItemDetailsAllocRatioTooltip',
    defaultMessage: 'Allocation Ratio',
    description:
      'Tooltip on row header for sales location assignment, Allocation Ratio',
  },
  maintainItemDetailsLocationType: {
    id: 'maintainItemDetailsLocationType',
    defaultMessage: 'Prim. / Homeb.',
    description: 'Row header for sales location assignment, Location Type',
  },
  maintainItemDetailsLocationTypeTooltip: {
    id: 'maintainItemDetailsLocationTypeTooltip',
    defaultMessage: 'Primary / Homebase',
    description:
      'Tooltip on row header for sales location assignment, Location Type',
  },
  maintainItemDetailsPickLocation: {
    id: 'maintainItemDetailsPickLocation',
    defaultMessage: 'Pick Location',
    description: 'Row header for sales location assignment, Pick Location',
  },
  maintainItemDetailsPickLocationTooltip: {
    id: 'maintainItemDetailsPickLocationTooltip',
    defaultMessage: 'Pick Location',
    description:
      'Tooltip on row header for sales location assignment, Pick Location',
  },

  maintainItemHistoryHeaderArticle: {
    id: 'maintainItemHistoryHeaderArticle',
    defaultMessage: 'Article-level change history',
    description:
      'Header on item history for table, for data affecting the item',
  },
  maintainItemHistoryHeaderSalesLocation: {
    id: 'maintainItemHistoryHeaderSalesLocation',
    defaultMessage: 'Sales Location-level change history',
    description:
      'Header on item history for table, for data affecting sales locations connected to the item',
  },
  maintainItemHistorySlid: {
    id: 'maintainItemHistorySlid',
    defaultMessage: 'SL-ID',
    description: '',
  },
  maintainItemHistorySlidTooltip: {
    id: 'maintainItemHistorySlidTooltip',
    defaultMessage: 'Sales Location ID',
    description: '',
  },
  maintainItemHistoryFromDate: {
    id: 'maintainItemHistoryFromDate',
    defaultMessage: 'From Date',
    description: '',
  },
  maintainItemHistoryFromDateTooltip: {
    id: 'maintainItemHistoryFromDateTooltip',
    defaultMessage: 'From Date',
    description: '',
  },
  maintainItemHistoryAction: {
    id: 'maintainItemHistoryAction',
    defaultMessage: 'Action',
    description: '',
  },
  maintainItemHistoryActionTooltip: {
    id: 'maintainItemHistoryActionTooltip',
    defaultMessage: 'Action',
    description: '',
  },
  maintainItemHistoryActionCreate: {
    id: 'maintainItemHistoryActionCreate',
    defaultMessage: 'Created',
    description: '',
  },
  maintainItemHistoryActionUpdate: {
    id: 'maintainItemHistoryActionUpdate',
    defaultMessage: 'Updated',
    description: '',
  },
  maintainItemHistoryActionDelete: {
    id: 'maintainItemHistoryActionDelete',
    defaultMessage: 'Deleted',
    description: '',
  },
  maintainItemHistoryShowApplicationEvents: {
    id: 'maintainItemHistoryShowApplicationEvents',
    defaultMessage: 'Show application events',
    description:
      'Label for checkbox showing whether to display application events',
  },
});

const searchSortTypesMessages = defineMessages({
  maintainSortAscendingArticleName: {
    id: 'maintainSortAscendingArticleName',
    defaultMessage: 'Asc. Art. Name',
    description: 'Article search sorting, by article name ascending',
    enumValue: SearchSortOrderEnum.ASCENDING_ARTICLE_NAME,
  },
  maintainSortExpForecastRevAvg: {
    id: 'maintainSortExpForecastRevAvg',
    defaultMessage: 'Exp. Forec. Rev. Avg.',
    description: 'Article search sorting, by average expected forecast revenue',
    enumValue: SearchSortOrderEnum.EXPECTED_FORECAST_REVENUE_AVG,
    hidden: true,
  },
  maintainSortMovesPerSoldM3: {
    id: 'maintainSortMovesPerSoldM3',
    defaultMessage: 'Moves per sold m³',
    description: 'Article search sorting, by moves per sold cubic meter',
    enumValue: SearchSortOrderEnum.MOVES_PER_SOLD_M3,
    hidden: true,
  },
  maintainSortAscendingFillingRate: {
    id: 'maintainSortAscendingFillingRate',
    defaultMessage: 'Asc. Filling Rate',
    description: 'Article search sorting, by filling rate ascending',
    enumValue: SearchSortOrderEnum.ASCENDING_FILLING_RATE,
    hidden: true,
  },
  maintainSortCalcSellOutDate: {
    id: 'maintainSortCalcSellOutDate',
    defaultMessage: 'Calc Sell-out Date',
    description: 'Article search sorting, by calculated sell-out date',
    enumValue: SearchSortOrderEnum.CALCULATED_SELL_OUT_DATE,
    hidden: true,
  },
  maintainSortCalcRemainVolume: {
    id: 'maintainSortCalcRemainVolume',
    defaultMessage: 'Calc Remain m³ at EDS',
    description:
      'Article search sorting, by calculated remaining volume at EDS',
    enumValue: SearchSortOrderEnum.CALCULATED_REMAINING_M3_AT_EDS,
    hidden: true,
  },
});

const searchProblemCodeOptionMessages = defineMessages({
  maintainFilterProblemCodeNewProblem: {
    id: 'maintainFilterProblemCodeNewProblem',
    defaultMessage: 'New Problem',
    description: 'Article search filter Problem Code, option New Problem',
    enumValue: SearchProblemCodeEnum.NEW_PROBLEM,
  },
  maintainFilterProblemCodeExistingProblem: {
    id: 'maintainFilterProblemCodeExistingProblem',
    defaultMessage: 'Existing Problem',
    description: 'Article search filter Problem Code, option Existing problem',
    enumValue: SearchProblemCodeEnum.EXISTING_PROBLEM,
  },
  maintainFilterProblemCodeSolved: {
    id: 'maintainFilterProblemCodeSolved',
    defaultMessage: 'Solved',
    description: 'Article search filter Problem Code, option Solved',
    enumValue: SearchProblemCodeEnum.SOLVED,
  },
});

const searchLifecycleOptionMessages = defineMessages({
  maintainFilterLifecycleNews: {
    id: 'maintainFilterLifecycleNews',
    defaultMessage: 'News',
    description: 'Article search filter Lifecycle, option News',
    enumValue: SearchLifeCycleEnum.NEWS,
  },
  maintainFilterLifecycleRunning: {
    id: 'maintainFilterLifecycleRunning',
    defaultMessage: 'Running',
    description: 'Article search filter Lifecycle, option Running',
    enumValue: SearchLifeCycleEnum.RUNNING,
  },
  maintainFilterLifecycleActDiscontinued: {
    id: 'maintainFilterLifecycleActDiscontinued',
    defaultMessage: 'Actual Discontinued',
    description: 'Article search filter Lifecycle, option Actual Discontinued.',
    enumValue: SearchLifeCycleEnum.ACTUAL_DISCONTINUED,
  },
  maintainFilterLifecyclePastDiscontinued: {
    id: 'maintainFilterLifecyclePastDiscontinued',
    defaultMessage: 'Past Discontinued',
    description: 'Article search filter Lifecycle, option Past Discontinued',
    enumValue: SearchLifeCycleEnum.PAST_DISCONTINUED,
  },
  maintainFilterLifecycleAllDiscontinued: {
    id: 'maintainFilterLifecycleAllDiscontinued',
    defaultMessage: 'All Discontinued',
    description: 'Article search filter Lifecycle, option All Discontinued',
    enumValue: SearchLifeCycleEnum.ALL_DISCONTINUED,
  },
  maintainFilterLifecycleNewsDiscontinued: {
    id: 'maintainFilterLifecycleNewsDiscontinued',
    defaultMessage: 'News + Discontinued',
    description:
      'Article search filter Lifecycle, option News plus Discontinued',
    enumValue: SearchLifeCycleEnum.NEWS_PLUS_DISCONTINUED,
  },
});

const searchFlowTypeOptionMessages = defineMessages({
  maintainFilterFlowTypeIndirect: {
    id: 'maintainFilterFlowTypeIndirect',
    defaultMessage: 'Indirect',
    description: 'Article search filter flow type option Indirect flow',
    enumValue: SearchFlowTypeEnum.INDIRECT,
  },
  maintainFilterFlowTypeDirect: {
    id: 'maintainFilterFlowTypeDirect',
    defaultMessage: 'Direct',
    description: 'Article search filter flow type option Direct flow',
    enumValue: SearchFlowTypeEnum.DIRECT,
  },
});

const searchArticleStatusOptionMessages = defineMessages({
  maintainFilterArticleStatusPlanned: {
    id: 'maintainFilterArticleStatusPlanned',
    defaultMessage: 'Planned',
    description: 'Article search filter Article Status option Planned',
    enumValue: SearchArticleStatusEnum.PLANNED,
  },
  maintainFilterArticleStatusUnplanned: {
    id: 'maintainFilterArticleStatusUnplanned',
    defaultMessage: 'Unplanned',
    description: 'Article search filter Article Status option Unplanned',
    enumValue: SearchArticleStatusEnum.UNPLANNED,
  },
  maintainFilterArticleStatusUpcoming: {
    id: 'maintainFilterArticleStatusUpcoming',
    defaultMessage: 'Upcoming Changes',
    description: 'Article search filter Article Status option Upcoming Changes',
    enumValue: SearchArticleStatusEnum.UPCOMING_CHANGES,
  },
  maintainFilterArticleStatusNosales: {
    id: 'maintainFilterArticleStatusNosales',
    defaultMessage: 'No Sales Location',
    description:
      'Article search filter Article Status option No Sales Location',
    enumValue: SearchArticleStatusEnum.NO_SALES_LOCATION,
  },
  maintainFilterArticleStatusLessassigned: {
    id: 'maintainFilterArticleStatusLessassigned',
    defaultMessage: 'ASSQ < RSSQ',
    description:
      'Article search filter Article Status option less assigned SSQ than recommended',
    enumValue: SearchArticleStatusEnum.ASSQ_LESS_THAN_RSSQ,
  },
});

const searchActionFilterOptionMessages = defineMessages({
  maintainFilterActionFilterAlertsAll: {
    id: 'maintainFilterActionFilterAlertsAll',
    defaultMessage: 'All Alerts',
    description: 'Article search filter Alerts & Warnings, option: All Alerts',
    enumValue: SearchActionFilterEnum.ALERTS_ALL,
  },
  maintainFilterActionFilterAlertsAssqLessThanMin: {
    id: 'maintainFilterActionFilterAlertsAssqLessThanMin',
    defaultMessage: '- Less than Min',
    description:
      'Article search filter Alerts & Warnings, option: Alerts -> Less than Min',
    enumValue: SearchActionFilterEnum.ALERTS_ASSQ_LESS_THAN_MIN,
  },
  maintainFilterActionFilterAlertsAssqBiggerThanMax: {
    id: 'maintainFilterActionFilterAlertsAssqBiggerThanMax',
    defaultMessage: '- Bigger than Max',
    description:
      'Article search filter Alerts & Warnings, option: Alerts -> Bigger than Max',
    enumValue: SearchActionFilterEnum.ALERTS_ASSQ_BIGGER_THAN_MAX,
  },
  maintainFilterActionFilterAlertsUnplanned: {
    id: 'maintainFilterActionFilterAlertsUnplanned',
    defaultMessage: '- Unplanned',
    description:
      'Article search filter Alerts & Warnings, option: Alerts -> Unplanned',
    enumValue: SearchActionFilterEnum.ALERTS_UNPLANNED,
  },
  maintainFilterActionFilterAlertsLocalLocalShortage: {
    id: 'maintainFilterActionFilterAlertsLocalLocalShortage',
    defaultMessage: '- Local Local Shortage',
    description:
      'Article search filter Alerts & Warnings, option: Alerts -> Local Local Shortage',
    enumValue: SearchActionFilterEnum.ALERTS_LOCAL_LOCAL_SHORTAGE,
  },
  maintainFilterActionFilterNotifications: {
    id: 'maintainFilterActionFilterNotifications',
    defaultMessage: 'Notifications',
    description:
      'Article search filter Alerts & Warnings, option: Notifications',
    enumValue: SearchActionFilterEnum.NOTIFICATIONS_ALL,
  },
  maintainFilterActionFilterNotificationsAndAlerts: {
    id: 'maintainFilterActionFilterNotificationsAndAlerts',
    defaultMessage: 'Notifications & Alerts',
    description:
      'Article search filter Alerts & Warnings, option: Notifications & Alerts',
    enumValue: SearchActionFilterEnum.NOTIFICATIONS_AND_ALERTS,
  },
  maintainFilterActionFilterWarningsAll: {
    id: 'maintainFilterActionFilterWarningsAll',
    defaultMessage: 'All Warnings',
    description:
      'Article search filter Alerts & Warnings, option: All Warnings',
    enumValue: SearchActionFilterEnum.WARNINGS_ALL,
  },
  maintainFilterActionFilterAssqLessThanMin: {
    id: 'maintainFilterActionFilterAssqLessThanMin',
    defaultMessage: '- Less than Min',
    description:
      'Article search filter Alerts & Warnings, option: Warnings -> Less than Min',
    enumValue: SearchActionFilterEnum.WARNINGS_ASSQ_LESS_THAN_MIN,
  },
  maintainFilterActionFilterWarningsAssqBiggerThanMax: {
    id: 'maintainFilterActionFilterWarningsAssqBiggerThanMax',
    defaultMessage: '- Bigger than Max',
    description:
      'Article search filter Alerts & Warnings, option: Warnings -> Bigger than Max',
    enumValue: SearchActionFilterEnum.WARNINGS_ASSQ_BIGGER_THAN_MAX,
  },
  maintainFilterActionFilterWarningsUnplanned: {
    id: 'maintainFilterActionFilterWarningsUnplanned',
    defaultMessage: '- Unplanned',
    description:
      'Article search filter Alerts & Warnings, option: Warnings -> Unplanned',
    enumValue: SearchActionFilterEnum.WARNINGS_UNPLANNED,
  },
});

const searchPicksOptionMessages = {
  maintainFilterPicksTooHigh: {
    id: 'maintainFilterPicksTooHigh',
    defaultMessage: 'Too high',
    description: 'Article search filter Picks, option: Too High',
  },
};

const searchAvailabilityOptionMessages = {
  maintainFilterAvailabilityLocOverstock: {
    id: 'maintainFilterAvailabilityLocOverstock',
    defaultMessage: 'Loc. Overstock',
    description: 'Article search filter Availability, option: Loc. Overstock',
    enumValue: SearchAvailabilityEnum.LOC_OVERSTOCK,
  },
  maintainFilterAvailabilityNoSupply: {
    id: 'maintainFilterAvailabilityNoSupply',
    defaultMessage: 'No Supply',
    description: 'Article search filter Availability, option: No Supply',
    enumValue: SearchAvailabilityEnum.NO_SUPPLY,
  },
  maintainFilterAvailabilityLimSupply: {
    id: 'maintainFilterAvailabilityLimSupply',
    defaultMessage: 'Lim. Supply',
    description: 'Article search filter Availability, option: Lim. Supply',
    enumValue: SearchAvailabilityEnum.LIM_SUPPLY,
  },
};

const searchSalesSteeringOptionMessages = {
  maintainFilterSalesSteeringFourAcesAndKing: {
    id: 'maintainFilterSalesSteeringFourAcesAndKing',
    defaultMessage: '4 A & 1 K.',
    description:
      'Article search filter Sales Steering, option: 4 Aces and a King',
    enumValue: SearchSalesSteeringEnum.FOUR_ACES_AND_KING,
  },
  maintainFilterSalesSteeringFamilyPrice: {
    id: 'maintainFilterSalesSteeringFamilyPrice',
    defaultMessage: 'Family Pr.',
    description: 'Article search filter Sales Steering, option: Family Price',
    enumValue: SearchSalesSteeringEnum.FAMILY_PRICE,
  },
  maintainFilterSalesSteeringLocalPrice: {
    id: 'maintainFilterSalesSteeringLocalPrice',
    defaultMessage: 'Local Pr',
    description: 'Article search filter Sales Steering, option: Local Price',
    enumValue: SearchSalesSteeringEnum.LOCAL_PRICE,
  },
  maintainFilterSalesSteeringActivity: {
    id: 'maintainFilterSalesSteeringActivity',
    defaultMessage: 'Activity',
    description: 'Article search filter Sales Steering, option: Activity',
    enumValue: SearchSalesSteeringEnum.ACTIVITY,
    hidden: true, // Not available on backend yet.
  },
};

const itemDetailsTableMerchandisingShortHandMessages =
  defineMessages<MerchandisingSolution>({
    [MerchandisingSolution.S_RTS]: {
      id: 'maintainDetailsTableMerchandisingSRTS',
      defaultMessage: 'SR',
      description:
        'Extra short abbreviation for Merchandising solution value: Shelf - Ready To Sell',
    },
    [MerchandisingSolution.F_RTS]: {
      id: 'maintainDetailsTableMerchandisingFRTS',
      defaultMessage: 'FR',
      description:
        'Extra short abbreviation for Merchandising solution value: Floor - Ready To Sell',
    },
    [MerchandisingSolution.S_NotRTS]: {
      id: 'maintainDetailsTableMerchandisingSNRTS',
      defaultMessage: 'SN',
      description:
        'Extra short abbreviation for Merchandising solution value: Shelf - NOT Ready To Sell',
    },
    [MerchandisingSolution.F_NotRTS]: {
      id: 'maintainDetailsTableMerchandisingFNRTS',
      defaultMessage: 'FN',
      description:
        'Extra short abbreviation for Merchandising solution value: Floor - NOT Ready To Sell',
    },
  });

const itemDetailsTableMerchandisingTooltipMessages =
  defineMessages<MerchandisingSolution>({
    [MerchandisingSolution.S_RTS]: {
      id: 'maintainDetailsTableMerchandisingSRTSTooltip',
      defaultMessage: 'Shelf - Ready To Sell (SR)',
      description:
        'Full text description for Merchandising solution value: Shelf - Ready To Sell',
    },
    [MerchandisingSolution.F_RTS]: {
      id: 'maintainDetailsTableMerchandisingFRTSTooltip',
      defaultMessage: 'Floor - Ready To Sell (FR)',
      description:
        'Full text description for Merchandising solution value: Floor - Ready To Sell',
    },
    [MerchandisingSolution.S_NotRTS]: {
      id: 'maintainDetailsTableMerchandisingSNRTSTooltip',
      defaultMessage: 'Shelf - Not Ready To Sell (SN)',
      description:
        'Full text description for Merchandising solution value: Shelf - NOT Ready To Sell',
    },
    [MerchandisingSolution.F_NotRTS]: {
      id: 'maintainDetailsTableMerchandisingFNRTSTooltip',
      defaultMessage: 'Floor - Not Ready To Sell (FN)',
      description:
        'Full text description for Merchandising solution value: Floor - NOT Ready To Sell',
    },
  });

const itemDetailsTableMerchandisingRegularMessages =
  defineMessages<MerchandisingSolution>({
    [MerchandisingSolution.S_RTS]: {
      id: 'maintainResultTableHeaderRecMerch_S_RTS',
      defaultMessage: 'S-RTS',
      description:
        'Abbreviation for Merchandising solution value: Shelf - Ready To Sell',
    },
    [MerchandisingSolution.F_RTS]: {
      id: 'maintainResultTableHeaderRecMerch_F_RTS',
      defaultMessage: 'F-RTS',
      description:
        'Abbreviation for Merchandising solution value: Floor - Ready To Sell',
    },
    [MerchandisingSolution.S_NotRTS]: {
      id: 'maintainResultTableHeaderRecMerch_S_NotRTS',
      defaultMessage: 'S-Not RTS',
      description:
        'Abbreviation for Merchandising solution value: Shelf - NOT Ready To Sell',
    },
    [MerchandisingSolution.F_NotRTS]: {
      id: 'maintainResultTableHeaderRecMerch_F_NotRTS',
      defaultMessage: 'F-Not RTS',
      description:
        'Abbreviation for Merchandising solution value: Floor - NOT Ready To Sell',
    },
  });

const merchandisingMessages = {
  short: itemDetailsTableMerchandisingShortHandMessages,
  normal: itemDetailsTableMerchandisingRegularMessages,
  full: itemDetailsTableMerchandisingTooltipMessages,
};

const itemDetailsTableLocationShortHandMessages = defineMessages<LocationType>({
  [LocationType.NORMAL]: {
    id: 'maintainDetailsTableLocationNormal',
    defaultMessage: 'n',
    description:
      'Article details view, shorthand text for Location Type for sales location assignment, option: Normal/regular location',
  },
  [LocationType.PRIMARY]: {
    id: 'maintainDetailsTableLocationPrimary',
    defaultMessage: 'P',
    description:
      'Article details view, shorthand text for Location Type for sales location assignment, option: Primary location',
  },
  [LocationType.HOMEBASE]: {
    id: 'maintainDetailsTableLocationHomebase',
    defaultMessage: 'H',
    description:
      'Article details view, shorthand text for Location Type for sales location assignment, option: Homebase location',
  },
  [LocationType.HOMEBASE_PRIMARY]: {
    id: 'maintainDetailsTableLocationHomebasePrimary',
    defaultMessage: 'P / H',
    description:
      'Article details view, shorthand text for Location Type for sales location assignment, option: Homebase and primary location',
  },
});

const itemDetailsTableLocationTooltipMessages = defineMessages<LocationType>({
  [LocationType.NORMAL]: {
    id: 'maintainDetailsTableLocationNormalTooltip',
    defaultMessage: 'Normal',
    description:
      'Article details view, tooltip text for Location Type for sales location assignment, option: Normal/regular location',
  },
  [LocationType.PRIMARY]: {
    id: 'maintainDetailsTableLocationPrimaryTooltip',
    defaultMessage: 'Primary',
    description:
      'Article details view, tooltip text for Location Type for sales location assignment, option: Primary location',
  },
  [LocationType.HOMEBASE]: {
    id: 'maintainDetailsTableLocationHomebaseTooltip',
    defaultMessage: 'Homebase',
    description:
      'Article details view, tooltip text for Location Type for sales location assignment, option: Homebase location',
  },
  [LocationType.HOMEBASE_PRIMARY]: {
    id: 'maintainDetailsTableLocationPrimaryHomebaseTooltip',
    defaultMessage: 'Primary / Homebase',
    description:
      'Article details view, tooltip text for Location Type for sales location assignment, option: Primary and Homebase location',
  },
});

const useCaseMessages = {
  addNewAssignment: defineMessages({
    pendingSuccess: {
      id: 'maintainAssignmentNewLocationPendingSuccess',
      defaultMessage:
        'New location assigned successfully, changes may take some time to appear',
      description: 'Success message displayed when connected to MHS',
    },
    success: {
      id: 'maintainAssignmentNewLocationSuccess',
      defaultMessage: 'New location assigned successfully',
      description: 'Success message displayed when connected to Cloud',
    },
    error: {
      id: 'maintainAssignmentNewLocationError',
      defaultMessage: 'Failed to assign new location',
    },
  }),
  editAssignment: defineMessages({
    pendingSuccess: {
      id: 'maintainAssignmentEditLocationPendingSuccess',
      defaultMessage:
        'Location changed successfully, changes may take some time to appear',
      description: 'Success message displayed when connected to MHS',
    },
    success: {
      id: 'maintainAssignmentEditLocationSuccess',
      defaultMessage: 'Location changed successfully',
      description: 'Success message displayed when connected to Cloud',
    },
    error: {
      id: 'maintainAssignmentEditLocationError',
      defaultMessage: 'Failed to edit location',
    },
  }),
  unassignLocation: defineMessages({
    pendingSuccess: {
      id: 'maintainAssignmentRemoveLocationPendingSuccess',
      defaultMessage:
        'Location unassigned successfully, changes may take some time to appear',
    },
    success: {
      id: 'maintainAssignmentRemoveLocationSuccess',
      defaultMessage: 'Location unassigned successfully',
    },
    error: {
      id: 'maintainAssignmentRemoveLocationError',
      defaultMessage: 'Failed to unassign location',
    },
  }),
  startRecalculation: defineMessages({
    success: {
      id: 'maintainStartRecalculationSuccess',
      defaultMessage:
        'Recalculation started, changes may take some time to appear',
    },
    error: {
      id: 'maintainStartRecalculationError',
      defaultMessage: 'Failed to start recalculation',
    },
  }),
  changeFlexibilityPercentage: defineMessages({
    error: {
      id: 'maintainChangeFlexibilityPercentage',
      defaultMessage: 'Failed to change flexibility percentage',
    },
  }),
  changeUseCommercialQuantity: defineMessages({
    error: {
      id: 'maintainChangeUseCommercialQuantity',
      defaultMessage: 'Failed to change commercial quantity',
    },
  }),
  markNotificationHandled: defineMessages({
    header: {
      id: 'maintainMarkNotificationHandledHeader',
      defaultMessage: 'Notification: {slid}',
      description:
        'Header shown on O11 Article details, where {slid} is the Sales Location.',
    },
    action: {
      id: 'maintainMarkNotificationHandledAction',
      defaultMessage: 'Mark as handled',
    },
    title: {
      id: 'maintainMarkNotificationHandledTitle',
      defaultMessage: 'Notification for {slid}',
      description:
        'Title shown in Notification confirmation popup, where {slid} is the Sales Location.',
    },
    body: {
      id: 'maintainMarkNotificationHandledBody',
      defaultMessage: 'Mark this Notification as handled?',
    },

    success: {
      id: 'maintainMarkNotificationSuccess',
      defaultMessage:
        'Marked Notification for {slid} as handled, changes may take some time to appear',
      description:
        'Success message shown when marking a Notifcation as handled, where {slid} is the Sales Location.',
    },
    error: {
      id: 'maintainMarkNotificationError',
      defaultMessage: 'Failed to mark Notification for {slid} as handled',
      description:
        'Error message shown when marking a Notification as handled has failed, where {slid} is the Sales Location.',
    },
  }),
};

const maintainMessages = { ...messages, ...{ useCases: useCaseMessages } };
export default maintainMessages;
export {
  searchSortTypesMessages,
  searchFlowTypeOptionMessages,
  searchArticleStatusOptionMessages,
  searchProblemCodeOptionMessages,
  searchLifecycleOptionMessages,
  searchActionFilterOptionMessages,
  searchPicksOptionMessages,
  searchAvailabilityOptionMessages,
  searchSalesSteeringOptionMessages,
  itemDetailsTableLocationShortHandMessages,
  itemDetailsTableLocationTooltipMessages,
  merchandisingMessages,
};
