import { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';

const LAST_SELECTED_BU_CODE = 'slm.lastSelectedBuCode';

/**
 * Obtains the buCode from the route.
 *
 * @return The buCode or `null` if the current route does not contain a buCode. E.g. the main landing page.
 */
export function useRouteBuCode(): string | null {
  const { buCode } = useParams();
  return buCode ?? null;
}

/**
 * Manages buCode selection.
 */
export function useBuCodeSelection() {
  const buCode = useRef<string>(localStorage.getItem(LAST_SELECTED_BU_CODE));

  const saveSelectedBuCode = useCallback((buCode: string) => {
    if (buCode !== null) {
      localStorage.setItem(LAST_SELECTED_BU_CODE, buCode);
    }
  }, []);

  return {
    lastSelectedBuCode: buCode.current,
    saveSelectedBuCode,
  };
}
