import { createApi } from '@reduxjs/toolkit/query/react';
import {
  GetParameterHistoryRequest,
  ParameterHistory,
  ParameterHistoryFromJSON,
} from 'apis/backendApi';
import { apiQuery, apiSerializeQueryArgs } from 'core/rtkQuery';

export const parameterHistoryApi = createApi({
  reducerPath: 'parameterHistoryApi',
  baseQuery: apiQuery(),
  serializeQueryArgs: apiSerializeQueryArgs,

  endpoints: builder => ({
    getParameterHistory: builder.query<
      ParameterHistory[],
      GetParameterHistoryRequest
    >({
      query: ({ filter, filterValue, paramId }) => ({
        url:
          filter && (filterValue !== '' || paramId)
            ? '/parameters/v1/{buCode}/history'
            : null,
        params: {
          filter,
          filterValue,
          paramId,
        },
      }),

      transformResponse: (json: any) => json?.map(ParameterHistoryFromJSON),
    }),
  }),
});

export const { useGetParameterHistoryQuery } = parameterHistoryApi;

export default parameterHistoryApi;
