/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const PriceLevelEnum = {
    QUALITY_NAME_UNSPECIFIED: 'unspecified',
    QUALITY_NAME_BACK_OFF_PRICE: 'backOffPrice',
    QUALITY_NAME_BREATH_TAKING_ITEM: 'breathTakingItem',
    QUALITY_NAME_HIGH: 'high',
    QUALITY_NAME_KNOCK_OUT_PRICE: 'knockOutPrice',
    QUALITY_NAME_LOW: 'low',
    QUALITY_NAME_MEDIUM: 'medium'
} as const;
export type PriceLevelEnum = typeof PriceLevelEnum[keyof typeof PriceLevelEnum];


export function instanceOfPriceLevelEnum(value: any): boolean {
    for (const key in PriceLevelEnum) {
        if (Object.prototype.hasOwnProperty.call(PriceLevelEnum, key)) {
            if (PriceLevelEnum[key as keyof typeof PriceLevelEnum] === value) {
                return true;
            }
        }
    }
    return false;
}

export function PriceLevelEnumFromJSON(json: any): PriceLevelEnum {
    return PriceLevelEnumFromJSONTyped(json, false);
}

export function PriceLevelEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceLevelEnum {
    return json as PriceLevelEnum;
}

export function PriceLevelEnumToJSON(value?: PriceLevelEnum | null): any {
    return value as any;
}

