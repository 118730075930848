/* tslint:disable */
/* eslint-disable */
/**
 * SLM API used by SLM frontend
 * This is the specification of the backend APIs. The main purpose of this specification is to unify the API between SLM-backend and SLM-frontend. For localizations the Accept-Language header is used.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dl.mhs.bd3.se@ingka.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RangeGroupDto
 */
export interface RangeGroupDto {
    /**
     * Range group id
     * @type {string}
     * @memberof RangeGroupDto
     */
    id?: string;
    /**
     * Description of the range group
     * @type {string}
     * @memberof RangeGroupDto
     */
    description?: string;
    /**
     * Range group type C or L
     * @type {string}
     * @memberof RangeGroupDto
     */
    type?: string;
    /**
     * Speciality shop to which this Range Group is assigned
     * @type {string}
     * @memberof RangeGroupDto
     */
    specialityShopId?: string;
    /**
     * Flag that says if a range group is visible or invisible
     * @type {boolean}
     * @memberof RangeGroupDto
     */
    hidden?: boolean;
    /**
     * Flag that says if a range group can be hidden
     * @type {boolean}
     * @memberof RangeGroupDto
     */
    canHide?: boolean;
    /**
     * Flag that says if a range group can be deleted
     * @type {boolean}
     * @memberof RangeGroupDto
     */
    canDelete?: boolean;
}

/**
 * Check if a given object implements the RangeGroupDto interface.
 */
export function instanceOfRangeGroupDto(value: object): value is RangeGroupDto {
    return true;
}

export function RangeGroupDtoFromJSON(json: any): RangeGroupDto {
    return RangeGroupDtoFromJSONTyped(json, false);
}

export function RangeGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RangeGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'description': json['description'] == null ? undefined : json['description'],
        'type': json['type'] == null ? undefined : json['type'],
        'specialityShopId': json['specialityShopId'] == null ? undefined : json['specialityShopId'],
        'hidden': json['hidden'] == null ? undefined : json['hidden'],
        'canHide': json['canHide'] == null ? undefined : json['canHide'],
        'canDelete': json['canDelete'] == null ? undefined : json['canDelete'],
    };
}

export function RangeGroupDtoToJSON(value?: RangeGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'description': value['description'],
        'type': value['type'],
        'specialityShopId': value['specialityShopId'],
        'hidden': value['hidden'],
        'canHide': value['canHide'],
        'canDelete': value['canDelete'],
    };
}

