import React, { useMemo } from 'react';
import { CommonTableProps } from 'views/Maintain/types';
import AttributesTable from 'views/Maintain/components/Result/AttributesTable';
import { useIntl } from 'react-intl';
import { SearchResultItemDto } from 'apis/backendApi';
import { spaceNeedMessages } from 'views/Maintain/tableMessages';

const SpaceNeedTable: React.FC<CommonTableProps> = ({ data, summary }) => {
  const { $t } = useIntl();
  const columns = useMemo(
    () => [
      {
        accessorFn: (data: SearchResultItemDto) => data.spaceNeed.backpanelNeed,
        id: 'spaceNeed_backpanelNeed',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.backpanelNeed),
            tooltip: $t(spaceNeedMessages.backpanelNeedTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.spaceNeed.backpanelUtilization,
        id: 'spaceNeed_backpanelUtilization',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.backpanelUtilization),
            tooltip: $t(spaceNeedMessages.backpanelUtilizationTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.spaceNeed.halfPalletNeed,
        id: 'spaceNeed_halfPalletNeed',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.halfPalletNeed),
            tooltip: $t(spaceNeedMessages.halfPalletNeedTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.spaceNeed.halfPalletUtilization,
        id: 'spaceNeed_halfPalletUtilization',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.halfPalletUtilization),
            tooltip: $t(spaceNeedMessages.halfPalletUtilizationTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.spaceNeed.fullPalletNeed,
        id: 'spaceNeed_fullPalletNeed',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.fullPalletNeed),
            tooltip: $t(spaceNeedMessages.fullPalletNeedTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.spaceNeed.fullPalletUtilization,
        id: 'spaceNeed_fullPalletUtilization',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.fullPalletUtilization),
            tooltip: $t(spaceNeedMessages.fullPalletUtilizationTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.spaceNeed.longPalletNeed,
        id: 'spaceNeed_longPalletNeed',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.longPalletNeed),
            tooltip: $t(spaceNeedMessages.longPalletNeedTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.spaceNeed.longPalletUtilization,
        id: 'spaceNeed_longPalletUtilization',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.longPalletUtilization),
            tooltip: $t(spaceNeedMessages.longPalletUtilizationTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.spaceNeed.handstackLocationNeed,
        id: 'spaceNeed_handstackLocationNeed',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.handstackLocationNeed),
            tooltip: $t(spaceNeedMessages.handstackLocationNeedTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.spaceNeed.handstackLocationUtilization,
        id: 'spaceNeed_handstackLocationUtilization',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.handstackLocationUtilization),
            tooltip: $t(spaceNeedMessages.handstackLocationUtilizationTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) => data.spaceNeed.spaceNeed,
        id: 'spaceNeed_spaceNeed',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.spaceNeed),
            tooltip: $t(spaceNeedMessages.spaceNeedTooltip),
          },
        },
      },
      {
        accessorFn: (data: SearchResultItemDto) =>
          data.spaceNeed.spaceUtilization,
        id: 'spaceNeed_spaceUtilization',
        meta: {
          headerMessages: {
            text: $t(spaceNeedMessages.spaceUtilization),
            tooltip: $t(spaceNeedMessages.spaceUtilizationTooltip),
          },
        },
      },
    ],
    [$t]
  );
  return <AttributesTable data={data} summary={summary} columns={columns} />;
};

export default SpaceNeedTable;
