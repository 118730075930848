import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '@ingka/button';
import copy from '@ingka/ssr-icon/paths/copy';
import checkmark from '@ingka/ssr-icon/paths/checkmark';

type CopyToClipboardButtonProps = {
  data: string;
  label?: string;
};

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  data,
  label = 'Copy to clipboard',
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const onClick = useCallback(async () => {
    await navigator.clipboard.writeText(data);
    setIsCopied(true);
  }, [data]);

  const intervalId = useRef(null);
  useEffect(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
    if (isCopied) {
      intervalId.current = setInterval(() => {
        setIsCopied(false);
      }, 1500);
    }
  }, [isCopied]);

  return (
    <Button small onClick={onClick} ssrIcon={isCopied ? checkmark : copy}>
      {label}
    </Button>
  );
};

export default CopyToClipboardButton;
