import React from 'react';
import { useIntl } from 'react-intl';
import im from 'views/Import/importMessages';

const Complete: React.FC = () => {
  const { $t } = useIntl();

  return (
    <>
      <h2>{$t(im.completeTitle)}</h2>
      <p>{$t(im.completeMessage)}</p>
    </>
  );
};

export default Complete;
